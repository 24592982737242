import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import DeleteModal from '../../../Components/Common/DeleteModal'
//import Components
import DisableModal from '../../../Components/Common/DisableModal';
import { useDispatch, useSelector } from 'react-redux';
import { businessDetailSuccess, showToast } from '../../../store/actions';
import TableContainer from '../../../Components/Common/TableContainer';
import useWindowDimension from '../../../Components/Hooks/useWindowDimension';
import { listAllTariffs, detailViewTariff, deleteTariff, LocManBus } from '../../../store/actions';
import { getUserRole } from '../../../helpers/helper.service';

const Tariff = () => {
    document.title = "Tariff | Vidhyutam";
    const history = useHistory();
    const dispatch = useDispatch();
    const dimensions = useWindowDimension();
    const [deleteModal, setDeleteModal] = useState(false);
    const [disabledModal, setDisabledModal] = useState(false);
    const [enabledModal, setEnabledModal] = useState(false);
    const [busssinessData, setBusinessData] = useState('');
    const [tariffModal, setTariffModal] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [tableTaskBar, setTableTaskBar] = useState(0);
    const [paginationDiv, setPaginationDiv] = useState(0);
    const [cardHeader, setCardHeaderHeight] = useState(0);
    const [tariffIDrow, setTariffIDrow] = useState('');
    const [businessList, setBusinessList] = useState([
        {
            _id: "1",
            name: "Tariff 01",
            ReservationCharge: '₹ 15.00',
            Cancellationcharge: '₹ 15.00',
            // Assignedto: '₹ 15.00',
            checked: true
        },
        {
            _id: "2",
            name: "Tariff 02",
            ReservationCharge: '₹ 15.00',
            Cancellationcharge: '₹ 15.00',
            // Assignedto: '₹ 15.00',
            checked: true
        },
        {
            _id: "3",
            name: "Tariff 03",
            ReservationCharge: '₹ 15.00',
            Cancellationcharge: '₹ 15.00',
            // Assignedto: '₹ 15.00',
            checked: true
        },
        {
            _id: "4",
            name: "Tariff 04",
            ReservationCharge: '₹ 15.00',
            Cancellationcharge: '₹ 15.00',
            // Assignedto: '₹ 15.00',
            checked: true
        },
        {
            _id: "5",
            name: "Tariff 05",
            ReservationCharge: '₹ 15.00',
            Cancellationcharge: '₹ 15.00',
            // Assignedto: '₹ 15.00',
            checked: true
        }

    ]);
    const [filteredTable, setfilteredTable] = useState([]);

    // if (getStationConnector[0]?.tariff !== null) {
    //     const currentTariff = allTariffList.filter(e => e?.tariffId === getStationConnector[0]?.tariff).map(e => e.tariffName);
    //     setSelectedGroup2({ id: `${getStationConnector[0]?.tariff}`, value: currentTariff });
    // }
    useEffect(() => {
        // if (getUserRole() !== 'LM') {
        if (window.localStorage.getItem('users') !== null && window.localStorage.getItem('users') !== undefined && JSON.parse(window.localStorage.getItem('users')).userRole !== 'LocationManager') {

            dispatch(listAllTariffs('?businessId='));
        }
    }, [])

    useEffect(() => {
        if (getUserRole() === 'LM') {

            const userID = JSON.parse(window.sessionStorage.getItem('users'))?.userId;
            userID && dispatch(LocManBus(userID));
        }
    }, []);

    const { allTariffList, tariffDetails, BusinessDetail } = useSelector(state => {

        return ({
            allTariffList: state?.ManageTariffs?.listAllTariff,
            tariffDetails: state?.ManageTariffs?.DetailTariff[0],
            BusinessDetail: state?.locationsList?.LocManBussDetail[0],
        })
    });



    // call business details api to get business id on opening link in new tab
    // mandatory to make listing tariff work
    useEffect(() => {
        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null && JSON.parse(window.localStorage.getItem('users')).userRole == 'LocationManager') {

            let userID;
            if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
                userID = JSON.parse(window.localStorage.getItem('users'))?.userId;
            } else if (window.sessionStorage.getItem('users') !== undefined && window.sessionStorage.getItem('users') !== null) {
                userID = JSON.parse(window.sessionStorage.getItem('users'))?.userId;
            }


            userID && dispatch(LocManBus(userID));
        }
    }, [])


    // using business id and calling tariff listing api
    useEffect(() => {
        // if (getUserRole() === 'LM' && BusinessDetail?.businessId) {

        if (window.localStorage.getItem('users') !== null && window.localStorage.getItem('users') !== undefined && JSON.parse(window.localStorage.getItem('users')).userRole == 'LocationManager' && BusinessDetail?.businessId !== undefined) {

            dispatch(listAllTariffs(`?businessId=${BusinessDetail?.businessId}`));
        }
    }, [BusinessDetail]);

    useEffect(() => {
        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
    }, []);
    const handleResize = () => {

        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);

    const handleDeleteContact = () => {

        dispatch(deleteTariff(tariffIDrow));
        setTimeout(() => {
            if (getUserRole() === 'LM' && BusinessDetail?.businessId) {
                dispatch(listAllTariffs(`?businessId=${BusinessDetail?.businessId}`));
            } else {
                dispatch(listAllTariffs('?businessId='));

            }
        }, 1000)
        // dispatch(showToast({
        //     message: 'tariff has been delete successfully.',
        //     type: 'success'
        // }));
        setDeleteModal(false);
    };


    const handleEnableContact = () => {
        toggleData();
        dispatch(showToast({
            message: 'tariff has been enabled successfully.',
            type: 'success'
        }));
        setEnabledModal(false);
    };
    const handleDisableContact = () => {
        toggleData();
        dispatch(showToast({
            message: 'tariff has been disabled successfully.',
            type: 'success'
        }));
        setDisabledModal(false);
    };

    const toggleData = () => {
        const temp = [...businessList]
        temp.map((e, index) => {
            if (e._id === busssinessData._id) {
                temp[index].checked = !busssinessData.checked
            }
            return null

        })
        setBusinessList([...temp])
    };
    const handleViewDetailClick = (row) => {

        // history.push('l');
    }

    const handleAddButtonClicks = () => {
        history.push('/tariff/addtariff', {
            state: {
                businessID: BusinessDetail?.businessId
            }
        });
    }

    const assignData = [
        {
            _id: '1',
            connector: 'C1',
            station: 'Station 01',
            location: 'Location 01'

        },
        {
            _id: '1',
            connector: 'C1',
            station: 'Station 01',
            location: 'Location 01'

        },
    ]
    const modalColumn = useMemo(
        () => [
            {
                Header: "Connector",
                accessor: "connector",
                filterable: true,
                Cell: (cell) => {
                    return <p className='text'>{cell.value}</p>;
                },
            },
            {
                Header: "Station",
                accessor: "station",
                filterable: true,
                Cell: (cell) => {
                    return <p className='text'>{cell.value}</p>;
                },
            },
            {
                Header: "Location",
                accessor: "location",
                filterable: true,
                Cell: (cell) => {
                    return <p className='text'>{cell.value}</p>;
                },
            }
        ],
        []
    );

    const handleNameSort = React.useMemo(() => {
        return (rowA, rowB, columnId, desc) => {

            return rowA.values.tariffName.toLowerCase() > rowB.values.tariffName.toLowerCase() ? 1 : -1;
        }
    }, [])

    const columns1 = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "tariffName",
                sortType: handleNameSort,
                filterable: true,
                Cell: (cell) => {
                    return <p className='text wordBreakAll'>{cell.value}</p>;
                },
            },
            {
                Header: "Reservation Charge",
                accessor: "rCharges",
                disableGlobalFilter: true,
                filterable: true,
                Cell: (cell) => {
                    return <p className='text wordBreakAll'>{cell.value}</p>;
                },
            },
            {
                // Header: "Cancellation charge",
                Header: "Cancellation Charge",
                accessor: "cCharges",
                disableGlobalFilter: true,
                filterable: true,
                Cell: (cell) => {
                    return <p className='text wordBreakAll'>{cell.value}</p>;
                },
            },
            // {
            //     Header: "Price",
            //     accessor: "price",
            //     filterable: true,
            //     Cell: (cell) => {
            //         return <p className='text'>{cell.value}</p>;
            //     },
            // },
            {
                // Header: "Assigned to",
                Header: "Details",
                accessor: "assignedTo",
                filterable: false,
                disableSortBy: true,
                Cell: (item) => {
                    return <div className='viewBtn' onClick={(e) => {
                        e.stopPropagation();
                    }}>
                        <Button disabled={item.row.original.checked === false ? true : false} onClick={(event) => {
                            event.stopPropagation()

                            dispatch(detailViewTariff(item.row.original?.tariffId))
                            setTariffIDrow(item.row.original?.tariffId);
                            setTariffModal(true);
                        }}>View</Button>
                    </div>
                },
            },

            // {
            //     Header: "Action",
            //     Cell: (item) => {
            //         return (
            //             <ul className="list-inline hstack gap-2 mb-0" onClick={(e) => { e.stopPropagation() }}>
            //                 <li>
            //                     <div className="form-check form-switch">
            //                         <Input
            //                             onClick={(e) => {
            //                                 e.stopPropagation();
            //                             }}
            //                             className="form-check-input"
            //                             type="checkbox"
            //                             role="switch"
            //                             id={item.row.original._id}
            //                             checked={item.row.original.checked}
            //                             onChange={() => {
            //                                 
            //                                 if (item.row.original.checked === true) {
            //                                     setDisabledModal(true);
            //                                 } else {
            //                                     setEnabledModal(true);
            //                                 }
            //                                 setBusinessData(item.row.original);
            //                             }
            //                             }


            //                         />
            //                     </div>
            //                 </li>
            //                 <li className="list-inline-item edit">
            //                     <Button
            //                         disabled={item.row.original.checked === false ? true : false}
            //                         className="text-primary d-inline-block edit-item-btn"
            //                         onClick={(e) => {
            //                             e.stopPropagation();
            //                             history.push('/businesses/editbusiness');
            //                         }}
            //                     >
            //                         <i className="bx bx-edit-alt editBtn"></i>
            //                     </Button>
            //                 </li>
            //                 <li className="list-inline-item">
            //                     <Button
            //                         // disabled={item.row.original.checked === false ? true : false}
            //                         className="text-danger d-inline-block remove-item-btn"
            //                         onClick={(e) => {
            //                             e.stopPropagation();
            //                             setDeleteModal(true);
            //                         }}
            //                     >
            //                         <i className="bx bx-trash deleteBtn "></i>
            //                     </Button>
            //                 </li>
            //             </ul>
            //         );
            //     },
            // },
        ],
        [history, busssinessData.checked]
    );
    const [variablePriceData, setvariablePriceData] = useState([
        {
            id: "1",
            Timing: "10:30 to 01:30",
            PriceperkW: "₹ 15.00",
            PriceperMinute: "₹ 15.00",
            dayCheck: [
                {
                    id: '1',
                    dayCheck: false
                },
                {
                    id: '2',
                    dayCheck: false
                },
                {
                    id: '3',
                    dayCheck: false
                },
                {
                    id: '4',
                    dayCheck: false
                },
                {
                    id: '5',
                    dayCheck: false
                },
                {
                    id: '6',
                    dayCheck: false
                },
                {
                    id: '7',
                    dayCheck: false
                }
            ]
        },

    ]);
    const dayCheckFunction = (e, i, index) => {
        setvariablePriceData(prev => {
            const WeekSedual = prev[index].dayCheck.map((x, ind) => {
                if (ind === i) {
                    return {
                        ...x,
                        dayCheck: !x.dayCheck
                    }
                } else {
                    return x;
                }
            })
            const CheckData = { ...prev[index], dayCheck: WeekSedual }
            let newstate = [
                ...prev
            ];
            newstate.splice(index, 1, CheckData)

            return newstate;
        })
    }
    return (
        <React.Fragment>
            <div className="page-content tariffMain">
                <Container fluid>
                    <div className='tariffPage'>
                        <Row>
                            <Col lg={12}>
                                <Card className='mb-0'>
                                    <CardHeader className='cardHeader'>
                                        <p className='headerTxt'>Tariff</p>
                                    </CardHeader>
                                    <CardBody>
                                        <div className={filteredTable.length > 0 && allTariffList.length > 0 ? 'table-content' : 'table-content disablePointer'}>
                                            <TableContainer
                                                columns={columns1}
                                                data={allTariffList}
                                                isGlobalFilter={true}
                                                customPageSize={16}
                                                maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - 96}
                                                handleViewDetailClick={(row) => handleViewDetailClick(row)}
                                                divClass="table-responsive mb-1 bussinessTable"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light text-muted"
                                                isAddButton={true}
                                                setfilteredTable={setfilteredTable}
                                                searchPlaceholder='Search Tariff Name'
                                                handleAddButtonClicks={handleAddButtonClicks}
                                                addButtonTitle='Add Tariff'
                                                noData={allTariffList?.length > 0 ? false : true}
                                                // noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1672821304/Lubi/WebApp/Static-images/no_tariff.svg"}
                                                noDataImage={"https://res.cloudinary.com/https-www-nimblechapps-com/image/upload/v1688013510/Lubi/WebApp/Static-images/no_tariff.svg"}
                                                noDataTitle={'No Tariff Found!'}
                                            />
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Modal className='tariffModal' isOpen={tariffModal} toggle={() => {
                setTariffModal(false);
            }}
                centered={true}>
                <ModalHeader>
                    <p>View Tariff</p>
                    <i className='bx bx-x' onClick={() => {
                        setTariffModal(false);
                    }} />
                </ModalHeader>
                <ModalBody className="">
                    <div className='main'>
                        <div className='model-field-wrapper'>
                            <ul>
                                <li>
                                    <p className='model-data-title'>
                                        Tariff Name
                                    </p>
                                    <span className='model-data-small-title'>
                                        {tariffDetails && tariffDetails?.tariffName}
                                    </span>
                                </li>
                                <li>
                                    <p className='model-data-title'>
                                        Price per kW
                                    </p>
                                    <span className='model-data-small-title'>
                                        {tariffDetails && `₹ ${tariffDetails?.KWPrice}`}
                                    </span>
                                </li>
                                <li>
                                    <p className='model-data-title'>
                                        Price per Minute
                                    </p>
                                    <span className='model-data-small-title'>
                                        {tariffDetails && `₹ ${tariffDetails?.minutePrice}`}
                                    </span>
                                </li>
                                <li>
                                    <p className='model-data-title'>
                                        Reservation Charge
                                    </p>
                                    <span className='model-data-small-title'>
                                        {tariffDetails && `₹ ${tariffDetails?.rCharges}`}
                                    </span>
                                </li>
                                <li>
                                    <p className='model-data-title'>
                                        Cancellation Charge
                                    </p>
                                    <span className='model-data-small-title'>
                                        {tariffDetails && `₹ ${tariffDetails?.cCharges}`}
                                    </span>
                                </li>
                                <li className='btn-group'>
                                    <span className='edit-icon' onClick={() => {
                                        setTariffModal(false);
                                        history.push('/tariff/edittariff', {
                                            state: {
                                                tariffID: tariffIDrow,
                                                type: 'Edit'
                                            }
                                        });
                                    }}>
                                        <i className='ri ri-pencil-line' />
                                    </span>
                                    <span className='Delete-icon' onClick={() => {
                                        setTariffModal(false);
                                        setDeleteModal(true);
                                    }}>
                                        <i className='ri ri-delete-bin-7-line' />
                                    </span>
                                </li>

                            </ul>

                        </div>
                        {/* <div className="Bottom-Table">
                            <div className="Table-header">
                                <ul className="Table-row">
                                    <li className="Table-name">
                                        <Label className="form-label">
                                            Timing
                                        </Label>
                                    </li>
                                    <li className="Table-name">
                                        <Label className="form-label ">
                                            Price per kW
                                        </Label>
                                    </li>
                                    <li className="Table-name">
                                        <Label className="form-label">
                                            Price per Minute
                                        </Label>
                                    </li>
                                    <li className="Table-name last-table">
                                        <ul className="Date-Table-name">
                                            <li className="Table-name">
                                                <Label className="form-label">Mon</Label>
                                            </li>
                                            <li className="Table-name">
                                                <Label className="form-label ">Tue</Label>
                                            </li>
                                            <li className="Table-name">
                                                <Label className="form-label ">Wed</Label>
                                            </li>
                                            <li className="Table-name">
                                                <Label className="form-label ">Thu</Label>
                                            </li>
                                            <li className="Table-name">
                                                <Label className="form-label ">Fri</Label>
                                            </li>
                                            <li className="Table-name">
                                                <Label className="form-label ">Sat</Label>
                                            </li>
                                            <li className="Table-name">
                                                <Label className="form-label ">Sun</Label>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                {variablePriceData?.map((item, index) => {
                                    return (
                                        <ul className="Table-Body" key={index}>
                                            <li className="Table-name">
                                                <div className='timerDiv'>
                                                    {item.Timing}
                                                </div>
                                            </li>
                                            <li className="Table-name">
                                                <div className='timerDiv'>
                                                    {item.PriceperkW}
                                                </div>
                                            </li>
                                            <li className="Table-name">
                                                <div className='timerDiv'>
                                                    {item.PriceperMinute}
                                                </div>
                                            </li>
                                            <li className="Table-name last-table">
                                                <ul className="Date-Table-name">
                                                    {item?.dayCheck?.map((value, i) => {
                                                        return (
                                                            <li className="Table-name" key={i}>
                                                                <div className='form-check form-check-outline form-check-secondary'>
                                                                    <Input
                                                                        className='form-check-input'
                                                                        type="checkbox"
                                                                        id={value.id}
                                                                        name='checkDay'
                                                                        checked={value.dayCheck}
                                                                        onChange={(e) => {
                                                                            dayCheckFunction(e, i, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </li>
                                                        );
                                                    })}

                                                </ul>
                                            </li>
                                        </ul>
                                    );
                                })}
                            </div>
                        </div> */}
                    </div>
                </ModalBody>
            </Modal>
            <DeleteModal
                show={deleteModal}
                title='Are you sure?'
                description='Are you sure you want to delete this record?'
                onDeleteClick={handleDeleteContact}
                onCloseClick={() => setDeleteModal(false)}
            />
            <DisableModal
                show={disabledModal}
                title='Are you sure?'
                subtitle='Are you sure you want to disable this business?'
                btnActionText='Yes, Disable it!'
                btncancelText='Cancel'
                onActionClick={handleDisableContact}
                onCloseClick={() => setDisabledModal(false)}
            />
            <DisableModal
                show={enabledModal}
                title='Are you sure?'
                subtitle='Are you sure you want to enable this business?'
                btnActionText='Yes, enable it!'
                btncancelText='Cancel'
                onActionClick={handleEnableContact}
                onCloseClick={() => setEnabledModal(false)}
            />
        </React.Fragment>
    );
};

export default Tariff;