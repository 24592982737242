import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import DeleteModal from '../../../Components/Common/DeleteModal'
//import Components
import DisableModal from '../../../Components/Common/DisableModal';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../../store/actions';
import TableContainer from '../../../Components/Common/TableContainer';
import { useFormik } from 'formik';
import Constant from '../../../utils/constant';
import * as Yup from "yup";
import CustomDropdownAvtar from '../../../Components/Common/CustomDropdownAvtar';
import { getConnectorList, connecterIcon, addconnectortype, editconnecter, Detailconnecter, DeletCONNECTOR, toggelCONNECTOR } from '../../../store/actions';
import useWindowDimension from '../../../Components/Hooks/useWindowDimension';
import ContentLoader from '../../../Components/Common/ContentLoader';


const ConnectorType = () => {
    document.title = " Connector Type | Vidhyutam";
    const history = useHistory();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const dimensions = useWindowDimension();
    const [deleteModal, setDeleteModal] = useState(false);
    const [disabledModal, setDisabledModal] = useState(false);
    const [enabledModal, setEnabledModal] = useState(false);
    const [addTypeModal, setAddTypeModal] = useState(false);
    const [editTypeModal, setEditTypeModal] = useState(false);
    const [textLength, setTextLength] = useState(0);
    const [fileImage, setImage] = useState('');
    const [iconType, setTconType] = useState('');
    const [connectorRowData, setConnectorRowData] = useState('');
    const [iconsData, setIconsData] = useState('');
    const [editData, setEditData] = useState([]);
    const [addEditType, setAddEditType] = useState(false);
    const [filteredTable, setfilteredTable] = useState([]);


    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [tableTaskBar, setTableTaskBar] = useState(0);
    const [paginationDiv, setPaginationDiv] = useState(0);
    const [breadCrumb, setBreadCrumbHeight] = useState(0);
    const [cardHeader, setCardHeaderHeight] = useState(0);
    const [customLoader, setCustomLoader] = useState(false)
    useEffect(() => {
        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        // setBreadCrumbHeight(
        //     document.getElementsByClassName('breadCrumb')[0]?.offsetHeight
        // );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
    }, []);
    const handleResize = () => {

        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        // setBreadCrumbHeight(
        //     document.getElementsByClassName('breadCrumb')[0]?.offsetHeight
        // );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);
    const [connectorTypeListing, setConnectorTypeListing] = useState([
        {
            _id: "1",
            idNumber: "01",
            connectorType: 'CCS 1',
            description: 'CCS1, Type 1 Combo',
            checked: true
        },
        {
            _id: "2",
            idNumber: "02",
            connectorType: 'CCS 2',
            description: 'CCS1, Type 2 Combo',
            checked: true
        },
        {
            _id: "3",
            idNumber: "03",
            connectorType: 'CHAdeMO',
            description: 'CHAdeMO, DC',
            checked: true
        },
        {
            _id: "4",
            idNumber: "04",
            connectorType: 'GB/T DC',
            description: 'GB/T DC',
            checked: true
        },
        {
            _id: "5",
            idNumber: "05",
            connectorType: 'IEC 62196 (Type 2)',
            description: 'IEC 62196 Type 2, Mennekes',
            checked: true
        },
    ]);

    useEffect(() => {
        dispatch(getConnectorList(false));
        dispatch(connecterIcon());

    }, [])

    const { connectorList, connectorIcon, detailConnector } = useSelector(state => ({
        connectorList: state?.AssetManagment?.connectorList,
        connectorIcon: state?.AssetManagment?.connectorsIcon,
        detailConnector: state?.AssetManagment?.detailConnectorTypes[0]

    }));

    useEffect(() => {
        if (connectorIcon.length > 0) {
            const IconconnectorTypeItems = connectorIcon.map((e, i) => {
                return {
                    id: `${e?.iconId}`,
                    src: `${process.env.REACT_APP_CLOUDINARY_URL + e.iconUrl}`
                }
            })
            setIconsData(IconconnectorTypeItems);
        }
    }, [connectorIcon])

    const handleDeleteContact = () => {
        const params = {
            connectorType: connectorRowData?.connectorType
        }
        dispatch(DeletCONNECTOR(params, connectorRowData?.typeId))
        setTimeout(() => {
            dispatch(getConnectorList(false));
            setConnectorRowData('');
        }, 1000)
        setDeleteModal(false);
    };
    const handleDisableContact = () => {
        const params = {
            connectorType: connectorRowData?.connectorType,
            status: "Inactive"
        }
        dispatch(toggelCONNECTOR(params, connectorRowData?.typeId))
        setDisabledModal(false);
        setTimeout(() => {
            dispatch(getConnectorList(false));
            setConnectorRowData('');
        }, 1000)
    };

    const handleEnableContact = () => {
        const params = {
            connectorType: connectorRowData?.connectorType,
            status: "Active"
        }
        dispatch(toggelCONNECTOR(params, connectorRowData?.typeId))
        setEnabledModal(false);
        setTimeout(() => {
            dispatch(getConnectorList(false));
            setConnectorRowData('');
        }, 1000)
    };

    const handleAddButtonClicks = () => {
        // history.push('');
        setTconType('')
        setAddTypeModal(true);
        setAddEditType(true);
        setEditTypeModal(false);
    }

    // set form values for edit
    // const setFormikFormvalues = () => {
    //     addTypeForm.setFieldValue('connectorType', editData?.connectorType);
    //     addTypeForm.setFieldValue('discription', editData?.description);
    // }

    

    // Column
    const connectorColumn = useMemo(
        () => [
            {
                Header: "Connector ID",
                accessor: d => `${d.prefix}${d.typeId}`,
                filterable: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Connector Type",
                accessor: "connectorType",
                filterable: true,
                disableSortBy: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Description",
                accessor: "description",
                disableGlobalFilter: true,
                filterable: true,
                disableSortBy: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Action",
                disableGlobalFilter: true,
                disableSortBy: true,
                Cell: (item) => {
                    
                    return (
                        <ul className="list-inline hstack gap-2 mb-0" onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            <li>
                                <div className="form-check form-switch">
                                    <Input
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id={item.row.original._id}
                                        checked={item.row.original.status === 'Active' ? true : false}
                                        onChange={() => {
                                            if (item.row.original.status === 'Active') {
                                                setDisabledModal(true);
                                            } else {
                                                setEnabledModal(true);
                                            }
                                            setConnectorRowData(item.row.original);
                                        }
                                        }


                                    />
                                </div>
                            </li>
                            <li className="list-inline-item edit">
                                <Button
                                    disabled={item.row.original.checked === false ? true : false}
                                    className="text-primary d-inline-block edit-item-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(Detailconnecter(item.row.original?.typeId));
                                        setEditData(item.row.original);
                                        setEditTypeModal(true);
                                        setAddTypeModal(true);
                                        setAddEditType(false);
                                        // setFormikFormvalues()

                                    }}
                                >
                                    <i className="bx bx-edit-alt editBtn"></i>
                                </Button>
                            </li>
                            <li className="list-inline-item">
                                <Button
                                    // disabled={item.row.original.checked === false ? true : false}
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDeleteModal(true);
                                        setConnectorRowData(item.row.original);
                                    }}
                                >
                                    <i className="bx bx-trash deleteBtn "></i>
                                </Button>
                            </li>
                        </ul>
                    );
                },
            },
        ],
        [history]
    );
    // const toggleData = () => {
    //     const temp = [...connectorTypeListing]
    //     temp.map((e, index) => {
    //         if (e._id === connectorListingData._id) {
    //             temp[index].checked = !connectorListingData.checked
    //         }

    //     })
    //     setConnectorTypeListing([...temp])
    // };

    let dataOBJ = {}
    useEffect(() => {
        
        if ((!addEditType) && editData.connectorType) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            dataOBJ = {
                connectorType: '',
                discription: '',
            }
            // addTypeForm.setFieldValue('connectorType', editData?.connectorType, { shouldValidate: true });
            // addTypeForm.setFieldValue('discription', editData?.description, { shouldValidate: true });

            if (editData?.connectorType) {
                setTconType({ id: `${detailConnector?.iconId} `, src: `${editData.connectorIcon} ` });
            }
        } else {
            dataOBJ = {
                connectorType: '',
                discription: '',
            }
            addTypeForm.setFieldValue('connectorType', '', { shouldValidate: true });
            addTypeForm.setFieldValue('discription', '', { shouldValidate: true });
        }
    }, [editData, addEditType]);

    useEffect(() => {
        if (detailConnector?.connectorIcon) {
            setTconType({ id: `${detailConnector?.connectorIcon}` });
        }
    }, [detailConnector]);

    



    const addTypeForm = useFormik({
        enableReinitialize: true,
        // initialValues: dataOBJ,
        initialValues: {
            connectorType: editData?.connectorType || '',
            discription: editData?.description || '',
            connectorIcon: editData?.connectorIcon?.toString() || ''
        },
        validationSchema: Yup.object(
            {
                connectorType: Yup.string()
                    .required('Connector type is required')
                    .matches(Constant.REGEX.NOSPECIALCHARACTERS, 'Alphanumeric character only'),
                discription: Yup.string(),
                connectorIcon: Yup.string().required('Please select a connector icon')
            }
        ),
        onSubmit: (values) => {
            setAddTypeModal(false);
            setEditTypeModal(false);
            if (addEditType) {
                const params = {
                    connectorType: values.connectorType,
                    description: values.discription,
                    connectorIcon: +(iconType.id)
                }
                dispatch(addconnectortype(params));
            }
            // dispatch(editconnecter(connectreTypeId, params))
            onSuccess();

            if (!addEditType) {
                const params1 = {
                    connectorType: values.connectorType,
                    description: values.discription,
                    connectorIcon: +(iconType.id)
                }
                dispatch(editconnecter(params1, detailConnector?.typeId))
            }


        }
    });

    useEffect(() => {
        addTypeForm.validateForm()
    }, [])


    const handleFileUpload = event => {
        setImage(URL.createObjectURL(event.target.files[0]))
    };
    const onSuccess = (values) => {


        setTimeout(() => {
            dispatch(getConnectorList(false));
            addTypeForm.resetForm();
        }, 2000);


    }
    // const onEdit = () => {
    //     setEditTypeModal(true);
    //     setAddTypeModal(true);
    // }

    // const IconconnectorTypeItems = [
    //     {
    //         id: '1',
    //         src: 'https://res.cloudinary.com/djyl1goby/image/upload/v1671007170/Lubi/WebApp/Static-images/ConnectT_3.svg'
    //     },
    //     {
    //         id: '2',
    //         src: 'https://res.cloudinary.com/djyl1goby/image/upload/v1671007170/Lubi/WebApp/Static-images/ConnectT_2.svg'
    //     },
    //     {
    //         id: '3',
    //         src: 'https://res.cloudinary.com/djyl1goby/image/upload/v1671007169/Lubi/WebApp/Static-images/ConnectT_1.svg'
    //     },
    // ]
    useEffect(() => {
        setTimeout(() => {
            setCustomLoader(true)
        }, 2000)
    }, [])

    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className='connecterTypePage'>
                        <Row>
                            <Col lg={12}>
                                <Card className='mb-0'>
                                    <CardHeader className='cardHeader'>
                                        <p className='headerTxt'>Connector Type</p>
                                    </CardHeader>
                                    <CardBody>
                                        <div className={filteredTable.length > 0 && connectorList.length > 0 ? 'table-content' : 'table-content disablePointer'}>
                                            {customLoader ? <TableContainer
                                                columns={connectorColumn}
                                                data={connectorList}
                                                isGlobalFilter={true}
                                                setfilteredTable={setfilteredTable}
                                                customPageSize={16}
                                                maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - 105}
                                                // tableHeight=''
                                                divClass="table-responsive mb-1 connectorTable"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light text-muted"
                                                isAddButton={true}
                                                searchPlaceholder='Search Connector ID, Connector Type'
                                                handleAddButtonClicks={handleAddButtonClicks}
                                                addButtonTitle='Add Type'
                                                noData={connectorList.length > 0 ? false : true}
                                                noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1671183689/Lubi/WebApp/Static-images/No_Connectors_found.svg"}
                                                noDataTitle={'No Connector Type Found!'}
                                            />
                                                : <ContentLoader />}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Modal className='addTypeModal' isOpen={addTypeModal} toggle={() => {
                setAddTypeModal(false);
                addTypeForm.resetForm();
            }}
                centered={true}>
                <ModalHeader>
                    <p>{editTypeModal ? "Edit Connector Type" : "Add Connector Type"}</p>
                    <i className='bx bx-x' onClick={() => {
                        setAddTypeModal(false);
                        addTypeForm.resetForm();
                    }} />
                </ModalHeader>
                <ModalBody className="">
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        addTypeForm.handleSubmit();
                        return false;
                    }}
                        action="#" >

                        <div className='main'>
                            <div className='subMian mb-3'>
                                <Label htmlFor="connectorType" className="form-label requierdLabel">Connector Type</Label>
                                <Input
                                    name='connectorType'
                                    type="text"
                                    className="form-control"
                                    id="connectorType"
                                    placeholder="Enter Connector Type"
                                    onChange={(e) => addTypeForm.handleChange('connectorType')(e.target.value.trimStart())}
                                    onKeyUp={addTypeForm.handleBlur}
                                    value={addTypeForm.values.connectorType || ''}
                                    invalid={
                                        addTypeForm.errors.connectorType && addTypeForm.touched.connectorType ? true : false
                                    }
                                />
                                {addTypeForm.errors.connectorType && addTypeForm.touched.connectorType ? (
                                    <FormFeedback type="invalid">{addTypeForm.errors.connectorType}</FormFeedback>
                                ) : null}
                            </div>
                            <div className='subMian mb-1'>
                                <Label htmlFor="discription" className="form-label">Description</Label>
                                <textarea
                                    name='discription'
                                    type="text-area"
                                    className="form-control textArea"
                                    id="discription"
                                    maxLength={350}
                                    placeholder="Write Here.."
                                    onKeyUp={addTypeForm.handleBlur}
                                    onChange={(e) => {
                                        addTypeForm.handleChange('discription')(e.target.value.trimStart());
                                        setTextLength(e.target.value);
                                    }}
                                    value={addTypeForm.values.discription || ''}
                                />
                                <Label htmlFor="discription" className="form-label count">  {textLength.length ? textLength.length : 0} / 350</Label>
                            </div>
                            <div className='subMian mb-3'>
                                <Label htmlFor="formFile"
                                    className="form-label requierdLabel">Connector Icon</Label>
                                <CustomDropdownAvtar
                                    defaultValue={''}
                                    isItemImage={true}
                                    selectedValue={iconType}
                                    // dropDownItems={process.env.REACT_APP_CLOUDINARY_URL + connectorIcon?.iconUrl}
                                    dropDownItems={iconsData}


                                    placeholder={'Select'}
                                    onSelect={(evt) => {
                                        setTconType(evt);
                                        
                                        addTypeForm.handleChange('connectorIcon')(evt.id)
                                    }}
                                />
                            </div>
                            <div className='bottom d-flex justify-content-end mt-1'>
                                <Button
                                    type='button'
                                    className='me-2'
                                    onClick={() => { setAddTypeModal(false); setEditTypeModal(false); addTypeForm.resetForm(); }}>
                                    Cancel
                                </Button>
                                <Button
                                    type='submit' disabled={!(addTypeForm.isValid && addTypeForm.dirty && iconType)}>
                                    {editTypeModal ? "Update" : "Add"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
            <DeleteModal
                show={deleteModal}
                title='Are you sure?'
                description='Are you sure you want to delete this record?'
                onDeleteClick={handleDeleteContact}
                onCloseClick={() => setDeleteModal(false)}
            />
            <DisableModal
                show={disabledModal}
                title='Are you sure?'
                subtitle='Are you sure you want to disable this connector?'
                btnActionText='Yes, Disable it!'
                btncancelText='Cancel'
                onActionClick={handleDisableContact}
                onCloseClick={() => setDisabledModal(false)}
            />
            <DisableModal
                show={enabledModal}
                title='Are you sure?'
                subtitle='Are you sure you want to enable this connector?'
                btnActionText='Yes, enable it!'
                btncancelText='Cancel'
                onActionClick={handleEnableContact}
                onCloseClick={() => setEnabledModal(false)}
            />
        </React.Fragment>
    );
};

export default ConnectorType;