import React, { useEffect, useLayoutEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
} from "reactstrap";
//Import images;

import classnames from "classnames";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import { useHistory, useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import CustomDropdown from "../../../../Components/Common/CustomDropdown";
import { useFormik, yupToFormErrors } from "formik";
import Constant from "../../../../utils/constant";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { allLocation, getStationName, listAllEVSE, detailEvse, DetailEvseConnector, connectorTypes, addStation, checkStationName, detailViewStation, editStations, LocationDetail, LocManBus } from "../../../../store/actions";
import { EMPTY_EVSE_CONNECTOR } from "../../../../store/assetsManagement/actionTypes";
import CustomButton from "../../../../Components/Common/CustomButton";
import { set } from "react-hook-form";
import { getUserRole } from "../../../../helpers/helper.service";

// register lottie and define custom element

const AddStation = (props) => {
    document.title = window.location.href.includes('edit') ? "Edit Station | Vidhyutam" : "Add Station | Vidhyutam";
    const history = useHistory();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [selectedGroup1, setSelectedGroup1] = useState(null);
    const [selectedGroup2, setSelectedGroup2] = useState(true);
    const [selectedGroup3, setSelectedGroup3] = useState(null);
    const [selectedGroup4, setSelectedGroup4] = useState(true);
    const [activeArrowTab, setactiveArrowTab] = useState(4);
    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    const [connectorDataSelect, setConnectorDataSelect] = useState();
    const [formatSelect, setFormatSelect] = useState();
    const [powerTypeSelect, setPowerTypeSelect] = useState();
    const [locationDetails, setLocationDetails] = useState();
    const [getLocId, setGetLocId] = useState(false);
    const [getEVSId, setGetEVSId] = useState(false);
    const [GetModal, setGetModal] = useState([]);
    const [allConnectors, setAllConnectors] = useState([]);
    const [Connenctors, setConnectors] = useState([]);
    const [stationGenName, setStationGenName] = useState('');
    const [stationID, setStationID] = useState('');
    const [stationTYpe, setStationTYpe] = useState('');
    const [staReadOnly, setStaReadOnly] = useState(state?.state?.type === 'EDIT' ? false : true);
    const [businessID, setBusinessID] = useState('');
    const [reserveValue, setReserveValue] = useState(false);









    useEffect(() => {
        dispatch(allLocation('?id='));
        dispatch(listAllEVSE(true));
        dispatch(connectorTypes(true));
        addStationForm.resetForm();
        // dispatch({
        //     type: 'CHECK_STATION',
        //     payload: []
        // });
        return () => {
            dispatch({ type: EMPTY_EVSE_CONNECTOR })
        }
    }, []);


    useEffect(() => {

        if (state?.state?.type === 'EDIT') {
            setStationID(state?.state?.StationID)
            setStationTYpe(state?.state?.type)
            dispatch(detailViewStation(state?.state?.StationID));
        }
        if (state?.state?.type === 'VIEW') {
            dispatch(LocationDetail(state?.state?.id));
        }
        // if (getUserRole() === 'LM') {
        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null && JSON.parse(window.localStorage.getItem('users')).userRole == 'LocationManager') {
            const userID = JSON.parse(window.sessionStorage.getItem('users'))?.userId;
            userID && dispatch(LocManBus(userID));
        }
    }, []);


    const { locationList, genratedStatonName, listAllEvse, getDetailEvseConnector, getConnetorTypes, loading, stationDetails, locationDetail, BusinessDetail } = useSelector(state => ({
        locationList: state?.locationsList?.allLocationList,
        genratedStatonName: state?.ManageStations?.StationName,
        listAllEvse: state?.AssetManagment?.listAllEvse,
        getDetailEvseConnector: state?.AssetManagment?.detailEvseConnector,
        getConnetorTypes: state?.AssetManagment?.connectorTypes,
        stationDetails: stationTYpe === 'EDIT' && state?.ManageStations?.DetailStation[0],
        locationDetail: state?.locationsList?.LocationDetail[0],
        BusinessDetail: state?.locationsList?.LocManBussDetail[0],
        loading: state.Toast.loader
    }));



    useEffect(() => {
        if (getUserRole() === 'LM' && BusinessDetail?.businessId) {
            setBusinessID(BusinessDetail?.businessId);
            // setSelectedGroup1(BusinessDetail);
        }
    }, [BusinessDetail]);

    useEffect(() => {
        if (state?.state?.type === 'EDIT' && stationDetails?.stationName) {
            // dispatch(checkStationName(`?stationId=${stationDetails?.stationId}`, {
            //     stationName: `${stationDetails?.stationName}`
            // }));
            addStationForm.setFieldValue('stationName', stationDetails?.stationName, { shouldValidate: true });
            setTimeout(() => {
                addStationForm.setFieldTouched('stationName', true);
            }, 500);
            setSelectedGroup4({ value: stationDetails?.stationType });
            setGetEVSId(stationDetails?.modelId);
        }
    }, [stationDetails])


    useEffect(() => {

        setReserveValue(stationDetails?.isReservable)
        if (state?.state?.type === 'EDIT') {
            if (stationDetails) {
                dispatch(getStationName(stationDetails?.locationId));
            }
        } else {
            if (stationDetails) {
                dispatch(getStationName(stationDetails?.locationId));
            }
        }
    }, [stationDetails])
    useEffect(() => {
        if (getLocId) {
            dispatch(getStationName(getLocId));
        }
    }, [getLocId])

    useEffect(() => {

        if (locationList.length > 0) {
            const GroupOptions1 = locationList.map((e, i) => {
                return {
                    id: `${e.locationId}`,
                    value: e.region ? `${e.region}, ${e.city}` : `${e.city}`,
                    secondValue: `${e.locationName}`,
                    businessId: `${e.businessId}`
                }
            })
            setLocationDetails(GroupOptions1);
        }
    }, [locationList, getLocId]);


    useEffect(() => {
        if (listAllEvse.length > 0) {
            getEVSId && dispatch(DetailEvseConnector(getEVSId));
            const GroupOptions2 = listAllEvse.map((e, i) => {
                return {
                    id: `${e.EVSEId}`,
                    value: `${e.modelName}`,
                    typeId: `${e.modelId}`
                }
            })
            setGetModal(GroupOptions2);
        }
    }, [listAllEvse, getEVSId]);

    useEffect(() => {
        // if (genratedStatonName.stationName) {
        //     setStationGenNameCheck(genratedStatonName);
        // }
        if (selectedGroup1) {
            dispatch(checkStationName(`?stationId=`, genratedStatonName));
            addStationForm.setFieldValue('stationName', genratedStatonName?.stationName, { shouldValidate: true });

            genratedStatonName?.stationName && setTimeout(() => {
                addStationForm.setFieldTouched('stationName', true);
                setStaReadOnly(false);
            }, 500)
        }
    }, [genratedStatonName]);


    // redirect back to listing if no id is found in case of manual copying url from previos tab
    useLayoutEffect(() => {
        if (state == undefined && window.location.href.includes('edit')) {
            history.push('/stations')
        }
    }, [])


    useEffect(() => {
        if (getDetailEvseConnector?.EVSEConnectors) {

            if (!Array.isArray(getDetailEvseConnector)) {


                // addStationForm.setFieldValue('maxPowerSupply', getDetailEvseConnector?.maxPower)
                addStationForm.setFieldValue('maxPowerSupply', getDetailEvseConnector.EVSEConnectors.map(i => i.maxPower).reduce((acc, a) => acc + a, 0))

            }

            setConnectors(getDetailEvseConnector?.EVSEConnectors);
        }
    }, [getDetailEvseConnector])


    useEffect(() => {
        if (getConnetorTypes?.length > 0) {
            const typesOfConnector = getConnetorTypes.map((e, i) => {
                return {
                    id: `${e.typeId}`,
                    value: e.connectorType
                }
            })
            setAllConnectors(typesOfConnector);
        }
    }, [getConnetorTypes]);

    // useEffect(() => {
    //     if (locationDetail?.locationId && state?.state?.type === 'VIEW') {
    //         setLocationDetails([{
    //             id: `${locationDetail?.locationId}`,
    //             value: locationDetail?.region ? `${locationDetail?.region}, ${locationDetail?.city}` : `${locationDetail?.city}`,
    //             secondValue: `${locationDetail?.locationName}`,
    //             businessId: `${locationDetail?.businessId}`
    //         }])

    //     }
    // }, [locationDetail]);

    useEffect(() => {

        if (locationDetail?.locationId && state?.state?.type === 'VIEW') {
            setGetLocId(locationDetail?.locationId);
            const locations = {
                id: `${locationDetail?.locationId}`,
                value: locationDetail?.region ? `${locationDetail?.region}, ${locationDetail?.city}` : `${locationDetail?.city}`,
                secondValue: `${locationDetail?.locationName}`,
                businessId: `${locationDetail?.businessId}`
            }
            setSelectedGroup1(locations);
        }
    }, [locationDetail]);


    function handleSelectGroups1(selectedGroup1) {
        setSelectedGroup1(selectedGroup1);
    }
    function handleSelectGroups2(selectedGroup2) {
        setSelectedGroup2(selectedGroup2);
    }
    function handleSelectGroups3(selectedGroup3) {
        setSelectedGroup3(selectedGroup3);
    }
    function handleSelectGroups4(selectedGroup4) {
        setSelectedGroup4(selectedGroup4);
    }
    // const GroupOptions1 = [
    //     { id: '1', value: 'Odhav Road , Ahmedabad', secondValue: 'Titania Pvt Ltd' },
    //     { id: '2', value: 'Dariapur, Ahmedabad', secondValue: 'Titania Pvt Ltd' },
    //     { id: '3', value: 'Ratan Pole , Ahmedabad,', secondValue: 'Titania Pvt Ltd' },
    //     { id: '4', value: 'Narol , Ahmedabad,', secondValue: 'Titania Pvt Ltd' },
    //     { id: '5', value: 'Paldi , Ahmedabad,', secondValue: 'Titania Pvt Ltd' },
    //     { id: '6', value: 'Naroda , Ahmedabad,', secondValue: 'Titania Pvt Ltd' },
    //     { id: '7', value: 'Bopal , Ahmedabad,', secondValue: 'Titania Pvt Ltd' },
    // ];

    // const GroupOptions2 = [
    //     { id: '1', value: 'IC100' },
    //     { id: '2', value: 'IC200' },
    //     { id: '3', value: 'IC300' },
    //     { id: '4', value: 'IC400' },
    //     { id: '5', value: 'IC500' },
    //     { id: '6', value: 'IC600' },
    //     { id: '7', value: 'IC700' },
    // ];

    const GroupOptions3 = [
        { id: '1', value: 'Titania Pvt Ltd' },
        { id: '2', value: 'Diemos LLP' },
        { id: '3', value: 'Titania Pvt Ltd' },
        { id: '4', value: 'Diemos LLP' },
        { id: '5', value: 'Titania Pvt Ltd' },
        { id: '6', value: 'Titania Pvt Ltd' },
        { id: '7', value: 'Diemos LLP' },
    ];
    const GroupOptions4 = [
        { id: '1', value: 'Private' },
        { id: '2', value: 'Public' },
    ];

    function toggleArrowTab(tab) {
        if (activeArrowTab !== tab) {
            var modifiedSteps = [...passedarrowSteps, tab];

            if (tab >= 4 && tab <= 7) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    }
    const ConnectorData = [
        {
            id: '1',
            value: 'IEC 62196 (Type 2)'
        },
        {
            id: '2',
            value: 'CCS 1'
        },
        {
            id: '3',
            value: 'CCS 2'
        },
        {
            id: '4',
            value: 'CHAdeMO'
        },
        {
            id: '5',
            value: 'GB/T AC'
        },
        {
            id: '6',
            value: 'GB/T DC'
        }
    ]
    const FormatData = [
        {
            id: '1',
            value: 'Socket'
        },
        {
            id: '2',
            value: 'Cable'
        },
    ]
    const PowerTypeData = [
        {
            id: '1',
            value: 'AC'
        },
        {
            id: '2',
            value: 'DC'
        }
    ]
    // const [Connenctors, setConnectors] = useState([
    //     {
    //         id: '1',
    //         connectorTypeName: 'Connector 01'
    //     },
    //     {
    //         id: '2',
    //         connectorTypeName: 'Connector 02'
    //     },
    //     {
    //         id: '3',
    //         connectorTypeName: 'Connector 03'
    //     },
    // ])

    const addStationForm = useFormik({

        enableReinitialize: true,

        initialValues: {
            stationName: stationDetails?.stationName || '',
            maxPowerSupply: stationDetails?.maxPower || '',
            firmwareVersion: '',
            stationType: `${stationDetails?.stationType}` || '',
            location: `${stationDetails?.region}, ${stationDetails?.city}` || '',
            model: `${stationDetails?.modelName}` || '',
            reservedValue: stationDetails?.isReservable === "true" ? true : false

            // Loader: loading || false
        },
        validationSchema: Yup.object({
            stationName: Yup.string()
                .required(getLocId ? 'Station name required' : 'Please select location first')
                .matches(/^[a-zA-Z0-9-]+$/gi, 'Alphanumeric character only'),
            // .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only'), // /^[ a-zA-Z0-9\.\-\,]+$/i

            stationType: Yup.string().required(),
            location: Yup.string().required(),
            model: Yup.string().required(),
            // Loader: Yup.boolean().oneOf([true])
        }),
        onSubmit: (values) => {
            setStationGenName(values.stationName);
            // const tempArray = [];
            // tempArray.push([...selectedGroup4.value].join());
            // tempArray.push(...getLocId);
            // tempArray.push(...getEVSId);

        }
    });


    let dataObj = {};
    let dataObjval = {};
    useEffect(() => {
        Connenctors?.forEach((d, i) => {
            dataObj[`maxVoltage-${i}`] = ''
            dataObj[`maxAmpere-${i}`] = ''
            dataObj[`maxPower-${i}`] = ''

            dataObjval[`maxVoltage-${i}`] = Yup.string()
                .required('Max. voltage is required')
                .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only')
            dataObjval[`maxAmpere-${i}`] = Yup.string()
                .required('Max. amphere is required')
                .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only')
            dataObjval[`maxPower-${i}`] = Yup.string()
                .required('Max. electric power is required')
                .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only')
        })
    });

    const addStationForm1 = useFormik({
        enableReinitialize: true,
        dirty: true,
        initialValues: dataObj,
        validationSchema: Yup.object(dataObjval),
        onSubmit: (values) => {

            if (stationTYpe === 'EDIT') {
                const params1 = {
                    location: getLocId ? +(getLocId) : +(stationDetails?.locationId),
                    stationName: stationGenName,
                    stationType: selectedGroup4.value,
                    reserveValue: selectedGroup4.value === 'Public' ? reserveValue : false
                    //EVSEId: +(stationDetails?.EVSEId)
                }

                dispatch(editStations(params1, stationID, props.history));

            } else {
                const params = {
                    businessId: getUserRole() === 'LM' ? +(businessID) : +(selectedGroup1?.businessId),
                    location: +(getLocId),
                    stationName: stationGenName,
                    stationType: selectedGroup4?.value,
                    masterEVSEId: +(getEVSId),
                    reserveValue: selectedGroup4.value === 'Public' ? reserveValue : false,
                    modelType: +(selectedGroup2?.typeId),
                    modelName: selectedGroup2?.value,
                    connectorDetails: Connenctors?.map((e, i) => {
                        return {
                            connectorId: i + 1,
                            masterConnectorId: +(e?.connectorId),
                            powerType: e?.powerType,
                            format: e?.format,
                            maxVoltage: e?.maxVoltage,
                            maxAmpere: e?.maxAmpere,
                            maxPower: e?.maxPower,
                            connectorType: +(e.typeId)
                        }
                    })
                }
                dispatch(addStation(params, props.history));
            }

        }
    });
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        onClickBack={(e) => {
                            e.preventDefault();
                            history.goBack();
                        }}
                        isButton={true}
                        child={stationTYpe === 'EDIT' ? true : false}
                        // title="Station-1"
                        title={stationTYpe === 'EDIT' ? addStationForm.values.stationName.trimStart() : "Add Station"}
                        // pageTitle="Titania Pvt Ltd"
                        pageTitle={stationTYpe === 'EDIT' ? stationDetails?.businessName : "Station"}
                        pageTitle2={stationTYpe === 'EDIT' && stationDetails?.locationName}
                        // pageTitle2='Location Name 01'
                        OnClickpageTitle={(e) => {
                            e.preventDefault();
                            history.goBack();
                        }}
                        OnClickpageTitle2={(e) => {
                            e.preventDefault();
                            history.goBack();
                        }}
                    />

                    <div className="addStationPage">
                        <Row>
                            <Col xl={12} lg={12}>
                                <Card>
                                    <CardBody className="form-steps">
                                        <div className="step-arrow-nav mb-5 tabBar">
                                            <Nav
                                                className="nav-pills custom-nav nav-justified"
                                                role="tablist"
                                            >
                                                <NavItem className="navOne">
                                                    <NavLink
                                                        href="#"
                                                        id="steparrow-gen-info-tab nav_1"
                                                        className={classnames({
                                                            active: activeArrowTab === 4,
                                                            done: activeArrowTab <= 6 && activeArrowTab > 3,
                                                        })}
                                                        onClick={() => {
                                                            toggleArrowTab(4);
                                                        }}
                                                    >
                                                        <div>
                                                            <i className="bx bx-station" />
                                                            <span></span>
                                                        </div>
                                                        Station Details
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem className="navTwo">
                                                    <NavLink
                                                        href="#"
                                                        id="steparrow-gen-info-tab nav_2"
                                                        className={classnames({
                                                            active: activeArrowTab === 5,
                                                            done: activeArrowTab <= 6 && activeArrowTab > 4,
                                                        })}
                                                        disabled={stationTYpe === 'EDIT'
                                                            ? !(addStationForm.isValid && addStationForm.dirty)
                                                            : (!(addStationForm.isValid && addStationForm.dirty) ? true : ((selectedGroup2 === true || selectedGroup4 === true) ? true : false))}
                                                        // eslint-disable-next-line no-unused-expressions
                                                        onClick={() => {
                                                            setStationGenName(addStationForm.values.stationName)
                                                            return toggleArrowTab(5);
                                                        }}
                                                    >
                                                        <div>
                                                            <i className="bx bx-plug" />
                                                            <span></span>
                                                        </div>
                                                        Connectors
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>


                                        <TabContent activeTab={activeArrowTab}>
                                            {/* step------1 */}
                                            <TabPane id="steparrow-gen-info" tabId={4}>

                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    addStationForm.handleSubmit();
                                                    return false;
                                                }}
                                                    action="#">
                                                    {/* <p className="headerTxt">{stationTYpe === 'EDIT' ? 'Edit Station' : 'Add Station'}</p> */}
                                                    <p className="headerTxt">{window.location.href.includes('edit') ? 'Edit Station' : 'Add Station'}</p>
                                                    <div>
                                                        <Row>
                                                            <Col lg={4} md={4}>
                                                                <div className="mb-3 rowOne">
                                                                    <Label htmlFor="choices-single-no-search" className="form-label requierdLabel">Location</Label>
                                                                    <CustomDropdown
                                                                        className='allDropdowns moreValueDropdown'
                                                                        moreValue={true}
                                                                        dropDownItems={locationDetails}
                                                                        defaultValue={stationTYpe === 'EDIT' ? stationDetails?.region ? `${stationDetails?.region}, ${stationDetails?.city}` : `${stationDetails?.city}` : ''}
                                                                        placeholder={'Choose Location'}
                                                                        selectedValue={selectedGroup1}
                                                                        showSecondVal={false}
                                                                        onSelect={evt => {
                                                                            // getUserRole() !== 'LM' && setSelectedGroup1(evt);
                                                                            setSelectedGroup1(evt);
                                                                            setGetLocId(evt.id);
                                                                            addStationForm.handleChange('location')(evt.value)
                                                                            setStaReadOnly(true);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg={4} md={4}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label requierdLabel"
                                                                        htmlFor="steparrow-gen-info-username-input"
                                                                    >
                                                                        Station Name
                                                                    </Label>
                                                                    <Input
                                                                        name='stationName'
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="firstnameInput"
                                                                        placeholder="Enter Station Name"
                                                                        readOnly={staReadOnly}
                                                                        maxLength={50}
                                                                        value={addStationForm.values.stationName.trimStart() || ''}
                                                                        invalid={
                                                                            addStationForm.errors.stationName && addStationForm.touched.stationName ? true : false
                                                                        }
                                                                        onChange={(e) => {
                                                                            const val = e.target.value.replace(/[^a-zA-Z0-9-]/g, '');
                                                                            const isValids = e.target.value.match(/[^a-zA-Z0-9-]/g);
                                                                            addStationForm.handleChange(e);

                                                                            // dispatch(checkStationName((stationTYpe === 'EDIT' ? `?stationId=${stationDetails?.stationId}` : `?stationId=`), {
                                                                            //     stationName: `${e.target.value}`
                                                                            // }));
                                                                            if (isValids == null && e.target.value.length > 0) {

                                                                                dispatch(checkStationName((stationTYpe === 'EDIT' ? `?stationId=${stationDetails?.stationId}` : `?stationId=`), {
                                                                                    stationName: val
                                                                                }));
                                                                            }
                                                                        }}
                                                                        onKeyUp={addStationForm.handleBlur}
                                                                    />

                                                                    {addStationForm.errors.stationName && addStationForm.touched.stationName ? (
                                                                        <FormFeedback type="invalid">{addStationForm.errors.stationName}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col>
                                                            <Col lg={4} md={4}>
                                                                <div className="mb-3 rowOne">
                                                                    <Label htmlFor="choices-single-no-search" className="form-label requierdLabel">Station Type</Label>
                                                                    <CustomDropdown
                                                                        className='allDropdowns'
                                                                        moreValue={true}
                                                                        dropDownItems={GroupOptions4}
                                                                        defaultValue={stationTYpe === 'EDIT' ? `${stationDetails?.stationType}` : ''}
                                                                        placeholder={'Choose Station Type'}
                                                                        selectedValue={selectedGroup4}
                                                                        onSelect={evt => {
                                                                            // dispatch()
                                                                            setSelectedGroup4(evt)
                                                                            addStationForm.handleChange('stationType')(evt.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="choices-single-default" className="form-label requierdLabel">Model</Label>
                                                                    <CustomDropdown
                                                                        className={stationTYpe === 'EDIT' ? 'allDropdowns read_OnlyDropdown' : 'allDropdowns'}
                                                                        dropDownItems={GetModal}
                                                                        disabled={stationTYpe === 'EDIT' ? true : false}
                                                                        defaultValue={stationTYpe === 'EDIT' ? `${stationDetails?.modelName}` : ''}
                                                                        placeholder={'Choose Model'}
                                                                        selectedValue={selectedGroup2}
                                                                        onSelect={evt => {

                                                                            if (evt !== undefined) {
                                                                                setSelectedGroup2(evt);
                                                                                setGetEVSId(evt.id);
                                                                                addStationForm.handleChange('model')(evt.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg={6} md={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-username-input"
                                                                    >
                                                                        {/* Max Power Supply */}
                                                                        Max. Sanctioned Power (kW)
                                                                    </Label>
                                                                    <Input
                                                                        name='maxPowerSupply'
                                                                        type="text"
                                                                        className='read_Only'
                                                                        id="maxPowerSupply"
                                                                        placeholder="Max. Sanctioned Power (kW)"
                                                                        readOnly={true}
                                                                        value={addStationForm.values.maxPowerSupply || ''}
                                                                        invalid={
                                                                            addStationForm.errors.maxPowerSupply && addStationForm.touched.maxPowerSupply ? true : false
                                                                        }
                                                                        onChange={addStationForm.handleChange}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="choices-single-no-search" className="form-label">Manufacturer</Label>
                                                                    {/* <CustomDropdown
                                                                        className='allDropdowns'
                                                                        dropDownItems={GroupOptions3}
                                                                        defaultValue={'Vidhyutam EV Solutions'}
                                                                        placeholder={'Choose manufacturer'}
                                                                        selectedValue={selectedGroup3}
`                                                                        onSelect={evt => {
                                                                            setSelectedGroup3(evt)
                                                                        }}
                                                                    /> */}
                                                                    <Input
                                                                        defaultValue={'Vidhyutam EV Solutions'}
                                                                        readOnly={true}
                                                                        className={'read_Only'}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg={6} md={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-username-input"
                                                                    >
                                                                        Firmware Version
                                                                    </Label>
                                                                    <Input
                                                                        name='firmwareVersion'
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="maxPowerSupply"
                                                                        placeholder="Enter Firmware Version"
                                                                        value={addStationForm.values.firmwareVersion.trim() || ''}
                                                                        invalid={
                                                                            addStationForm.errors.firmwareVersion && addStationForm.touched.firmwareVersion ? true : false
                                                                        }
                                                                        onChange={addStationForm.handleChange}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} md={6}>
                                                                <div className="form-check form-check-success">
                                                                    <Input
                                                                        className={"form-check-input"}
                                                                        type="checkbox"
                                                                        name={`reservename`}
                                                                        checked={(reserveValue === "true" || reserveValue === true) ? true : false}
                                                                        disabled={selectedGroup4?.value === 'Private'}
                                                                        // onChange={addLocationForm.handleChange}
                                                                        onChange={(e) => {
                                                                            addStationForm.setFieldValue('reservedValue', e.target.checked);
                                                                            setReserveValue(e.target.checked)
                                                                        }}
                                                                    />
                                                                    <Label className="form-check-label" for="formCheck1">
                                                                        Reservable
                                                                    </Label>
                                                                </div>

                                                            </Col>

                                                        </Row>
                                                    </div>
                                                    <div className="d-flex align-items-start justify-content-between gap-3 mt-4">
                                                        <CustomButton
                                                            title={"Next"}
                                                            disabled={stationTYpe === 'EDIT'
                                                                ? !(addStationForm.isValid && addStationForm.dirty)
                                                                : (!(addStationForm.isValid && addStationForm.dirty) ? true : ((selectedGroup2 === true || selectedGroup4 === true) ? true : false))}
                                                            type='submit'
                                                            loading={loading}
                                                            className='btn addButtonWithLoader nexttab nexttab add_FormBtn'
                                                            onClick={() => {
                                                                toggleArrowTab(activeArrowTab + 1);
                                                            }}
                                                            Next
                                                        />

                                                        <Button
                                                            type="button"
                                                            className="btn bottomBtn cancelBtn"
                                                            onClick={() => {
                                                                history.goBack();
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </TabPane>
                                            {/* step------2 */}
                                            <TabPane id="steparrow-description-info" tabId={5}>
                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    addStationForm1.handleSubmit();
                                                    return false;
                                                }}
                                                    action="#">
                                                    <p className="headerTxt">{window.location.href.includes('edit') ? 'Edit Station' : 'Add Station'}</p>
                                                    {
                                                        Connenctors?.map((item, index) => {
                                                            return (
                                                                <div key={index} className="aContainer">
                                                                    <p className="headerTitle">
                                                                        <h5>{`Connector 0${index + 1}`}</h5>
                                                                    </p>
                                                                    <Row>
                                                                        <Col lg={4} md={4}>
                                                                            <div className="mb-3">
                                                                                <Label>Connector Type</Label>
                                                                                <CustomDropdown
                                                                                    className='allDropdowns read_OnlyDropdown'
                                                                                    //dropDownItems={allConnectors}
                                                                                    readOnly={true}
                                                                                    disabled={true}
                                                                                    defaultValue={Connenctors[index]?.connectorType}//'IEC 62196 (Type 2)'}
                                                                                    placeholder={'Select'}
                                                                                    selectedValue={Connenctors[index].connectorType}
                                                                                    onSelect={evt => {
                                                                                        Connenctors[index].connectorType = evt
                                                                                        setConnectors([...Connenctors])
                                                                                        //setConnectorDataSelect(evt)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={4} md={4}>
                                                                            <div className="mb-3">
                                                                                <Label>Format</Label>
                                                                                <CustomDropdown
                                                                                    className='allDropdowns read_OnlyDropdown'
                                                                                    // dropDownItems={FormatData}
                                                                                    readOnly={true}
                                                                                    disabled={true}
                                                                                    defaultValue={Connenctors[index]?.format}   // {'Socket'}
                                                                                    placeholder={'Select'}
                                                                                    selectedValue={Connenctors[index].format}
                                                                                    onSelect={evt => {
                                                                                        Connenctors[index].format = evt
                                                                                        setConnectors([...Connenctors])
                                                                                        //setFormatSelect(evt)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={4} md={4}>
                                                                            <div className="mb-3">
                                                                                <Label>Power Type</Label>
                                                                                <CustomDropdown
                                                                                    className='allDropdowns read_OnlyDropdown'
                                                                                    // dropDownItems={PowerTypeData}
                                                                                    readOnly={true}
                                                                                    disabled={true}
                                                                                    defaultValue={Connenctors[index]?.powerType}
                                                                                    placeholder={'Select'}
                                                                                    selectedValue={Connenctors[index].powerType}
                                                                                    onSelect={evt => {
                                                                                        Connenctors[index].powerType = evt
                                                                                        setConnectors([...Connenctors])
                                                                                        // setPowerTypeSelect(evt)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>

                                                                        <Col lg={4} md={4}>
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label"
                                                                                    htmlFor="steparrow-gen-info-username-input"
                                                                                >
                                                                                    {/* Max Voltage */}
                                                                                    Max. Voltage (V)
                                                                                </Label>
                                                                                <Input
                                                                                    name={`maxVoltage-${index}`}
                                                                                    type="text"
                                                                                    className="form-control read_Only"
                                                                                    id={`maxVoltage-${index}`}
                                                                                    placeholder="Enter Max. Voltage (V)"
                                                                                    readOnly={true}
                                                                                    // defaultValue={Connenctors[index]?.maxVoltage}
                                                                                    value={addStationForm1.values[`maxVoltage-${index}`] || Connenctors[index]?.maxVoltage || ''}
                                                                                    invalid={
                                                                                        addStationForm1.errors[`maxVoltage-${index}`] && addStationForm1.touched[`maxVoltage-${index}`] ? true : false
                                                                                    }
                                                                                    onChange={(e) => {
                                                                                        addStationForm1.handleChange(e);
                                                                                        Connenctors[index].maxVoltage = e.target.value;
                                                                                        setConnectors([...Connenctors]);
                                                                                    }}
                                                                                    onKeyUp={addStationForm1.handleBlur}
                                                                                />
                                                                                {addStationForm1.errors.maxVoltage && addStationForm1.touched.maxVoltage ? (
                                                                                    <FormFeedback type="invalid">{addStationForm1.errors.maxVoltage}</FormFeedback>
                                                                                ) : null}
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={4} md={4}>
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label"
                                                                                    htmlFor="steparrow-gen-info-username-input"
                                                                                >
                                                                                    {/* Max Ampere */}
                                                                                    Max. Current (A)
                                                                                </Label>
                                                                                <Input
                                                                                    name={`maxAmpere-${index}`}
                                                                                    type="text"
                                                                                    className="form-control read_Only"
                                                                                    id={`maxAmpere-${index}`}
                                                                                    placeholder="Enter Max. Current(A)"
                                                                                    readOnly={true}
                                                                                    value={addStationForm1.values[`maxAmpere-${index}`] || Connenctors[index]?.maxAmpere || ''}
                                                                                    invalid={
                                                                                        addStationForm1.errors[`maxAmpere-${index}`] && addStationForm1.touched[`maxAmpere-${index}`] ? true : false
                                                                                    }
                                                                                    onChange={(e) => {

                                                                                        addStationForm1.handleChange(e);
                                                                                        Connenctors[index].maxAmpere = e.target.value;
                                                                                        setConnectors([...Connenctors]);
                                                                                    }}
                                                                                    onKeyUp={addStationForm1.handleBlur}

                                                                                />
                                                                                {addStationForm1.errors[`maxAmpere-${index}`] && addStationForm1.touched[`maxAmpere-${index}`] ? (
                                                                                    <FormFeedback type="invalid">{addStationForm1.errors[`maxAmpere-${index}`]}</FormFeedback>
                                                                                ) : null}
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={4} md={4}>
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label"
                                                                                    htmlFor="steparrow-gen-info-username-input"
                                                                                >
                                                                                    {/* Max Electric Power */}
                                                                                    Max. Electric Power (kW)
                                                                                </Label>
                                                                                <Input
                                                                                    name={`maxPower-${index}`}
                                                                                    type="text"
                                                                                    className="form-control read_Only"
                                                                                    id={`maxPower-${index}`}
                                                                                    placeholder="Enter Max. Electric Power(kW)"
                                                                                    readOnly={true}
                                                                                    value={addStationForm1.values[`maxPower-${index}`] || Connenctors[index]?.maxPower || ''}
                                                                                    invalid={
                                                                                        addStationForm1.errors[`maxPower-${index}`] && addStationForm1.touched[`maxPower-${index}`] ? true : false
                                                                                    }
                                                                                    onChange={(e) => {

                                                                                        addStationForm1.handleChange(e);
                                                                                        Connenctors[index].maxPower = e.target.value;
                                                                                        setConnectors([...Connenctors]);
                                                                                    }}
                                                                                    onKeyUp={addStationForm1.handleBlur}
                                                                                />
                                                                                {addStationForm1.errors[`maxPower-${index}`] && addStationForm1.touched[`maxPower-${index}`] ? (
                                                                                    <FormFeedback type="invalid">{addStationForm1.errors[`maxPower-${index}`]}</FormFeedback>
                                                                                ) : null}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <div className="d-flex align-items-start justify-content-between gap-3 mt-4">
                                                        <div className="d-flex align-items-start justify-content-start gap-3">
                                                            <Button
                                                                type="button"
                                                                className="btn previestab"
                                                                onClick={() => {
                                                                    toggleArrowTab(activeArrowTab - 1);
                                                                }}
                                                            >
                                                                Previous
                                                            </Button>
                                                            <CustomButton
                                                                title={"Add"}
                                                                type='submit'
                                                                loading={loading}
                                                                className='btn addButtonWithLoader nexttab nexttab add_FormBtn'
                                                                onClick={() => {
                                                                    // toggleArrowTab(activeArrowTab + 1);
                                                                }}
                                                            />
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            className="btn cancelBtn"
                                                            onClick={() => {
                                                                history.goBack();
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        {/* <button
                                                            type="button"
                                                            className="btn btn-success nexttab nexttab"
                                                            onClick={() => {

                                                            }}
                                                        >
                                                            Next
                                                        </button> */}
                                                    </div>
                                                </Form>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddStation;
