import React, { useEffect, useState } from 'react';
import socket from './socketConfig';
import { io } from 'socket.io-client';

// import socket from './socketConfig';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';
import { useDispatch, useSelector } from 'react-redux';
import { firmwareStatusAll, listAllStation, listAllStationSuccess, socketCredentials, socketData, socketHeartData } from './store/actions';
import { loginSuccess } from './store/auth/login/actions'

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Fake Backend 
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { useHistory } from 'react-router-dom';
// import { useWebSocket } from 'react-use-wsocket/dist/lib/use-websocket';
// const URL_WEB_SOCKET = 'wss://stream.binance.com:9443/ws';
// const URL_WEB_SOCKET = 'wss:/103.189.173.151:8123';
// const URL_WEB_SOCKET = 'ws://113.20.17.179:4000/?token=123';
// const URL_WEB_SOCKET = 'ws://113.20.17.179:4000/';
// const URL_WEB_SOCKET = process.env.REACT_APP_SOCKETURL;

// const URL_WEB_SOCKET = 'ws://192.168.1.224:4000/';


// Activating fake backend
fakeBackend();

const request = {
  method: 'SUBSCRIBE',
  params: ['btcusdt@trade'],
  id: 1,
};


// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);
// https://socket.nimblechapps.com
// http://65.2.126.32:4000
// export const socket = io('https://socket.vidhyutam.com', { transports: ['websocket']});



// console.log(socket,'socket')

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [ws, setWs] = useState(null);


  // validate if user is logged in
  useEffect(() => {
    if (window.localStorage.getItem('islogged') !== undefined && window.localStorage.getItem('islogged') !== null && window.localStorage.getItem('islogged') == 'true') {
      dispatch(loginSuccess())
    }
  }, [])


  // redux state
  const fullState = useSelector(state => state)

  // useEffect(() => {

  //   // const x = ws?.onmessage();s
  //   
  // }, [ws])

  // useEffect(() => {

  //   if (fullState.Login.isLoggedIn == true) {
  //     const wsClient = new WebSocket(URL_WEB_SOCKET + '?token=' + Math.floor(Math.random() * (999 - 100 + 1) + 100).toString());
  //     wsClient.onopen = () => {

  //       setWs(wsClient);


  //       let data = {
  //         stations: ['Vidhyutam', 'Vidhyutam-2', 'Vidhyutam-3'],
  //         // metadata: {
  //         //   stationName: 'Vidhyutam',
  //         //   connectorId: 389
  //         // }
  //       };
  //       // wsClient.send(JSON.stringify(data));
  //     };
  //     return () => {
  //       wsClient.close();
  //     };
  //   } else if (ws !== null) {
  //     ws.close();
  //   }
  // }, [fullState.Login.isLoggedIn]);

  // // after fetching stations from api send request to ws server for each station data
  // useEffect(() => {
  //   if (fullState.ManageStations.listAllStation.length > 0) {
  //     
  //     //   stations: fullState.ManageStations.listAllStation.map((item => item.stationName))
  //     // });
  //     ws?.send(JSON.stringify({
  //       stations: fullState.ManageStations.listAllStation.map((item => item.stationName))
  //     }));
  //   }
  // }, [fullState.ManageStations.listAllStation, ws])

  // useEffect(() => {
  //   if (ws) {
  //     ws.onmessage = (evt) => {
  //       
  //       dispatch(socketData(JSON.parse(evt.data).necessaryStationData))
  //     };
  //   }
  // }, [ws]);

  // log out from all tabs
  useEffect(() => {
    const handler = (e) => {
      if (e.key == 'islogged') {
        history.push('/login');
      }
    }
    window.addEventListener('storage', handler)
    return () => {
      window.removeEventListener('storage', handler)
    }
  }, [])

  // window.onbeforeunload = function () {
  //   localStorage.clear();
  // }


  // // socketio implementation
  useEffect(() => {
    console.log(fullState.Login.isLoggedIn, 'fullState.Login.isLoggedIn !!!!')

    if (fullState.Login.isLoggedIn == true) {
      socket.connect()
      socket.on('connect', () => {
        console.log(socket.id, 'socket.id')
        dispatch(socketCredentials({ id: socket.id }))
      });

      socket.on('disconnect', () => {

      });
      let newArray = [];

      socket.on('getTriggerWebMobile', res => {

        // let existingObj = newArray.find(obj => obj.chargerId === JSON.parse(res).chargerId && obj.connectorId === JSON.parse(res).connectorId && obj.updateResp === JSON.parse(res).updateResp);
        let parsedRes = JSON.parse(res);

        let index = newArray.findIndex(obj =>
          obj.chargerId === parsedRes.chargerId &&
          obj.connectorId === parsedRes.connectorId

        );
        if (index !== -1) {

          newArray[index] = parsedRes;
        } else {
          newArray.push(parsedRes);
        }


        // if (!existingObj) {
        //   newArray.push(JSON.parse(res))
        // }
        dispatch(socketHeartData(newArray))
        window.localStorage.setItem('socketHeart', JSON.stringify(newArray));

      });

      socket.on('statusNotifications', res => {
        // console.log(res, 'resstatusNotifications!!')
        // console.log(typeof res,'resstatusNotifications!!')
        if (res) {

          window.localStorage.setItem('socketStatus', res);
          dispatch(socketData(JSON.parse(res)))
        }
      });



      socket.on('connect_error', error => {
        console.log(error, 'error');

      });

      socket.on('firmwareUpdate', (res) => {

        dispatch(firmwareStatusAll(res))
      })

      return () => {
        socket.off('connect');
        socket.off('disconnect');
        socket.off('statusNotifications');
        socket.off('connect_error');
        socket.disconnect();
      };
    } else if (fullState.Login.isLoggedIn == false) {
      socket.disconnect()
    }
  }, [fullState.Login.isLoggedIn]);



  return (
    <React.Fragment>
      <div>
        <Route />
      </div>
    </React.Fragment>
  );
}

export default App;

