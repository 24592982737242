import React from 'react';
import PropTypes from 'prop-types';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'

const CustomDropdown = (props) => {
    let { selectedValue, dropDownItems = [], placeholder, onSelect, defaultValue, disabled, moreValue, id, showSecondVal, className } = props;
    const getTitle = () => {
        if (selectedValue && selectedValue.id) {
            const item = dropDownItems?.find(i => i?.id === selectedValue?.id || i?.name === selectedValue)
            return (item && moreValue ? (item?.value + '  ' + (showSecondVal ? item?.secondValue : '')) : (item?.value)) || placeholder
        } else {
            return defaultValue ? defaultValue : placeholder
        }
    }

    return (
        <div className="customDropdown">
            <DropdownButton
                defaultValue={defaultValue}
                disabled={disabled}
                key={`${selectedValue?.id}-${id}`}
                id={`dropdown-variants-${selectedValue?.id}-${id}`}
                title={getTitle()}
                onSelect={(evt) => {
                    onSelect(dropDownItems?.find(i => evt === i?.id))
                }}
                className={`${selectedValue ? "selected" : ""} ${className}`}
            >

                <div className="dropdownData">
                    {dropDownItems?.length > 0 ? dropDownItems?.map((item) => <Dropdown.Item eventKey={item?.id} key={`${item?.id}`} >
                        <span className={'firstSpan'}>{item?.value}</span>
                        <span>{moreValue && item?.secondValue}</span>
                    </Dropdown.Item>)
                        : <Dropdown.Item>
                            <p className='noData'>No Data Found!</p>
                        </Dropdown.Item>}
                </div>
            </DropdownButton>
        </div>
    )
}

CustomDropdown.propTypes = {
    onClick: PropTypes.func
};
CustomDropdown.defultProps = {
    moreValue: false,
    showSecondVal: true
};

export default CustomDropdown;
