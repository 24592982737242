import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
//import Components
import { useDispatch, useSelector } from 'react-redux';
import { showToast, showLoader } from '../../../store/actions';
import TableContainer from '../../../Components/Common/TableContainer';
import { allBusinessList, businessResendInvite, getBusinessDetails } from "../../../store/actions";
import CustomButton from '../../../Components/Common/CustomButton';
import { getValueFromObject, getUserRole } from '../../../helpers/helper.service';
import useWindowDimension from '../../../Components/Hooks/useWindowDimension';
import ContentLoader from '../../../Components/Common/ContentLoader';


const Businesses = (props) => {
    document.title = "Businesses | Vidhyutam";
    const history = useHistory();
    const dispatch = useDispatch();
    const dimensions = useWindowDimension();
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [tableTaskBar, setTableTaskBar] = useState(0);
    const [paginationDiv, setPaginationDiv] = useState(0);
    const [cardHeader, setCardHeaderHeight] = useState(0);
    const [busssinessData, setBusinessData] = useState('');
    const [noOfLocation, setNoOfLocation] = useState('');
    const [noOfStation, setNoOfStation] = useState('');
    const [noOfBusiness, setNoOfBusiness] = useState('');
    const [businessListLoader, setBusinessListLoader] = useState(false)
    const [filteredTable, setfilteredTable] = useState([]);
    // handle filtered table counting
    const handleFilterCount = () => {
        let count = 0;
        let stationcount = 0;
        setNoOfBusiness(filteredTable.length);
        filteredTable.forEach((item) => {
            count = count + item.original.nLocations;
            stationcount = stationcount + item.original.nStations;
        })
        setNoOfLocation(count);
        setNoOfStation(stationcount);

    }

    // stop loader on page load 
    useEffect(() => {
        dispatch(showLoader(false))
    }, [])

    useEffect(() => {
        handleFilterCount();
    }, [filteredTable])

    useEffect(() => {
    }, [noOfLocation])

    useEffect(() => {
        setTimeout(() => {
            setBusinessListLoader(true)
        }, 2000)
    }, [])
    useEffect(() => {
        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
    }, []);
    const handleResize = () => {

        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        dispatch(allBusinessList('?id='));
    }, []);

    const { businessList } = useSelector((state) => ({
        businessList: state?.businessList?.allBusinessList.filter(
            (i) => i?.status !== "Deleted"
        ),
    }));


    useEffect(() => {
        if (businessList.length > 0) {
            const numOfLoca = businessList?.map((e, i) => e.nLocations).reduce((Acc, e) => Acc + e, 0);
            setNoOfLocation(numOfLoca);

            const numOfStat = businessList?.map((e, i) => e.nStations).reduce((Acc, e) => Acc + e, 0);
            setNoOfStation(numOfStat);
            setNoOfBusiness(businessList.length);
        }
    }, [businessList])


    const handleViewDetailClick = (e) => {
        history.push('/businesses/businessesdetail', {
            state: {
                value: e.original.businessId
            }
        });
    }
    const handleAddButtonClicks = () => {
        history.push('/businesses/addbusiness');
    }

    // custom sort fn
    const handleSort = React.useMemo(() => {
        return (rowA, rowB, columnId, desc) => {

            return rowA.values.businessName.toLowerCase() > rowB.values.businessName.toLowerCase() ? 1 : -1;
        }
    }, [])

    // Column
    const columns1 = useMemo(
        () => [
            {
                Header: dimensions.width > 1280 ? "Business ID" : "Business Details",
                accessor: d => `${d.prefix + d.rBusinessId}`,
                filterable: true,
                show: true,
                Cell: (cell) => {
                    return (dimensions.width > 1280 ? <><p className='text textOverflow'>{cell.row.original.prefix + cell.row.original.rBusinessId}</p></> :
                        <p className='text textOverflow'>{cell.row.original.prefix + cell.row.original.rBusinessId}
                            <p className='text textOverflow'>{cell?.row?.original?.businessName}</p></p>);
                },
            },
            dimensions.width > 1280 ? {
                Header: `Business Name (${noOfBusiness ? noOfBusiness : '0'})`,
                accessor: "businessName",
                filterable: true,
                sortType: handleSort,
                show: true,
                Cell: (cell) => {
                    console.log("buisness name cell", cell)
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            } : {},
            {
                Header: "Business Owner",
                accessor: d => `${d.lastName} ${d.firstName} ${d.email} ${d.contactnumber}`,
                filterable: true,
                disableSortBy: true,
                // show: getUserRole(),
                show: window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null && JSON.parse(window.localStorage.getItem('users')).userRole !== 'BusinessOwner',
                Cell: (businessOwner) => {
                    return <div className='managerBox'>
                        <p className='name wordBreakAll textOverflow'>{getValueFromObject('firstName', businessOwner.row.original, '')} {getValueFromObject('lastName', businessOwner.row.original, '')}</p>
                        <p className='email textOverflow wordBreakAll'>{getValueFromObject('email', businessOwner.row.original, '')}</p>
                        {/* <p className='number'>{getValueFromObject('countryCode', businessOwner.row.original, '')} {getValueFromObject('contactnumber', businessOwner.row.original, '')}</p> */}
                        <p className='number'>{businessOwner.row.original.contactNumber ? `+${businessOwner.row.original.countryCode} ${businessOwner.row.original.contactNumber}` : ''}</p>
                    </div>
                },
            },
            {
                Header: (e) => {
                    return dimensions.width > 1280
                        ? `Location (${noOfLocation ? noOfLocation : '0'})`
                        : `Counts (${noOfLocation ? noOfLocation : '0'})`;
                },
                accessor: "nLocations",
                filterable: true,
                disableGlobalFilter: true,
                show: true,
                Cell: (cell) => {
                    return (
                        dimensions.width > 1280 ? <>
                            <p className="text textOverflow">
                                {cell.value}
                            </p></> :
                            <>
                                <p className="text textOverflow">
                                    Location : {cell.value}

                                </p>
                                <p className="text textOverflow">
                                    Station : {cell?.row?.original?.nStations}

                                </p>
                            </>
                    );
                }
            },
            dimensions.width > 1280 ? {
                Header: (e) => `Station (${noOfStation ? noOfStation : '0'})`,
                accessor: "nStations",
                filterable: true,
                disableGlobalFilter: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            } : {},
            {
                Header: "Action",
                // show: getUserRole(),
                show: window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null && JSON.parse(window.localStorage.getItem('users')).userRole !== 'BusinessOwner',
                disableSortBy: true,
                Cell: (item) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0" onClick={(e) => { e.stopPropagation() }}>
                            <li className="list-inline-item">
                                {
                                    (item.row.original.isVerified === 0 &&

                                        <CustomButton
                                            // disabled={item.row.original.checked === false ? true : false}
                                            title='Resend invite'
                                            type='button'
                                            loading={false}
                                            className='resendBtn'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                dispatch(businessResendInvite({ businessId: item.row.original.businessId }));
                                            }}
                                        />
                                    ) || '-'

                                }
                            </li>
                        </ul>
                    );
                },
            },
        ], [history, businessList, busssinessData.checked, noOfLocation, noOfStation, noOfBusiness, handleSort]
    );
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className='businessPage'>
                        <Row>
                            <Col lg={12}>
                                <Card className='mb-0'>
                                    <CardHeader className='cardHeader'>
                                        <p className='headerTxt'>Manage Businesses</p>
                                    </CardHeader>
                                    <CardBody>
                                        <div className={filteredTable.length > 0 && businessList.length > 0 ? 'table-content' : 'table-content disablePointer'}>
                                            {businessListLoader ?
                                                <TableContainer
                                                    setfilteredTable={setfilteredTable}
                                                    columns={columns1.filter((a) => a.show)}
                                                    data={businessList}
                                                    isGlobalFilter={true}
                                                    customPageSize={8}
                                                    handleViewDetailClick={(row) => handleViewDetailClick(row)}
                                                    maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - 105}
                                                    // divClass={getUserRole() ? "table-responsive mb-1 bussinessTable" : "table-responsive mb-1 bussinessTable businessOwner"}
                                                    divClass={window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null && JSON.parse(window.localStorage.getItem('users')).userRole !== 'BusinessOwner' ? "table-responsive mb-1 bussinessTable" : "table-responsive mb-1 bussinessTable businessOwner"}
                                                    tableClass="align-middle table-nowrap"
                                                    theadClass="table-light text-muted"
                                                    // isAddButton={true && getUserRole()}
                                                    isAddButton={true && window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null && JSON.parse(window.localStorage.getItem('users')).userRole !== 'BusinessOwner'}
                                                    searchPlaceholder={getUserRole() ? 'Search Business ID, Business Name, Business Owner' : 'Search Business ID, Business Name'}
                                                    handleAddButtonClicks={handleAddButtonClicks}
                                                    addButtonTitle='Add Business'
                                                    noData={businessList.length > 0 ? false : true}
                                                    // noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1670402495/Lubi/WebApp/Static-images/no_data_found.svg"}
                                                    noDataImage={"https://res.cloudinary.com/https-www-nimblechapps-com/image/upload/v1688013508/Lubi/WebApp/Static-images/no_data_found.svg"}
                                                    noDataTitle={'No Data Found!'}
                                                />
                                                : <ContentLoader />}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Businesses;