/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Row } from 'reactstrap';
import DeleteModal from '../../../Components/Common/DeleteModal';
//import Components
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DisableModal from '../../../Components/Common/DisableModal';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../../store/actions';
import TableContainer from '../../../Components/Common/TableContainer';
import { getBusinessDetails, allLocation,activateDeactivateBusinessAction,deleteBusinessAction } from '../../../store/actions';
import { getUserRole, getValueFromObject } from '../../../helpers/helper.service';
import ContentLoader from '../../../Components/Common/ContentLoader';

const BussinessDetail = (props) => {
    document.title = "Businesses | Vidhyutam";
    const history = useHistory();
    const { pathname, state } = useLocation();
    const dispatch = useDispatch();
    const [deleteModal, setDeleteModal] = useState(false);
    const [disabledModal, setDisabledModal] = useState(false);
    const [noOfConnectors, setNoOfConnectors] = useState('');
    const [noOfStation, setNoOfStation] = useState('');
    const [StationLoader, SetStationLoader] = useState(false);
    const [filteredTable, setfilteredTable] = useState([]);

    // handle filtered table counting
    const handleFilterCount = () => {
        let station = 0;
        let connector = 0;
        
        filteredTable.forEach((item) => {
            
            connector += item.original.nConnectors;
            station += item.original.nStations;
            
        })

        setNoOfStation(station)
        setNoOfConnectors(connector)

    }

    // redirect back to listing if no id is found in case of manual copying url from previos tab
    useLayoutEffect(() => {
        if (state == undefined) {
            history.push('/businesses')
        }
    }, [])

    useEffect(() => {
        handleFilterCount();
    }, [filteredTable])


    useEffect(() => {
        if (state !== undefined) {
            const businessId = state?.state?.value;
            dispatch(getBusinessDetails(props.history, businessId));
            dispatch(allLocation(`?id=${businessId}`));
        }
    }, []);


    const { businessDetail, businessLocationDetail } = useSelector(state => ({
        businessDetail: state?.businessList?.businessDetail,
        businessLocationDetail: state?.locationsList?.allLocationList,
    }));
console.log(businessDetail, businessLocationDetail);
    useEffect(() => {
        setTimeout(() => {
            SetStationLoader(true)
        }, 2000)
    }, []);
    

    // useEffect(() => {
    //     if (businessLocationDetail) {
    //         const numOfConn = businessLocationDetail?.map((e, i) => e.nConnectors).reduce((Acc, e) => Acc + e, 0);

    //         setNoOfConnectors(numOfConn);

    //         const numOfStat = businessLocationDetail?.map((e, i) => e.nStation).reduce((Acc, e) => Acc + e, 0);

    //         setNoOfStation(numOfStat);
    //     }
    // }, [businessLocationDetail])

    const [disabledLocationModal, setDisabledLocationModal] = useState(false);
    const [enableModal, setEnableModal] = useState(false);
    const [enabledLocationModal, setEnabledLocationModal] = useState(false);
    const [locationData, setLocationData] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [locationDetail, setLocationDetail] = useState([
        {
            _id: "1",
            locationName: "Location Name 01",
            regionName: 'Odhav Road , Ahmedabad',
            name: 'Tim.Jennings',
            email: 'tim.jennings@example.com',
            number: '+918555744761',
            noOfStations: '5',
            noOfConnectors: '5',
            // checked: false
        },
        {
            _id: "2",
            locationName: "Location Name 02",
            regionName: 'Dariapur, Ahmedabad',
            name: 'Kenzi Lawson',
            email: 'kenzi.lawson@example.com',
            number: '+918555744762',
            noOfStations: '6',
            noOfConnectors: '6',
            // checked: false
        },
        {
            _id: "3",
            locationName: "Location Name 03",
            regionName: 'Ratan Pole , Ahmedabad',
            name: 'Sara Cruz',
            email: 'sara.cruz@example.com',
            number: '+918555744763',
            noOfStations: '7',
            noOfConnectors: '7',
            // checked: true
        },
        {
            _id: "4",
            locationName: "Location Name 01",
            regionName: 'Odhav Road , Ahmedabad',
            name: 'Tim.Jennings',
            email: 'tim.jennings@example.com',
            number: '+918555744761',
            noOfStations: '5',
            noOfConnectors: '5',
            // checked: false
        },
        {
            _id: "5",
            locationName: "Location Name 02",
            regionName: 'Dariapur, Ahmedabad',
            name: 'Kenzi Lawson',
            email: 'kenzi.lawson@example.com',
            number: '+918555744762',
            noOfStations: '6',
            noOfConnectors: '6',
            // checked: false
        },
        {
            _id: "6",
            locationName: "Location Name 03",
            regionName: 'Ratan Pole , Ahmedabad',
            name: 'Sara Cruz',
            email: 'sara.cruz@example.com',
            number: '+918555744763',
            noOfStations: '7',
            noOfConnectors: '7',
            // checked: true
        },
        {
            _id: "7",
            locationName: "Location Name 01",
            regionName: 'Odhav Road , Ahmedabad',
            name: 'Tim.Jennings',
            email: 'tim.jennings@example.com',
            number: '+918555744761',
            noOfStations: '5',
            noOfConnectors: '5',
            // checked: false
        },
        {
            _id: "8",
            locationName: "Location Name 02",
            regionName: 'Dariapur, Ahmedabad',
            name: 'Kenzi Lawson',
            email: 'kenzi.lawson@example.com',
            number: '+918555744762',
            noOfStations: '6',
            noOfConnectors: '6',
            // checked: false
        },
        {
            _id: "9",
            locationName: "Location Name 03",
            regionName: 'Ratan Pole , Ahmedabad',
            name: 'Sara Cruz',
            email: 'sara.cruz@example.com',
            number: '+918555744763',
            noOfStations: '7',
            noOfConnectors: '7',
            // checked: true
        },
    ])
    const handleDeleteContact = () => {
        dispatch(deleteBusinessAction(`?businessId=${businessDetail[0].businessId}`));
        if(businessLocationDetail.length === 0){
   history.push("/businesses");
        }else{
        setTimeout(() => {
          if (state !== undefined) {
            const businessId = state?.state?.value;
            dispatch(getBusinessDetails(props.history, businessId));
            dispatch(allLocation(`?id=${businessId}`));
          }
        }, 1000);
        }
          
        setDeleteModal(false);
    };
    const handleDisableContact = () => {
        setIsChecked(!isChecked);
      
                dispatch(
                  activateDeactivateBusinessAction(
                    `?businessId=${businessDetail[0].businessId}&status=Deactivate`
                  )
                );
                  setTimeout(()=>{
         if (state !== undefined) {
           const businessId = state?.state?.value;
           dispatch(getBusinessDetails(props.history, businessId));
           dispatch(allLocation(`?id=${businessId}`));
         }
                  },1000)
    
        setDisabledModal(false);
        
    };

    const handleEnableContact = () => {
       
        setIsChecked(!isChecked);

             dispatch(
               activateDeactivateBusinessAction(
                 `?businessId=${businessDetail[0].businessId}&status=Activate`
               )
             );
               setTimeout(() => {
                 if (state !== undefined) {
                   const businessId = state?.state?.value;
                   dispatch(getBusinessDetails(props.history, businessId));
                   dispatch(allLocation(`?id=${businessId}`));
                 }
               }, 1000);
        setEnableModal(false);
    };
    const toggleData = () => {
        const temp = [...locationDetail]
        temp.map((e, index) => {
            if (e._id === locationData._id) {
                temp[index].checked = !locationData.checked
            }

        })
        setLocationDetail([...temp])
    };
    const handleDisabledLocationModal = () => {
        toggleData();
        dispatch(showToast({
            message: 'location has been disabled successfully.',
            type: 'success'
        }));
        setDisabledLocationModal(false);
    };
    const handleEnableLocation = () => {
        toggleData();
        dispatch(showToast({
            message: 'location has been enabled successfully.',
            type: 'success'
        }));
        setEnabledLocationModal(false);
    };
    const handleAddButtonClicks = () => {
        history.push('/location/addlocation', {
            state: {
                businessID: businessDetail[0]?.businessId,
                type: 'VIEW',
                businessNAme: businessDetail[0]?.businessName
            }
        });
    }
    const handleViewDetailClick = (e) => {
        
        history.push('/location/viewlocation', {
            state: {
                value: e?.original?.locationId
            }
        });
    }

    const handleLocationNameSort = React.useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            
            return rowA.values.locationName.toLowerCase() > rowB.values.locationName.toLowerCase() ? 1 : -1;
        }
    }, [])


    // Column
    const columns1 = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "locationName",
                sortType: handleLocationNameSort,
                filterable: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{getValueFromObject('value', cell, '-')}</p>;
                },
            },
            {
                Header: "Region, City",
                accessor: d => `${d.region} ${d.city}`,
                filterable: true,
                disableSortBy: true,
                Cell: (cell) => {
                    return <p className='text wordBreak'>{cell.row.original.region ? `${cell.row.original.region}, ${cell.row.original.city}` : `${cell.row.original.city}`}</p>;
                },
            },
            {
                Header: "Location Manager",
                accessor: d => `${d.lFirstName} ${d.lLastName} ${d.lEmail} ${d.lContactNumber}`,
                filterable: true,
                disableSortBy: true,
                Cell: (cell) => {
                    return <div className='managerBox'>
                        <p className='name textOverflow'>{getValueFromObject('lFirstName', cell.row.original, '')} {getValueFromObject('lLastName', cell.row.original, '')}</p>
                        <p className='email textOverflow'>{getValueFromObject('lEmail', cell.row.original, '')}</p>
                        <p className='number textOverflow'>{businessLocationDetail?.lContactNumber ? `+${businessLocationDetail?.lCountryCode} ${businessLocationDetail?.lContactNumber}` : ''}</p>
                    </div>;
                },
            },
            {
                Header: `Station (${noOfStation ? noOfStation : '0'})`,
                accessor: "nStations",
                filterable: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value ? cell.value : '0'}</p>;
                },
            },
            {
                Header: `Connectors (${noOfConnectors ? noOfConnectors : '0'})`,
                accessor: "nConnectors",
                filterable: true,
                Cell: (cell) => {
                    return <p className='text'>{cell.value ? cell.value : '0'}</p>;
                },
            },
        ],
        [isChecked, businessLocationDetail, noOfStation, noOfConnectors, handleLocationNameSort]
    );
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        onClickBack={(e) => {
                            e.preventDefault();
                            history.goBack();
                        }}
                        isButton={true}
                        pageTitle="Businesses"
                        OnClickpageTitle={(e) => { e.preventDefault(); history.goBack(); }}
                        OnClickpageTitle2={(e) => { e.preventDefault(); history.goBack(); }}
                        title={businessDetail[0]?.businessName} />
                    {StationLoader
                        ? <div className='businessDetailPage'>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardHeader>
                                            <p className='headerTxt'>
                                                {getValueFromObject('businessName', businessDetail[0], '-')}
                                            </p>
                                            {/* business role start*/}
                                            {/* {!(getUserRole()) */}
                                            {!(window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null && JSON.parse(window.localStorage.getItem('users')).userRole !== 'BusinessOwner')
                                                ? <Row className='cardHeaderBoxRow'>
                                                    <Col lg={2} className='mb-2 leftPartCOlMain'>
                                                        <div className='leftPart  d-flex align-items-center justify-content-center flex-column'>
                                                            <div className='outLine d-flex align-items-center justify-content-center mb-2' style={{ backgroundColor: businessDetail[0]?.businessLogo ? '#f3f3f9' : businessDetail[0]?.primaryColor }}>
                                                                {businessDetail[0]?.businessLogo ?
                                                                    <img
                                                                        src={process.env.REACT_APP_CLOUDINARY_URL + businessDetail[0]?.businessLogo}
                                                                        alt=''
                                                                    /> :
                                                                    <span style={{ color: '#fff', fontSize: '20px' }}>{businessDetail[0]?.businessName?.toUpperCase().match(/\b\w/g).join('')}</span>

                                                                }
                                                            </div>
                                                            <p className='text-center'>{getValueFromObject('businessName', businessDetail[0], '-')}</p>
                                                        </div>
                                                    </Col>
                                                    <Col lg={10} className='mb-2 rightPartCOlMain'>
                                                        <div className='rightPart'>
                                                            <Row className='rightPartRow'>
                                                                <Col lg={10} className='lside'>
                                                                    <div>
                                                                        <Row className='rightpartRowInner'>
                                                                            <Col lg={4}>
                                                                                <div className='box d-flex align-items-start justify-content-start flex-column'>
                                                                                    <div className='header d-flex align-items-center justify-content-center mb-4'>
                                                                                        <div>
                                                                                            <i className='ri-money-dollar-circle-fill' />
                                                                                        </div>
                                                                                        <span className='txt'>No of Locations:</span>
                                                                                        <span className='value textOverflow'>{businessLocationDetail ? businessLocationDetail?.length : '0'}</span>
                                                                                    </div>
                                                                                    <div className='content'>
                                                                                        <p className='title mb-2'>Business Id</p>
                                                                                        <p className='txt1 mb-1'>{`${getValueFromObject('bPrefix', businessDetail[0], '-')}${getValueFromObject('rBusinessId', businessDetail[0], '-')}`}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={4}>
                                                                                <div className='box d-flex align-items-start justify-content-start flex-column'>
                                                                                    <div className='header d-flex align-items-center justify-content-center mb-4'>
                                                                                        <div>

                                                                                            <i className='ri-file-copy-2-fill' />
                                                                                        </div>
                                                                                        <span className='txt'>No of Stations:</span>
                                                                                        <span className='value textOverflow'>{businessLocationDetail?.length > 0 ? noOfStation : '0'}</span>
                                                                                    </div>
                                                                                    <div className='content'>
                                                                                        <p className='title mb-2'>Website</p>
                                                                                        <p className='txt1 mb-1'>{getValueFromObject('website', businessDetail[0], '-')}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={4}>
                                                                                <div className='box d-flex align-items-start justify-content-start flex-column'>
                                                                                    <div className='header d-flex align-items-center justify-content-center mb-4'>
                                                                                        <div>
                                                                                            <i className='ri-stack-fill' />
                                                                                        </div>
                                                                                        <span className='txt'>No of Connectors:</span>
                                                                                        <span className='value textOverflow'>{noOfConnectors ? noOfConnectors : '0'}</span>
                                                                                    </div>
                                                                                    <p className='themeTxt mb-2'>Theme</p>
                                                                                    <div className='themeBox'>
                                                                                        <div className='round'><span style={{ backgroundColor: businessDetail[0]?.primaryColor }}></span></div>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={2} className='rside'>
                                                                    <div className='box d-flex align-items-end justify-content-center flex-column'>
                                                                        <div className='actionBox'>
                                                                            <div className="form-check form-switch">
                                                                                <Input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    role="switch"
                                                                                    id=''
                                                                                    checked={businessDetail[0]?.status === 'Active' ? true : false}
                                                                                    onChange={() => {
                                                                                        
                                                                                        if (
                                                                                          businessDetail[0]
                                                                                            ?.status ===
                                                                                          "Active"
                                                                                        ) {
                                                                                          setDisabledModal(
                                                                                            true
                                                                                          );
                                                                                        } else {
                                                                                          setEnableModal(
                                                                                            true
                                                                                          );
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <Button
                                                                                className={businessDetail[0]?.status === 'Active' ? 'editBtn' : 'editBtn opactityDisabled'}
                                                                                onClick={() => {
                                                                                    // history.push('/businesses/editbusiness')
                                                                                    
                                                                                    history.push('/businesses/editbusiness', {
                                                                                        state: {
                                                                                            value: businessDetail[0]?.businessId,
                                                                                            type: 'Edit'
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                disabled={businessDetail[0]?.status === 'Active' ? false : true}
                                                                            >
                                                                                <i className='bx bx-edit-alt' />
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>


                                                :
                                                <Row className='dStrucutre'>

                                                    <div className='dLeft'>
                                                        {/* <div className='dBox'>

                                                    </div> */}
                                                        <div className='innerDiv'>
                                                            <div className='dBox'>
                                                                <div className='logoMain'>
                                                                    <div className='outLine d-flex align-items-center justify-content-center' style={{ backgroundColor: businessDetail[0]?.businessLogo ? '#f3f3f9' : businessDetail[0]?.primaryColor }}>
                                                                        {businessDetail[0]?.businessLogo ?
                                                                            <img
                                                                                src={process.env.REACT_APP_CLOUDINARY_URL + businessDetail[0]?.businessLogo}
                                                                                alt=''
                                                                            /> :
                                                                            <span style={{ color: '#fff', fontSize: '20px' }}>{businessDetail[0]?.businessName?.toUpperCase().match(/\b\w/g).join('')}</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='content'>
                                                                    <p className='title textOverflow mb-2'>Business ID</p>
                                                                    <p className='txt1 textOverflow mb-1'>{getValueFromObject('bPrefix', businessDetail[0], '-') + getValueFromObject('rBusinessId', businessDetail[0], '-')}</p>
                                                                </div>
                                                            </div>
                                                            <div className='dBox'>
                                                                <div className='content'>
                                                                    <p className='title mb-2'>Business owner name</p>
                                                                    <p className='txt1 textOverflow mb-1'>{getValueFromObject('firstName', businessDetail[0], '-')} {getValueFromObject('lastName', businessDetail[0], '-')}</p>
                                                                </div>
                                                            </div>
                                                            <div className='dBox'>
                                                                <div className='content'>
                                                                    <p className='title textOverflow mb-2'>Email address</p>
                                                                    <p className='txt1 mb-1'><a href='mailto:someone@example.com' target="">{getValueFromObject('email', businessDetail[0], '-')}</a></p>
                                                                </div>
                                                            </div>
                                                            <div className='dBox'>
                                                                <div className='content'>
                                                                    <p className='title textOverflow mb-2'>Phone number</p>
                                                                    <p className='txt1 textOverflow mb-1'>{`+${businessDetail[0]?.countryCode} ${getValueFromObject('contactNumber', businessDetail[0], '-')}`}</p>
                                                                </div>
                                                            </div>
                                                            <div className='dBox'>
                                                                <div className='content'>
                                                                    <p className='title textOverflow mb-2'>Website</p>
                                                                    <p className='txt1 mb-1'><a href='' target="_blank">{getValueFromObject('website', businessDetail[0], '-')}</a></p>
                                                                </div>
                                                            </div>
                                                            <div className='dBox'>
                                                                <div className='box d-flex align-items-start justify-content-center flex-column'>
                                                                    <p className='themeTxt mb-2'>Theme</p>
                                                                    <div className='themeBox'>
                                                                        <div className='round'><span style={{ backgroundColor: getValueFromObject('primaryColor', businessDetail[0], 'red') }}></span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='dRight'>
                                                        <div className='actionBox'>
                                                            <div className="form-check form-switch">
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    role="switch"
                                                                    id=''
                                                                    checked={businessDetail[0]?.status === 'Active' ? true : false}
                                                                    onChange={() => {
                                                                        if (businessDetail[0]?.status === 'Active') {
                                                                    
                                                                            setDisabledModal(true);
                                                                        } else {
                                                                            
                                                                            setEnableModal(true);


                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <Button
                                                                className={businessDetail[0]?.status === 'Active' ? 'editBtn' : 'editBtn opactityDisabled'}
                                                                onClick={() => {
                                                                    history.push('/businesses/editbusiness', {
                                                                        state: {
                                                                            value: businessDetail[0]?.businessId,
                                                                            type: 'Edit'
                                                                        }
                                                                    });
                                                                }}
                                                                disabled={businessDetail[0]?.status === 'Active' ? false : true}
                                                            >
                                                                <i className='bx bx-edit-alt' />
                                                            </Button>
                                                            <Button
                                                                // disabled={isChecked === false ? true : false}
                                                                className="text-danger d-inline-block remove-item-btn"
                                                                onClick={() => {
                                                                    setDeleteModal(true);
                                                                }}
                                                            >
                                                                <i className="bx bx-trash deleteBtn "></i>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Row>

                                            }
                                        </CardHeader>
                                        <CardBody>
                                            <div className='title'>
                                                <p className='Txt'>Locations</p>
                                            </div>
                                            <div className='table-content'>
                                                <div className={filteredTable.length > 0 && businessLocationDetail.length > 0 ? 'table-content' : 'table-content disablePointer'}>
                                                    <TableContainer
                                                        columns={columns1}
                                                        setfilteredTable={setfilteredTable}
                                                        data={businessLocationDetail}
                                                        isGlobalFilter={true}
                                                        customPageSize={16}
                                                        handleViewDetailClick={(row) => handleViewDetailClick(row)}
                                                        // tableHeight='600px'
                                                        // maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - 105}
                                                        divClass="table-responsive mb-1 businessDetailTable"
                                                        tableClass="align-middle table-nowrap"
                                                        theadClass="table-light text-muted"
                                                        isAddButton={true}
                                                        isAddButtonDisabled={businessDetail[0]?.status === 'Active' ? false : true}
                                                        searchPlaceholder='Search Location Name, Region, Location Manager'
                                                        handleAddButtonClicks={handleAddButtonClicks}
                                                        addButtonTitle={'Add Location'}
                                                        noData={businessLocationDetail.length > 0 ? false : true}
                                                        noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1670411148/Lubi/WebApp/Static-images/No_Location_found.svg"}
                                                        noDataTitle={'No Locations Found!'}
                                                    />
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div> : <ContentLoader />}
                </Container>
            </div>
            <DeleteModal
                show={deleteModal}
                title='Are you sure?'
                description='Are you sure you want to delete this record?'
                onDeleteClick={handleDeleteContact}
                onCloseClick={() => setDeleteModal(false)}
            />
            <DisableModal
                show={disabledModal}
                title='Are you sure?'
                subtitle='Are you sure you want to disable this business?'
                btnActionText='Yes, Disable it!'
                btncancelText='Cancel'
                onActionClick={handleDisableContact}
                onCloseClick={() => setDisabledModal(false)}
            />
            <DisableModal
                show={disabledLocationModal}
                title='Are you sure?'
                subtitle='Are you sure you want to disable this business?'
                btnActionText='Yes, Disable it!'
                btncancelText='Cancel'
                onActionClick={handleDisabledLocationModal}
                onCloseClick={() => setDisabledLocationModal(false)}
            />
            <DisableModal
                show={enableModal}
                title='Are you sure?'
                subtitle='Are you sure you want to enable this business?'
                btnActionText='Yes, enable it!'
                btncancelText='Cancel'
                onActionClick={handleEnableContact}
                onCloseClick={() => setEnableModal(false)}
            />
            <DisableModal
                show={enabledLocationModal}
                title='Are you sure?'
                subtitle='Are you sure you want to enable this business?'
                btnActionText='Yes, enable it!'
                btncancelText='Cancel'
                onActionClick={handleEnableLocation}
                onCloseClick={() => setEnabledLocationModal(false)}
            />
        </React.Fragment>
    );
};

export default BussinessDetail;