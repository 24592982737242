import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from 'reactstrap';
import { Tabs, Tab, Accordion } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import Flatpickr from "react-flatpickr";
import TableContainer from '../../../Components/Common/TableContainer';
import moment from "moment";
import useWindowDimension from '../../../Components/Hooks/useWindowDimension';

import { allLogs, showToast } from '../../../store/actions';
// import socket from "../../../socketConfig";
import socket from "../../../socketConfig"
import CustomDropdown from '../../../Components/Common/CustomDropdown';
import CustomButton from '../../../Components/Common/CustomButton';
import { CSVLink } from "react-csv";

import ContentLoader from '../../../Components/Common/ContentLoader';


const Logs = () => {
    document.title = "Logs | Vidhyutam";
    const dispatch = useDispatch();
    const history = useHistory();
    const [orderTab, setOrderTab] = useState('unAssign');
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [tableTaskBar, setTableTaskBar] = useState(0);
    const [paginationDiv, setPaginationDiv] = useState(0);
    const [cardHeader, setCardHeaderHeight] = useState(0);
    const dimensions = useWindowDimension();
    const [customerData, setStatusData] = useState([]);

    const [bootNotificationData, setBootData] = useState([]);
    const [heartData, setHeartData] = useState([]);
    const [remoteStartData, setRemoteStartData] = useState([]);
    const [loggingData, setLoggingData] = useState([]);
    const [remoteStopData, setRemoteStopData] = useState([]);
    const [meterValueData, setMeterValueData] = useState([]);
    const [changeFilterDate, setchangeFilterDate] = useState(new Date());
    const [activeKey, setActiveKey] = useState('');
    const [basicModal, setBasicModal] = useState(false);
    const [modelValue, setModelValue] = useState({});
    const [modelValueRes, setModelValueRes] = useState({});
    const [heartbeatIdVlaue, setHeartbeatIdVlaue] = useState('');
    const [heartbeatTabsFilterDate, setHeartbeatTabsFilterDate] = useState(new Date());
    const [bootNotificationIdVlaue, setBootNotificationIdVlaue] = useState('');
    const [bootNotificationTabsFilterDate, setBootNotificationTabsFilterDate] = useState(new Date());
    const [statusNotificationsIdVlaue, setStatusNotificationsIdVlaue] = useState('');
    const [statusNotificationsTabsFilterDate, setStatusNotificationsTabsFilterDate] = useState(new Date());
    const [statusNotifications, setStatusNotifications] = useState(null);

    const [remoteStartIdVlaue, setRemoteStartIdVlaue] = useState('');
    const [remoteStartRfidTag, setRemoteStartRfidTag] = useState('');
    const [remoteStartTransactionIdVlaue, setRemoteStartTransactionIdVlaue] = useState('');
    const [remoteStartTabsFilterDate, setRemoteStartTabsFilterDate] = useState(new Date());
    const [remoteStartStatus, setRemoteStartStatus] = useState(null);

    const [meterValuesId, setMeterValuesId] = useState('');
    const [meterValuesTransactionId, setMeterValuesTransactionId] = useState('');
    const [meterValuesTabsFilterDate, setMeterValuesTabsFilterDate] = useState(new Date());


    const [remoteStopIdVlaue, setRemoteStopIdVlaue] = useState('');
    const [remoteStopTransactionIdVlaue, setRemoteStopTransactionIdVlaue] = useState('');
    const [remoteStopTabsFilterDate, setRemoteStopTabsFilterDate] = useState(new Date());
    const [remoteStopStatus, setRemoteStopStatus] = useState(null);


    const [logsIdVlaue, setLogsIdVlaue] = useState('');

    const [logsTabsFilterDate, setLogsTabsFilterDate] = useState(new Date());
    const [logsAction, setLogsAction] = useState(null);
    const [downloadData, setDownloadData] = useState()
    const [customLoader, setCustomLoader] = useState(false)
    const [liveSocketData, setLiveSocketData] = useState()
    const [statusFilterData, setStatusFilterData] = useState([])
    const [bootFilterData, setBootFilterData] = useState([])
    const [heartFilterData, setHeartFilterData] = useState([])
    const [startTraFilterData, setStartTraFilterData] = useState([])
    const [meterFilterData, setMeterFilterData] = useState([])
    const [stopTrFilterData, setStopTraFilterData] = useState([])
    const [loggingFilterData, setLoggingFilterData] = useState([])
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [currentPageSize, setCurrentPageSize] = useState(20)
    const [skipPageSize, setSkipPageSize] = useState(0)

    const [currentPayloadData, setCurrentPayloadData] = useState([])


    let allLogsData = [];
    useEffect(() => {
        handleTabSelect(activeKey, changeFilterDate)
    }, [liveSocketData])
    useEffect(() => {
        // setchangeFilterDate(new Date())
        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
        setLiveSocketData(false)
    }, []);

    const handleResize = () => {

        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setTableTaskBar(
            document.getElementsByClassName('tableTaskBar')[0]?.offsetHeight
        );
        setPaginationDiv(
            document.getElementsByClassName('paginationDiv')[0]?.offsetHeight
        );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, [orderTab]);

    // useEffect(() => {
    //     if (window.localStorage.getItem("users") !== null) {

    //     }

    // }, [window.localStorage.getItem("users")]);
    // useEffect(() => {
    //     socket.on('statusNotificationsWEB', (res) => {

    //         // return;
    //         // let a = moment(JSON.parse(res).timestamp);
    //         // let b = moment(changeFilterDate)
    //         // if (a.isSame(b, 'day') && a.isSame(b, 'month') && a.isSame(b, 'year')) {
    //         // } else {
    //         // }


    //         if (JSON.parse(res).purpose == "statusNotifications") {
    //             //    setStatusData((prev) => [...prev, JSON.parse(res)])
    //         } else {
    //         }
    //     });


    // }, []);

    useEffect(() => {
        socket.on('heartbeatWEB', (res) => {
            let parsedObj = JSON.parse(res);
            let responseData = JSON.stringify({ currentTime: moment(parsedObj.currentTime).format('DD-MM-YYYY HH:mm:ss') });
            let newObj = {
                "requestData": res,
                "responseData": responseData,
                ...parsedObj
            };


            if (liveSocketData) {
                setHeartData((prev) => [...prev, newObj])
                setLoggingData((prev) => [...prev, newObj])

                if (newObj.purpose == "Heartbeat" && heartbeatIdVlaue === '' && moment(newObj.currentTime).format('DD-MM-YYYY') === moment(heartbeatTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    // setHeartData((prev) => [...prev, newObj])
                    var stillUtc = moment.utc(parsedObj.currentTime).toDate();
                    var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                    newObj = { ...newObj, currentTime: local }

                    setHeartFilterData((prev) => [...prev, newObj])


                } else if (newObj.purpose == "Heartbeat" && heartbeatIdVlaue === newObj.chargerId && moment(newObj.currentTime).format('DD-MM-YYYY') === moment(heartbeatTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    // setHeartData((prev) => [...prev, newObj])
                    var stillUtc = moment.utc(parsedObj.currentTime).toDate();
                    var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                    newObj = { ...newObj, currentTime: local }
                    setHeartFilterData((prev) => [...prev, newObj])
                } else {
                    console.log('anot')
                }




                let isMatch = (
                    (newObj?.chargerId === logsIdVlaue || logsIdVlaue === undefined || logsIdVlaue === null || logsIdVlaue === '') &&
                    (moment(newObj?.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate).format('DD-MM-YYYY') || moment(newObj?.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) &&
                    (newObj?.purpose === logsAction?.value || logsAction?.value === undefined || logsAction?.value === null)
                )
                if (isMatch) {
                    var stillUtc = moment.utc(newObj.timestamp).toDate();
                    var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                    newObj = { ...newObj, timestamp: local }
                    setLoggingFilterData((prev) => [...prev, newObj])
                }

            }


        });
        return () => {
            socket.off('heartbeatWEB');
        };
    }, [heartbeatTabsFilterDate, heartbeatIdVlaue, logsTabsFilterDate, logsIdVlaue, logsAction, liveSocketData]);

    useEffect(() => {
        socket.on('bootNotificationsWEBNode', (res) => {
            let parsedObj = JSON.parse(res);
            let responseData = JSON.stringify({ timestamp: moment(parsedObj.currentTime).format('DD-MM-YYYY HH:mm:ss'), status: parsedObj.status, interval: 300 });

            let newObj = {
                "requestData": res,
                "responseData": responseData,
                ...parsedObj
            };
            if (liveSocketData) {
                setBootData((prev) => [...prev, newObj])
                setLoggingData((prev) => [...prev, newObj])

                if (newObj.purpose == "bootNotification" && bootNotificationIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(bootNotificationTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    var stillUtc = moment.utc(parsedObj.timestamp).toDate();
                    var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                    newObj = { ...newObj, timestamp: local }
                    setBootFilterData((prev) => [...prev, newObj])
                } else if (newObj.purpose == "bootNotification" && bootNotificationIdVlaue === newObj.chargerId && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(bootNotificationTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    var stillUtc = moment.utc(parsedObj.timestamp).toDate();
                    var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                    newObj = { ...newObj, timestamp: local }
                    setBootFilterData((prev) => [...prev, newObj])

                } else {
                    console.log('cnot')
                }

                let isMatch = (
                    (newObj?.chargerId === logsIdVlaue || logsIdVlaue === undefined || logsIdVlaue === null || logsIdVlaue === '') &&
                    (moment(newObj?.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate).format('DD-MM-YYYY') || moment(newObj?.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) &&
                    (newObj?.purpose === logsAction?.value || logsAction?.value === undefined || logsAction?.value === null)
                )
                if (isMatch) {
                    var stillUtc = moment.utc(parsedObj.timestamp).toDate();
                    var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                    newObj = { ...newObj, timestamp: local }
                    setLoggingFilterData((prev) => [...prev, newObj])
                }

            }



        });
        return () => {
            socket.off('bootNotificationsWEBNode');
        };
    }, [bootNotificationTabsFilterDate, bootNotificationIdVlaue, logsTabsFilterDate, logsIdVlaue, logsAction, liveSocketData]);


    useEffect(() => {
        socket.on('statusNotificationsWEB', (res) => {
            let parsedObj = JSON.parse(res);


            let newObj = {
                "requestData": res,
                "responseData": JSON.stringify({ data: {} }),
                ...parsedObj
            };

            if (liveSocketData) {
                setStatusData((prev) => [...prev, newObj])
                setLoggingData((prev) => [...prev, newObj])

                if (newObj.purpose == "statusNotifications" && statusNotifications == null && statusNotificationsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(statusNotificationsTabsFilterDate[0]).format('DD-MM-YYYY')) {

                    var stillUtc = moment.utc(parsedObj.timestamp).toDate();
                    var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                    newObj = { ...newObj, timestamp: local }

                    setStatusFilterData((prev) => [...prev, newObj])

                } else if (newObj.purpose == "statusNotifications" && newObj.status == statusNotifications?.value && statusNotificationsIdVlaue === newObj.chargerId && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(statusNotificationsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    // setStatusData((prev) => [...prev, newObj])
                    var stillUtc = moment.utc(parsedObj.timestamp).toDate();
                    var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                    newObj = { ...newObj, timestamp: local }
                    setStatusFilterData((prev) => [...prev, newObj])

                }
                else if (newObj.purpose == "statusNotifications" && newObj.status == statusNotifications?.value && statusNotificationsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(statusNotificationsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    // setStatusData((prev) => [...prev, newObj])
                    var stillUtc = moment.utc(parsedObj.timestamp).toDate();
                    var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                    newObj = { ...newObj, timestamp: local }
                    setStatusFilterData((prev) => [...prev, newObj])

                } else {
                    console.log('not')
                }

                let isMatch = (
                    (newObj?.chargerId === logsIdVlaue || logsIdVlaue === undefined || logsIdVlaue === null || logsIdVlaue === '') &&
                    (newObj?.timestamp.split(' ')[0] === moment(logsTabsFilterDate).format('DD-MM-YYYY') || newObj?.timestamp.split(' ')[0] === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) &&
                    (newObj?.purpose === logsAction?.value || logsAction?.value === undefined || logsAction?.value === null)
                )

                if (isMatch) {
                    setLoggingFilterData((prev) => [...prev, newObj])
                }



                // if (logsAction == null && logsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                //     setLoggingData((prev) => [...prev, newObj])
                //     setLoggingFilterData((prev) => [...prev, newObj])

                // } else if (newObj.purpose == logsAction?.value && logsIdVlaue === newObj.chargerId && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                //     setLoggingData((prev) => [...prev, newObj])
                //     setLoggingFilterData((prev) => [...prev, newObj])

                // }
                // else if (newObj.purpose == logsAction?.value && logsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                //     setLoggingData((prev) => [...prev, newObj])
                //     setLoggingFilterData((prev) => [...prev, newObj])

                // } else {
                // }
                // setStatusFilterData(customerData)
            }
        });
        return () => {
            socket.off('statusNotificationsWEB');
        };

    }, [statusNotificationsTabsFilterDate, statusNotificationsIdVlaue, statusNotifications, logsTabsFilterDate, logsIdVlaue, logsAction, liveSocketData]);
    //   setRemoteStartData((prev) => [...prev, JSON.parse(res)])

    const queryParamsToObject = (queryParamsString) => {
        const queryStringWithoutQuestionMark = queryParamsString.startsWith('?')
            ? queryParamsString.substring(1)
            : queryParamsString;
        const queryParamsArray = queryStringWithoutQuestionMark.split("&");
        const queryParamsObject = {};

        queryParamsArray.forEach(param => {
            const [key, value] = param.split("=");
            queryParamsObject[key] = value;
        });

        return queryParamsObject;
    }



    useEffect(() => {
        socket.on('startransactionWEBNode', (res) => {

            let parsedObj = JSON.parse(res);
            let newO = {
                'idTagInfo': { "status": "Accepted", "expiryDate": moment(new Date().toISOString()).format('DD-MM-YYYY HH:mm:ss') },
                'transactionId': parsedObj.transactionId
            };

            let newObj = {
                "requestData": res,
                "responseData": JSON.stringify(newO),
                ...parsedObj
            };
            setRemoteStartData((prev) => [...prev, newObj])
            setLoggingData((prev) => [...prev, newObj])

            if (liveSocketData) {

                let fullDate = moment(remoteStartTabsFilterDate[0]).format('DD-MM-YYYY');
                let queryParams = '';

                if (fullDate) {
                    queryParams += `date=${fullDate}&`;
                }

                if (activeKey) {
                    queryParams += `purpose=${activeKey}&`;
                }

                if (remoteStartIdVlaue) {
                    queryParams += `chargerId=${remoteStartIdVlaue}&`;
                }

                if (remoteStartStatus) {
                    queryParams += `status=${remoteStartStatus.value}&`;
                }

                if (remoteStartTransactionIdVlaue) {
                    queryParams += `transactionId=${remoteStartTransactionIdVlaue}&`;
                }

                if (remoteStartRfidTag) {
                    queryParams += `idTag=${remoteStartRfidTag}&`;
                }

                // Remove trailing '&' if there are any parameters
                queryParams = queryParams.length > 0 ? `?${queryParams.slice(0, -1)}` : '';

                // Convert query parameters string to object
                const queryParamsObject = queryParamsToObject(queryParams);
                let isMatch = (queryParamsObject?.chargerId === newObj?.chargerId || queryParamsObject.chargerId === undefined) &&
                    queryParamsObject?.date === moment(newObj?.timeStamp).format('DD-MM-YYYY') &&
                    (queryParamsObject?.status === newObj?.status || queryParamsObject.status === undefined) &&
                    (queryParamsObject?.idTag === newObj?.idTag || queryParamsObject.idTag === undefined) &&
                    (queryParamsObject?.transactionId === newObj?.transactionId || queryParamsObject.transactionId === undefined);
                if (isMatch) {
                    // setRemoteStartData((prev) => [...prev, newObj])
                    newObj.timestamp = newObj.timeStamp;
                    var stillUtc = moment.utc(newObj.timestamp).toDate();
                    var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                    newObj = { ...newObj, timestamp: local }

                    setStartTraFilterData((prev) => [...prev, newObj])

                }


                let isMatch1 = (
                    (newObj?.chargerId === logsIdVlaue || logsIdVlaue === undefined || logsIdVlaue === null || logsIdVlaue === '') &&
                    (newObj?.timestamp.split(' ')[0] === moment(logsTabsFilterDate).format('DD-MM-YYYY') || newObj?.timestamp.split(' ')[0] === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) &&
                    (newObj?.purpose === logsAction?.value || logsAction?.value === undefined || logsAction?.value === null)
                )
                if (isMatch1) {
                    setLoggingFilterData((prev) => [...prev, newObj])
                }

            }
        });
        return () => {
            socket.off('startransactionWEBNode');
        };
    }, [remoteStartIdVlaue, remoteStartRfidTag, remoteStartTransactionIdVlaue, remoteStartTabsFilterDate, remoteStartStatus, logsTabsFilterDate, logsIdVlaue, logsAction, liveSocketData]);

    useEffect(() => {
        socket.on('metervalueWEBNode', (res) => {
            if (JSON.parse(res).transactionId !== undefined) {


                let da = JSON.parse(res).meterValues.filter(item1 => item1.unit === 'Wh');
                let percentValue = JSON.parse(res).meterValues.find(meter => meter.unit === 'Percent');
                let newObj = {
                    chargerId: JSON.parse(res).station,
                    connectorId: JSON.parse(res).connectorId,
                    timeStamp: JSON.parse(res).timeStamp,
                    transactionId: JSON.parse(res).transactionId,
                    purpose: JSON.parse(res).purpose,
                    unit: da[0].unit,
                    // value: Math.round(da[0].value),

                    value: da.length > 0 ? `${Math.round(da[0].value)}${percentValue ? ` (${percentValue.value})%` : ''}` : undefined,
                    "requestData": res,

                }
                let newObj1 = {
                    "requestData": newObj,
                    "responseData": JSON.stringify({ data: {} }),
                    ...newObj
                };
                setMeterValueData((prev) => [...prev, newObj1])
                setLoggingData((prev) => [...prev, newObj1])

                JSON.parse(res).meterValues.map((item1) => {
                    if (item1.unit === 'Wh' && item1.context !== "Sample.Clock") {
                        if (liveSocketData) {

                            if (meterValuesTransactionId == '' && meterValuesId === '' && moment(JSON.parse(res).timeStamp).format('DD-MM-YYYY') === moment(meterValuesTabsFilterDate[0]).format('DD-MM-YYYY')) {
                                // setMeterValueData((prev) => [...prev, newObj1])
                                var stillUtc = moment.utc(JSON.parse(res).timeStamp).toDate();
                                var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                                newObj1 = { ...newObj1, timeStamp: local }
                                setMeterFilterData((prev) => [...prev, newObj1])


                            } else if (JSON.parse(res).transactionId.toString() !== meterValuesTransactionId.toString() && meterValuesId === JSON.parse(res).station && moment(JSON.parse(res).timeStamp).format('DD-MM-YYYY') === moment(meterValuesTabsFilterDate[0]).format('DD-MM-YYYY')) {
                                // setMeterValueData((prev) => [...prev, newObj1])
                                var stillUtc = moment.utc(JSON.parse(res).timeStamp).toDate();
                                var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                                newObj1 = { ...newObj1, timeStamp: local }
                                setMeterFilterData((prev) => [...prev, newObj1])

                            }
                            else if (JSON.parse(res).transactionId.toString() === meterValuesTransactionId.toString() && meterValuesId === '' && moment(JSON.parse(res).timeStamp).format('DD-MM-YYYY') === moment(meterValuesTabsFilterDate[0]).format('DD-MM-YYYY')) {
                                // setMeterValueData((prev) => [...prev, newObj1])
                                var stillUtc = moment.utc(JSON.parse(res).timeStamp).toDate();
                                var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                                newObj1 = { ...newObj1, timeStamp: local }
                                setMeterFilterData((prev) => [...prev, newObj1])


                            } else if (JSON.parse(res).transactionId.toString() === meterValuesTransactionId.toString() && meterValuesId === JSON.parse(res).station && moment(JSON.parse(res).timeStamp).format('DD-MM-YYYY') === moment(meterValuesTabsFilterDate[0]).format('DD-MM-YYYY')) {
                                // setMeterValueData((prev) => [...prev, newObj1])
                                var stillUtc = moment.utc(JSON.parse(res).timeStamp).toDate();
                                var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                                newObj1 = { ...newObj1, timeStamp: local }
                                setMeterFilterData((prev) => [...prev, newObj1])


                            } else {
                                console.log('fnot')
                            }

                            let isMatch = (
                                (newObj?.chargerId === logsIdVlaue || logsIdVlaue === undefined || logsIdVlaue === null || logsIdVlaue === '') &&
                                (moment(newObj?.timeStamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate).format('DD-MM-YYYY') || moment(newObj?.timeStamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) &&
                                (newObj?.purpose === logsAction?.value || logsAction?.value === undefined || logsAction?.value === null)
                            )
                            if (isMatch) {
                                var stillUtc = moment.utc(newObj.timeStamp).toDate();
                                var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                                newObj = { ...newObj, timestamp: local }
                                setLoggingFilterData((prev) => [...prev, newObj])
                            }

                        }
                    }

                });


            }
        });
        return () => {
            socket.off('metervalueWEBNode');
        };
    }, [meterValuesTabsFilterDate, meterValuesTransactionId, meterValuesId, logsTabsFilterDate, logsIdVlaue, logsAction, liveSocketData]);


    useEffect(() => {
        socket.on('stoptransactionWEBNode', (res) => {
            if (JSON.parse(res).purpose == "remoteStop") {

                let parsedObj = JSON.parse(res);
                let newO = {
                    "expiryDate": moment(new Date().toISOString()).format('DD-MM-YYYY HH:mm:ss'),
                    "status": "Accepted",
                };
                let newObj = {
                    "requestData": res,
                    "responseData": JSON.stringify(newO),
                    ...parsedObj
                };
                setRemoteStopData((prev) => [...prev, newObj])
                setLoggingData((prev) => [...prev, newObj])

                if (liveSocketData) {
                    let fullDate = moment(remoteStopTabsFilterDate[0]).format('DD-MM-YYYY');
                    let queryParams = '';

                    if (fullDate) {
                        queryParams += `date=${fullDate}&`;
                    }

                    if (activeKey) {
                        queryParams += `purpose=${activeKey}&`;
                    }

                    if (remoteStopIdVlaue) {
                        queryParams += `chargerId=${remoteStopIdVlaue}&`;
                    }

                    if (remoteStopStatus) {
                        queryParams += `status=${remoteStopStatus.value}&`;
                    }

                    if (remoteStopTransactionIdVlaue) {
                        queryParams += `transactionId=${remoteStopTransactionIdVlaue}&`;
                    }

                    // Remove trailing '&' if there are any parameters
                    queryParams = queryParams.length > 0 ? `?${queryParams.slice(0, -1)}` : '';



                    const queryParamsObject = queryParamsToObject(queryParams);

                    let isMatch = (queryParamsObject?.chargerId === newObj?.chargerId || queryParamsObject.chargerId === undefined) &&
                        queryParamsObject?.date === moment(newObj?.timeStamp).format('DD-MM-YYYY') &&
                        (queryParamsObject?.status === newObj?.status || queryParamsObject.status === undefined) &&
                        (queryParamsObject?.transactionId === newObj?.transactionId || queryParamsObject.transactionId === undefined);

                    var stillUtc = moment.utc(JSON.parse(res).timeStamp).toDate();
                    var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                    newObj = { ...newObj, timeStamp: local }

                    if (isMatch) {
                        setStopTraFilterData((prev) => [...prev, newObj])
                    }


                    let isMatch1 = (
                        (newObj?.chargerId === logsIdVlaue || logsIdVlaue === undefined || logsIdVlaue === null || logsIdVlaue === '') &&
                        (moment(newObj?.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate).format('DD-MM-YYYY') || moment(newObj?.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) &&
                        (newObj?.purpose === logsAction?.value || logsAction?.value === undefined || logsAction?.value === null)
                    )
                    var stillUtc = moment.utc(newObj.timestamp).toDate();
                    var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss');
                    newObj = { ...newObj, timestamp: local }
                    if (isMatch1) {
                        setLoggingFilterData((prev) => [...prev, newObj])
                    }
                }
            }
        });
        return () => {
            socket.off('stoptransactionWEBNode');
        };


    }, [remoteStopIdVlaue, remoteStopTransactionIdVlaue, remoteStopTabsFilterDate, remoteStopStatus, logsTabsFilterDate, logsIdVlaue, logsAction, liveSocketData]);
    const handleViewDetailClick = async (data) => {
        let finalData;
        let finalData1;
        if (JSON.parse(data?.original?.requestData)?.timeStamp) {
            const parsedData = JSON.parse(data?.original?.requestData);
            parsedData.timeStamp = moment(parsedData.timeStamp).format('DD-MM-YYYY HH:mm:ss');
            finalData = parsedData
        }
        if (JSON.parse(data?.original?.requestData)?.timestamp) {
            const parsedData = JSON.parse(data?.original?.requestData);
            parsedData.timestamp = moment(parsedData.timestamp).format('DD-MM-YYYY HH:mm:ss');
            finalData = parsedData
        }
        if (JSON.parse(data?.original?.requestData)?.currentTime) {
            const parsedData = JSON.parse(data?.original?.requestData);
            parsedData.currentTime = moment(parsedData.currentTime).format('DD-MM-YYYY HH:mm:ss');
            finalData = parsedData
        }
        if (data?.original?.purpose === "statusNotifications") {

            if (data?.original?.responseData != undefined) {
                finalData1 = JSON.parse(data?.original?.responseData)

            } else {
                finalData1 = {}

            }
        }

        if (data?.original?.purpose === "meterValue") {
            if (data?.original?.responseData != undefined) {
                finalData1 = JSON.parse(data?.original?.responseData)

            } else {
                finalData1 = {}

            }
        }
        if (data?.original?.purpose === "bootNotification") {
            if (data?.original?.responseData != undefined) {
                finalData1 = JSON.parse(data?.original?.responseData)
                finalData1.currentTime = moment(finalData1.currentTime).format('DD-MM-YYYY HH:mm:ss');


            } else {
                finalData1 = {}

            }
        }
        if (data?.original?.purpose === "Heartbeat") {
            if (data?.original?.responseData != undefined) {
                finalData1 = JSON.parse(data?.original?.responseData)

                if (finalData1.currentTime.includes("T") && finalData1.currentTime.includes("Z")) {
                    finalData1.currentTime = moment(finalData1.currentTime).format('DD-MM-YYYY HH:mm:ss');
                } else {
                    finalData1.currentTime = finalData1.currentTime
                }
            } else {
                finalData1 = {}

            }
        }
        if (data?.original?.purpose === "RemoteStart") {
            if (data?.original?.responseData != undefined) {
                finalData1 = JSON.parse(data?.original?.responseData)

                if (finalData1.idTagInfo.expiryDate.includes("T") && finalData1.idTagInfo.expiryDate.includes("Z")) {
                    finalData1.idTagInfo.expiryDate = moment(finalData1.idTagInfo.expiryDate).format('DD-MM-YYYY HH:mm:ss');
                } else {
                    finalData1.idTagInfo.expiryDate = finalData1.idTagInfo.expiryDate
                }



            } else {
                finalData1 = {}

            }

        }
        if (data?.original?.purpose === "remoteStop") {
            if (data?.original?.responseData != undefined) {
                finalData1 = JSON.parse(data?.original?.responseData)
                if (finalData1.expiryDate.includes("T") && finalData1.expiryDate.includes("Z")) {
                    finalData1.expiryDate = moment(finalData1.expiryDate).format('DD-MM-YYYY HH:mm:ss');
                } else {
                    finalData1.expiryDate = finalData1.expiryDate
                }
            } else {
                finalData1 = {}
            }
        }

        setModelValue(finalData)
        setModelValueRes(finalData1)
        setBasicModal(true);

    };


    const exportDataFunc = () => {

        if (!liveSocketData) {

            dispatch(allLogs(currentPayloadData, (response) => {
                // Remove rows with only null or empty values
                const filteredData = response.filter(row => {
                    for (const key in row) {
                        if (row[key] !== null && row[key] !== undefined && row[key] !== '') {
                            return true; // Row has at least one non-null, non-empty value
                        }
                    }
                    return false; // Row has only null or empty values, exclude it
                });
                initiateCSVDownload(filteredData);
                if (activeKey === 'Heartbeat') {
                    let newArray = response.map(obj => {
                        // Destructure the 'name' property and create a new object with 'fullname'
                        let { timestamp: currentTime, ...rest } = obj;
                        return { currentTime, ...rest };
                    });
                    initiateCSVDownload(newArray);
                }
                if (activeKey === 'meterValue') {
                    let newArray = response.map(obj => {
                        // Destructure the 'name' property and create a new object with 'fullname'
                        let { timestamp: timeStamp, ...rest } = obj;
                        return { timeStamp, ...rest };
                    });
                    initiateCSVDownload(newArray);
                }


                // After setting the data, initiate the CSV download
            }, (error) => { }));
        } else {
            if (activeKey === '') {
                initiateCSVDownload(loggingFilterData);
            }
            if (activeKey === 'Heartbeat') {
                initiateCSVDownload(heartFilterData);
            }
            if (activeKey === 'bootNotification') {
                initiateCSVDownload(bootFilterData);
            }
            if (activeKey === 'statusNotifications') {
                initiateCSVDownload(statusFilterData);
            }

            if (activeKey === 'RemoteStart') {
                initiateCSVDownload(startTraFilterData);
            }
            if (activeKey === 'meterValue') {
                initiateCSVDownload(meterFilterData);
            }
            if (activeKey === 'remoteStop') {
                initiateCSVDownload(stopTrFilterData);
            }
        }
    }
    const initiateCSVDownload = (data) => {

        if (data.length > 0) {
            // Create a temporary anchor element
            const link = document.createElement('a');
            link.setAttribute('href', encodeURI(createCSVContent(data)));
            link.setAttribute('download', activeKey !== '' ? activeKey : 'logging.csv');
            // Simulate click on the anchor element to trigger download
            link.click();
        }
    }

    const createCSVContent = (data) => {
        let headers = [];
        if (activeKey === '') {
            headers = ['chargerId', 'timestamp', 'purpose', 'requestData', 'responseData'];
        }
        if (activeKey === 'Heartbeat') {
            headers = ['chargerId', 'currentTime', 'requestData', 'responseData'];
        }
        if (activeKey === 'bootNotification') {
            headers = ['timestamp', 'chargerId', 'chargePointModel', 'chargePointSerialNumber', 'chargePointVendor', 'firmwareVersion', 'requestData', 'responseData'];
        }
        if (activeKey === 'statusNotifications') {
            headers = ['timestamp', 'connectorId', 'chargerId', 'status', 'errorCode', 'vendorId', 'vendorErrorCode', 'requestData', 'responseData'];
        }
        if (activeKey === 'RemoteStart') {
            headers = ['chargerId', 'connectorId', 'transactionId', 'idTag', 'status', 'timestamp', 'requestData', 'responseData'];
        }
        if (activeKey === 'meterValue') {
            headers = ['chargerId', 'connectorId', 'timeStamp', 'unit', 'value', 'transactionId', 'requestData', 'responseData'];
        }
        if (activeKey === 'remoteStop') {
            headers = ['chargerId', 'connectorId', 'transactionId', 'reason', 'status', 'timestamp', 'requestData', 'responseData'];
        }
        const csvContent = [
            headers.join(','), // Header row
            ...data.map(row => headers.map(header => row[header]).join(',')) // Data rows
        ].join('\n');
        return 'data:text/csv;charset=utf-8,' + csvContent;
    }

    // const heartbeatExportData = heartFilterData?.map((item) => ({
    //     chargerId: item?.chargerId,
    //     createdAt: moment(item?.createdAt).format('DD-MM-YYYY HH:mm:ss'),
    // }));


    // const bootExportData = bootFilterData?.map((item) => ({
    //     chargerId: item?.chargerId,
    //     status: 'Accepted',
    //     createdAt: moment(item?.createdAt).format('DD-MM-YYYY HH:mm:ss'),
    //     chargePointVendor: item.chargePointVendor,
    //     chargePointModel: item.chargePointModel,
    //     chargePointSerialNumber: item.chargePointSerialNumber,
    //     firmwareVersion: item.firmwareVersion,
    // }));
    // const statusExportData = statusFilterData?.map((item) => ({
    //     chargerId: item?.chargerId,
    //     connectorId: item?.connectorId,
    //     status: item?.status,
    //     createdAt: moment(item?.createdAt).format('DD-MM-YYYY HH:mm:ss'),
    //     errorCode: item?.errorCode,
    //     vendorId: item?.vendorId,
    //     vendorErrorCode: item?.vendorErrorCode,
    // }));

    // const remoteStartExportData = startTraFilterData?.map((item) => ({
    //     chargerId: item?.chargerId,
    //     connectorId: item?.connectorId,
    //     transactionId: item?.transactionId,
    //     idTag: item?.idTag,
    //     status: item?.status,
    //     createdAt: moment(item?.createdAt).format('DD-MM-YYYY HH:mm:ss'),
    // }));

    // const meterValueExportData = meterFilterData?.map((item) => ({
    //     chargerId: item?.chargerId,
    //     connectorId: item?.connectorId,
    //     createdAt: moment(item?.createdAt).format('DD-MM-YYYY HH:mm:ss'),
    //     transactionId: item?.transactionId,
    //     unit: item?.unit,
    //     value: item?.value,
    // }));

    // const remoteStopExportData = stopTrFilterData?.map((item) => ({
    //     chargerId: item?.chargerId,
    //     connectorId: item?.connectorId,
    //     transactionId: item?.transactionId,
    //     reason: item?.reason,
    //     status: item?.status,
    //     createdAt: moment(item?.createdAt).format('DD-MM-YYYY HH:mm:ss'),
    // }));


    // const loggingExportData = loggingFilterData?.map((item) => ({
    //     chargerId: item?.chargerId,
    //     purpose: item?.purpose,
    //     timestamp: moment(item?.timestamp).format('DD-MM-YYYY HH:mm:ss'),
    // }));


    const statusNotificationNextPage = async (data) => {

        setCurrentPageIndex(currentPageIndex + 1)
        setSkipPageSize((currentPageIndex) * currentPageSize)
        setCurrentPayloadData([`?date=${moment(statusNotificationsTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=statusNotifications`])

        let queryParams = `?date=${moment(statusNotificationsTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=statusNotifications&limit=${currentPageSize}&skip=${currentPageIndex * currentPageSize}`
        if (statusNotificationsIdVlaue && statusNotificationsIdVlaue != '') {
            queryParams += `&chargerId=${statusNotificationsIdVlaue}`
        }

        if (statusNotifications && statusNotifications != null) {
            queryParams += `&status=${statusNotifications.value}`
        }

        dispatch(allLogs(queryParams, (response) => {

            setCustomLoader(true)
            // setStatusData(response);
            setStatusFilterData(response)

        }, (error) => { }));
    }

    const statusNotificationPrevPage = async (data) => {
        setSkipPageSize(((currentPageIndex - 2) * currentPageSize))
        setCurrentPageIndex(currentPageIndex - 1)

        setCurrentPayloadData([`?date=${moment(statusNotificationsTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=statusNotifications`])


        let queryParams = `?date=${moment(statusNotificationsTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=statusNotifications&limit=${currentPageSize}&skip=${((currentPageIndex - 2) * currentPageSize)}`
        if (statusNotificationsIdVlaue && statusNotificationsIdVlaue != '') {
            queryParams += `&chargerId=${statusNotificationsIdVlaue}`
        }

        if (statusNotifications && statusNotifications != null) {
            queryParams += `&status=${statusNotifications.value}`
        }


        dispatch(allLogs(queryParams, (response) => {

            setCustomLoader(true)
            // setStatusData(response);
            setStatusFilterData(response)

        }, (error) => { }));
    }

    const heartBeatNextPage = async (data) => {

        setCurrentPageIndex(currentPageIndex + 1)
        setSkipPageSize((currentPageIndex) * currentPageSize)
        setCurrentPayloadData([`?date=${moment(heartbeatTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=Heartbeat`])

        let queryParams = `?date=${moment(heartbeatTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=Heartbeat&limit=${currentPageSize}&skip=${((currentPageIndex - 2) * currentPageSize)}`
        if (heartbeatIdVlaue && heartbeatIdVlaue != '') {
            queryParams += `&chargerId=${heartbeatIdVlaue}`
        }


        dispatch(allLogs(queryParams, (response) => {

            setCustomLoader(true)
            let newArray = response.map(obj => {
                // Destructure the 'name' property and create a new object with 'fullname'
                let { timestamp: currentTime, ...rest } = obj;
                return { currentTime, ...rest };
            });
            setHeartFilterData(newArray)


        }, (error) => { }));
    }

    const heartBeatPrevPage = async (data) => {
        setSkipPageSize(((currentPageIndex - 2) * currentPageSize))
        setCurrentPageIndex(currentPageIndex - 1)

        setCurrentPayloadData([`?date=${moment(heartbeatTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=Heartbeat`])
        let queryParams = `?date=${moment(heartbeatTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=Heartbeat&limit=${currentPageSize}&skip=${((currentPageIndex - 2) * currentPageSize)}`
        if (heartbeatIdVlaue) {
            queryParams += `&chargerId=${heartbeatIdVlaue}`
        }
        dispatch(allLogs(queryParams, (response) => {

            setCustomLoader(true)
            // setStatusData(response);
            let newArray = response.map(obj => {
                // Destructure the 'name' property and create a new object with 'fullname'
                let { timestamp: currentTime, ...rest } = obj;
                return { currentTime, ...rest };
            });
            setHeartFilterData(newArray)

        }, (error) => { }));
    }


    const bootNextPage = async (data) => {

        setCurrentPageIndex(currentPageIndex + 1)
        setSkipPageSize((currentPageIndex) * currentPageSize)


        setCurrentPayloadData([`?date=${moment(bootNotificationTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=bootNotification`])

        let queryParams = `?date=${moment(bootNotificationTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=bootNotification&limit=${currentPageSize}&skip=${currentPageIndex * currentPageSize}`
        if (bootNotificationIdVlaue) {
            queryParams += `&chargerId=${bootNotificationIdVlaue}`
        }

        dispatch(allLogs(queryParams, (response) => {

            setCustomLoader(true)
            setBootFilterData(response)


        }, (error) => { }));
    }

    const bootPrevPage = async (data) => {
        setSkipPageSize(((currentPageIndex - 2) * currentPageSize))
        setCurrentPageIndex(currentPageIndex - 1)

        setCurrentPayloadData([`?date=${moment(bootNotificationTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=bootNotification`])
        let queryParams = `?date=${moment(bootNotificationTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=bootNotification&limit=${currentPageSize}&skip=${((currentPageIndex - 2) * currentPageSize)}`
        if (bootNotificationIdVlaue) {
            queryParams += `&chargerId=${bootNotificationIdVlaue}`
        }


        dispatch(allLogs(queryParams, (response) => {

            setCustomLoader(true)
            setBootFilterData(response)

        }, (error) => { }));
    }


    const loggingNextPage = async (data) => {

        setCurrentPageIndex(currentPageIndex + 1)
        setSkipPageSize((currentPageIndex) * currentPageSize)

        setCurrentPayloadData([`?date=${moment(logsTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=`])

        let queryParams = `?date=${moment(logsTabsFilterDate[0]).format("DD-MM-YYYY")}&limit=${currentPageSize}&skip=${currentPageIndex * currentPageSize}`
        if (logsAction) {
            queryParams += `&purpose=${logsAction.value}`;

        }
        if (logsIdVlaue) {
            queryParams += `&chargerId=${logsIdVlaue}`;

        }

        console.log(queryParams, '----queryParams')
        dispatch(allLogs(queryParams, (response) => {

            setCustomLoader(true)
            setLoggingFilterData(response)


        }, (error) => { }));
    }

    const loggingPrevPage = async (data) => {
        setSkipPageSize(((currentPageIndex - 2) * currentPageSize))
        setCurrentPageIndex(currentPageIndex - 1)

        setCurrentPayloadData([`?date=${moment(logsTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=`])
        let queryParams = `?date=${moment(logsTabsFilterDate[0]).format("DD-MM-YYYY")}&limit=${currentPageSize}&skip=${((currentPageIndex - 2) * currentPageSize)}`
        if (logsAction) {
            queryParams += `&purpose=${logsAction.value}`;

        }
        if (logsIdVlaue) {
            queryParams += `&chargerId=${logsIdVlaue}`;

        }
        dispatch(allLogs(queryParams, (response) => {

            setCustomLoader(true)
            setLoggingFilterData(response)

        }, (error) => { }));
    }



    const remoteStartNextPage = async (data) => {

        setCurrentPageIndex(currentPageIndex + 1)
        setSkipPageSize((currentPageIndex) * currentPageSize)
        setCurrentPayloadData([`?date=${moment(remoteStartTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=RemoteStart`])



        let fullDate = moment(remoteStartTabsFilterDate[0]).format('DD-MM-YYYY');
        let queryParams = `?date=${moment(remoteStartTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=RemoteStart`






        if (remoteStartIdVlaue) {
            queryParams += `&chargerId=${remoteStartIdVlaue}`;
        }

        if (remoteStartStatus) {
            queryParams += `&status=${remoteStartStatus.value}`;
        }

        if (remoteStartTransactionIdVlaue) {
            queryParams += `&transactionId=${remoteStartTransactionIdVlaue}`;
        }

        if (remoteStartRfidTag) {
            queryParams += `&idTag=${remoteStartRfidTag}`;
        }

        queryParams += `&limit=${currentPageSize}&skip=${currentPageIndex * currentPageSize}`



        dispatch(allLogs(queryParams, (response) => {

            setCustomLoader(true)
            // setStatusData(response);
            setStartTraFilterData(response)

        }, (error) => { }));
    }

    const remoteStartPrevPage = async (data) => {

        // setSkipPageSize(currentPageSize - skipPageSize)
        setSkipPageSize(((currentPageIndex - 2) * currentPageSize))
        setCurrentPageIndex(currentPageIndex - 1)

        setCurrentPayloadData([`?date=${moment(remoteStartTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=RemoteStart`])




        let fullDate = moment(remoteStartTabsFilterDate[0]).format('DD-MM-YYYY');
        let queryParams = `?date=${moment(remoteStartTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=RemoteStart`



        if (remoteStartIdVlaue) {
            queryParams += `&chargerId=${remoteStartIdVlaue}`;
        }

        if (remoteStartStatus) {
            queryParams += `&status=${remoteStartStatus.value}`;
        }

        if (remoteStartTransactionIdVlaue) {
            queryParams += `&transactionId=${remoteStartTransactionIdVlaue}`;
        }

        if (remoteStartRfidTag) {
            queryParams += `&idTag=${remoteStartRfidTag}`;
        }


        queryParams += `&limit=${currentPageSize}&skip=${((currentPageIndex - 2) * currentPageSize)}`

        dispatch(allLogs(queryParams, (response) => {

            setCustomLoader(true)
            // setStatusData(response);
            setStartTraFilterData(response)

        }, (error) => { }));
    }


    const meterValueNextPage = async (data) => {

        setCurrentPageIndex(currentPageIndex + 1)
        setSkipPageSize((currentPageIndex) * currentPageSize)
        setCurrentPayloadData([`?date=${moment(meterValuesTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=meterValue`])

        let queryParams = `?date=${moment(meterValuesTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=meterValue&limit=${currentPageSize}&skip=${currentPageIndex * currentPageSize}`
        if (meterValuesId) {
            queryParams += `&chargerId=${meterValuesId}`;
        }

        if (meterValuesTransactionId) {
            queryParams += `&transactionId=${meterValuesTransactionId}`;
        }


        dispatch(allLogs(queryParams, (response) => {

            setCustomLoader(true)
            // setStatusData(response);
            let newArray = response.map(obj => {
                // Destructure the 'name' property and create a new object with 'fullname'
                let { timestamp: timeStamp, ...rest } = obj;
                return { timeStamp, ...rest };
            });
            let newArray1 = newArray.map(d => {
                const formattedDate = moment.utc(d.timeStamp);
                return { ...d, timeStamp: formattedDate };
            })
            setMeterFilterData(newArray1)
            // setMeterFilterData(response)

        }, (error) => { }));
    }

    const meterValuePrevPage = async (data) => {

        // setSkipPageSize(currentPageSize - skipPageSize)

        setSkipPageSize(((currentPageIndex - 2) * currentPageSize))
        setCurrentPageIndex(currentPageIndex - 1)

        setCurrentPayloadData([`?date=${moment(meterValuesTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=meterValue`])


        let queryParams = `?date=${moment(meterValuesTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=meterValue&limit=${currentPageSize}&skip=${((currentPageIndex - 2) * currentPageSize)}`
        if (meterValuesId) {
            queryParams += `&chargerId=${meterValuesId}`;
        }

        if (meterValuesTransactionId) {
            queryParams += `&transactionId=${meterValuesTransactionId}`;
        }

        dispatch(allLogs(queryParams, (response) => {

            setCustomLoader(true)
            let newArray = response.map(obj => {
                // Destructure the 'name' property and create a new object with 'fullname'
                let { timestamp: timeStamp, ...rest } = obj;
                return { timeStamp, ...rest };
            });
            let newArray1 = newArray.map(d => {
                const formattedDate = moment.utc(d.timeStamp);
                return { ...d, timeStamp: formattedDate };
            })
            // setStatusData(response);
            setMeterFilterData(newArray1)

        }, (error) => { }));
    }


    const remoteStopNextPage = async (data) => {


        setCurrentPageIndex(currentPageIndex + 1)
        setSkipPageSize((currentPageIndex) * currentPageSize)

        setCurrentPayloadData([`?date=${moment(remoteStopTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=remoteStop`])
        let queryParams = `?date=${moment(remoteStopTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=remoteStop&limit=${currentPageSize}&skip=${currentPageIndex * skipPageSize}`

        if (remoteStopIdVlaue) {
            queryParams += `&chargerId=${remoteStopIdVlaue}`;
        }

        if (remoteStopStatus) {
            queryParams += `&status=${remoteStopStatus.value}`;
        }

        if (remoteStopTransactionIdVlaue) {
            queryParams += `&transactionId=${remoteStopTransactionIdVlaue}`;
        }


        dispatch(allLogs(queryParams, (response) => {

            setCustomLoader(true)
            // setStatusData(response);
            setStopTraFilterData(response)

        }, (error) => { }));
    }

    const remoteStopPrevPage = async (data) => {

        // setSkipPageSize(currentPageSize - skipPageSize)

        setSkipPageSize(((currentPageIndex - 2) * currentPageSize))
        setCurrentPageIndex(currentPageIndex - 1)

        setCurrentPayloadData([`?date=${moment(remoteStopTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=remoteStop`])


        let queryParams = `?date=${moment(remoteStopTabsFilterDate[0]).format("DD-MM-YYYY")}&purpose=remoteStop&limit=${currentPageSize}&skip=${((currentPageIndex - 2) * currentPageSize)}`

        if (remoteStopIdVlaue) {
            queryParams += `&chargerId=${remoteStopIdVlaue}`;
        }

        if (remoteStopStatus) {
            queryParams += `&status=${remoteStopStatus.value}`;
        }

        if (remoteStopTransactionIdVlaue) {
            queryParams += `&transactionId=${remoteStopTransactionIdVlaue}`;
        }


        dispatch(allLogs(queryParams, (response) => {

            setCustomLoader(true)
            // setStatusData(response);
            setStopTraFilterData(response)


        }, (error) => { }));
    }

    // loggingData
    const loggingColumns = useMemo(
        () => [
            // {
            //     Header: "Id",
            //     accessor: "Id",
            //     filterable: true,
            //     show: true,
            //     Cell: (cell) => {
            //         return <p className='text textOverflow'>{cell.value}</p>;
            //     },
            // },
            {
                Header: "Charger id",
                accessor: "chargerId",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Msg received time",
                accessor: "timestamp",
                filterable: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{moment.utc(cell.value).format("DD-MM-YYYY HH:mm:ss")}</p>;
                },
            },
            {
                Header: "From",
                accessor: "From",
                filterable: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>CP</p>;
                },
            },
            {
                Header: "To",
                accessor: "To",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>CS</p>;
                },
            },
            {
                Header: "Request action",
                accessor: "purpose",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            // {
            //     Header: "Response action",
            //     accessor: "Response",
            //     filterable: true,
            //     disableSortBy: false,
            //     show: true,
            //     Cell: (cell) => {
            //         return <p className='text textOverflow'>{cell.value}</p>;
            //     },
            // },
            {
                Header: "Action",
                accessor: "Show",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text show textOverflow'>
                        <Button>
                            <i className='' />SHOW
                        </Button></p>;

                },
            },
        ],
        [history]
    );


    // statusNotificationData
    const statusNotificationColumns = useMemo(
        () => [

            {
                Header: "Received time",
                accessor: "timestamp",
                filterable: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{moment.utc(cell.value).format("DD-MM-YYYY HH:mm:ss")}</p>;
                },
            },
            {
                Header: "Connector Id",
                accessor: "connectorId",
                filterable: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Charger Id",
                accessor: "chargerId",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Status",
                accessor: "status",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <div>
                        <span className={cell.value === "Available" ? 'badge green' : 'badge others'}>{cell.value}</span>
                    </div>;
                },
            },
            {
                Header: "Error Code",
                accessor: "errorCode",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Vendor Id",
                accessor: "vendorId",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Vendor Error Code",
                accessor: "vendorErrorCode",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Action",
                accessor: "Show",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text show textOverflow'>
                        <Button>
                            <i className='' />SHOW
                        </Button></p>;

                },
            },
        ],
        [history]
    );

    // remoteStartData
    const remoteStartColumns = useMemo(
        () => [
            {
                Header: "Id",
                accessor: "Id",
                filterable: false,
                disableSortBy: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Charger ID",
                accessor: "chargerId",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Connector",
                accessor: "connectorId",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Transaction",
                accessor: "transactionId",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Tag Id",
                accessor: "idTag",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Message",
                accessor: "Message",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>-</p>;
                },
            },
            {
                Header: "Status",
                accessor: "status",
                filterable: false,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            // {
            //     Header: "Charging Profile",
            //     accessor: "ChargingProfile",
            //     filterable: false,
            //     disableSortBy: true,
            //     show: true,
            //     Cell: (cell) => {
            //         return <p className='text show textOverflow'>{cell.value}</p>;
            //     },
            // },
            {
                Header: "Time",
                accessor: "timestamp",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text show textOverflow'>{moment.utc(cell.value).format("DD-MM-YYYY HH:mm:ss")}</p>;
                },
            },
            {
                Header: "Action",
                accessor: "Show",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text show textOverflow'>
                        <Button>
                            <i className='' />SHOW
                        </Button></p>;

                },
            },
        ],
        [history]
    );

    // remoteStopData
    const remoteStopColumns = useMemo(
        () => [
            {
                Header: "Id",
                accessor: "Id",
                filterable: false,
                disableSortBy: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Charger ID",
                accessor: "chargerId",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Connector",
                accessor: "connectorId",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Transaction",
                accessor: "transactionId",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Message",
                accessor: "message",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>-</p>;
                },
            },
            {
                Header: "Reason",
                accessor: "reason",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Status",
                accessor: "status",
                filterable: false,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Time",
                accessor: "timestamp",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text show textOverflow'>{moment.utc(cell.value).format("DD-MM-YYYY HH:mm:ss")}</p>;
                },
            },
            {
                Header: "Action",
                accessor: "Show",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text show textOverflow'>
                        <Button>
                            <i className='' />SHOW
                        </Button></p>;

                },
            },
        ],
        [history]
    );

    // remoteStopData
    const metrValueColumns = useMemo(
        () => [
            {
                Header: "station",
                accessor: "chargerId",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Connector",
                accessor: "connectorId",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Time",
                accessor: "timeStamp",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{moment.utc(cell.value).format("DD-MM-YYYY HH:mm:ss")}</p>;
                },
            },
            {
                Header: "Unit",
                accessor: "unit",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Value",
                accessor: "value",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Transaction",
                accessor: "transactionId",
                filterable: false,
                disableSortBy: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Action",
                accessor: "Show",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text show textOverflow'>
                        <Button>
                            <i className='' />SHOW
                        </Button></p>;

                },
            },

        ],
        [history]
    );
    // bootNotificationData
    const bootNotificationColumns = useMemo(
        () => [
            // {
            //     Header: "Id",
            //     accessor: "Id",
            //     filterable: true,
            //     show: true,
            //     Cell: (cell) => {
            //         return <p className='text textOverflow'>{cell.value}</p>;
            //     },
            // },
            {
                Header: "Last Boot Time",
                accessor: "timestamp",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{moment(cell.value).format("DD-MM-YYYY HH:mm:ss")}</p>;
                },
            },
            {
                Header: "Charger ID",
                accessor: "chargerId",
                filterable: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Charge Point Model",
                accessor: "chargePointModel",
                filterable: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Charge Point Serial Num",
                accessor: "chargePointSerialNumber",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            // {
            //     Header: "Charge Box Serial Num",
            //     accessor: "ChargeBoxSerialNum",
            //     filterable: true,
            //     disableSortBy: false,
            //     show: true,
            //     Cell: (cell) => {
            //         return <p className='text textOverflow'>{cell.value}</p>;
            //     },
            // },
            {
                Header: "Charge Point Vendor",
                accessor: "chargePointVendor",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Firmware Version",
                accessor: "firmwareVersion",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text show textOverflow'>{cell.value}</p>;
                },
            },
            {
                Header: "Action",
                accessor: "Show",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text show textOverflow'>
                        <Button>
                            <i className='' />SHOW
                        </Button></p>;

                },
            },

        ],
        [history]
    );

    // bootNotificationData
    const heartColumns = useMemo(
        () => [

            {
                Header: "Charger ID",
                accessor: "chargerId",
                filterable: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value}</p>;
                },
            },

            {
                Header: "Time",
                accessor: "currentTime",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {

                    return <p className='text show textOverflow'>{moment.utc(cell.value).format("DD-MM-YYYY HH:mm:ss")}</p>;
                },
            },
            {
                Header: "Action",
                accessor: "Show",
                filterable: true,
                disableSortBy: false,
                show: true,
                Cell: (cell) => {
                    return <p className='text show textOverflow'>
                        <Button>
                            <i className='' />SHOW
                        </Button></p>;

                },
            },

        ],
        [history]
    );
    const handleTabSelect = (eventKey, datee) => {
        setStatusNotificationsTabsFilterDate(new Date())
        setHeartbeatTabsFilterDate(new Date())
        setBootNotificationTabsFilterDate(new Date())
        setRemoteStartTabsFilterDate(new Date())
        setMeterValuesTabsFilterDate(new Date())
        setRemoteStopTabsFilterDate(new Date())
        setLogsTabsFilterDate(new Date())
        setCustomLoader(false)

        setLogsIdVlaue('')
        setLogsAction(null)
        setHeartbeatIdVlaue('')
        setBootNotificationIdVlaue('')
        setStatusNotificationsIdVlaue('')
        setStatusNotifications(null)
        setRemoteStartIdVlaue('')
        setRemoteStartRfidTag('')
        setRemoteStartTransactionIdVlaue('')
        setRemoteStartStatus(null)
        setMeterValuesId('')
        setMeterValuesTransactionId('')
        setRemoteStopIdVlaue('')
        setRemoteStopTransactionIdVlaue('')
        setRemoteStopStatus(null)




        setActiveKey(eventKey);
        let fullDate = moment(datee[0]).format('DD-MM-YYYY');

        if (!liveSocketData) {

            setSkipPageSize(currentPageSize + 0)
            setCurrentPageIndex(1)

            setCurrentPayloadData([`?date=${fullDate}&purpose=${eventKey}`])

            dispatch(allLogs(`?date=${fullDate}&purpose=${eventKey}&limit=${currentPageSize}&skip=${0}`, (response) => {
                if (eventKey === 'Heartbeat') {
                    let newArray = response.map(obj => {
                        // Destructure the 'name' property and create a new object with 'fullname'
                        let { timestamp: currentTime, ...rest } = obj;
                        return { currentTime, ...rest };
                    });
                    setHeartFilterData(newArray)

                }
                if (eventKey === 'meterValue') {
                    let newArray = response.map(obj => {
                        // Destructure the 'name' property and create a new object with 'fullname'
                        let { timestamp: timeStamp, ...rest } = obj;
                        return { timeStamp, ...rest };
                    });
                    setMeterFilterData(newArray)

                }
                setCustomLoader(true)
                setStatusData(response);
                setStatusFilterData(response)
                setBootFilterData(response)
                setLoggingData(response);
                setHeartData(response);
                setBootData(response);
                setRemoteStartData(response)
                setLoggingFilterData(response)
                setStopTraFilterData(response)
                setStartTraFilterData(response)
                setRemoteStopData(response)
                setMeterValueData(response)
            }, (error) => { }));
        } else {
            setCustomLoader(true)

        }

    };

    // logs tab filter

    const logsActionList = [
        { id: '1', value: 'Heartbeat' },
        { id: '2', value: 'bootNotification' },
        { id: '3', value: 'statusNotifications' },
        { id: '4', value: 'RemoteStart' },
        { id: '5', value: 'meterValue' },
        { id: '6', value: 'remoteStop' },
        // { id: '4', value: 'Diemos LLP' },
    ];

    const handleLogsFilterSearchClick = () => {

        setCurrentPageIndex(1)
        let fullDate = moment(logsTabsFilterDate[0]).format('DD-MM-YYYY');
        let queryParams = '';
        let checkPayloadObj = ''
        console.log(logsIdVlaue, 'logsIdVlaue')
        setLogsIdVlaue(logsIdVlaue)
        setLogsTabsFilterDate(logsTabsFilterDate)
        setLogsAction(logsAction)
        if (fullDate) {
            queryParams += `?date=${fullDate}&`;
            checkPayloadObj += `?date=${fullDate}&`;
        }

        if (logsAction) {
            queryParams += `purpose=${logsAction.value}&`;
            checkPayloadObj += `purpose=${logsAction.value}&`;

        }
        if (logsIdVlaue) {
            queryParams += `chargerId=${logsIdVlaue}&`;
            checkPayloadObj += `chargerId=${logsIdVlaue}&`;

        }
        queryParams += `limit=${currentPageSize}&skip=${(0 * currentPageSize)}`;
        // 
        setLoggingFilterData([])
        // queryParams = queryParams.length > 0 ? `?${queryParams.slice(0, -1)}` : '';

        if (!liveSocketData) {
            setCurrentPayloadData([checkPayloadObj])
            dispatch(allLogs(queryParams, (response) => {
                // setLoggingData(response);
                setLoggingFilterData(response)

            }, (error) => { }));
        } else {
            loggingData.map((newObj, index) => {


                let isMatch = (
                    (newObj?.chargerId === logsIdVlaue || logsIdVlaue === undefined || logsIdVlaue === null || logsIdVlaue === '') &&
                    (moment(newObj?.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate).format('DD-MM-YYYY') || moment(newObj?.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) &&
                    (newObj?.purpose === logsAction?.value || logsAction?.value === undefined || logsAction?.value === null)
                )

                let timeStampValue;
                if (newObj.timestamp) {
                    timeStampValue = newObj.timestamp;
                } else if (newObj.currentTime) {
                    timeStampValue = newObj.currentTime;
                } else if (newObj.timeStamp) {
                    timeStampValue = newObj.timeStamp;
                }

                // Create a new object with the timeStampValue
                if (isMatch && timeStampValue) {
                    newObj = {
                        ...newObj,
                        timestamp: timeStampValue
                    };
                }


                if (isMatch) {
                    setLoggingFilterData((prev) => [...prev, { ...newObj, newObj }])
                }


                // if (logsAction == null && logsIdVlaue === '' && (moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate).format('DD-MM-YYYY') || moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY'))) {
                //     setLoggingFilterData((prev) => [...prev, newObj])
                // } else if (newObj.chargerId == logsAction?.value && logsIdVlaue === newObj.chargerId && (moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate).format('DD-MM-YYYY') || moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY'))) {
                //     setLoggingFilterData((prev) => [...prev, newObj])
                // }
                // else if (newObj.chargerId == logsAction?.value && logsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate).format('DD-MM-YYYY') || moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                //     setLoggingFilterData((prev) => [...prev, newObj])
                // } else {
                //     setLoggingFilterData([])
                // }

            })
        }
    };


    // Heartbeat tab filter

    const handleHeartbeatFilterSearchClick = () => {
        let fullDate = moment(heartbeatTabsFilterDate[0]).format('DD-MM-YYYY');
        setHeartbeatTabsFilterDate(heartbeatTabsFilterDate)
        setHeartbeatIdVlaue(heartbeatIdVlaue)
        setCurrentPageIndex(1)

        if (!liveSocketData) {
            if (heartbeatIdVlaue !== '') {

                setCurrentPayloadData([`?date=${fullDate}&purpose=${activeKey}&chargerId=${heartbeatIdVlaue}`])
                dispatch(allLogs(`?date=${fullDate}&purpose=${activeKey}&chargerId=${heartbeatIdVlaue}&limit=${currentPageSize}&skip=${(0 * currentPageSize)}`, (response) => {
                    // setHeartData(response);
                    let newArray = response.map(obj => {
                        // Destructure the 'name' property and create a new object with 'fullname'
                        let { timestamp: currentTime, ...rest } = obj;
                        return { currentTime, ...rest };
                    });
                    setHeartFilterData(newArray);

                }, (error) => { }));
            } else {
                setCurrentPayloadData([`?date=${fullDate}&purpose=${activeKey}`])

                dispatch(allLogs(`?date=${fullDate}&purpose=${activeKey}&limit=${currentPageSize}&skip=${(0 * currentPageSize)}`, (response) => {
                    // setHeartData(response);
                    let newArray = response.map(obj => {
                        // Destructure the 'name' property and create a new object with 'fullname'
                        let { timestamp: currentTime, ...rest } = obj;
                        return { currentTime, ...rest };
                    });

                    setHeartFilterData(newArray);

                }, (error) => { }));
            }
        } else {
            setHeartFilterData([])
            heartData.map((newObj, index) => {

                if (newObj.purpose == "Heartbeat" && heartbeatIdVlaue === '' && moment(newObj.currentTime).format('DD-MM-YYYY') === moment(heartbeatTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    // setHeartData((prev) => [...prev, newObj])
                    setHeartFilterData((prev) => [...prev, newObj])

                } else if (newObj.purpose == "Heartbeat" && heartbeatIdVlaue === newObj.chargerId && moment(newObj.currentTime).format('DD-MM-YYYY') === moment(heartbeatTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    // setHeartData((prev) => [...prev, newObj])
                    setHeartFilterData((prev) => [...prev, newObj])
                } else {
                    console.log('hnot')
                }




                if (logsAction == null && logsIdVlaue === '' && moment(newObj.currentTime).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    // setLoggingData((prev) => [...prev, newObj])
                    setLoggingFilterData((prev) => [...prev, newObj])
                } else if (newObj.purpose == logsAction?.value && logsIdVlaue === newObj.chargerId && moment(newObj.currentTime).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    // setLoggingData((prev) => [...prev, newObj])
                    setLoggingFilterData((prev) => [...prev, newObj])

                }
                else if (newObj.purpose == logsAction?.value && logsIdVlaue === '' && moment(newObj.currentTime).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    // setLoggingData((prev) => [...prev, newObj])
                    setLoggingFilterData((prev) => [...prev, newObj])

                } else {
                    console.log('bnot')
                }
            });
        }

    };

    // bootNotification tab filter

    const handleBootNotificationFilterSearchClick = () => {
        let fullDate = moment(bootNotificationTabsFilterDate[0]).format('DD-MM-YYYY');
        setBootNotificationIdVlaue(bootNotificationIdVlaue)
        setBootNotificationTabsFilterDate(bootNotificationTabsFilterDate)
        setCurrentPageIndex(1)

        if (!liveSocketData) {
            if (bootNotificationIdVlaue !== '') {
                setCurrentPayloadData([`?date=${fullDate}&purpose=${activeKey}&chargerId=${bootNotificationIdVlaue}`])

                dispatch(allLogs(`?date=${fullDate}&purpose=${activeKey}&chargerId=${bootNotificationIdVlaue}&limit=${currentPageSize}&skip=${(0 * currentPageSize)}`, (response) => {
                    // setBootData(response);
                    setBootFilterData(response)
                }, (error) => { }));
            } else {
                setCurrentPayloadData([`?date=${fullDate}&purpose=${activeKey}`])

                dispatch(allLogs(`?date=${fullDate}&purpose=${activeKey}&limit=${currentPageSize}&skip=${(0 * currentPageSize)}`, (response) => {
                    // setBootData(response);
                    setBootFilterData(response)

                }, (error) => { }));
            }

        } else {
            setBootFilterData([])
            bootNotificationData.map((newObj, index) => {
                // setBootData((prev) => [...prev, newObj])
                if (newObj.purpose == "bootNotification" && bootNotificationIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(bootNotificationTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    setBootFilterData((prev) => [...prev, newObj])
                } else if (newObj.purpose == "bootNotification" && bootNotificationIdVlaue === newObj.chargerId && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(bootNotificationTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    setBootFilterData((prev) => [...prev, newObj])

                } else {
                    setBootFilterData([])
                }

                if (logsAction == null && logsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    // setLoggingData((prev) => [...prev, newObj])
                    setLoggingFilterData((prev) => [...prev, newObj])

                } else if (newObj.purpose == logsAction?.value && logsIdVlaue === newObj.chargerId && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    // setLoggingData((prev) => [...prev, newObj])
                    setLoggingFilterData((prev) => [...prev, newObj])

                }
                else if (newObj.purpose == logsAction?.value && logsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    // setLoggingData((prev) => [...prev, newObj])
                    setLoggingFilterData((prev) => [...prev, newObj])

                } else {
                    console.log('dnot')
                }
            });

        }

    };

    // statusNotifications tab filter

    const statusNotificationsList = [
        { id: '1', value: 'Available' },
        { id: '2', value: 'Charging' },
        { id: '3', value: 'Unavailable' },
        { id: '4', value: 'Finishing' },
        { id: '5', value: 'Preparing' },
        { id: '6', value: 'Occupied' },
        { id: '7', value: 'Faulted' },
    ];

    const handleStatusNotificationsFilterSearchClick = () => {
        let fullDate = moment(statusNotificationsTabsFilterDate[0]).format('DD-MM-YYYY');

        setStatusNotificationsTabsFilterDate(statusNotificationsTabsFilterDate)
        setStatusNotificationsIdVlaue(statusNotificationsIdVlaue)
        setStatusNotifications(statusNotifications)
        setStatusFilterData([])
        setCurrentPageIndex(1)
        let checkPayloadObj = ''

        if (!liveSocketData) {
            let query;
            if (statusNotificationsIdVlaue !== '' && statusNotifications !== null) {
                query = `?date=${fullDate}&purpose=${activeKey}&chargerId=${statusNotificationsIdVlaue}&status=${statusNotifications.value}&limit=${currentPageSize}&skip=${(0 * currentPageSize)}`;
                checkPayloadObj = `?date=${fullDate}&purpose=${activeKey}&chargerId=${statusNotificationsIdVlaue}&status=${statusNotifications.value}`;
            } else if (statusNotificationsIdVlaue === '' && statusNotifications !== null) {
                query = `?date=${fullDate}&purpose=${activeKey}&status=${statusNotifications.value}&limit=${currentPageSize}&skip=${(0 * currentPageSize)}`;
                checkPayloadObj = `?date=${fullDate}&purpose=${activeKey}&status=${statusNotifications.value}`;
            } else if (statusNotificationsIdVlaue !== '' && statusNotifications === null) {
                query = `?date=${fullDate}&purpose=${activeKey}&chargerId=${statusNotificationsIdVlaue}&limit=${currentPageSize}&skip=${(0 * currentPageSize)}`;
                checkPayloadObj = `?date=${fullDate}&purpose=${activeKey}&chargerId=${statusNotificationsIdVlaue}`;
            } else {
                query = `?date=${fullDate}&purpose=${activeKey}&limit=${currentPageSize}&skip=${(0 * currentPageSize)}`;
                checkPayloadObj = `?date=${fullDate}&purpose=${activeKey}`;
            }
            setCurrentPayloadData([checkPayloadObj])
            dispatch(allLogs(query, (response) => {
                // setStatusData(response);
                setStatusFilterData(response)
            }, (error) => { }));

        } else {


            customerData.map((newObj, index) => {
                if (newObj.purpose == "statusNotifications" && statusNotifications == null && statusNotificationsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(statusNotificationsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    setStatusFilterData((prev) => [...prev, newObj])
                } else if (newObj.purpose == "statusNotifications" && newObj.status == statusNotifications?.value && statusNotificationsIdVlaue === newObj.chargerId && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(statusNotificationsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    setStatusFilterData((prev) => [...prev, newObj])
                }
                else if (newObj.purpose == "statusNotifications" && newObj.status == statusNotifications?.value && statusNotificationsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(statusNotificationsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    setStatusFilterData((prev) => [...prev, newObj])
                } else {
                    setStatusFilterData([])
                }
                if (logsAction == null && logsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    setLoggingData((prev) => [...prev, newObj])
                } else if (newObj.purpose == logsAction?.value && logsIdVlaue === newObj.chargerId && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    setLoggingData((prev) => [...prev, newObj])
                }
                else if (newObj.purpose == logsAction?.value && logsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    setLoggingData((prev) => [...prev, newObj])
                } else {
                    console.log('dnot')
                }

            })



        }

    };

    // remoteStart tab filter

    const remoteStartStatusList = [
        { id: '1', value: 'Accepted' },
        { id: '2', value: 'Failed' },
        { id: '3', value: 'Rejected' }
    ];

    const handleRemoteStartFilterSearchClick = () => {
        setRemoteStartIdVlaue(remoteStartIdVlaue)
        setRemoteStartRfidTag(remoteStartRfidTag)
        setRemoteStartTransactionIdVlaue(remoteStartTransactionIdVlaue)
        setRemoteStartTabsFilterDate(remoteStartTabsFilterDate)
        setRemoteStartStatus(remoteStartStatus)
        let fullDate = moment(remoteStartTabsFilterDate[0]).format('DD-MM-YYYY');
        let queryParams = '';
        let checkPayloadObj = ''
        setCurrentPageIndex(1)




        if (fullDate) {
            queryParams += `?date=${fullDate}&`;
            checkPayloadObj += `?date=${fullDate}&`;
        }

        if (activeKey) {
            queryParams += `purpose=${activeKey}&`;
            checkPayloadObj += `purpose=${activeKey}&`;
        }

        if (remoteStartIdVlaue) {
            queryParams += `chargerId=${remoteStartIdVlaue}&`;
            checkPayloadObj += `chargerId=${remoteStartIdVlaue}&`;
        }

        if (remoteStartStatus) {
            queryParams += `status=${remoteStartStatus.value}&`;
            checkPayloadObj += `status=${remoteStartStatus.value}&`;
        }

        if (remoteStartTransactionIdVlaue) {
            queryParams += `transactionId=${remoteStartTransactionIdVlaue}&`;
            checkPayloadObj += `transactionId=${remoteStartTransactionIdVlaue}&`;
        }

        if (remoteStartRfidTag) {
            queryParams += `idTag=${remoteStartRfidTag}&`;
            checkPayloadObj += `idTag=${remoteStartRfidTag}&`;
        }

        queryParams += `limit=${currentPageSize}&skip=${(0 * currentPageSize)}`;

        // Remove trailing '&' if there are any parameters
        // queryParams = queryParams.length > 0 ? `?${queryParams.slice(0, -1)}` : '';
        setStartTraFilterData([])
        if (!liveSocketData) {
            setCurrentPayloadData([checkPayloadObj])

            dispatch(allLogs(queryParams, (response) => {
                // setRemoteStartData(response);
                setStartTraFilterData(response)
            }, (error) => { }));

        } else {
            setStartTraFilterData([])
            remoteStartData.map((newObj, index) => {
                let isMatch = (
                    (newObj?.chargerId === remoteStartIdVlaue || remoteStartIdVlaue === undefined || remoteStartIdVlaue === null || remoteStartIdVlaue === '') &&
                    (moment(newObj?.timeStamp).format('DD-MM-YYYY') === moment(remoteStartTabsFilterDate).format('DD-MM-YYYY') || moment(newObj?.timeStamp).format('DD-MM-YYYY') === moment(remoteStartTabsFilterDate[0]).format('DD-MM-YYYY')) &&
                    (newObj?.status === remoteStartStatus?.value || remoteStartStatus?.value === undefined || remoteStartStatus?.value === null) &&
                    (newObj?.idTag === remoteStartRfidTag || remoteStartRfidTag === '') &&
                    (newObj?.transactionId.toString() === remoteStartTransactionIdVlaue.toString() || remoteStartTransactionIdVlaue === '')
                );

                if (isMatch) {
                    setStartTraFilterData((prev) => [...prev, newObj])
                }


                if (logsAction == null && logsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    setLoggingData((prev) => [...prev, newObj])
                } else if (newObj.purpose == logsAction?.value && logsIdVlaue === newObj.chargerId && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    setLoggingData((prev) => [...prev, newObj])
                }
                else if (newObj.purpose == logsAction?.value && logsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    setLoggingData((prev) => [...prev, newObj])
                } else {
                    console.log('enot')
                }

            });

        }
    };

    // meterValues tab filter

    const handleMeterValuesFilterSearchClick = () => {
        setMeterValuesTabsFilterDate(meterValuesTabsFilterDate)
        setMeterValuesTransactionId(meterValuesTransactionId)
        setMeterValuesId(meterValuesId)
        setCurrentPageIndex(1)

        let fullDate = moment(meterValuesTabsFilterDate[0]).format('DD-MM-YYYY');
        let queryParams = '';
        let checkPayloadObj = ''

        if (fullDate) {
            queryParams += `?date=${fullDate}&`;
            checkPayloadObj += `?date=${fullDate}&`;
        }

        if (activeKey) {
            queryParams += `purpose=${activeKey}&`;
            checkPayloadObj += `purpose=${activeKey}&`;
        }

        if (meterValuesId) {
            queryParams += `chargerId=${meterValuesId}&`;
            checkPayloadObj += `chargerId=${meterValuesId}&`;
        }

        if (meterValuesTransactionId) {
            queryParams += `transactionId=${meterValuesTransactionId}&`;
            checkPayloadObj += `transactionId=${meterValuesTransactionId}&`;
        }

        // if (meterValuesTagId) {
        //     queryParams += `idTag=${meterValuesTagId}&`;
        // }

        queryParams += `limit=${currentPageSize}&skip=${(0 * currentPageSize)}`;

        // Remove trailing '&' if there are any parameters
        // queryParams = queryParams.length > 0 ? `?${queryParams.slice(0, -1)}` : '';

        setMeterFilterData([])

        if (!liveSocketData) {


            setCurrentPayloadData([checkPayloadObj])
            dispatch(allLogs(queryParams, (response) => {
                let newArray = response.map(obj => {
                    // Destructure the 'name' property and create a new object with 'fullname'
                    let { timestamp: timeStamp, ...rest } = obj;
                    return { timeStamp, ...rest };
                });
                let newArray1 = newArray.map(d => {
                    const formattedDate = moment.utc(d.timeStamp);
                    return { ...d, timeStamp: formattedDate };
                })

                setMeterFilterData(newArray1)
            }, (error) => { }));
        } else {

            meterValueData.map((newObj, index) => {


                let isMatch = (
                    (newObj?.chargerId === meterValuesId || meterValuesId === undefined || meterValuesId === null || meterValuesId === '') &&
                    (moment(newObj?.timeStamp).format('DD-MM-YYYY') === moment(meterValuesTabsFilterDate).format('DD-MM-YYYY') || moment(newObj?.timeStamp).format('DD-MM-YYYY') === moment(meterValuesTabsFilterDate[0]).format('DD-MM-YYYY')) &&
                    (newObj?.transactionId.toString() === meterValuesTransactionId.toString() || meterValuesTransactionId === '')
                );

                if (isMatch) {
                    setMeterFilterData((prev) => [...prev, newObj])
                }
                // if (JSON.parse(res).purpose == "meterValue" && meterValuesTransactionId == '' && meterValuesId === '' && moment(JSON.parse(res).timeStamp).format('DD-MM-YYYY') === moment(meterValuesTabsFilterDate[0]).format('DD-MM-YYYY')) {
                //     // setMeterValueData((prev) => [...prev, newObj1])
                //     setMeterFilterData((prev) => [...prev, newObj1])


                // } else if (JSON.parse(res).purpose == "meterValue" && JSON.parse(res).transactionId.toString() !== meterValuesTransactionId.toString() && meterValuesId === JSON.parse(res).station && moment(JSON.parse(res).timeStamp).format('DD-MM-YYYY') === moment(meterValuesTabsFilterDate[0]).format('DD-MM-YYYY')) {
                //     // setMeterValueData((prev) => [...prev, newObj1])
                //     setMeterFilterData((prev) => [...prev, newObj1])

                // }
                // else if (JSON.parse(res).purpose == "meterValue" && JSON.parse(res).transactionId.toString() === meterValuesTransactionId.toString() && meterValuesId === '' && moment(JSON.parse(res).timeStamp).format('DD-MM-YYYY') === moment(meterValuesTabsFilterDate[0]).format('DD-MM-YYYY')) {
                //     // setMeterValueData((prev) => [...prev, newObj1])
                //     setMeterFilterData((prev) => [...prev, newObj1])


                // } else if (JSON.parse(res).purpose == "meterValue" && JSON.parse(res).transactionId.toString() === meterValuesTransactionId.toString() && meterValuesId === JSON.parse(res).station && moment(JSON.parse(res).timeStamp).format('DD-MM-YYYY') === moment(meterValuesTabsFilterDate[0]).format('DD-MM-YYYY')) {
                //     // setMeterValueData((prev) => [...prev, newObj1])
                //     setMeterFilterData((prev) => [...prev, newObj1])


                // } else {
                // }

                // if (logsAction == null && logsIdVlaue === '' && moment(JSON.parse(res).timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                //     setLoggingData((prev) => [...prev, newObj])
                // } else if (JSON.parse(res).purpose == logsAction?.value && logsIdVlaue === JSON.parse(res).chargerId && moment(JSON.parse(res).timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                //     setLoggingData((prev) => [...prev, newObj])
                // }
                // else if (JSON.parse(res).purpose == logsAction?.value && logsIdVlaue === '' && moment(JSON.parse(res).timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                //     setLoggingData((prev) => [...prev, newObj])
                // } else {
                // }
            });
        }
    };

    // remoteStop tab filter

    const remoteStopStatusList = [
        { id: '1', value: 'Accepted' },
        { id: '2', value: 'Available' },
        { id: '3', value: 'Charging' },
        { id: '4', value: 'Unavailable' },
        { id: '5', value: 'Finishing' },
        { id: '6', value: 'Preparing' },
        { id: '7', value: 'Occupied' },
        { id: '8', value: 'Faulted' }
    ];

    const handleRemoteStopFilterSearchClick = () => {
        setStopTraFilterData([])
        setCurrentPageIndex(1)
        setRemoteStopIdVlaue(remoteStopIdVlaue)
        setRemoteStopTransactionIdVlaue(remoteStopTransactionIdVlaue)
        setRemoteStopTabsFilterDate(remoteStopTabsFilterDate)
        setRemoteStopStatus(remoteStopStatus)
        let checkPayloadObj = ''
        let fullDate = moment(remoteStopTabsFilterDate[0]).format('DD-MM-YYYY');
        let queryParams = '';

        if (fullDate) {
            queryParams += `?date=${fullDate}&`;
            checkPayloadObj += `?date=${fullDate}&`;
        }

        if (activeKey) {
            queryParams += `purpose=${activeKey}&`;
            checkPayloadObj += `purpose=${activeKey}&`;
        }

        if (remoteStopIdVlaue) {
            queryParams += `chargerId=${remoteStopIdVlaue}&`;
            checkPayloadObj += `chargerId=${remoteStopIdVlaue}&`;
        }

        if (remoteStopStatus) {
            queryParams += `status=${remoteStopStatus.value}&`;
            checkPayloadObj += `status=${remoteStopStatus.value}&`;
        }

        if (remoteStopTransactionIdVlaue) {
            queryParams += `transactionId=${remoteStopTransactionIdVlaue}&`;
            checkPayloadObj += `transactionId=${remoteStopTransactionIdVlaue}&`;
        }

        queryParams += `limit=${currentPageSize}&skip=${(0 * currentPageSize)}`;

        // Remove trailing '&' if there are any parameters
        // queryParams = queryParams.length > 0 ? `?${queryParams.slice(0, -1)}` : '';

        if (!liveSocketData) {
            setCurrentPayloadData([checkPayloadObj])

            dispatch(allLogs(queryParams, (response) => {
                // setRemoteStopData(response);
                setStopTraFilterData(response);
            }, (error) => { }));

        } else {
            setStopTraFilterData([])



            remoteStopData.map((newObj, index) => {

                let isMatch = (
                    (newObj?.chargerId === remoteStopIdVlaue || remoteStopIdVlaue === undefined || remoteStopIdVlaue === null || remoteStopIdVlaue === '') &&
                    (moment(newObj?.timeStamp).format('DD-MM-YYYY') === moment(remoteStopTabsFilterDate).format('DD-MM-YYYY') || moment(newObj?.timeStamp).format('DD-MM-YYYY') === moment(remoteStopTabsFilterDate[0]).format('DD-MM-YYYY')) &&
                    (newObj?.status === remoteStopStatus?.value || remoteStopStatus?.value === undefined || remoteStopStatus?.value === null) &&
                    (newObj?.transactionId.toString() === remoteStopTransactionIdVlaue.toString() || remoteStopTransactionIdVlaue === '')
                );

                if (isMatch) {
                    setStopTraFilterData((prev) => [...prev, newObj])
                }



                if (logsAction == null && logsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    setLoggingData((prev) => [...prev, newObj])
                } else if (newObj.purpose == logsAction?.value && logsIdVlaue === newObj.chargerId && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    setLoggingData((prev) => [...prev, newObj])
                }
                else if (newObj.purpose == logsAction?.value && logsIdVlaue === '' && moment(newObj.timestamp).format('DD-MM-YYYY') === moment(logsTabsFilterDate[0]).format('DD-MM-YYYY')) {
                    setLoggingData((prev) => [...prev, newObj])
                } else {
                    console.log('hnot')
                }
            });




        }



    };

    return (
        <><React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className='logs' >
                        <Card className='m-0'>
                            <CardHeader className='cardHeader'>
                                <Row>
                                    <Col md={4} lg={4} xl={4}>
                                        <p className='headerTxt'>Logs</p>

                                    </Col>
                                    <Col md={4} lg={4} xl={4}>
                                        <div className="form-check form-switch">
                                            <Input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id=''
                                                checked={liveSocketData}

                                                onChange={(e) => {
                                                    setLiveSocketData(e.target.checked)

                                                    setStatusNotificationsTabsFilterDate(new Date())
                                                    setHeartbeatTabsFilterDate(new Date())
                                                    setBootNotificationTabsFilterDate(new Date())
                                                    setRemoteStartTabsFilterDate(new Date())
                                                    setMeterValuesTabsFilterDate(new Date())
                                                    setRemoteStopTabsFilterDate(new Date())
                                                    setLogsTabsFilterDate(new Date())
                                                    setStatusFilterData([])
                                                    setBootFilterData([])
                                                    setStartTraFilterData([])
                                                    setStopTraFilterData([])
                                                    setHeartFilterData([])
                                                    setStatusData([])
                                                    setLoggingData([]);
                                                    setMeterFilterData([])
                                                    setHeartData([]);
                                                    setBootData([]);
                                                    setRemoteStartData([])
                                                    setRemoteStopData([])
                                                    setMeterValueData([])
                                                    setLoggingFilterData([])
                                                    // handleTabSelect(activeKey, new Date())
                                                }}
                                            />
                                            <p className='headerTxt'>LIVE</p>
                                        </div>
                                    </Col>
                                    {loggingFilterData.length > 0 || statusFilterData.length > 0 || bootFilterData.length > 0 || heartFilterData.length > 0 || startTraFilterData.length > 0 || meterFilterData.length > 0 || stopTrFilterData.length > 0 ? (<Col className='exportBtnRowStyle' md={4} lg={4} xl={4}>
                                        <button className="exportBtnStyle" onClick={exportDataFunc}>
                                            Export
                                            <i className="mdi mdi-download me-1" />
                                        </button>
                                    </Col>) : (<p></p>)}

                                </Row>
                            </CardHeader>
                            <CardBody>

                                <Tabs defaultActiveKey="logging" id="tab" activeKey={activeKey}
                                    onSelect={handleTabSelect}>
                                    <Tab eventKey="" title="Logging">
                                        <div className='table-content'>
                                            <div className='filterRowStyle'>
                                                <div className='chargerId'>
                                                    <Input
                                                        // autocomplete="off"
                                                        name="ChargerId"
                                                        type="text"
                                                        className="form-control"
                                                        id="ChargerId"
                                                        maxLength={50}
                                                        placeholder="Charger ID"
                                                        value={logsIdVlaue}
                                                        onChange={(e) => {
                                                            setLogsIdVlaue(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className='dateInputStyle'>
                                                    <Flatpickr
                                                        placeholder='Select Date'
                                                        className="form-control"
                                                        options={{
                                                            dateFormat: "d M, Y",
                                                            maxDate: new Date(),
                                                        }}
                                                        value={logsTabsFilterDate}
                                                        onChange={(date) => {
                                                            // setDate(date);
                                                            setLogsTabsFilterDate(date);
                                                        }} />
                                                </div>
                                                <div className="actionstyle">
                                                    <CustomDropdown
                                                        className='allDropdowns'
                                                        dropDownItems={logsActionList}
                                                        defaultValue={'Select Action'}
                                                        placeholder={'Choose manufacturer'}
                                                        selectedValue={logsAction}
                                                        onSelect={evt => {
                                                            console.log(evt, 'evt')
                                                            setLogsAction(evt)
                                                        }}
                                                    />
                                                </div>
                                                <div className='searchStyle'>
                                                    <button onClick={handleLogsFilterSearchClick} className='searchBtnStyle'>search</button>
                                                </div>
                                            </div>
                                            {customLoader ? <TableContainer
                                                pagination={liveSocketData ? true : false}
                                                paginationLogsPage={liveSocketData ? false : true}
                                                columns={loggingColumns.filter((a) => a.show)}
                                                data={loggingFilterData}
                                                customPageSize={currentPageSize}
                                                customPageAll={Math.ceil(loggingFilterData[0]?.totalCount / currentPageSize) === 0 ? 1 : Math.ceil(loggingFilterData[0]?.totalCount / currentPageSize)}
                                                customPreviousPage={(row) => { loggingPrevPage(row) }}
                                                customDisablePreviousPage={currentPageIndex === 1 ? true : false}
                                                customNextPage={(row) => { loggingNextPage(row) }}
                                                // customDisableNextPage={loggingFilterData[0]?.totalCount < 20 ? true : false}
                                                // customDisableNextPage={Math.round(loggingFilterData[0]?.totalCount / currentPageSize) === currentPageIndex || loggingFilterData[0]?.totalCount < 20 ? true : false}
                                                customDisableNextPage={(loggingFilterData[0]?.totalCount / currentPageSize) < currentPageIndex || loggingFilterData[0]?.totalCount < 20 ? true : false}

                                                customPageIndex={currentPageIndex}
                                                handleViewDetailClick={(row) => { handleViewDetailClick(row); }}
                                                divClass="table-responsive mb-1 logsTable"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light text-muted"
                                                maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - 165}
                                                noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1672224362/Lubi/WebApp/Static-images/noCustomerfound.svg"}
                                                noDataTitle={'No Logging Found!'} /> : <ContentLoader />}
                                        </div>
                                    </Tab>


                                    <Tab eventKey="Heartbeat" title="Heartbeat">
                                        <div className='table-content'>
                                            <div className='filterRowStyle'>
                                                <div className='chargerId'>
                                                    <Input
                                                        // autocomplete="off"
                                                        name="ChargerId"
                                                        type="text"
                                                        className="form-control"
                                                        id="ChargerId"
                                                        maxLength={50}
                                                        placeholder="Charger ID"
                                                        value={heartbeatIdVlaue}
                                                        handleViewDetailClick={(row) => { handleViewDetailClick(row); }}
                                                        onChange={(e) => {
                                                            setHeartbeatIdVlaue(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className='dateInputStyle'>
                                                    <Flatpickr
                                                        placeholder='Select Date'
                                                        className="form-control"
                                                        options={{
                                                            dateFormat: "d M, Y",
                                                            maxDate: new Date(),
                                                        }}
                                                        value={heartbeatTabsFilterDate}
                                                        onChange={(date) => {
                                                            setHeartbeatTabsFilterDate(date);
                                                        }} />
                                                </div>
                                                <div className='searchStyle'>
                                                    <button onClick={handleHeartbeatFilterSearchClick} className='searchBtnStyle'>search</button>
                                                </div>
                                            </div>

                                            {customLoader ? <TableContainer
                                                pagination={liveSocketData ? true : false}
                                                paginationLogsPage={liveSocketData ? false : true}
                                                columns={heartColumns.filter((a) => a.show)}
                                                data={heartFilterData}
                                                customPageSize={currentPageSize}
                                                // customPageAll={Math.round(heartFilterData[0]?.totalCount / currentPageSize)}
                                                customPageAll={Math.ceil(heartFilterData[0]?.totalCount / currentPageSize) === 0 ? 1 : Math.ceil(heartFilterData[0]?.totalCount / currentPageSize)}

                                                customPreviousPage={(row) => { heartBeatPrevPage(row) }}
                                                customDisablePreviousPage={currentPageIndex === 1 ? true : false}
                                                customNextPage={(row) => { heartBeatNextPage(row) }}
                                                // customDisableNextPage={Math.round(heartFilterData[0]?.totalCount / currentPageSize) === currentPageIndex || heartFilterData[0]?.totalCount < 20 ? true : false}
                                                customDisableNextPage={(heartFilterData[0]?.totalCount / currentPageSize) < currentPageIndex || heartFilterData[0]?.totalCount < 20 ? true : false}

                                                customPageIndex={currentPageIndex}
                                                divClass="table-responsive mb-1 logsTable"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light text-muted"
                                                handleViewDetailClick={(row) => { handleViewDetailClick(row); }}
                                                maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - 165}
                                                customFilter={true}
                                                noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1672224362/Lubi/WebApp/Static-images/noCustomerfound.svg"}
                                                noDataTitle={'No Heartbeat Found!'} /> : <ContentLoader />}
                                        </div>
                                    </Tab>

                                    <Tab eventKey="bootNotification" title="Boot Notification">
                                        <div className='table-content'>
                                            <div className='filterRowStyle'>
                                                <div className='chargerId'>
                                                    <Input
                                                        // autocomplete="off"
                                                        name="ChargerId"
                                                        type="text"
                                                        className="form-control"
                                                        id="ChargerId"
                                                        maxLength={50}
                                                        placeholder="Charger ID"
                                                        value={bootNotificationIdVlaue}
                                                        onChange={(e) => {
                                                            setBootNotificationIdVlaue(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className='dateInputStyle'>
                                                    <Flatpickr
                                                        placeholder='Select Date'
                                                        className="form-control"
                                                        options={{
                                                            dateFormat: "d M, Y",
                                                            maxDate: new Date(),
                                                        }}
                                                        value={bootNotificationTabsFilterDate}
                                                        onChange={(date) => {
                                                            setBootNotificationTabsFilterDate(date);
                                                        }} />
                                                </div>
                                                <div className='searchStyle'>
                                                    <button onClick={handleBootNotificationFilterSearchClick} className='searchBtnStyle'>search</button>
                                                </div>
                                            </div>
                                            {customLoader ?
                                                <TableContainer
                                                    pagination={liveSocketData ? true : false}
                                                    paginationLogsPage={liveSocketData ? false : true}
                                                    columns={bootNotificationColumns.filter((a) => a.show)}
                                                    data={bootFilterData}
                                                    customPageSize={currentPageSize}

                                                    customPageAll={Math.ceil(bootFilterData[0]?.totalCount / currentPageSize) === 0 ? 1 : Math.ceil(bootFilterData[0]?.totalCount / currentPageSize)}
                                                    customPreviousPage={(row) => { bootPrevPage(row) }}
                                                    customDisablePreviousPage={currentPageIndex === 1 ? true : false}

                                                    customNextPage={(row) => { bootNextPage(row) }}
                                                    // customDisableNextPage={bootFilterData[0]?.totalCount < 20 ? true : false}
                                                    // customDisableNextPage={Math.round(bootFilterData[0]?.totalCount / currentPageSize) === currentPageIndex || bootFilterData[0]?.totalCount < 20 ? true : false}
                                                    customDisableNextPage={(bootFilterData[0]?.totalCount / currentPageSize) < currentPageIndex || bootFilterData[0]?.totalCount < 20 ? true : false}

                                                    customPageIndex={currentPageIndex}

                                                    handleViewDetailClick={(row) => { handleViewDetailClick(row); }}

                                                    divClass="table-responsive mb-1 logsTable"
                                                    tableClass="align-middle table-nowrap"
                                                    theadClass="table-light text-muted"
                                                    maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - 165}
                                                    customFilter={true}
                                                    noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1672224362/Lubi/WebApp/Static-images/noCustomerfound.svg"}
                                                    noDataTitle={'No Boot Notification Found!'} /> : <ContentLoader />}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="statusNotifications" title="Status Notification">
                                        <div className='table-content'>
                                            <div className='filterRowStyle'>
                                                <div className='chargerId'>
                                                    <Input
                                                        // autocomplete="off"
                                                        name="ChargerId"
                                                        type="text"
                                                        className="form-control"
                                                        id="ChargerId"
                                                        maxLength={50}
                                                        placeholder="Charger ID"
                                                        value={statusNotificationsIdVlaue}
                                                        onChange={(e) => {
                                                            setStatusNotificationsIdVlaue(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className='dateInputStyle'>
                                                    <Flatpickr
                                                        placeholder='Select Date'
                                                        className="form-control"
                                                        options={{
                                                            dateFormat: "d M, Y",
                                                            maxDate: new Date(),
                                                        }}
                                                        value={statusNotificationsTabsFilterDate}
                                                        onChange={(date) => {
                                                            // setDate(date);
                                                            setStatusNotificationsTabsFilterDate(date);
                                                        }} />
                                                </div>
                                                <div className="actionstyle">
                                                    <CustomDropdown
                                                        className='allDropdowns'
                                                        dropDownItems={statusNotificationsList}
                                                        defaultValue={'Select Status'}
                                                        placeholder={'Choose manufacturer'}
                                                        selectedValue={statusNotifications}
                                                        onSelect={evt => {
                                                            setStatusNotifications(evt)
                                                        }}
                                                    />
                                                </div>
                                                <div className='searchStyle'>
                                                    <button onClick={handleStatusNotificationsFilterSearchClick} className='searchBtnStyle'>search</button>
                                                </div>
                                            </div>
                                            {customLoader ? <TableContainer
                                                pagination={liveSocketData ? true : false}
                                                paginationLogsPage={liveSocketData ? false : true}
                                                columns={statusNotificationColumns.filter((a) => a.show)}
                                                data={statusFilterData}
                                                customPageSize={currentPageSize}
                                                customPageAll={Math.ceil(statusFilterData[0]?.totalCount / currentPageSize) === 0 ? 1 : Math.ceil(statusFilterData[0]?.totalCount / currentPageSize)}

                                                // customPageAll={Math.round(statusFilterData[0]?.totalCount / currentPageSize)}
                                                customPreviousPage={(row) => { statusNotificationPrevPage(row) }}
                                                customDisablePreviousPage={currentPageIndex === 1 ? true : false}
                                                customNextPage={(row) => { statusNotificationNextPage(row) }}
                                                // customDisableNextPage={Math.round(statusFilterData[0]?.totalCount / currentPageSize) === currentPageIndex || statusFilterData[0]?.totalCount < 20 ? true : false}
                                                customDisableNextPage={(statusFilterData[0]?.totalCount / currentPageSize) < currentPageIndex || statusFilterData[0]?.totalCount < 20 ? true : false}

                                                customPageIndex={currentPageIndex}
                                                divClass="table-responsive mb-1 logsTable"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light text-muted"
                                                handleViewDetailClick={(row) => { handleViewDetailClick(row); }}

                                                maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - 165}
                                                customFilter={true}
                                                noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1672224362/Lubi/WebApp/Static-images/noCustomerfound.svg"}
                                                noDataTitle={'No Status Notification Found!'} /> : <ContentLoader />}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="RemoteStart" title="Remote Start">
                                        <div className='table-content'>
                                            <div className='filterRowStyle'>
                                                <div className='chargerId'>
                                                    <Input
                                                        name="ChargerId"
                                                        type="text"
                                                        className="form-control"
                                                        id="ChargerId"
                                                        maxLength={50}
                                                        placeholder="Charger Id"
                                                        value={remoteStartIdVlaue}
                                                        onChange={(e) => {
                                                            setRemoteStartIdVlaue(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className='chargerId'>
                                                    <Input
                                                        name="transactionId"
                                                        type="text"
                                                        className="form-control"
                                                        id="transactionId"
                                                        maxLength={50}
                                                        placeholder="Transaction Id"
                                                        value={remoteStartTransactionIdVlaue}
                                                        onChange={(e) => {
                                                            setRemoteStartTransactionIdVlaue(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className='chargerId'>
                                                    <Input
                                                        name="rfidTag"
                                                        type="text"
                                                        className="form-control"
                                                        id="rfidTag"
                                                        maxLength={50}
                                                        placeholder="RFID Tag"
                                                        value={remoteStartRfidTag}
                                                        onChange={(e) => {
                                                            setRemoteStartRfidTag(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className='dateInputStyle'>
                                                    <Flatpickr
                                                        placeholder='Select Date'
                                                        className="form-control"
                                                        options={{
                                                            dateFormat: "d M, Y",
                                                            maxDate: new Date(),
                                                        }}
                                                        value={remoteStartTabsFilterDate}
                                                        onChange={(date) => {
                                                            // setDate(date);
                                                            setRemoteStartTabsFilterDate(date);
                                                        }} />
                                                </div>
                                                <div className="actionstyle">
                                                    <CustomDropdown
                                                        className='allDropdowns'
                                                        dropDownItems={remoteStartStatusList}
                                                        defaultValue={'Select Status'}
                                                        placeholder={'Choose manufacturer'}
                                                        selectedValue={remoteStartStatus}
                                                        onSelect={evt => {
                                                            setRemoteStartStatus(evt)
                                                        }}
                                                    />
                                                </div>
                                                <div className='searchStyle'>
                                                    <button onClick={handleRemoteStartFilterSearchClick} className='searchBtnStyle'>search</button>
                                                </div>
                                            </div>
                                            {customLoader ? <TableContainer
                                                pagination={liveSocketData ? true : false}
                                                paginationLogsPage={liveSocketData ? false : true}
                                                columns={remoteStartColumns.filter((a) => a.show)}
                                                data={startTraFilterData}
                                                customPageSize={currentPageSize}
                                                customPageAll={Math.ceil(startTraFilterData[0]?.totalCount / currentPageSize) === 0 ? 1 : Math.ceil(startTraFilterData[0]?.totalCount / currentPageSize)}
                                                customPreviousPage={(row) => { remoteStartPrevPage(row) }}
                                                customDisablePreviousPage={currentPageIndex === 1 ? true : false}
                                                customNextPage={(row) => { remoteStartNextPage(row) }}
                                                // customDisableNextPage={Math.round(startTraFilterData[0]?.totalCount / currentPageSize) === currentPageIndex || startTraFilterData[0]?.totalCount < 20 ? true : false}
                                                customDisableNextPage={(startTraFilterData[0]?.totalCount / currentPageSize) < currentPageIndex || startTraFilterData[0]?.totalCount < 20 ? true : false}

                                                customPageIndex={currentPageIndex}
                                                divClass="table-responsive mb-1 logsTable"
                                                tableClass="align-middle table-nowrap"
                                                handleViewDetailClick={(row) => { handleViewDetailClick(row); }}

                                                theadClass="table-light text-muted"
                                                maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - 165}
                                                customFilter={true}
                                                noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1672224362/Lubi/WebApp/Static-images/noCustomerfound.svg"}
                                                noDataTitle={'No Remote Start Found!'} /> : <ContentLoader />}
                                        </div>
                                    </Tab>

                                    <Tab eventKey="meterValue" title="Meter Value">
                                        <div className='table-content'>
                                            <div className='filterRowStyle'>
                                                <div className='chargerId'>
                                                    <Input
                                                        name="ChargerId"
                                                        type="text"
                                                        className="form-control"
                                                        id="ChargerId"
                                                        maxLength={50}
                                                        placeholder="Charger Id"
                                                        value={meterValuesId}
                                                        onChange={(e) => {
                                                            setMeterValuesId(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className='chargerId'>
                                                    <Input
                                                        name="transactionId"
                                                        type="text"
                                                        className="form-control"
                                                        id="transactionId"
                                                        maxLength={50}
                                                        placeholder="Transaction Id"
                                                        value={meterValuesTransactionId}
                                                        onChange={(e) => {
                                                            setMeterValuesTransactionId(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                {/* <div className='chargerId'>
                                                    <Input
                                                        name="rfidTag"
                                                        type="text"
                                                        className="form-control"
                                                        id="rfidTag"
                                                        maxLength={50}
                                                        placeholder="RFID Tag"
                                                        value={meterValuesTagId}
                                                        onChange={(e) => {
                                                            setMeterValuesTagId(e.target.value)
                                                        }}
                                                    />
                                                </div> */}
                                                <div className='dateInputStyle'>
                                                    <Flatpickr
                                                        placeholder='Select Date'
                                                        className="form-control"
                                                        options={{
                                                            dateFormat: "d M, Y",
                                                            maxDate: new Date(),
                                                        }}
                                                        value={meterValuesTabsFilterDate}
                                                        onChange={(date) => {
                                                            // setDate(date);
                                                            setMeterValuesTabsFilterDate(date);
                                                        }} />
                                                </div>
                                                <div className='searchStyle'>
                                                    <button onClick={handleMeterValuesFilterSearchClick} className='searchBtnStyle'>search</button>
                                                </div>
                                            </div>
                                            {customLoader ? <TableContainer
                                                pagination={liveSocketData ? true : false}
                                                paginationLogsPage={liveSocketData ? false : true}
                                                columns={metrValueColumns.filter((a) => a.show)}
                                                data={meterFilterData}
                                                handleViewDetailClick={(row) => { handleViewDetailClick(row); }}
                                                customPageSize={currentPageSize}
                                                customPageAll={Math.ceil(meterFilterData[0]?.totalCount / currentPageSize) === 0 ? 1 : Math.ceil(meterFilterData[0]?.totalCount / currentPageSize)}
                                                customPreviousPage={(row) => { meterValuePrevPage(row) }}
                                                customDisablePreviousPage={currentPageIndex === 1 ? true : false}
                                                customNextPage={(row) => { meterValueNextPage(row) }}
                                                // customDisableNextPage={meterFilterData[0]?.totalCount < 20 ? true : false}
                                                // customDisableNextPage={(Math.round(meterFilterData[0]?.totalCount / currentPageSize) === currentPageIndex) || (meterFilterData[0]?.totalCount < 20) ? true : false}
                                                customDisableNextPage={(meterFilterData[0]?.totalCount / currentPageSize) < currentPageIndex || meterFilterData[0]?.totalCount < 20 ? true : false}

                                                customPageIndex={currentPageIndex}
                                                divClass="table-responsive mb-1 logsTable"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light text-muted"
                                                maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - 165}
                                                customFilter={true}
                                                noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1672224362/Lubi/WebApp/Static-images/noCustomerfound.svg"}
                                                noDataTitle={'No Meter Value Found!'} /> : <ContentLoader />}
                                        </div>
                                    </Tab>

                                    <Tab eventKey="remoteStop" title="Remote Stop">
                                        <div className='table-content'>
                                            <div className='filterRowStyle'>
                                                <div className='chargerId'>
                                                    <Input
                                                        // autocomplete="off"
                                                        name="ChargerId"
                                                        type="text"
                                                        className="form-control"
                                                        id="ChargerId"
                                                        maxLength={50}
                                                        placeholder="Charger Id"
                                                        value={remoteStopIdVlaue}
                                                        onChange={(e) => {
                                                            setRemoteStopIdVlaue(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className='chargerId'>
                                                    <Input
                                                        name="transactionId"
                                                        type="text"
                                                        className="form-control"
                                                        id="transactionId"
                                                        maxLength={50}
                                                        placeholder="Transaction Id"
                                                        value={remoteStopTransactionIdVlaue}
                                                        onChange={(e) => {
                                                            setRemoteStopTransactionIdVlaue(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                {/* <div className='chargerId'>
                                                    <Input
                                                        name="rfidTag"
                                                        type="text"
                                                        className="form-control"
                                                        id="rfidTag"
                                                        maxLength={50}
                                                        placeholder="RFID Tag"
                                                        value={remoteStopTagId}
                                                        onChange={(e) => {
                                                            setRemoteStopTagId(e.target.value)
                                                        }}
                                                    />
                                                </div> */}
                                                <div className='dateInputStyle'>
                                                    <Flatpickr
                                                        placeholder='Select Date'
                                                        className="form-control"
                                                        options={{
                                                            dateFormat: "d M, Y",
                                                            maxDate: new Date(),
                                                        }}
                                                        value={remoteStopTabsFilterDate}
                                                        onChange={(date) => {
                                                            // setDate(date);
                                                            setRemoteStopTabsFilterDate(date);
                                                        }} />
                                                </div>
                                                <div className="actionstyle">
                                                    <CustomDropdown
                                                        className='allDropdowns'
                                                        dropDownItems={remoteStopStatusList}
                                                        defaultValue={'Select Status'}
                                                        placeholder={'Choose manufacturer'}
                                                        selectedValue={remoteStopStatus}
                                                        onSelect={evt => {
                                                            setRemoteStopStatus(evt)
                                                        }}
                                                    />
                                                </div>
                                                <div className='searchStyle'>
                                                    <button onClick={handleRemoteStopFilterSearchClick} className='searchBtnStyle'>search</button>
                                                </div>
                                            </div>
                                            {customLoader ? <TableContainer
                                                pagination={liveSocketData ? true : false}
                                                paginationLogsPage={liveSocketData ? false : true}
                                                columns={remoteStopColumns.filter((a) => a.show)}
                                                data={stopTrFilterData}
                                                handleViewDetailClick={(row) => { handleViewDetailClick(row); }}
                                                customPageSize={currentPageSize}
                                                customPageAll={Math.ceil(stopTrFilterData[0]?.totalCount / currentPageSize) === 0 ? 1 : Math.ceil(stopTrFilterData[0]?.totalCount / currentPageSize)}
                                                customPreviousPage={(row) => { remoteStopPrevPage(row) }}
                                                customDisablePreviousPage={currentPageIndex === 1 ? true : false}

                                                customNextPage={(row) => { remoteStopNextPage(row) }}
                                                customDisableNextPage={(stopTrFilterData[0]?.totalCount / currentPageSize) < currentPageIndex || stopTrFilterData[0]?.totalCount < 20 ? true : false}
                                                // customDisableNextPage={(stopTrFilterData[0]?.totalCount / currentPageSize) > currentPageIndex || stopTrFilterData[0]?.totalCount < 20 ? true : false}

                                                customPageIndex={currentPageIndex}
                                                divClass="table-responsive mb-1 logsTable"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light text-muted"
                                                maxHeight={dimensions.height - headerHeight - footerHeight - tableTaskBar - paginationDiv - cardHeader - 165}
                                                customFilter={true}
                                                noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1672224362/Lubi/WebApp/Static-images/noCustomerfound.svg"}
                                                noDataTitle={'No Remote Stop Found!'} /> : <ContentLoader />}
                                        </div>
                                    </Tab>
                                </Tabs>


                            </CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
            <Modal
                className="assignModal"
                isOpen={basicModal}
                toggle={() => {
                    setBasicModal(false);
                }}
                centered={true}
            >

                <ModalHeader>
                    <p>{modelValue.purpose}</p>
                </ModalHeader>
                <ModalBody className="">
                    <div className="assignMain" >
                        <div className="subMian subMainEdit mb-3">
                            <Label
                                htmlFor="choices-single-default"
                                className="form-label labelcustomstyle"
                            ><strong>Request</strong>
                                <pre>{JSON.stringify(modelValue, null, 2).replace(/^{/, '{\n').replace(/}$/, '}\n')}</pre>
                            </Label>
                            <hr></hr>
                            <Label
                                htmlFor="choices-single-default"
                                className="form-label labelcustomstyle"
                            ><strong>Response</strong>
                                {modelValueRes !== undefined ? (<pre>{JSON.stringify(modelValueRes, null, 2).replace(/^{/, '{\n').replace(/}$/, '}\n')}</pre>) : (<pre></pre>)}
                            </Label>

                        </div>
                        <div className="bottom d-flex justify-content-end mt-1 mb-3">
                            {/* <Button

                            >
                                Assign
                            </Button> */}
                            <Button
                                onClick={() => {
                                    setBasicModal(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal></>

    );
};


export default Logs;