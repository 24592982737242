import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../store/actions';

//import images
import AuthLogo from '../../Components/Common/AuthLogo';
import CustomButton from '../../Components/Common/CustomButton';
import { useEffect } from 'react';


const BasicLogout = () => {
    document.title = "Log Out | Vidhyutam";
    const history = useHistory();
    const dispatch = useDispatch();
    const handleSubmit = () => {
        history.push('/login');
    }

    useEffect(() => {
        
        dispatch(logoutUser());
        window.localStorage.removeItem('islogged');
        window.sessionStorage.clear();
        window.localStorage.removeItem('users')
        window.localStorage.removeItem('token')
        window.localStorage.setItem('current', 'login');

    }, []);

    return (
        <React.Fragment>
            <div className="auth-page-content">
                <div className="auth-page-wrapper">
                    <ParticlesAuth>
                        <div className="auth-page-content">
                            <Container>

                                <Row className="justify-content-center">
                                    <Col md={8} lg={6} xl={5}>
                                        <Card className="mt-4">
                                            <CardBody className="p-4 text-center">
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="text-center mt-sm-2 mb-4 text-white-50">
                                                            <AuthLogo />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <lord-icon
                                                    src="https://cdn.lordicon.com/hzomhqxz.json"
                                                    trigger="loop"
                                                    colors="primary:#25a0e2,secondary:#00bd9d"
                                                    style={{ width: "180px", height: "180px" }}>
                                                </lord-icon>

                                                <div className="mt-4 pt-2">
                                                    <h5 className='text-success'>You are Logged Out</h5>
                                                    <p className="text-muted">Thank you</p>
                                                    <div className="mt-4">
                                                        {/* <Link to="/login" className="btn btn-success w-100">Sign In</Link> */}
                                                        <CustomButton
                                                            title='Sign In'
                                                            type='submit'
                                                            className='button'
                                                            onClick={handleSubmit}
                                                        />
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </ParticlesAuth>

                </div>
            </div>
        </React.Fragment>
    );
};

export default BasicLogout;