import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { LIST_ALL_TARIFFS, ADD_TARIFF, VIEW_DETAIL_TARIFF, DELET_TARIFF, EDIT_TARIFF, ASSING_TARIFF } from "./actionTypes";

import { listAllTariffsSuccess, detailViewTariffSuccess } from "../actions";

import { ListallTariffs, detailTariff, ADDTariff, DeletTariff, EDITtariff, AssingTariff } from "../../helpers/Auth";

import { showToast, showLoader } from "../actions";

function* lsitAllTariffsList({ payload: { ID } }) {
    try {
        const response = yield call(ListallTariffs, ID)
        if (response) {
            yield put(listAllTariffsSuccess(response.payload));
        }
    } catch (e) {
        console.log(e);
        // yield put(showToast({
        //     message: e?.response?.data?.message,// e.message, 'Logged failed.'
        //     type: 'error'
        // }));
    }
}

function* DetailViewTariff({ payload: { TariffID } }) {
    try {
        const response = yield call(detailTariff, TariffID)
        if (response) {
            console.log(response);
            yield put(detailViewTariffSuccess(response.payload));
        }
    } catch (error) {
        console.log(error);
        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}

function* addTariff({ payload: { data, history } }) {
    yield put(showLoader(true));
    try {
        const response = yield call(ADDTariff, data)
        if (response) {
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
            setTimeout(() => {
                history.goBack();
            }, 1000);
            yield put(showLoader(false));
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
        yield put(showLoader(false));
    }
}

function* EditTariff({ payload: { data, TariffID, history } }) {
    yield put(showLoader(true));
    try {
        const response = yield call(EDITtariff, data, TariffID)
        if (response) {
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
            setTimeout(() => {
                history.goBack();
            }, 1000);
            yield put(showLoader(false));
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
        yield put(showLoader(false));
    }
}

function* DeleteTariff({ payload: { TariffID } }) {
    try {
        const response = yield call(DeletTariff, TariffID)
        if (response) {
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
        }
    } catch (e) {
        console.log(e);
        yield put(showToast({
            message: e?.response?.data?.message,
            type: 'error'
        }))
    }
}

function* AssignTariff({ payload: { data } }) {
    try {
        const response = yield call(AssingTariff, data)
        if (response) {
            console.log(response);
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
        }
    } catch (e) {
        console.log(e);
        yield put(showToast({
            message: e?.response?.data?.message,
            type: 'error'
        }))
    }
}

export function* TariffManagement() {
    yield takeEvery(LIST_ALL_TARIFFS, lsitAllTariffsList);
    yield takeEvery(VIEW_DETAIL_TARIFF, DetailViewTariff);
    yield takeEvery(ADD_TARIFF, addTariff);
    yield takeEvery(DELET_TARIFF, DeleteTariff);
    yield takeEvery(EDIT_TARIFF, EditTariff);
    yield takeEvery(ASSING_TARIFF, AssignTariff);
}

export default TariffManagement;