import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";
import Businesses from "../pages/Admin/Businesses/BussinessPage";
import BussinessDetail from "../pages/Admin/Businesses/BussinessDetail";
import Customers from "../pages/Admin/Customers/Customers";
import Fleet from "../pages/Admin/Fleet/Fleet";
import Rfid from "../pages/Admin/RFID/RFID.js";
import Profile from '../pages/Profile/Settings/EditProfile';
import AddBusinesses from "../pages/Admin/Businesses/AddBusinesses/AddBusiness";
import AddLocation from "../pages/Admin/Location/AddLocation/AddLocation";
import Location from "../pages/Admin/Location/Location";
import ViewLocation from "../pages/Admin/Location/ViewLocation";
import Station from "../pages/Admin/Station/Stations";
import ViewStation from "../pages/Admin/Station/ViewStation";
import AddStation from "../pages/Admin/Station/AddStation/AddStation";
import ConnectorType from "../pages/Admin/Assets/ConnectorType";


// Used pages for Vidhyutam
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import ResetPasswordPage from "../pages/Authentication/ResetPassword";
import SetPasswordPage from "../pages/Authentication/SetPassword";
import Verify from '../pages/Authentication/Verify';
import Logout from "../pages/Authentication/BasicLogout";
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
import AddEVSEModel from "../pages/Admin/Assets/AddEVSEModel/AddEVSEModel";
import OnBoarding from "../pages/OnBoarding/OnBoarding";
import Tariff from "../pages/Admin/Tariff/Tariff";
import Addtariff from "../pages/Admin/Tariff/addTariff";
import EVSEPage from "../pages/Admin/Assets/EVSEPage";
import ViewEVSE from "../pages/Admin/Assets/ViewEVSEmodel";
import RFIDManagement from "../pages/Admin/RFID Manager/RFIDManagement";
import TokenVerified from "../pages/Authentication/TokenVerified";

let authProtectedRoutes = [];

if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
  if (JSON.parse(window.localStorage.getItem('users')).userRole == 'LocationManager') {
    authProtectedRoutes = [
      // { path: "/*", component: Basic404 },
      { path: "/dashboard", component: Dashboard },
      { path: "/dashboard/", component: Dashboard },
      // { path: "/businesses", component: Businesses },
      // { path: "/businesses/businessesdetail", component: BussinessDetail },
      // { path: "/businesses/addbusiness", component: AddBusinesses },
      // { path: "/businesses/editbusiness", component: AddBusinesses },
      { path: "/customers", component: Customers },
      { path: "/customers/", component: Customers },
      { path: "/fleet", component: Fleet },
      { path: "/fleet/", component: Fleet },
      { path: "/rfid", component: Rfid },
      { path: "/rfid/", component: Rfid },
      { path: "/rfidmanagement", component: RFIDManagement },
      { path: "/rfidmanagement/", component: RFIDManagement },
      { path: "/profile", component: Profile },
      { path: "/profile/", component: Profile },
      { path: "/location/addlocation", component: AddLocation },
      { path: "/location/addlocation/", component: AddLocation },
      { path: "/location/editlocation", component: AddLocation },
      { path: "/location/editlocation/", component: AddLocation },
      { path: "/location", component: Location },
      { path: "/location/", component: Location },
      { path: "/location/viewlocation", component: ViewLocation },
      { path: "/location/viewlocation/", component: ViewLocation },
      { path: "/stations", component: Station },
      { path: "/stations/", component: Station },
      { path: "/stations/viewstation", component: ViewStation },
      { path: "/stations/viewstation/", component: ViewStation },
      { path: "/stations/addstation", component: AddStation },
      { path: "/stations/addstation/", component: AddStation },
      { path: "/stations/editstation", component: AddStation },
      { path: "/stations/editstation/", component: AddStation },
      { path: "/onboarding", component: OnBoarding },
      { path: "/onboarding/", component: OnBoarding },
      { path: "/tariff", component: Tariff },
      { path: "/tariff/", component: Tariff },
      { path: "/tariff/addtariff", component: Addtariff },
      { path: "/tariff/addtariff/", component: Addtariff },
      { path: "/tariff/edittariff", component: Addtariff },
      { path: "/tariff/edittariff/", component: Addtariff },
      // { path: "/assets/connectortype", component: ConnectorType },
      // { path: "/assets/addevsemodel", component: AddEVSEModel },
      // { path: "/assets/editevsemodel", component: AddEVSEModel },
      // { path: "/assets/editevseconnector", component: AddEVSEModel },
      // { path: "/assets/evsemodel", component: EVSEPage },
      // { path: "/assets/evsemodel/viewevse", component: ViewEVSE },


      // this route should be at the end of all other routes
      // eslint-disable-next-line react/display-name
      // {
      //   path: "/",
      //   exact: true,
      //   component: () => <Redirect to="/dashboard-analytics" />,
      // },
      {
        path: "/",
        exact: true,
        component: () => <Redirect to="/login" />,
      },

      { path: "/*", component: Basic404 },
    ];
  } else if (JSON.parse(window.localStorage.getItem('users')).userRole == 'BusinessOwner') {
    authProtectedRoutes = [
      // { path: "/*", component: Basic404 },
      { path: "/dashboard", component: Dashboard },
      { path: "/dashboard/", component: Dashboard },
      { path: "/businesses", component: Businesses },
      { path: "/businesses/", component: Businesses },
      { path: "/businesses/businessesdetail", component: BussinessDetail },
      { path: "/businesses/businessesdetail/", component: BussinessDetail },
      { path: "/businesses/addbusiness", component: AddBusinesses },
      { path: "/businesses/addbusiness/", component: AddBusinesses },
      { path: "/businesses/editbusiness", component: AddBusinesses },
      { path: "/businesses/editbusiness/", component: AddBusinesses },
      { path: "/customers", component: Customers },
      { path: "/customers/", component: Customers },
      { path: "/fleet", component: Fleet },
      { path: "/fleet/", component: Fleet },
      { path: "/rfid", component: Rfid },
      { path: "/rfid/", component: Rfid },
      { path: "/rfidmanagement", component: RFIDManagement },
      { path: "/rfidmanagement/", component: RFIDManagement },
      { path: "/profile", component: Profile },
      { path: "/profile/", component: Profile },
      { path: "/location/addlocation", component: AddLocation },
      { path: "/location/addlocation/", component: AddLocation },
      { path: "/location/editlocation", component: AddLocation },
      { path: "/location/editlocation", component: AddLocation },
      { path: "/location/editlocation/", component: AddLocation },
      { path: "/location", component: Location },
      { path: "/location/", component: Location },
      { path: "/location/viewlocation", component: ViewLocation },
      { path: "/location/viewlocation/", component: ViewLocation },
      { path: "/stations", component: Station },
      { path: "/stations/", component: Station },
      { path: "/stations/viewstation", component: ViewStation },
      { path: "/stations/viewstation/", component: ViewStation },
      { path: "/stations/addstation", component: AddStation },
      { path: "/stations/addstation/", component: AddStation },
      { path: "/stations/editstation", component: AddStation },
      { path: "/stations/editstation/", component: AddStation },
      { path: "/onboarding", component: OnBoarding },
      { path: "/onboarding/", component: OnBoarding },
      { path: "/tariff", component: Tariff },
      { path: "/tariff/", component: Tariff },
      { path: "/tariff/addtariff", component: Addtariff },
      { path: "/tariff/addtariff/", component: Addtariff },
      { path: "/tariff/edittariff", component: Addtariff },
      { path: "/tariff/edittariff/", component: Addtariff },
      // { path: "/assets/connectortype", component: ConnectorType },
      // { path: "/assets/addevsemodel", component: AddEVSEModel },
      // { path: "/assets/editevsemodel", component: AddEVSEModel },
      // { path: "/assets/editevseconnector", component: AddEVSEModel },
      // { path: "/assets/evsemodel", component: EVSEPage },
      // { path: "/assets/evsemodel/viewevse", component: ViewEVSE },


      // this route should be at the end of all other routes
      // eslint-disable-next-line react/display-name
      // {
      //   path: "/",
      //   exact: true,
      //   component: () => <Redirect to="/dashboard-analytics" />,
      // },
      {
        path: "/",
        exact: true,
        component: () => <Redirect to="/login" />,
      },

      { path: "/*", component: Basic404 },
    ];
  } else if (JSON.parse(window.localStorage.getItem('users')).userRole == 'SuperAdmin') {
    authProtectedRoutes = [
      // { path: "/*", component: Basic404 },
      { path: "/dashboard", component: Dashboard },
      { path: "/dashboard/", component: Dashboard },
      { path: "/businesses", component: Businesses },
      { path: "/businesses/", component: Businesses },
      { path: "/businesses/businessesdetail", component: BussinessDetail },
      { path: "/businesses/businessesdetail/", component: BussinessDetail },
      { path: "/businesses/addbusiness", component: AddBusinesses },
      { path: "/businesses/addbusiness/", component: AddBusinesses },
      { path: "/businesses/editbusiness", component: AddBusinesses },
      { path: "/businesses/editbusiness/", component: AddBusinesses },
      { path: "/customers", component: Customers },
      { path: "/customers/", component: Customers },
      { path: "/fleet", component: Fleet },
      { path: "/fleet/", component: Fleet },
      { path: "/rfid", component: Rfid },
      { path: "/rfid/", component: Rfid },
      { path: "/rfidmanagement", component: RFIDManagement },
      { path: "/rfidmanagement/", component: RFIDManagement },
      { path: "/profile", component: Profile },
      { path: "/profile/", component: Profile },
      { path: "/location/addlocation", component: AddLocation },
      { path: "/location/addlocation/", component: AddLocation },
      { path: "/location/editlocation", component: AddLocation },
      { path: "/location/editlocation/", component: AddLocation },
      { path: "/location", component: Location },
      { path: "/location/", component: Location },
      { path: "/location/viewlocation", component: ViewLocation },
      { path: "/location/viewlocation/", component: ViewLocation },
      { path: "/stations", component: Station },
      { path: "/stations/", component: Station },
      { path: "/stations/viewstation", component: ViewStation },
      { path: "/stations/viewstation/", component: ViewStation },
      { path: "/stations/addstation", component: AddStation },
      { path: "/stations/addstation/", component: AddStation },
      { path: "/stations/editstation", component: AddStation },
      { path: "/stations/editstation/", component: AddStation },
      { path: "/onboarding", component: OnBoarding },
      { path: "/onboarding/", component: OnBoarding },
      // { path: "/tariff", component: Tariff },
      // { path: "/tariff/", component: Tariff },
      // { path: "/tariff/addtariff", component: Addtariff },
      // { path: "/tariff/addtariff/", component: Addtariff },
      // { path: "/tariff/edittariff", component: Addtariff },
      // { path: "/tariff/edittariff/", component: Addtariff },
      { path: "/assets/connectortype", component: ConnectorType },
      { path: "/assets/connectortype/", component: ConnectorType },
      { path: "/assets/addevsemodel", component: AddEVSEModel },
      { path: "/assets/addevsemodel/", component: AddEVSEModel },
      { path: "/assets/editevsemodel", component: AddEVSEModel },
      { path: "/assets/editevsemodel/", component: AddEVSEModel },
      { path: "/assets/editevseconnector", component: AddEVSEModel },
      { path: "/assets/editevseconnector/", component: AddEVSEModel },
      { path: "/assets/evsemodel", component: EVSEPage },
      { path: "/assets/evsemodel/", component: EVSEPage },
      { path: "/assets/evsemodel/viewevse", component: ViewEVSE },
      { path: "/assets/evsemodel/viewevse/", component: ViewEVSE },


      // this route should be at the end of all other routes
      // eslint-disable-next-line react/display-name
      // {
      //   path: "/",
      //   exact: true,
      //   component: () => <Redirect to="/dashboard-analytics" />,
      // },
      {
        path: "/",
        exact: true,
        component: () => <Redirect to="/login" />,
      },

      { path: "/*", component: Basic404 },
    ];
  }
}

else {


  authProtectedRoutes = [
    // { path: "/*", component: Basic404 },
    { path: "/dashboard", component: Dashboard },
    { path: "/dashboard/", component: Dashboard },
    { path: "/businesses", component: Businesses },
    { path: "/businesses/", component: Businesses },
    { path: "/businesses/businessesdetail", component: BussinessDetail },
    { path: "/businesses/businessesdetail/", component: BussinessDetail },
    { path: "/businesses/addbusiness", component: AddBusinesses },
    { path: "/businesses/addbusiness/", component: AddBusinesses },
    { path: "/businesses/editbusiness", component: AddBusinesses },
    { path: "/businesses/editbusiness/", component: AddBusinesses },
    { path: "/customers", component: Customers },
    { path: "/customers/", component: Customers },
    { path: "/fleet", component: Fleet },
    { path: "/fleet/", component: Fleet },
    { path: "/rfid", component: Rfid },
    { path: "/rfid/", component: Rfid },
    { path: "/rfidmanagement", component: RFIDManagement },
    { path: "/rfidmanagement/", component: RFIDManagement },
    { path: "/profile", component: Profile },
    { path: "/profile/", component: Profile },
    { path: "/location/addlocation", component: AddLocation },
    { path: "/location/addlocation/", component: AddLocation },
    { path: "/location/editlocation", component: AddLocation },
    { path: "/location/editlocation/", component: AddLocation },
    { path: "/location", component: Location },
    { path: "/location/", component: Location },
    { path: "/location/viewlocation", component: ViewLocation },
    { path: "/location/viewlocation/", component: ViewLocation },
    { path: "/stations", component: Station },
    { path: "/stations/", component: Station },
    { path: "/stations/viewstation", component: ViewStation },
    { path: "/stations/viewstation/", component: ViewStation },
    { path: "/stations/addstation", component: AddStation },
    { path: "/stations/addstation/", component: AddStation },
    { path: "/stations/editstation", component: AddStation },
    { path: "/stations/editstation/", component: AddStation },
    { path: "/onboarding", component: OnBoarding },
    { path: "/onboarding/", component: OnBoarding },
    { path: "/tariff", component: Tariff },
    { path: "/tariff/", component: Tariff },
    { path: "/tariff/addtariff", component: Addtariff },
    { path: "/tariff/addtariff/", component: Addtariff },
    { path: "/tariff/edittariff", component: Addtariff },
    { path: "/tariff/edittariff/", component: Addtariff },
    { path: "/assets/connectortype", component: ConnectorType },
    { path: "/assets/connectortype/", component: ConnectorType },
    { path: "/assets/addevsemodel", component: AddEVSEModel },
    { path: "/assets/addevsemodel/", component: AddEVSEModel },
    { path: "/assets/editevsemodel", component: AddEVSEModel },
    { path: "/assets/editevsemodel/", component: AddEVSEModel },
    { path: "/assets/editevseconnector", component: AddEVSEModel },
    { path: "/assets/editevseconnector/", component: AddEVSEModel },
    { path: "/assets/evsemodel", component: EVSEPage },
    { path: "/assets/evsemodel/", component: EVSEPage },
    { path: "/assets/evsemodel/viewevse", component: ViewEVSE },
    { path: "/assets/evsemodel/viewevse/", component: ViewEVSE },


    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    // {
    //   path: "/",
    //   exact: true,
    //   component: () => <Redirect to="/dashboard-analytics" />,
    // },
    {
      path: "/",
      exact: true,
      component: () => <Redirect to="/login" />,
    },

    { path: "/*", component: Basic404 },
  ];
}



const publicRoutes = [
  // Authentication Page
  // { path: "/logout", component: Logout },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/resetpassword", component: ResetPasswordPage },
  { path: "/setpassword", component: SetPasswordPage },
  { path: "/verify", component: Verify },
  { path: "/verifyEmail", component: TokenVerified },

];

export { authProtectedRoutes, publicRoutes };