import axios from "axios";
import { api } from "../config";
import { get } from "lodash";

const instance = axios.create({
  baseURL: api.API_URL,
  // baseURL: "http://localhost:4000/v1/",
});

let isRefreshing = false;
let failedQueue = [];

// Process all failed request
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) prom.reject(error);
    else prom.resolve(token);
  });
  failedQueue = [];
};

instance.interceptors.request.use((config) => {
  let token;
  // token = JSON.parse(sessionStorage.getItem("token")) ? JSON.parse(sessionStorage.getItem("token"))?.accessToken : null;
  if (JSON.parse(sessionStorage.getItem("token")) !== null) {
    token = JSON.parse(sessionStorage.getItem("token")) ? JSON.parse(sessionStorage.getItem("token"))?.accessToken : null;
  } else if (JSON.parse(localStorage.getItem("token")) !== null) {
    token = JSON.parse(localStorage.getItem("token")) ? JSON.parse(localStorage.getItem("token"))?.accessToken : null;
  }
  
  if (token)
    config.headers["Authorization"] = token;
  return config;
});

instance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const originalRequest = error.config;
    const responseStatus = get(error, "response.status", "");

    // If refresh token fails
    if (
      responseStatus === 401 &&
      error.config.url.indexOf("refresh-token") != -1
    ) {
      processQueue(error, null);
      isRefreshing = false;
      return Promise.reject(error);
    }

    if (responseStatus === 401 && error.config.url.indexOf("login") != -1) {
      return Promise.reject(error);
    }

    // Check if original request
    if (responseStatus === 401 && !originalRequest._retry) {
      // Push all the failed request due to expired token in queue
      if (isRefreshing) {
        return new Promise((resolve, reject) =>
          failedQueue.push({ resolve, reject })
        )
          .then((token) => {
            originalRequest.headers["Authorization"] = "Token " + token;
            return instance(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      // Try to refresh token
      return new Promise((resolve, reject) => {
        let token;
        // token = JSON.parse(sessionStorage.getItem("token")) ? JSON.parse(sessionStorage.getItem("token"))?.accessToken : null;
        if (JSON.parse(sessionStorage.getItem("token")) !== null) {
          token = JSON.parse(sessionStorage.getItem("token")) ? JSON.parse(sessionStorage.getItem("token"))?.refreshToken : null;
        } else if (JSON.parse(localStorage.getItem("token")) !== null) {
          token = JSON.parse(localStorage.getItem("token")) ? JSON.parse(localStorage.getItem("token"))?.refreshToken : null;
        }


        const refreshToken = JSON.parse(sessionStorage.getItem("token"))?.refreshToken;
        // const request = { refreshToken };
        const request = { refreshToken: token };
        axios
          .post(`${api.API_URL}/auth/refreshToken`, request)
          .then((response) => {
            const token = get(response.payload, "accessToken", "");
            const refreshToken = get(
              response.payload,
              "refreshToken",
              ""
            );
            sessionStorage.setItem("token", JSON.stringify(response.payload));
            localStorage.setItem("token", JSON.stringify(response.payload));

            // await window.localStorage.setItem("access_token", token);
            // await window.localStorage.setItem("refresh_token", refreshToken);
            instance.defaults.headers.common["Authorization"] = token;
            originalRequest.headers["Authorization"] = token;
            processQueue(null, token);
            resolve(instance(originalRequest));
          })
          .catch((err) => {
            console.log('norefreshtoken');
            const homeURL = window.location.protocol + '//' + window.location.hostname + '/login';
            processQueue(err, null);
            window.location.href = homeURL;
            window.localStorage.clear();
            window.sessionStorage.clear();
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;