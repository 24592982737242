import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
} from "reactstrap";
import classnames from "classnames";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import { useHistory, useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import CustomDropdown from "../../../../Components/Common/CustomDropdown";
import { useFormik } from "formik";
import Constant from "../../../../utils/constant";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { connectorTypes, modelTypes, detailEvse, DetailEvseConnector, editEvseModel, connecterDetails, DetailEvseSingleConnector, editEvseConnecter } from "../../../../store/actions";
import { addEVSE } from "../../../../store/actions";
import CustomButton from "../../../../Components/Common/CustomButton";

// register lottie and define custom element

const AddEVSEModel = (props) => {
    document.title = window.location.href.includes('add') ? "Add EVSE Model | Vidhyutam" : window.location.href.includes('editevseconnector') ? "Edit EVSE Connector | Vidhyutam" : "Edit EVSE Model | Vidhyutam";
    const history = useHistory();
    const { state } = useLocation();

    const dispatch = useDispatch();
    const [selectedGroup1, setSelectedGroup1] = useState(null);
    const [selectedGroup2, setSelectedGroup2] = useState('');
    const [selectedGroup3, setSelectedGroup3] = useState(null);
    const [activeArrowTab, setactiveArrowTab] = useState(4);
    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    // const [connectorDataSelect, setConnectorDataSelect] = useState();
    // const [formatSelect, setFormatSelect] = useState();
    // const [powerTypeSelect, setPowerTypeSelect] = useState();
    const [allConnectors, setAllConnectors] = useState([]);
    const [allModels, setAllModels] = useState([]);
    const [tempConnectorData, setTempConnectorData] = useState([]);
    const [formData1, setFormData1] = useState([]);
    const [connectorData, setConnectorData] = useState([]);
    const [formatData, setFormatData] = useState([]);
    const [powerTypeData, setPowerTypeData] = useState([]);
    const [IsEditEvseModel] = useState(state?.state?.type === 'EDIT_MODAL' ? true : false); // edit model
    const [IsAddEvseModel] = useState(state?.state?.type ? false : true); //for nav tab
    const [IsEditConnector] = useState(state?.state?.type === 'EDIT_CONNECTOR' ? true : false); // edit conncector
    const [typeOfEdit, setTypeOfEdit] = useState(false);
    const [EVSEid, setEVSEid] = useState('');
    const [evseConnecterId, setEvseConnecterId] = useState('');
    const [EVSEconnId, setEVSEconnId] = useState('');

    const [form2DefaultValues, setform2DefaultValues] = useState({});
    const [form2Validations, setform2Validations] = useState({});





    

    function handleSelectGroups1(selectedGroup1) {
        setSelectedGroup1(selectedGroup1);
        
    }
    function handleSelectGroups3(selectedGroup3) {
        setSelectedGroup3(selectedGroup3);
    }

    useEffect(() => {
        dispatch(connectorTypes(true));
        dispatch(modelTypes());
        // dispatch(connecterDetails());
        
    }, []);

    useEffect(() => {
        
        if (state?.state?.type === 'EDIT_MODAL') {
            setTypeOfEdit(state?.state?.type);
            const EvseID = state?.state?.EVSEid;
            setEVSEid(EvseID);
            dispatch(detailEvse(EvseID));

        }
        // if (state?.state?.type === 'EDIT_CONNECTOR') {
        //     const EvseconnecterId = state?.state?.ConnectorID;

        //     setEvseConnecterId(EvseconnecterId);

        //     //dispatch(connecterDetails(EvseconnecterId));

        // }
    }, [])

    useEffect(() => {
        if (state?.state?.type === 'EDIT_CONNECTOR') {
            setTypeOfEdit(state?.state?.type);
            const EvseConnectorID = state?.state?.ConnectorID;
            setEVSEconnId(EvseConnectorID);
            dispatch(DetailEvseSingleConnector(EvseConnectorID));
        }
    }, [])


    const [isForm3Valid, setisForm3Valid] = useState(false);
    const [form3Values, setform3Values] = useState({
        powerType: '',
        formatData: '',
        connectorType: ''
    })

    const { getConnetorTypes, getModelTypes, getDetailEvse, getDetailEvseConnector, getDetailEvseSingleConnector, getconnecterDetail, } = useSelector((state) => ({
        getConnetorTypes: state?.AssetManagment?.connectorTypes,
        getModelTypes: state?.AssetManagment?.modelTypes,
        getDetailEvse: typeOfEdit === 'EDIT_MODAL' && state?.AssetManagment?.detailEvse[0],
        getDetailEvseConnector: state?.AssetManagment?.detailEvseConnector,
        getDetailEvseSingleConnector: state?.AssetManagment?.detailSingleEvseConnector[0],
        // getconnecterDetail: state?.AssetManagment?.detailSingleEvseConnector
    }))
    
    
    

    // update handler
    const handleform3edit = (Type, oldvalue, newvalue) => {
        
        // console.table([isForm3Valid, oldvalue, newvalue]);
        if (oldvalue !== newvalue) {
            setisForm3Valid(true);
            return;
        } else if (Type == 'powerType' && getDetailEvseSingleConnector.powerType !== newvalue) {
            setisForm3Valid(true);
            return;
        } else if (Type == 'formatData' && getDetailEvseSingleConnector.format !== newvalue) {
            setisForm3Valid(true);
            return;
        } else if (Type == 'connectorType' && getDetailEvseSingleConnector.connectorType !== newvalue) {
            setisForm3Valid(true);
            return;
        } else {
            setisForm3Valid(false);
        }



        // if (Type == 'powerType' && form3Values.powerType !== newvalue || Type == 'formatData' && form3Values.formatData !== newvalue || Type == 'connectorType' && form3Values.connectorType !== newvalue) {
        //     setisForm3Valid(true);
        //     return;
        // } else {
        //     setisForm3Valid(false);
        // }
        // } else if (Type == 'powerType' && oldvalue !== newvalue) {
        //     setisForm3Valid(true);
        //     return;
        // } else if (Type == 'formatData' && oldvalue !== newvalue) {
        //     setisForm3Valid(true);
        //     return;
        // } else if (Type == 'connectorType' && oldvalue !== newvalue) {
        //     setisForm3Valid(true);
        //     return;
        // } else {
        //     setisForm3Valid(false);
        // }
    }


    useEffect(() => {
        // getDetailEvseSingleConnector && setConnectorData({ id: `${getDetailEvseSingleConnector?.connectorId}`, value: getDetailEvseSingleConnector?.connectorType })
        setFormatData({ id: `1`, value: getDetailEvseSingleConnector?.format })
        setPowerTypeData({ id: `1`, value: getDetailEvseSingleConnector?.powerType })
    }, [getDetailEvseSingleConnector])

    useEffect(() => {
        if (getDetailEvse?.modelType) {
            setSelectedGroup2({ id: `${getDetailEvse?.modelId}`, value: getDetailEvse?.modelType });

        }
    }, [getDetailEvse]);

    useEffect(() => {
        if (getDetailEvseConnector?.connectorType) {
            setConnectorData({ id: `${getDetailEvseConnector?.connectorId}`, value: getDetailEvseConnector?.connectorType });

        }
    }, [getDetailEvseConnector]);

    

    useEffect(() => {
        if (getConnetorTypes?.length > 0) {
            const typesOfConnector = getConnetorTypes.map((e, i) => {
                return {
                    id: `${e.typeId}`,
                    value: e.connectorType
                }
            })
            setAllConnectors(typesOfConnector);
        }
        if (getModelTypes?.length > 0) {
            const typesOfModel = getModelTypes.map((e, i) => {
                return {
                    id: `${e.modelId}`,
                    value: e.modelType,
                    label: e.modelType
                }
            });
            setAllModels(typesOfModel);
        }
    }, [getConnetorTypes, getModelTypes]);

    const GroupOptions1 = [
        { id: '1', value: 'Odhav Road , Ahmedabad, Titania Pvt Ltd', label: 'Odhav Road , Ahmedabad, Titania Pvt Ltd' },
        { id: '2', value: 'Dariapur, Ahmedabad, Titania Pvt Ltd', label: 'Dariapur, Ahmedabad,  Titania Pvt Ltd' },
        { id: '3', value: 'Ratan Pole , Ahmedabad,  Titania Pvt Ltd', label: 'Ratan Pole , Ahmedabad,  Titania Pvt Ltd' },
        { id: '4', value: 'Narol , Ahmedabad,  Titania Pvt Ltd', label: 'Narol , Ahmedabad,  Titania Pvt Ltd' },
        { id: '5', value: 'Paldi , Ahmedabad,  Titania Pvt Ltd', label: 'Paldi , Ahmedabad,  Titania Pvt Ltd' },
        { id: '6', value: 'Naroda , Ahmedabad,  Titania Pvt Ltd', label: 'Naroda , Ahmedabad,  Titania Pvt Ltd' },
        { id: '7', value: 'Bopal , Ahmedabad,  Titania Pvt Ltd', label: 'Bopal , Ahmedabad,  Titania Pvt Ltd' },
    ];

    const GroupOptions2 = [
        { id: '1', value: 'IC100', label: 'IC100' },
        { id: '2', value: 'IC200', label: 'IC200' },
        { id: '3', value: 'IC300', label: 'IC300' },
        { id: '4', value: 'IC400', label: 'IC400' },
        { id: '5', value: 'IC500', label: 'IC500' },
        { id: '6', value: 'IC600', label: 'IC600' },
        { id: '7', value: 'IC700', label: 'IC700' },
    ];

    const GroupOptions3 = [
        { id: '1', value: 'Zero', label: 'Zero' },
        { id: '2', value: 'Two', label: 'Two' },
        { id: '3', value: 'Four', label: 'Four' },
        { id: '4', value: 'One', label: 'One' },
        { id: '5', value: 'Five', label: 'Five' },
        { id: '6', value: 'Three', label: 'Three' },
        { id: '7', value: 'Six', label: 'Six' },
    ];

    function toggleArrowTab(tab) {
        if (activeArrowTab !== tab) {
            var modifiedSteps = [...passedarrowSteps, tab];

            if (tab >= 4 && tab <= 7) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    }
    const ConnectorData = [
        {
            id: '1',
            value: 'IEC 62196 (Type 2)'
        },
        {
            id: '2',
            value: 'CCS 1'
        },
        {
            id: '3',
            value: 'CCS 2'
        },
        {
            id: '4',
            value: 'CHAdeMO'
        },
        {
            id: '5',
            value: 'GB/T AC'
        },
        {
            id: '6',
            value: 'GB/T DC'
        }
    ]
    const FormatData = [
        {
            id: '1',
            value: 'Socket'
        },
        {
            id: '2',
            value: 'Plug'
        }
    ]
    const PowerTypeData = [
        {
            id: '1',
            value: 'AC'
        },
        {
            id: '2',
            value: 'DC'
        }
    ]
    const Connenctors = [
        {
            id: '1',
            connectorTypeName: 'Connector 01'
        },
        {
            id: '2',
            connectorTypeName: 'Connector 02'
        },
        {
            id: '3',
            connectorTypeName: 'Connector 03'
        },
    ]
    // useEffect(() => {
    //     if ((!form1) && getDetailEvse) {
    //         // eslint-disable-next-line react-hooks/exhaustive-deps
    //         dataObj = {
    //             modelName: '',
    //             maxPowerSupply: '',
    //             noOfConnector: '',
    //         }
    //         form1.setFieldValue('connectorType', getDetailEvse.modelName, { shouldValidate: true });
    //         form1.setFieldValue('maxPowerSupply',  '', { shouldValidate: true });
    //         form1.setFieldValue('noOfConnector',  '', { shouldValidate: true });


    //         // addLocationForm.setFieldValue('email', item?.email, { shouldValidate: true });
    //     } else {
    //         dataObj = {
    //             connectorType: '',
    //             discription: '',
    //         }
    //         
    //         form1.setFieldValue('connectorType', '', { shouldValidate: true });
    //         form1.setFieldValue('maxPowerSupply',  '', { shouldValidate: true });
    //         form1.setFieldValue('noOfConnector',  '', { shouldValidate: true });
    //     }
    // }, [getDetailEvse, form1]);


    const form1 = useFormik({
        enableReinitialize: true,

        initialValues: {
            modelName: getDetailEvse?.modelName || '',
            // maxPowerSupply: getDetailEvse?.maxPower || '',
            noOfConnector: '',
            modelType: getDetailEvse?.modelType || ''
        },
        validationSchema: Yup.object(typeOfEdit === 'EDIT_MODAL' ? {
            modelName: Yup.string()
                .required('Model name is required')
                .matches(Constant.REGEX.ALPHANUMERIC, 'Please provide a valid model name'),

            // noOfConnector: Yup.number().max(3, "No of connector should be less than 3").min(1)

            // .required('No. of connector required'),
            // .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only')
            // .matches(Constant.REGEX.MIN_3, 'Number of connector should be 1, 2 or 3'),
            // maxPowerSupply: Yup.number().min(1)
            // .required('Max power supply is required'),
            // .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only')
            modelType: Yup.string().required()
        } : {
            modelName: Yup.string()

                .required('Model name is required')
                .matches(Constant.REGEX.ALPHANUMERIC, 'Please provide a valid model name'),

            noOfConnector: Yup.number().max(3, "No of connector should be less than 3").min(1, "No of connector should be greater than 0")
                .required('No. of connector is required'),
            // .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only')
            // .matches(Constant.REGEX.MIN_3, 'Number of connector should be 1, 2 or 3'),
            // maxPowerSupply: Yup.number().min(1)
            // .required('Max power supply is required'),
            // .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only')
            modelType: Yup.string().required()
        }),
        onSubmit: (values) => {
            
            

            if (typeOfEdit === 'EDIT_MODAL') {
                const params = {
                    modelName: values?.modelName,
                    modelType: +(selectedGroup2?.id)
                }
                dispatch(editEvseModel(params, EVSEid, props.history));
            }
            if (values.noOfConnector) {
                setFormData1(values);
                
                noOfConnectors(Number(values.noOfConnector));
            }

        }
    });

    const noOfConnectors = (noValues) => {
        const tempArray = [];
        if (noValues < 4) {
            for (let i = 0; i < noValues; i++) {
                
                const table = {
                    id: `${i + 1}`,
                    connectorTypeName: `Connector 0${i + 1}`,
                    connectorType: '',
                    format: '',
                    powerType: '',
                    maxVoltage: '',
                    maxAmpere: '',
                    maxPower: ''
                }
                tempArray.push(table);
            }
            setTempConnectorData(tempArray);
        }
    }
    // const [tempConnectorData, setTempConnectorData] = useState([
    //     {
    //         id: '1',
    //         connectorTypeName: 'Connector 01',
    //         ConnectorData: '',
    //         FormatData: '',
    //         PowerTypeData: '',
    //         maxVoltage: '',
    //         maxAmphere: '',
    //         maxElectricPower: ''
    //     },
    //     {
    //         id: '2',
    //         connectorTypeName: 'Connector 02',
    //         ConnectorData: '',
    //         FormatData: '',
    //         PowerTypeData: '',
    //         maxVoltage: '',
    //         maxAmphere: '',
    //         maxElectricPower: ''
    //     }
    // ]);
    const editConnectorData = {
        id: '1',
        connectorTypeName: 'Connector 01',
        ConnectorData: '',
        FormatData: '',
        PowerTypeData: '',
        maxVoltage: '',
        maxAmphere: '',
        maxElectricPower: ''
    }

    let dataObj = {};
    let dataObjval = {};
    useEffect(() => {
        tempConnectorData.forEach((d, i) => {
            dataObj[`maxVoltage-${i}`] = ''
            dataObj[`maxAmpere-${i}`] = ''
            dataObj[`maxElectricPower-${i}`] = ''
            // dataObj[`connectorType-${i}`] = 'IEC 62196 (Type 2)'
            // dataObj[`format-${i}`] = 'Socket'
            // dataObj[`powerType-${i}`] = 'DC'
            dataObj[`connectorType-${i}`] = ''
            dataObj[`format-${i}`] = ''
            dataObj[`powerType-${i}`] = ''

            dataObjval[`maxVoltage-${i}`] = Yup.number().min(1).max(1500, 'Max. voltage should be less than 1500')
                .required('Max. voltage is required')
            // .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only')
            dataObjval[`maxAmpere-${i}`] = Yup.number().min(1).max(500, 'Max. current should be less than 500')
                .required('Max. amphere is required')
            // .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only')
            dataObjval[`maxElectricPower-${i}`] = Yup.number().min(1).max(300, 'Max. electric power should be less than 300')
                .required('Max. electric power is required')
            // .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only')
            dataObjval[`connectorType-${i}`] = Yup.string().required()
            dataObjval[`format-${i}`] = Yup.string().required()
            dataObjval[`powerType-${i}`] = Yup.string().required()
        })
        setform2DefaultValues(dataObj);
        setform2Validations(dataObjval);
    }, [tempConnectorData]);
    let form2 = useFormik({
        enableReinitialize: true,
        dirty: true,
        // initialValues: dataObj,
        initialValues: form2DefaultValues,
        // validationSchema: Yup.object(dataObjval),
        validationSchema: Yup.object().shape(form2Validations),
        onSubmit: (values) => {
            
            
            const connectorDetailObj = tempConnectorData.map((e, i) => {
                return {
                    connectorType: e.ConnectorData ? +(e.ConnectorData.id) : +(allConnectors[0].id),
                    format: e?.FormatData ? e?.FormatData.value : FormatData[0].value,
                    powerType: e?.PowerTypeData ? e?.PowerTypeData.value : PowerTypeData[1].value,
                    maxVoltage: Number(e?.maxVoltage),
                    maxAmpere: +(e?.maxAmpere),
                    maxPower: Number(e?.maxElectricPower)
                }
            })
            const params = {
                modelName: formData1?.modelName,
                modelType: +(selectedGroup2?.id),
                connectorDetails: connectorDetailObj
            }
            dispatch(addEVSE(params, props.history));
        }
    });
    let form3 = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            connectorData: getDetailEvseSingleConnector?.connectorType || '',
            formatData: getDetailEvseSingleConnector?.format || '',
            powerTypeData: getDetailEvseSingleConnector?.powerType || '',
            maxVoltageEdit: getDetailEvseSingleConnector?.maxVoltage || '',
            maxAmphereEdit: getDetailEvseSingleConnector?.maxAmpere || '',
            maxElectricPowerEdit: getDetailEvseSingleConnector?.maxPower || '',
        },

        validationSchema: Yup.object().shape(typeOfEdit === 'EDIT_CONNECTOR' && {
            // email: Yup.string().required("Please Enter Your Email"),
            maxVoltageEdit: Yup.number().max(1500, 'Max voltage should be less than 1500').min(0)
                .required('Max. voltage is required'),
            // .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only'),
            // .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only').test('notsame', '', function (newvalue) {
            //     return Number(newvalue) !== getDetailEvseSingleConnector.maxVoltage;
            // }),

            maxAmphereEdit: Yup.number().max(500, 'Max. current should be less than 500').min(0)
                .required('Max. amphere is required'),
            // .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only'),

            maxElectricPowerEdit: Yup.number().max(300, 'Max electric power should be less than 300').min(0)
                .required('Max. electric power is required')
            // .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric character only')
        }),
        onSubmit: (values) => {
            
            if (typeOfEdit === 'EDIT_CONNECTOR') {
                const params = {
                    connectorType: connectorData?.id || getDetailEvseSingleConnector?.typeId,
                    format: formatData?.value || getDetailEvseSingleConnector?.format,
                    powerType: powerTypeData?.value || getDetailEvseSingleConnector?.powerType,
                    maxVoltage: values.maxVoltageEdit,
                    maxAmpere: values.maxAmphereEdit,
                    maxPower: values.maxElectricPowerEdit
                }
                
                
                dispatch(editEvseConnecter(params, EVSEconnId, props.history));

            }

        }
    });
    
    function inpNum(e) {
        // e = e || window.event;
        // var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        // var charStr = String.fromCharCode(charCode);
        

        // if (isNaN(parseFloat(e.target.value))) {
        //     e.preventDefault();
        //     return;
        // }

        if (!(/[0-9\b\.]/.test(e.key))) {
            e.preventDefault();
            return;
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        onClickBack={(e) => {
                            e.preventDefault();
                            history.goBack();
                        }}
                        isButton={true}
                        title="Add EVSE Model"
                        OnClickpageTitle={(e) => { e.preventDefault(); history.goBack() }}
                        pageTitle="EVSE Model"

                    />
                    <div className="addEVSEModelPage">
                        <Row>
                            <Col xl={12} lg={12}>
                                <Card>
                                    <CardBody className="form-steps">
                                        {IsAddEvseModel === true &&
                                            <div className="step-arrow-nav mb-5 tabBar">
                                                <Nav
                                                    className="nav-pills custom-nav nav-justified"
                                                    role="tablist"
                                                >
                                                    <NavItem className="navOne">
                                                        <NavLink
                                                            href="#"
                                                            id="steparrow-gen-info-tab"
                                                            className={classnames({
                                                                active: activeArrowTab === 4,
                                                                done: activeArrowTab <= 6 && activeArrowTab > 3,
                                                            })}
                                                            onClick={() => {
                                                                if (tempConnectorData.length > 0) {
                                                                    toggleArrowTab(4);
                                                                }
                                                            }}
                                                        >
                                                            <div>
                                                                <i className="bx bx-station" />
                                                                <span></span>
                                                            </div>
                                                            Model Details
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="navTwo">
                                                        <NavLink
                                                            href="#"
                                                            id="steparrow-gen-info-tab"
                                                            className={classnames({
                                                                active: activeArrowTab === 5,
                                                                done: activeArrowTab <= 6 && activeArrowTab > 4,
                                                            })}
                                                            onClick={() => {
                                                                if (tempConnectorData.length > 0) {
                                                                    toggleArrowTab(5);
                                                                }
                                                            }}
                                                        >
                                                            <div>
                                                                <i className="bx bx-plug" />
                                                                <span></span>
                                                            </div>
                                                            Connectors
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </div>
                                        }

                                        {!IsEditConnector && <TabContent activeTab={activeArrowTab}>
                                            {/* step------1 */}
                                            <TabPane id="steparrow-gen-info" tabId={4}>
                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    form1.handleSubmit();
                                                    return false;
                                                }}
                                                    action="#">
                                                    <p className="headerTxt">{typeOfEdit === 'EDIT_MODAL' ? 'Edit EVSE Model' : 'Add EVSE Model'}</p>
                                                    <div>
                                                        <Row>
                                                            <Col lg={6} md={6}>
                                                                <div className="mb-3 rowOne">
                                                                    <Label
                                                                        className="form-label requierdLabel"
                                                                        htmlFor="steparrow-gen-info-username-input"
                                                                    >
                                                                        Model Name
                                                                    </Label>
                                                                    <Input
                                                                        name='modelName'
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="modelName"
                                                                        maxLength={50}
                                                                        placeholder="Enter Model Name"
                                                                        value={form1.values.modelName.trimStart() || ''}
                                                                        invalid={
                                                                            form1.errors.modelName && form1.touched.modelName ? true : false
                                                                        }
                                                                        onChange={form1.handleChange}
                                                                        onKeyUp={form1.handleBlur}
                                                                    />
                                                                    {form1.errors.modelName && form1.touched.modelName ? (
                                                                        <FormFeedback type="invalid">{form1.errors.modelName}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col>
                                                            <Col lg={6} md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="choices-single-default" className="form-label requierdLabel">
                                                                        Model Type
                                                                    </Label>
                                                                  
                                                                    <CustomDropdown
                                                                        placeholder='Choose model type'
                                                                        selectedValue={selectedGroup2}
                                                                        onSelect={(evt) => {
                                                                            setSelectedGroup2(evt);
                                                                            
                                                                            form1.handleChange('modelType')(evt.value);
                                                                            
                                                                        }}
                                                                        dropDownItems={allModels}
                                                                    />

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {/* <Col lg={6} md={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label requierdLabel"
                                                                        htmlFor="steparrow-gen-info-username-input"
                                                                    >
                                                                        Max Power Supply (KW)
                                                                    </Label>
                                                                    <Input
                                                                        name='maxPowerSupply'
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="maxPowerSupply"
                                                                        placeholder="Enter Max Power Supply"
                                                                        value={form1.values.maxPowerSupply || ''}
                                                                        invalid={
                                                                            form1.errors.maxPowerSupply && form1.touched.maxPowerSupply ? true : false
                                                                        }
                                                                        onChange={form1.handleChange}
                                                                        onKeyUp={form1.handleBlur}
                                                                    />
                                                                    {form1.errors.maxPowerSupply && form1.touched.maxPowerSupply ? (
                                                                        <FormFeedback type="invalid">{form1.errors.maxPowerSupply}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col> */}
                                                            {IsEditEvseModel ? '' : <Col lg={6} md={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label requierdLabel"
                                                                        htmlFor="steparrow-gen-info-username-input"
                                                                    >
                                                                        Number of Connector
                                                                    </Label>
                                                                    <Input
                                                                        name='noOfConnector'
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="noOfConnector"
                                                                        placeholder="Enter Number Of Connector"
                                                                        maxLength={1}
                                                                        value={form1.values.noOfConnector || ''}
                                                                        invalid={
                                                                            form1.errors.noOfConnector && form1.touched.noOfConnector ? true : false
                                                                        }
                                                                        onChange={form1.handleChange}
                                                                        onKeyUp={form1.handleBlur}
                                                                        onKeyDown={(e) => {
                                                                            
                                                                            if (!((e.key.charCodeAt() > 47 && e.key.charCodeAt() < 58) || e.key.charCodeAt() === 66)) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                    {form1.errors.noOfConnector && form1.touched.noOfConnector ? (
                                                                        <FormFeedback type="invalid">{form1.errors.noOfConnector}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col>}
                                                        </Row>
                                                    </div>
                                                    <div className="d-flex align-items-start justify-content-between gap-3 mt-4">
                                                        <Button
                                                            // disabled={!(form1.isValid && form1.dirty && selectedGroup2)}
                                                            type="submit"
                                                            className="btn bottomBtn successBtn nexttab"
                                                            onClick={() => {
                                                                setTimeout(() => {
                                                                    
                                                                    typeOfEdit === 'EDIT_MODAL' ? history.goBack() : toggleArrowTab(activeArrowTab + 1);
                                                                }, 500)
                                                            }}
                                                            // disabled={typeOfEdit === 'EDIT_MODAL' ? !(form1.isValid && form1.dirty) : !(form1.isValid && form1.dirty)}
                                                            disabled={!(form1.dirty && form1.isValid)}
                                                        // disabled={true}

                                                        >
                                                            {typeOfEdit === 'EDIT_MODAL' ? 'Update' : 'Next'}
                                                        </Button>
                                                        <Button
                                                            type="button"
                                                            className="btn bottomBtn cancelBtn"
                                                            onClick={() => {
                                                                history.goBack();
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </TabPane>
                                            {/* step------2 */}
                                            <TabPane id="steparrow-description-info" tabId={5}>
                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    form2.handleSubmit();
                                                    return false;
                                                }}
                                                    action="#">
                                                    <p className="headerTxt">Add EVSE Model</p>
                                                    {
                                                        tempConnectorData?.map((item, index) => {
                                                      
                                                            return (
                                                                <div key={index} className="aContainer">
                                                                    <p className="headerTitle">
                                                                        {item.connectorTypeName}
                                                                    </p>
                                                                    <Row>
                                                                        <Col lg={4} md={4}>
                                                                            <div className="mb-3">
                                                                                <Label className="requierdLabel">Connector Type</Label>
                                                                                <CustomDropdown
                                                                                    id={`connector-${index}`}
                                                                                    className='allDropdowns'
                                                                                    dropDownItems={allConnectors}
                                                                                    // defaultValue={'IEC 62196 (Type 2)'}
                                                                                    defaultValue={form2.values[`connectorType-${index}`]}
                                                                                    placeholder={'Choose Connector Type'}
                                                                                    selectedValue={tempConnectorData[index]?.ConnectorData?.value}
                                                                                    onSelect={evt => {
                                                                                        tempConnectorData[index].ConnectorData = evt;
                                                                                        setTempConnectorData([
                                                                                            ...tempConnectorData
                                                                                        ]);
                                                                                        form2.handleChange(`connectorType-${index}`)(evt.value);
                                                                                        
                                                                                        // setConnectorDataSelect(evt)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={4} md={4}>
                                                                            <div className="mb-3">
                                                                                <Label className="requierdLabel">Format</Label>
                                                                                <CustomDropdown
                                                                                    id={`format-${index}`}
                                                                                    className='allDropdowns'
                                                                                    dropDownItems={FormatData}
                                                                                    // defaultValue={'Socket'}
                                                                                    defaultValue={form2.values[`format-${index}`]}
                                                                                    placeholder={'Choose Format'}
                                                                                    selectedValue={tempConnectorData[index].FormatData}
                                                                                    onSelect={evt => {
                                                                                        tempConnectorData[index].FormatData = evt;
                                                                                        form2.handleChange(`format-${index}`)(evt.value);
                                                                                        setTempConnectorData([
                                                                                            ...tempConnectorData
                                                                                        ]);
                                                                                        // setFormatSelect(evt)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={4} md={4}>
                                                                            <div className="mb-3">
                                                                                <Label className="requierdLabel">Power Type</Label>
                                                                                <CustomDropdown
                                                                                    id={`powerType-${index}`}
                                                                                    dropDownItems={PowerTypeData}
                                                                                    // defaultValue={'DC'}
                                                                                    defaultValue={form2.values[`powerType-${index}`]}
                                                                                    placeholder={'Choose Power Type'}
                                                                                    selectedValue={tempConnectorData[index].PowerTypeData}
                                                                                    onSelect={evt => {
                                                                                        tempConnectorData[index].PowerTypeData = evt;
                                                                                        form2.handleChange(`powerType-${index}`)(evt.value);
                                                                                        setTempConnectorData([
                                                                                            ...tempConnectorData
                                                                                        ]);
                                                                                        // setPowerTypeSelect(evt)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>

                                                                        <Col lg={4} md={4}>
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label requierdLabel"
                                                                                    htmlFor="steparrow-gen-info-username-input"
                                                                                >
                                                                                    {/* Max Voltage */}
                                                                                    Max. Voltage (V)
                                                                                </Label>
                                                                                <Input
                                                                                    name={`maxVoltage-${index}`}
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    id={`maxVoltage-${index}`}
                                                                                    placeholder="Enter Max. Voltage (V)"
                                                                                    value={form2.values[`maxVoltage-${index}`]}
                                                                                    invalid={
                                                                                        form2.errors[`maxVoltage-${index}`] && form2.touched[`maxVoltage-${index}`] ? true : false
                                                                                    }
                                                                                    onChange={(e) => {
                                                                                        
                                                                                        

                                                                                        

                                                                                        if (/[0-9\b]/.test(e.nativeEvent.data) || e.nativeEvent.data == null) {

                                                                                            tempConnectorData[index].maxVoltage = e.target.value;
                                                                                            setTempConnectorData([
                                                                                                ...tempConnectorData
                                                                                            ]);
                                                                                            form2.handleChange(e);
                                                                                        }
                                                                                    }
                                                                                    }
                                                                                    onKeyUp={(e) => {
                                                                                        inpNum(e)
                                                                                    }}
                                                                                    onKeyPress={inpNum}
                                                                                    onKeyDown={form2.handleBlur}

                                                                                />
                                                                                
                                                                                {form2.errors[`maxVoltage-${index}`] && form2.touched[`maxVoltage-${index}`] ? (
                                                                                    <FormFeedback type="invalid">{form2.errors[`maxVoltage-${index}`]}</FormFeedback>
                                                                                ) : null}
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={4} md={4}>
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label requierdLabel"
                                                                                    htmlFor="steparrow-gen-info-username-input"
                                                                                >
                                                                                    {/* Max Ampere */}
                                                                                    Max. Current (A)
                                                                                </Label>
                                                                                <Input
                                                                                    name={`maxAmpere-${index}`}
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    id={`maxAmpere-${index}`}
                                                                                    placeholder="Enter Max. Current (A)"
                                                                                    value={form2.values[`maxAmpere-${index}`] || ''}
                                                                                    invalid={
                                                                                        form2.errors[`maxAmpere-${index}`] && form2.touched[`maxAmpere-${index}`] ? true : false
                                                                                    }
                                                                                    // onChange={form2.handleChange}
                                                                                    onChange={(e) => {
                                                                                        

                                                                                        if (/[0-9\b]/.test(e.nativeEvent.data) || e.nativeEvent.data == null) {
                                                                                            form2.handleChange(e);
                                                                                            tempConnectorData[index].maxAmpere = e.target.value;
                                                                                            setTempConnectorData([
                                                                                                ...tempConnectorData
                                                                                            ]);
                                                                                        }
                                                                                    }}
                                                                                    onKeyDown={form2.handleBlur}

                                                                                    onKeyUp={(e) => {
                                                                                        inpNum(e)
                                                                                    }}
                                                                                    onKeyPress={inpNum}


                                                                                />
                                                                                {/* {form2.errors[`maxAmpere-${index}`] && form2.touched[`maxAmpere-${index}`] ? (
                                                                                    <FormFeedback type="invalid">{form2.errors[`maxAmpere-${index}`]}</FormFeedback>
                                                                                ) : null} */}
                                                                                {form2.errors[`maxAmpere-${index}`] && form2.touched[`maxAmpere-${index}`] !== undefined ? (
                                                                                    <FormFeedback type="invalid">{form2.errors[`maxAmpere-${index}`]}</FormFeedback>
                                                                                ) : null}
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={4} md={4}>
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label requierdLabel"
                                                                                    htmlFor="steparrow-gen-info-username-input"
                                                                                >
                                                                                    {/* Max Electric Power */}
                                                                                    Max. Electric Power (kW)
                                                                                </Label>
                                                                                <Input
                                                                                    name={`maxElectricPower-${index}`}
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    id={`maxElectricPower-${index}`}
                                                                                    placeholder="Enter Max. Electric Power (kW)"
                                                                                    value={form2.values[`maxElectricPower-${index}`] || ''}
                                                                                    invalid={
                                                                                        form2.errors[`maxElectricPower-${index}`] && form2.touched[`maxElectricPower-${index}`] ? true : false
                                                                                    }
                                                                                    // onChange={form1.handleChange}
                                                                                    onChange={(e) => {
                                                                                        

                                                                                        if (/[0-9\b]/.test(e.nativeEvent.data) || e.nativeEvent.data == null) {
                                                                                            form2.handleChange(e);
                                                                                            tempConnectorData[index].maxElectricPower = e.target.value;
                                                                                            setTempConnectorData([
                                                                                                ...tempConnectorData
                                                                                            ]);
                                                                                        }
                                                                                    }}
                                                                                    onKeyDown={form2.handleBlur}

                                                                                    onKeyUp={(e) => {
                                                                                        inpNum(e)
                                                                                    }}
                                                                                    onKeyPress={inpNum}
                                                                                />
                                                                                {form2.errors[`maxElectricPower-${index}`] && form2.touched[`maxElectricPower-${index}`] ? (
                                                                                    <FormFeedback type="invalid">{form2.errors[`maxElectricPower-${index}`]}</FormFeedback>
                                                                                ) : null}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <div className="d-flex align-items-start justify-content-between gap-3 mt-4">
                                                        <div className="d-flex align-items-start justify-content-start gap-3">
                                                            <Button
                                                                type="button"
                                                                className="btn btn-light previestab"
                                                                onClick={() => {
                                                                    toggleArrowTab(activeArrowTab - 1);
                                                                }}
                                                            >
                                                                Previous
                                                            </Button>
                                                            {/* 2 next */}
                                                            <CustomButton
                                                                title={"Add"}
                                                                type='submit'
                                                                loading={false}
                                                                className='btn addButtonWithLoader nexttab nexttab add_FormBtn'
                                                                disabled={!(form2.isValid && form2.dirty)}
                                                            />
                                                        </div>

                                                        <Button
                                                            type="button"
                                                            className="btn cancelBtn"
                                                            onClick={() => {
                                                                history.goBack();
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        {/* <Button
                                                            type="submit"
                                                            className="btn btn-success nexttab nexttab"
                                                            onClick={() => {

                                                            }}
                                                        >
                                                            Next
                                                        </Button> */}
                                                    </div>
                                                </Form>
                                            </TabPane>
                                        </TabContent>}
                                        {
                                            IsEditConnector &&
                                            <Form onSubmit={(e) => {
                                                e.preventDefault();
                                                form3.handleSubmit();
                                                return false;
                                            }}
                                                action="#">
                                                <p className="headerTxt">Edit Connector</p>
                                                <div className="aContainer">
                                                    <p className="headerTitle">
                                                        {editConnectorData.connectorTypeName}
                                                    </p>
                                                    <Row>
                                                        <Col lg={4} md={4}>
                                                            <div className="mb-3">
                                                                <Label className="requierdLabel">Connector Type</Label>
                                                                <CustomDropdown
                                                                    id={editConnectorData.id}
                                                                    className='allDropdowns'
                                                                    dropDownItems={allConnectors}
                                                                    defaultValue={getDetailEvseSingleConnector?.connectorType || 'IEC 62196 (Type 2)'}
                                                                    name='connectorData'
                                                                    placeholder={'Select'}
                                                                    selectedValue={connectorData}
                                                                    onSelect={evt => {
                                                                        setConnectorData(evt);
                                                                        
                                                                        handleform3edit('connectorType', getDetailEvseSingleConnector?.connectorType, evt.value);
                                                                        form3.handleChange('connectorData')(evt.value);
                                                                        // setConnectorDataSelect(evt)
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={4} md={4}>
                                                            <div className="mb-3">
                                                                <Label className="requierdLabel">Format</Label>
                                                                <CustomDropdown
                                                                    id={editConnectorData.id}
                                                                    className='allDropdowns'
                                                                    dropDownItems={FormatData}
                                                                    defaultValue={formatData.value}
                                                                    placeholder={'Select'}
                                                                    selectedValue={formatData.value}
                                                                    onSelect={evt => {
                                                                        setFormatData(evt);
                                                                        handleform3edit('formatData', getDetailEvseSingleConnector?.format, evt.value);
                                                                        form3.handleChange('formatData')(evt.value);
                                                                        // setFormatSelect(evt)
                                                                    }}
                                                                />
                                                            </div>
                                                            
                                                        </Col>
                                                        <Col lg={4} md={4}>
                                                            <div className="mb-3">
                                                                <Label className="requierdLabel">Power Type</Label>
                                                                <CustomDropdown
                                                                    id={editConnectorData.id}
                                                                    dropDownItems={PowerTypeData}
                                                                    defaultValue={powerTypeData.value}
                                                                    placeholder={'Select'}
                                                                    selectedValue={powerTypeData.value}
                                                                    onSelect={evt => {
                                                                        setPowerTypeData(evt);
                                                                        handleform3edit('powerType', getDetailEvseSingleConnector?.powerType, evt.value);
                                                                        form3.handleChange('powerTypeData')(evt.value);
                                                                        
                                                                        // setPowerTypeSelect(evt)
                                                                    }}
                                                                />
                                                            </div>
                                                            
                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                        <Col lg={4} md={4}>
                                                            <div className="mb-3">
                                                                <Label
                                                                    className="form-label requierdLabel"
                                                                    htmlFor="steparrow-gen-info-username-input"
                                                                >
                                                                    {/* Max Voltage */}
                                                                    Max. Voltage (V)
                                                                </Label>
                                                                <Input
                                                                    name='maxVoltageEdit'
                                                                    type="number"
                                                                    className="form-control"
                                                                    id={editConnectorData.id}
                                                                    placeholder="Enter Max. Voltage (V)"
                                                                    value={form3.values.maxVoltageEdit}
                                                                    invalid={
                                                                        form3.errors.maxVoltageEdit && form3.touched.maxVoltageEdit ? true : false
                                                                    }
                                                                    // onChange={
                                                                    //     form3.handleChange
                                                                    // }
                                                                    onChange={(e) => {
                                                                        
                                                                        if (/[0-9\b]/.test(e.nativeEvent.data) || e.nativeEvent.data == null) {
                                                                            form3.handleChange(e);
                                                                        }
                                                                        
                                                                    }
                                                                    }

                                                                    onKeyDown={form3.handleBlur}

                                                                    onKeyUp={(e) => {
                                                                        inpNum(e)
                                                                    }}
                                                                    onKeyPress={inpNum}

                                                                />
                                                                
                                                                {form3.errors.maxVoltageEdit && form3.touched.maxVoltageEdit ? (
                                                                    <FormFeedback type="invalid">{form3.errors.maxVoltageEdit}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={4} md={4}>
                                                            <div className="mb-3">
                                                                <Label
                                                                    className="form-label requierdLabel"
                                                                    htmlFor="steparrow-gen-info-username-input"
                                                                >
                                                                    {/* Max Ampere */}
                                                                    Max. Current (A)
                                                                </Label>
                                                                <Input
                                                                    name={'maxAmphereEdit'}
                                                                    type="number"
                                                                    className="form-control"
                                                                    id={editConnectorData.id}
                                                                    placeholder="Enter Max. Current (A)"
                                                                    value={form3.values.maxAmphereEdit || ''}
                                                                    invalid={
                                                                        form3.errors.maxAmphereEdit && form3.touched.maxAmphereEdit ? true : false
                                                                    }
                                                                    // onChange={form2.handleChange}
                                                                    // onChange={
                                                                    //     form3.handleChange}
                                                                    onChange={(e) => {
                                                                        if (/[0-9\b]/.test(e.nativeEvent.data) || e.nativeEvent.data == null) {
                                                                            form3.handleChange(e);
                                                                        }

                                                                    }
                                                                    }
                                                                    onKeyDown={form3.handleBlur}

                                                                    onKeyUp={(e) => {
                                                                        inpNum(e)
                                                                    }}
                                                                    onKeyPress={inpNum}

                                                                />
                                                                {form3.errors.maxAmphereEdit && form3.touched.maxAmphereEdit ? (
                                                                    <FormFeedback type="invalid">{form3.errors.maxAmphereEdit}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={4} md={4}>
                                                            <div className="mb-3">
                                                                <Label
                                                                    className="form-label requierdLabel"
                                                                    htmlFor="steparrow-gen-info-username-input"
                                                                >
                                                                    {/* Max Electric Power */}
                                                                    Max. Electric Power (kW)
                                                                </Label>
                                                                <Input
                                                                    name={'maxElectricPowerEdit'}
                                                                    type="number"
                                                                    className="form-control"
                                                                    id={editConnectorData.id}
                                                                    placeholder="Enter Max. Electric Power (kW)"
                                                                    value={form3.values.maxElectricPowerEdit || ''}
                                                                    invalid={
                                                                        form3.errors.maxElectricPowerEdit && form3.touched.maxElectricPowerEdit ? true : false
                                                                    }
                                                                    // onChange={form1.handleChange}
                                                                    onChange={(e) => {
                                                                        if (/[0-9\b]/.test(e.nativeEvent.data) || e.nativeEvent.data == null) {
                                                                            form3.handleChange(e);
                                                                        }

                                                                    }
                                                                    }
                                                                    onKeyDown={form3.handleBlur}

                                                                    onKeyUp={(e) => {
                                                                        inpNum(e)
                                                                    }}
                                                                    onKeyPress={inpNum}


                                                                />
                                                                {form3.errors.maxElectricPowerEdit && form3.touched.maxElectricPowerEdit ? (
                                                                    <FormFeedback type="invalid">{form3.errors.maxElectricPowerEdit}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="d-flex align-items-start justify-content-between gap-3 mt-4">
                                                    <div className="d-flex align-items-start justify-content-start gap-3">
                                                        <CustomButton
                                                            title={"Update"}
                                                            type='submit'
                                                            loading={false}
                                                            className='btn addButtonWithLoader nexttab nexttab add_FormBtn'
                                                            disabled={!(form3.isValid && form3.dirty)}
                                                            onClick={() => {
                                                                
                                                            }}
                                                        />

                                                        
                                                    </div>

                                                    <Button
                                                        type="button"
                                                        className="btn cancelBtn"
                                                        onClick={() => {
                                                            history.goBack();
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    {/* <Button
                                                            type="submit"
                                                            className="btn btn-success nexttab nexttab"
                                                            onClick={() => {

                                                            }}
                                                        >
                                                            Next
                                                        </Button> */}
                                                </div>
                                            </Form>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddEVSEModel;
