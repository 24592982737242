import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";
import { getUserRole } from "../helpers/helper.service";
import { useSelector } from "react-redux";

const Navdata = () => {
    const history = useHistory();
    const fullState = useSelector(state => state)
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isAddBusinesses, setIsAddBusinesses] = useState(false);
    const [isLocation, setIsLocation] = useState(false);
    const [isAssets, setIsAssets] = useState(false);
    const [isStation, setStation] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAuth, setIsAuth] = useState(false);

    // userrole
    const [userRole, setuserRole] = useState(undefined)

    // Pages
    const [isProfile, setIsProfile] = useState(false);
    const [isLanding, setIsLanding] = useState(false);

    // Charts
    const [isApex, setIsApex] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    // function updateIconSidebar(e) {
    //     if (e && e.target && e.target.getAttribute("subitems")) {
    //         const ul = document.getElementById("two-column-menu");
    //         const iconItems = ul.querySelectorAll(".nav-icon.active");
    //         let activeIconItems = [...iconItems];
    //         activeIconItems.forEach((item) => {
    //             item.classList.remove("active");
    //             var id = item.getAttribute("subitems");
    //             if (document.getElementById(id))
    //                 document.getElementById(id).classList.remove("show");
    //         });
    //     }
    // }

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
                document.body.classList.remove("vertical-sidebar-enable");
            });
        }
    }



    useEffect(() => {

        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'AddBusinesses') {
            setIsAddBusinesses(false);
        }
        if (iscurrentState !== 'Location') {
            setIsLocation(false);
        }
        if (iscurrentState !== 'Assets') {
            setIsAssets(false);
        }
        if (iscurrentState !== 'Station') {
            setStation(false);
        }
        if (iscurrentState !== 'Profile') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState === 'dashboard-analytics') {
            history.push("/dashboard-analytics");
            // document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState === 'Landing') {
            setIsLanding(false);
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isApps,
        isAssets,
        isStation,
        isProfile,
        isAuth,
        isLocation,
        isAddBusinesses,
        isLanding
    ]);
    const removeDataItem = () => {

    }
    const menuItems = [
        {
            label: "Reports",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Dashboard",
            icon: <i className="bx bx-home"></i>,
            // link: "/dashboard",
            link: "/dashboard",
            isVisble: true,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('dashboard');
                removeDataItem(e)
            }
        },
        {
            label: "Management",
            isHeader: true,
            isVisble: true,
        },
        {
            id: "Businesses",
            label: "Businesses",
            icon: <i className="bx bx-briefcase" />,
            link: "/businesses",
            stateVariables: isAddBusinesses,
            isVisble: getUserRole() === 'LM' ? false : true,
            click: function (e) {
                // e.preventDefault();
                setIsAddBusinesses(!isAddBusinesses);
                setIscurrentState('Businesses');
                updateIconSidebar(e);
            },
            // subItems: [
            //     {
            //         id: "addbusinesses",
            //         label: "Add Businesses",
            //         link: "/businesses/addbusiness",
            //         parentId: "addbusinesses",
            //     },
            //     {
            //         id: "bussinessDetail",
            //         label: "Businesses Detail",
            //         link: "/businesses/businessesdetail",
            //         parentId: "bussinessDetail",
            //     },
            // ],
        },
        {
            id: "Location",
            label: "Location",
            // icon: <FeatherIcon icon="briefcase" className="icon-dual" />,
            icon: <i className="bx bx-map-alt" />,
            link: "/location",
            stateVariables: isLocation,
            isVisble: true,
            click: function (e) {
                // e.preventDefault();
                setIsLocation(!isLocation);
                setIscurrentState('Location');
                updateIconSidebar(e);
            },
            // subItems: [
            //     {
            //         id: "addlocation",
            //         label: "Add Location",
            //         link: "/location/addlocation",
            //         parentId: "addlocation",
            //     },
            //     {
            //         id: "viewlocation",
            //         label: "View Location",
            //         link: "/location/viewlocation",
            //         parentId: "viewlocation",
            //     }
            // ],
        },
        {
            id: "Stations",
            label: "Stations",
            icon: <i className="bx bx-station" />,
            link: "/stations",
            stateVariables: isStation,
            isVisble: true,
            click: function (e) {
                // e.preventDefault();
                setStation(!isStation);
                setIscurrentState('Stations');
                updateIconSidebar(e);
            }
        },
        {
            id: "assets",
            label: "Assets",
            icon: <i className="bx bx-plug" />,
            isVisble: getUserRole() === true ? true : getUserRole() === 'LM' ? false : getUserRole() === false ? false : true,
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsAssets(!isAssets);
                setIscurrentState('Assets');
                updateIconSidebar(e);
            },
            stateVariables: isAssets,
            subItems: [
                {
                    id: "EVSEModel",
                    label: "EVSE Model",
                    link: "/assets/evsemodel",
                    parentId: "assets",
                },
                {
                    id: "connectorType",
                    label: "Connector Type",
                    link: "/assets/connectortype",
                    parentId: "assets",
                }
            ],
        },
        {
            label: "User",
            isHeader: true,
            isVisble: true,
        },
        {
            id: "customers",
            label: "Customers",
            icon: <i className="bx bx-mobile" />,
            link: "/customers",
            // link: "/dashboard",
            isVisble: true,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('customers');
            }
        },
        {
            id: "fleet",
            label: "Fleet",
            icon: <i className="bx bx-mobile" />,
            link: "/fleet",
            // link: "/dashboard",
            isVisble: true,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('fleet');
            }
        },
        {
            id: "rfid",
            label: "RFID",
            icon: <i className="bx bx-id-card" />,
            link: getUserRole() ? "/rfid" : '/rfidmanagement',
            // link: "/dashboard",
            isVisble: getUserRole() === 'LM' ? false : true,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('rfid');
            }
        },
        {
            label: "Other",
            isHeader: true,
            isVisble: true,
        },
        {
            id: "tariff",
            label: "Tariff",
            icon: <i className="bx bx-dollar" />,
            link: "/tariff",
            // isVisble: (!getUserRole()) || (getUserRole() === 'LM'),
            isVisble: getUserRole() === false ? true : getUserRole() === 'LM' ? true : false,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Assets');
            }
        },
        {
            id: "profile",
            label: "Profile",
            icon: <i className="bx bx-user" />,
            link: "/profile",
            // link: "/dashboard",
            isVisble: true,
            click: function (e) {

                e.preventDefault();
                setIscurrentState('Profile');
            }
        },
    ];

    const menuItems2 = useMemo(() => [
        {
            label: "Reports",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Dashboard",
            icon: <i className="bx bx-home"></i>,
            // link: "/dashboard",
            link: "/dashboard",
            isVisble: true,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('dashboard');
                removeDataItem(e)
            }
        },
        {
            label: "Management",
            isHeader: true,
            isVisble: true,
        },
        {
            id: "Businesses",
            label: "Businesses",
            icon: <i className="bx bx-briefcase" />,
            link: "/businesses",
            stateVariables: isAddBusinesses,
            isVisble: getUserRole() === 'LM' ? false : true,
            click: function (e) {
                // e.preventDefault();
                setIsAddBusinesses(!isAddBusinesses);
                setIscurrentState('Businesses');
                updateIconSidebar(e);
            },
            // subItems: [
            //     {
            //         id: "addbusinesses",
            //         label: "Add Businesses",
            //         link: "/businesses/addbusiness",
            //         parentId: "addbusinesses",
            //     },
            //     {
            //         id: "bussinessDetail",
            //         label: "Businesses Detail",
            //         link: "/businesses/businessesdetail",
            //         parentId: "bussinessDetail",
            //     },
            // ],
        },
        {
            id: "Location",
            label: "Location",
            // icon: <FeatherIcon icon="briefcase" className="icon-dual" />,
            icon: <i className="bx bx-map-alt" />,
            link: "/location",
            stateVariables: isLocation,
            isVisble: true,
            click: function (e) {
                // e.preventDefault();
                setIsLocation(!isLocation);
                setIscurrentState('Location');
                updateIconSidebar(e);
            },
            // subItems: [
            //     {
            //         id: "addlocation",
            //         label: "Add Location",
            //         link: "/location/addlocation",
            //         parentId: "addlocation",
            //     },
            //     {
            //         id: "viewlocation",
            //         label: "View Location",
            //         link: "/location/viewlocation",
            //         parentId: "viewlocation",
            //     }
            // ],
        },
        {
            id: "Stations",
            label: "Stations",
            icon: <i className="bx bx-station" />,
            link: "/stations",
            stateVariables: isStation,
            isVisble: true,
            click: function (e) {
                // e.preventDefault();
                setStation(!isStation);
                setIscurrentState('Stations');
                updateIconSidebar(e);
            }
        },
        {
            id: "assets",
            label: "Assets",
            icon: <i className="bx bx-plug" />,
            isVisble: getUserRole() === true ? true : getUserRole() === 'LM' ? false : getUserRole() === false ? false : true,
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsAssets(p => !p);
                setIscurrentState('Assets');
                updateIconSidebar(e);
            },
            stateVariables: isAssets,
            subItems: [
                {
                    id: "EVSEModel",
                    label: "EVSE Model",
                    link: "/assets/evsemodel",
                    parentId: "assets",
                },
                {
                    id: "connectorType",
                    label: "Connector Type",
                    link: "/assets/connectortype",
                    parentId: "assets",
                }
            ],
        },
        {
            label: "User",
            isHeader: true,
            isVisble: true,
        },
        {
            id: "customers",
            label: "Customers",
            icon: <i className="bx bx-mobile" />,
            link: "/customers",
            // link: "/dashboard",
            isVisble: true,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('customers');
            }
        },
        {
            id: "fleet",
            label: "Fleet",
            icon: <i className="bx bx-mobile" />,
            link: "/fleet",
            // link: "/dashboard",
            isVisble: true,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('fleet');
            }
        },
        {
            id: "rfid",
            label: "RFID",
            icon: <i className="bx bx-id-card" />,
            link: getUserRole() ? "/rfid" : '/rfidmanagement',
            // link: "/dashboard",
            isVisble: getUserRole() === 'LM' ? false : true,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('rfid');
            }
        },
        {
            label: "Other",
            isHeader: true,
            isVisble: true,
        },
        {
            id: "tariff",
            label: "Tariff",
            icon: <i className="bx bx-dollar" />,
            link: "/tariff",
            // isVisble: (!getUserRole()) || (getUserRole() === 'LM'),
            isVisble: getUserRole() === false ? true : getUserRole() === 'LM' ? true : false,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Assets');
            }
        },
        {
            id: "Logs",
            label: "Logs",
            icon: <i className="bx bx-user" />,
            link: "/logs",
            // link: "/dashboard",
            isVisble: true,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Logs');
            }
        },
        {
            id: "profile",
            label: "Profile",
            icon: <i className="bx bx-user" />,
            link: "/profile",
            // link: "/dashboard",
            isVisble: true,
            click: function (e) {

                e.preventDefault();
                setIscurrentState('Profile');
            }
        },
    ], [fullState.Login.isLoggedIn, isAssets, iscurrentState])

    // if (window.localStorage.getItem('users') !== null && window.localStorage.getItem('users') !== undefined) {
    //     if (JSON?.parse(window.localStorage.getItem('users'))?.userRole == 'LocationManager') {
    //         return <React.Fragment>{[
    //             {
    //                 label: "Reports",
    //                 isHeader: true,
    //             },
    //             {
    //                 id: "dashboard",
    //                 label: "Dashboard",
    //                 icon: <i className="bx bx-home"></i>,
    //                 // link: "/dashboard",
    //                 link: "/dashboard",
    //                 isVisble: true,
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIscurrentState('dashboard');
    //                     removeDataItem(e)
    //                 }
    //             },
    //             {
    //                 label: "Management",
    //                 isHeader: true,
    //                 isVisble: true,
    //             },
    //             {
    //                 id: "Businesses",
    //                 label: "Businesses",
    //                 icon: <i className="bx bx-briefcase" />,
    //                 link: "/businesses",
    //                 stateVariables: isAddBusinesses,
    //                 isVisble: false,
    //                 click: function (e) {
    //                     // e.preventDefault();
    //                     setIsAddBusinesses(!isAddBusinesses);
    //                     setIscurrentState('Businesses');
    //                     updateIconSidebar(e);
    //                 },
    //                 // subItems: [
    //                 //     {
    //                 //         id: "addbusinesses",
    //                 //         label: "Add Businesses",
    //                 //         link: "/businesses/addbusiness",
    //                 //         parentId: "addbusinesses",
    //                 //     },
    //                 //     {
    //                 //         id: "bussinessDetail",
    //                 //         label: "Businesses Detail",
    //                 //         link: "/businesses/businessesdetail",
    //                 //         parentId: "bussinessDetail",
    //                 //     },
    //                 // ],
    //             },
    //             {
    //                 id: "Location",
    //                 label: "Location",
    //                 // icon: <FeatherIcon icon="briefcase" className="icon-dual" />,
    //                 icon: <i className="bx bx-map-alt" />,
    //                 link: "/location",
    //                 stateVariables: isLocation,
    //                 isVisble: true,
    //                 click: function (e) {
    //                     // e.preventDefault();
    //                     setIsLocation(!isLocation);
    //                     setIscurrentState('Location');
    //                     updateIconSidebar(e);
    //                 },
    //                 // subItems: [
    //                 //     {
    //                 //         id: "addlocation",
    //                 //         label: "Add Location",
    //                 //         link: "/location/addlocation",
    //                 //         parentId: "addlocation",
    //                 //     },
    //                 //     {
    //                 //         id: "viewlocation",
    //                 //         label: "View Location",
    //                 //         link: "/location/viewlocation",
    //                 //         parentId: "viewlocation",
    //                 //     }
    //                 // ],
    //             },
    //             {
    //                 id: "Stations",
    //                 label: "Stations",
    //                 icon: <i className="bx bx-station" />,
    //                 link: "/stations",
    //                 stateVariables: isStation,
    //                 isVisble: true,
    //                 click: function (e) {
    //                     // e.preventDefault();
    //                     setStation(!isStation);
    //                     setIscurrentState('Stations');
    //                     updateIconSidebar(e);
    //                 }
    //             },
    //             {
    //                 id: "assets",
    //                 label: "Assets",
    //                 icon: <i className="bx bx-plug" />,
    //                 isVisble: false,
    //                 link: "/#",
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIsAssets(!isAssets);
    //                     setIscurrentState('Assets');
    //                     updateIconSidebar(e);
    //                 },
    //                 stateVariables: isAssets,
    //                 subItems: [
    //                     {
    //                         id: "EVSEModel",
    //                         label: "EVSE Model",
    //                         link: "/assets/evsemodel",
    //                         parentId: "assets",
    //                     },
    //                     {
    //                         id: "connectorType",
    //                         label: "Connector Type",
    //                         link: "/assets/connectortype",
    //                         parentId: "assets",
    //                     }
    //                 ],
    //             },
    //             {
    //                 label: "User",
    //                 isHeader: true,
    //                 isVisble: true,
    //             },
    //             {
    //                 id: "customers",
    //                 label: "Customers",
    //                 icon: <i className="bx bx-mobile" />,
    //                 link: "/customers",
    //                 // link: "/dashboard",
    //                 isVisble: true,
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIscurrentState('customers');
    //                 }
    //             },
    //             {
    //                 id: "rfid",
    //                 label: "RFID",
    //                 icon: <i className="bx bx-id-card" />,
    //                 link: getUserRole() ? "/rfid" : '/rfidmanagement',
    //                 // link: "/dashboard",
    //                 isVisble: false,
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIscurrentState('rfid');
    //                 }
    //             },
    //             {
    //                 label: "Other",
    //                 isHeader: true,
    //                 isVisble: true,
    //             },
    //             {
    //                 id: "tariff",
    //                 label: "Tariff",
    //                 icon: <i className="bx bx-dollar" />,
    //                 link: "/tariff",
    //                 // isVisble: (!getUserRole()) || (getUserRole() === 'LM'),
    //                 isVisble: true,
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIscurrentState('Assets');
    //                 }
    //             },
    //             {
    //                 id: "profile",
    //                 label: "Profile",
    //                 icon: <i className="bx bx-user" />,
    //                 link: "/profile",
    //                 // link: "/dashboard",
    //                 isVisble: true,
    //                 click: function (e) {

    //                     e.preventDefault();
    //                     setIscurrentState('Profile');
    //                 }
    //             },
    //         ]}</React.Fragment>;
    //     } else if (JSON?.parse(window.localStorage.getItem('users'))?.userRole == 'BusinessOwner') {
    //         return <React.Fragment>{[
    //             {
    //                 label: "Reports",
    //                 isHeader: true,
    //             },
    //             {
    //                 id: "dashboard",
    //                 label: "Dashboard",
    //                 icon: <i className="bx bx-home"></i>,
    //                 // link: "/dashboard",
    //                 link: "/dashboard",
    //                 isVisble: true,
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIscurrentState('dashboard');
    //                     removeDataItem(e)
    //                 }
    //             },
    //             {
    //                 label: "Management",
    //                 isHeader: true,
    //                 isVisble: true,
    //             },
    //             {
    //                 id: "Businesses",
    //                 label: "Businesses",
    //                 icon: <i className="bx bx-briefcase" />,
    //                 link: "/businesses",
    //                 stateVariables: isAddBusinesses,
    //                 isVisble: true,
    //                 click: function (e) {
    //                     // e.preventDefault();
    //                     setIsAddBusinesses(!isAddBusinesses);
    //                     setIscurrentState('Businesses');
    //                     updateIconSidebar(e);
    //                 },
    //                 // subItems: [
    //                 //     {
    //                 //         id: "addbusinesses",
    //                 //         label: "Add Businesses",
    //                 //         link: "/businesses/addbusiness",
    //                 //         parentId: "addbusinesses",
    //                 //     },
    //                 //     {
    //                 //         id: "bussinessDetail",
    //                 //         label: "Businesses Detail",
    //                 //         link: "/businesses/businessesdetail",
    //                 //         parentId: "bussinessDetail",
    //                 //     },
    //                 // ],
    //             },
    //             {
    //                 id: "Location",
    //                 label: "Location",
    //                 // icon: <FeatherIcon icon="briefcase" className="icon-dual" />,
    //                 icon: <i className="bx bx-map-alt" />,
    //                 link: "/location",
    //                 stateVariables: isLocation,
    //                 isVisble: true,
    //                 click: function (e) {
    //                     // e.preventDefault();
    //                     setIsLocation(!isLocation);
    //                     setIscurrentState('Location');
    //                     updateIconSidebar(e);
    //                 },
    //                 // subItems: [
    //                 //     {
    //                 //         id: "addlocation",
    //                 //         label: "Add Location",
    //                 //         link: "/location/addlocation",
    //                 //         parentId: "addlocation",
    //                 //     },
    //                 //     {
    //                 //         id: "viewlocation",
    //                 //         label: "View Location",
    //                 //         link: "/location/viewlocation",
    //                 //         parentId: "viewlocation",
    //                 //     }
    //                 // ],
    //             },
    //             {
    //                 id: "Stations",
    //                 label: "Stations",
    //                 icon: <i className="bx bx-station" />,
    //                 link: "/stations",
    //                 stateVariables: isStation,
    //                 isVisble: true,
    //                 click: function (e) {
    //                     // e.preventDefault();
    //                     setStation(!isStation);
    //                     setIscurrentState('Stations');
    //                     updateIconSidebar(e);
    //                 }
    //             },
    //             {
    //                 id: "assets",
    //                 label: "Assets",
    //                 icon: <i className="bx bx-plug" />,
    //                 isVisble: false,
    //                 link: "/#",
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIsAssets(!isAssets);
    //                     setIscurrentState('Assets');
    //                     updateIconSidebar(e);
    //                 },
    //                 stateVariables: isAssets,
    //                 subItems: [
    //                     {
    //                         id: "EVSEModel",
    //                         label: "EVSE Model",
    //                         link: "/assets/evsemodel",
    //                         parentId: "assets",
    //                     },
    //                     {
    //                         id: "connectorType",
    //                         label: "Connector Type",
    //                         link: "/assets/connectortype",
    //                         parentId: "assets",
    //                     }
    //                 ],
    //             },
    //             {
    //                 label: "User",
    //                 isHeader: true,
    //                 isVisble: true,
    //             },
    //             {
    //                 id: "customers",
    //                 label: "Customers",
    //                 icon: <i className="bx bx-mobile" />,
    //                 link: "/customers",
    //                 // link: "/dashboard",
    //                 isVisble: true,
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIscurrentState('customers');
    //                 }
    //             },
    //             {
    //                 id: "rfid",
    //                 label: "RFID",
    //                 icon: <i className="bx bx-id-card" />,
    //                 link: getUserRole() ? "/rfid" : '/rfidmanagement',
    //                 // link: "/dashboard",
    //                 isVisble: true,
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIscurrentState('rfid');
    //                 }
    //             },
    //             {
    //                 label: "Other",
    //                 isHeader: true,
    //                 isVisble: true,
    //             },
    //             {
    //                 id: "tariff",
    //                 label: "Tariff",
    //                 icon: <i className="bx bx-dollar" />,
    //                 link: "/tariff",
    //                 // isVisble: (!getUserRole()) || (getUserRole() === 'LM'),
    //                 isVisble: true,
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIscurrentState('Assets');
    //                 }
    //             },
    //             {
    //                 id: "profile",
    //                 label: "Profile",
    //                 icon: <i className="bx bx-user" />,
    //                 link: "/profile",
    //                 // link: "/dashboard",
    //                 isVisble: true,
    //                 click: function (e) {

    //                     e.preventDefault();
    //                     setIscurrentState('Profile');
    //                 }
    //             },
    //         ]}</React.Fragment>;
    //     } else if (JSON?.parse(window.localStorage.getItem('users'))?.userRole == 'SuperAdmin') {
    //         return <React.Fragment>{[
    //             {
    //                 label: "Reports",
    //                 isHeader: true,
    //             },
    //             {
    //                 id: "dashboard",
    //                 label: "Dashboard",
    //                 icon: <i className="bx bx-home"></i>,
    //                 // link: "/dashboard",
    //                 link: "/dashboard",
    //                 isVisble: true,
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIscurrentState('dashboard');
    //                     removeDataItem(e)
    //                 }
    //             },
    //             {
    //                 label: "Management",
    //                 isHeader: true,
    //                 isVisble: true,
    //             },
    //             {
    //                 id: "Businesses",
    //                 label: "Businesses",
    //                 icon: <i className="bx bx-briefcase" />,
    //                 link: "/businesses",
    //                 stateVariables: isAddBusinesses,
    //                 isVisble: true,
    //                 click: function (e) {
    //                     // e.preventDefault();
    //                     setIsAddBusinesses(!isAddBusinesses);
    //                     setIscurrentState('Businesses');
    //                     updateIconSidebar(e);
    //                 },
    //                 // subItems: [
    //                 //     {
    //                 //         id: "addbusinesses",
    //                 //         label: "Add Businesses",
    //                 //         link: "/businesses/addbusiness",
    //                 //         parentId: "addbusinesses",
    //                 //     },
    //                 //     {
    //                 //         id: "bussinessDetail",
    //                 //         label: "Businesses Detail",
    //                 //         link: "/businesses/businessesdetail",
    //                 //         parentId: "bussinessDetail",
    //                 //     },
    //                 // ],
    //             },
    //             {
    //                 id: "Location",
    //                 label: "Location",
    //                 // icon: <FeatherIcon icon="briefcase" className="icon-dual" />,
    //                 icon: <i className="bx bx-map-alt" />,
    //                 link: "/location",
    //                 stateVariables: isLocation,
    //                 isVisble: true,
    //                 click: function (e) {
    //                     // e.preventDefault();
    //                     setIsLocation(!isLocation);
    //                     setIscurrentState('Location');
    //                     updateIconSidebar(e);
    //                 },
    //                 // subItems: [
    //                 //     {
    //                 //         id: "addlocation",
    //                 //         label: "Add Location",
    //                 //         link: "/location/addlocation",
    //                 //         parentId: "addlocation",
    //                 //     },
    //                 //     {
    //                 //         id: "viewlocation",
    //                 //         label: "View Location",
    //                 //         link: "/location/viewlocation",
    //                 //         parentId: "viewlocation",
    //                 //     }
    //                 // ],
    //             },
    //             {
    //                 id: "Stations",
    //                 label: "Stations",
    //                 icon: <i className="bx bx-station" />,
    //                 link: "/stations",
    //                 stateVariables: isStation,
    //                 isVisble: true,
    //                 click: function (e) {
    //                     // e.preventDefault();
    //                     setStation(!isStation);
    //                     setIscurrentState('Stations');
    //                     updateIconSidebar(e);
    //                 }
    //             },
    //             {
    //                 id: "assets",
    //                 label: "Assets",
    //                 icon: <i className="bx bx-plug" />,
    //                 isVisble: true,
    //                 link: "/#",
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIsAssets(!isAssets);
    //                     setIscurrentState('Assets');
    //                     updateIconSidebar(e);
    //                 },
    //                 stateVariables: isAssets,
    //                 subItems: [
    //                     {
    //                         id: "EVSEModel",
    //                         label: "EVSE Model",
    //                         link: "/assets/evsemodel",
    //                         parentId: "assets",
    //                     },
    //                     {
    //                         id: "connectorType",
    //                         label: "Connector Type",
    //                         link: "/assets/connectortype",
    //                         parentId: "assets",
    //                     }
    //                 ],
    //             },
    //             {
    //                 label: "User",
    //                 isHeader: true,
    //                 isVisble: true,
    //             },
    //             {
    //                 id: "customers",
    //                 label: "Customers",
    //                 icon: <i className="bx bx-mobile" />,
    //                 link: "/customers",
    //                 // link: "/dashboard",
    //                 isVisble: true,
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIscurrentState('customers');
    //                 }
    //             },
    //             {
    //                 id: "rfid",
    //                 label: "RFID",
    //                 icon: <i className="bx bx-id-card" />,
    //                 link: getUserRole() ? "/rfid" : '/rfidmanagement',
    //                 // link: "/dashboard",
    //                 isVisble: true,
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIscurrentState('rfid');
    //                 }
    //             },
    //             {
    //                 label: "Other",
    //                 isHeader: true,
    //                 isVisble: true,
    //             },
    //             {
    //                 id: "tariff",
    //                 label: "Tariff",
    //                 icon: <i className="bx bx-dollar" />,
    //                 link: "/tariff",
    //                 // isVisble: (!getUserRole()) || (getUserRole() === 'LM'),
    //                 isVisble: false,
    //                 click: function (e) {
    //                     e.preventDefault();
    //                     setIscurrentState('Assets');
    //                 }
    //             },
    //             {
    //                 id: "profile",
    //                 label: "Profile",
    //                 icon: <i className="bx bx-user" />,
    //                 link: "/profile",
    //                 // link: "/dashboard",
    //                 isVisble: true,
    //                 click: function (e) {

    //                     e.preventDefault();
    //                     setIscurrentState('Profile');
    //                 }
    //             },
    //         ]}</React.Fragment>;
    //     }
    // } else {
    // return <React.Fragment>{menuItems}</React.Fragment>;
    return <React.Fragment>{menuItems2}</React.Fragment>;
    // }

};

export default Navdata;