import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import DeleteModal from '../../../Components/Common/DeleteModal'
import QRCode from "qrcode.react";
//import Components
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DisableModal from '../../../Components/Common/DisableModal';
import { useDispatch, useSelector } from 'react-redux';
import { detailEvse, DetailEvseConnector, toggelEvse, DeletEvse } from '../../../store/actions';
import { showToast } from '../../../store/actions';
import useWindowDimension from '../../../Components/Hooks/useWindowDimension';
import { getValueFromObject } from '../../../helpers/helper.service';

const ViewEVSE = (props) => {
    document.title = "View EVSE | Vidhyutam";
    const history = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const [opsDelete, setOpsDelete] = useState(false);
    const [deleteModalUser, setdeleteModalUser] = useState(false);
    const [disabledModal, setDisabledModal] = useState(false);
    const [isChecked, setIsChecked] = useState(true);
    const dimensions = useWindowDimension();
    const [headerHeight, SetHeaderHeight] = useState(0);
    const [breadCrumb, setBreadCrumbHeight] = useState(0);
    const [footerHeight, SetFooterHeight] = useState(0);
    const [cardHeader, setCardHeader] = useState(0);
    const [titleheight, setTitleheight] = useState(0);

    useEffect(() => {
        SetHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setCardHeader(
            document.getElementsByClassName('card-header')[0]?.offsetHeight
        );
        SetFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setBreadCrumbHeight(
            document.getElementsByClassName('breadCrumb')[0]?.offsetHeight
        );
        setTitleheight(
            document.getElementsByClassName('titleheight')[0]?.offsetHeight
        );
    }, []);

    const handleResize = () => {

        SetHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setCardHeader(
            document.getElementsByClassName('card-header')[0]?.offsetHeight
        );
        SetFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setBreadCrumbHeight(
            document.getElementsByClassName('breadCrumb')[0]?.offsetHeight
        );
        setTitleheight(
            document.getElementsByClassName('titleheight')[0]?.offsetHeight
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const EvseID = state?.state?.EVSE_id
        
        dispatch(detailEvse(EvseID));
        dispatch(DetailEvseConnector(EvseID));
    }, []);

    const { getDetailEvse, getDetailEvseConnector } = useSelector(state => ({
        getDetailEvse: state?.AssetManagment?.detailEvse[0],
        getDetailEvseConnector: state?.AssetManagment?.detailEvseConnector
    }))
    


    const handleDeleteContact = () => {
        const params = {
            modelName: getDetailEvse?.modelName
        }
        dispatch(DeletEvse(params, getDetailEvse?.EVSEId, props.history));
        setdeleteModalUser(false);
    };

    const handleDisableContact = (e) => {
        const params = {
            modelName: getDetailEvse?.modelName,
            status: getDetailEvse?.status === 'Active' ? 'Inactive' : 'Active'
        }
        dispatch(toggelEvse(params, getDetailEvse?.EVSEId));
        setDisabledModal(false);
        setTimeout(() => {
            dispatch(detailEvse(getDetailEvse?.EVSEId));
        }, 1000);
    };

    // const enableDisableModal = () => {
    //     if (isChecked === true) {

    //         setDisabledModal(false);
    //         setTimeout(() => {

    //         }, 3000);
    //     } else {
    //         // setIsChecked(!isChecked);
    //         // dispatch(showToast({
    //         //     message: 'Connector Type “IEC 62196 (Type 2)” has been enabled successfully.',
    //         //     type: 'success'
    //         // }));
    //         setDisabledModal(false);
    //         setTimeout(() => {

    //         }, 3000);
    //     }
    // }

    const connectionData1 = [
        {
            id: '1',
            type: 'IEC_62196_T2_COMBO',
            format: 'Socket',
            powerType: 'DC',
            maxVoltage: '240.00V',
            maxAmperage: '125.00A',
            macElectricPower: '20.00 KW'


        },
        {
            id: '2',
            type: 'IEC_62196_T2_COMBO',
            format: 'Socket',
            powerType: 'DC',
            maxVoltage: '240.00V',
            maxAmperage: '125.00A',
            macElectricPower: '20.00 KW'


        },
        {
            id: '3',
            type: 'IEC_62196_T2_COMBO',
            format: 'Socket',
            powerType: 'DC',
            maxVoltage: '240.00V',
            maxAmperage: '125.00A',
            macElectricPower: '20.00 KW'


        },
        {
            id: '4',
            type: 'IEC_62196_T2_COMBO',
            format: 'Socket',
            powerType: 'DC',
            maxVoltage: '240.00V',
            maxAmperage: '125.00A',
            macElectricPower: '20.00 KW'


        },
        {
            id: '5',
            type: 'IEC_62196_T2_COMBO',
            format: 'Socket',
            powerType: 'DC',
            maxVoltage: '240.00V',
            maxAmperage: '125.00A',
            macElectricPower: '20.00 KW'


        },
        {
            id: '6',
            type: 'IEC_62196_T2_COMBO',
            format: 'Socket',
            powerType: 'DC',
            maxVoltage: '240.00V',
            maxAmperage: '125.00A',
            macElectricPower: '20.00 KW'


        },
        {
            id: '7',
            type: 'IEC_62196_T2_COMBO',
            format: 'Socket',
            powerType: 'DC',
            maxVoltage: '240.00V',
            maxAmperage: '125.00A',
            macElectricPower: '20.00 KW'


        },
    ]
    // const statusTime = [
    //     {
    //         id: '1',
    //         dTime: '08:00 - 08:30',
    //         isBooked: true
    //     },
    //     {
    //         id: '2',
    //         dTime: '08:30 - 09:00',
    //         isBooked: false
    //     },
    //     {
    //         id: '3',
    //         dTime: '09:00 - 09:30',
    //         isBooked: true
    //     },
    //     {
    //         id: '4',
    //         dTime: '09:30 - 10:00',
    //         isBooked: true
    //     },
    //     {
    //         id: '5',
    //         dTime: '10:00 - 10:30',
    //         isBooked: false
    //     },
    //     {
    //         id: '6',
    //         dTime: '10:30 - 11:00',
    //         isBooked: true
    //     },
    //     {
    //         id: '7',
    //         dTime: '11:00 - 11:30',
    //         isBooked: false
    //     },
    //     {
    //         id: '8',
    //         dTime: '11:30 - 12:00',
    //         isBooked: false
    //     },
    //     {
    //         id: '9',
    //         dTime: '12:00 - 12:30',
    //         isBooked: false
    //     },
    //     {
    //         id: '10',
    //         dTime: '12:30 - 01:00',
    //         isBooked: false
    //     },
    //     {
    //         id: '11',
    //         dTime: '01:00 - 01:30',
    //         isBooked: false
    //     },
    //     {
    //         id: '12',
    //         dTime: '01:30 - 02:00',
    //         isBooked: false
    //     },
    //     {
    //         id: '13',
    //         dTime: '02:00 - 02:30',
    //         isBooked: false
    //     },
    //     {
    //         id: '14',
    //         dTime: '02:30 - 03:00',
    //         isBooked: false
    //     },
    //     {
    //         id: '15',
    //         dTime: '03:00 - 03:30',
    //         isBooked: true
    //     },
    //     {
    //         id: '16',
    //         dTime: '03:30 - 04:00',
    //         isBooked: false
    //     },
    //     {
    //         id: '17',
    //         dTime: '04:00 - 04:30',
    //         isBooked: false
    //     },
    //     {
    //         id: '18',
    //         dTime: '04:30 - 05:00',
    //         isBooked: true
    //     },
    //     {
    //         id: '19',
    //         dTime: '05:00 - 05:30',
    //         isBooked: true
    //     },
    //     {
    //         id: '20',
    //         dTime: '05:30 - 06:00',
    //         isBooked: false
    //     },
    // ]
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        onClickBack={(e) => {
                            e.preventDefault();
                            history.goBack();
                        }}
                        isButton={true}
                        pageTitle="EVSE Model"
                        OnClickpageTitle={(e) => { e.preventDefault(); history.goBack(); }}
                        title={getDetailEvse?.modelName}

                    />
                    <div className='viewEvsePage'>
                        <Row>
                            <Col lg={12}>
                                <Card className='mb-0'>
                                    <CardHeader className='cardHeader'>
                                        <Row>
                                            <Col lg={12} className='mb-2'>
                                                {/* <div className='main'>
                                                    <Row className='boxRow'>
                                                        <Col lg={12} className=' p-0'>
                                                            <div className='lA'>
                                                                <p className='lnameText'>{getValueFromObject('modelName', getDetailEvse, '-')}</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className='boxRow'>
                                                        <div className='mainContect'>
                                                            <div className='content'>
                                                                <div className='box'>
                                                                    <p className='mainTitle'>EVSE Model ID</p>
                                                                    <p className='txt textOverflow'>{getValueFromObject('prefix', getDetailEvse, '')}{getValueFromObject('EVSEId', getDetailEvse, '-')}</p>
                                                                </div>
                                                                <div className='box'>
                                                                    <p className='mainTitle'>Max. Power Supply</p>
                                                                    <p className='txt textOverflow'>{getValueFromObject('maxPower', getDetailEvse, '-') + ' kW'}</p>
                                                                </div>
                                                                <div className='box'>
                                                                    <p className='mainTitle'>Model Type</p>
                                                                    <p className='txt textOverflow'>{getValueFromObject('modelType', getDetailEvse, '-')}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row className='boxRow'>
                                                        <Col lg={12} className=' p-0'>
                                                            <div className='option'>
                                                                <Button>
                                                                    <div className="form-check form-switch">
                                                                        <Input
                                                                            onClick={() => { }}
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id=""
                                                                            checked={getDetailEvse?.status === 'Active' ? true : false}
                                                                            onChange={(e) => {

                                                                                setDisabledModal(true);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Button>
                                                                <Button
                                                                    disabled={getDetailEvse?.status === 'Inactive' ? true : false}
                                                                    className={getDetailEvse?.status === 'Inactive' ? 'disabled' : ''}
                                                                    onClick={() => {
                                                                        history.push('/assets/editevsemodel', {
                                                                            state: {
                                                                                EVSEid: getDetailEvse?.EVSEId,
                                                                                type: 'EDIT_MODAL'
                                                                            }
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className='bx bx-edit-alt editBtn' />
                                                                </Button>
                                                                <Button>
                                                                    <i className='bx bx-trash deleteBtn' onClick={() => { setdeleteModalUser(true); }} />
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div> */}
                                                <div className='mainEvse'>
                                                    <div className='leftColumn'>
                                                        <h6>{getDetailEvse?.modelName}</h6>
                                                    </div>
                                                    <div className='centerColumn'>
                                                        <div className='centerColumnRow'>
                                                            <div className='centerColumnColumn'>
                                                                <p className='title'>EVSE Model ID</p>
                                                                <p className='subTitle'>{getDetailEvse?.modelId}</p>
                                                            </div>
                                                            <div className='centerColumnColumn'>
                                                                <p className='title'>Max Power Supply</p>
                                                                <p className='subTitle'>{getDetailEvse?.maxPower} kW</p>
                                                            </div>
                                                            <div className='centerColumnColumn'>
                                                                <p className='title'>Model Type</p>
                                                                <p className='subTitle'>{getDetailEvse?.modelType}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='rightColumn'>
                                                        <div className='option'>
                                                            <Button>
                                                                <div className="form-check form-switch">
                                                                    <Input
                                                                        onClick={() => { }}
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        id=""
                                                                        checked={getDetailEvse?.status === 'Active' ? true : false}
                                                                        onChange={(e) => {

                                                                            setDisabledModal(true);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Button>
                                                            <Button
                                                                disabled={getDetailEvse?.status === 'Inactive' ? true : false}
                                                                className={getDetailEvse?.status === 'Inactive' ? 'disabled' : ''}
                                                                onClick={() => {
                                                                    history.push('/assets/editevsemodel', {
                                                                        state: {
                                                                            EVSEid: getDetailEvse?.EVSEId,
                                                                            type: 'EDIT_MODAL'
                                                                        }
                                                                    })
                                                                }}
                                                            >
                                                                <i className='bx bx-edit-alt editBtn' />
                                                            </Button>
                                                            <Button>
                                                                <i className='bx bx-trash deleteBtn' onClick={() => { setdeleteModalUser(true); }} />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='title titleheight'>
                                            <p className='Txt'>{getDetailEvseConnector?.EVSEConnectors?.length > 1 ? 'Connectors' : 'Connector'} ({getDetailEvseConnector?.EVSEConnectors?.length})</p>
                                        </div>
                                        <div className='main' style={{ height: dimensions.height - headerHeight - footerHeight - breadCrumb - titleheight - 96 }}>
                                            <Row className='row'>
                                                {
                                                    getDetailEvseConnector?.EVSEConnectors?.map((item, index) => {
                                                        
                                                        return (
                                                            <Col lg={6} md={6} className={'connectorBox'} key={index}>
                                                                <Card className='customCard1'>
                                                                    <CardBody>
                                                                        <div className='topBar d-flex align-items-center justify-content-end mb-2'>
                                                                            <i onClick={() => {
                                                                                history.push('/assets/editevseconnector', {
                                                                                    state: {
                                                                                        ConnectorID: item?.connectorId,
                                                                                        type: "EDIT_CONNECTOR"
                                                                                    }
                                                                                });
                                                                            }} className='bx bx-edit-alt editBtn' />
                                                                        </div>
                                                                        <div className='upper'>
                                                                            <div className='mimage'>
                                                                                {/* <img src='https://res.cloudinary.com/djyl1goby/image/upload/v1667470119/Lubi/WebApp/Static-images/CCS_4.svg' alt='' /> */}
                                                                                <img src={`${process.env.REACT_APP_CLOUDINARY_URL}${item?.iconUrl}`} alt='' />
                                                                            </div>
                                                                            <div className='content'>
                                                                                <div className="table-responsive">
                                                                                    <Table className="table-sm mb-0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td className="fw-medium"><p className='txt'>TYPE:</p></td>
                                                                                                <td>
                                                                                                    <p className='txt textOverflow'>{item?.connectorType ? item?.connectorType : '-'}</p>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="fw-medium"><p className='txt'>Format:</p></td>
                                                                                                <td> <p className='txt textOverflow'>{item?.format ? item?.format : '-'}</p></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="fw-medium"><p className='txt'>Power Type:</p></td>
                                                                                                <td><p className='txt textOverflow'>{item?.powerType ? item?.powerType : '-'}</p></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                {/* <td className="fw-medium"><p className='txt'>Max Voltage:</p></td> */}
                                                                                                <td className="fw-medium"><p className='txt'>Max. Voltage:</p></td>
                                                                                                <td><p className='txt textOverflow'>{item?.maxVoltage ? item?.maxVoltage + ' V' : '-'}</p></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                {/* <td className="fw-medium"><p className='txt'>Max Ampere:</p></td> */}
                                                                                                <td className="fw-medium"><p className='txt'>Max. Current:</p></td>
                                                                                                <td><p className='txt textOverflow'>{item?.maxAmpere ? item?.maxAmpere + ' A' : '-'}</p></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                {/* <td className="fw-medium"><p className='txt'>Max Electric Power:</p></td> */}
                                                                                                <td className="fw-medium"><p className='txt'>Max. Electric Power:</p></td>
                                                                                                <td><p className='txt textOverflow'>{item?.maxPower ? item?.maxPower + ' kW' : '-'}</p></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>

                                            {/* no data */}
                                            {/* <Row>
                                                <div className='noData' style={{ height: dimensions.height - headerHeight - footerHeight - cardHeader - 40 + 'px' }}>
                                                    <div className='contentNoData'>
                                                        <img alt='' src='https://res.cloudinary.com/djyl1goby/image/upload/v1671183689/Lubi/WebApp/Static-images/No_Connectors_found.svg' />
                                                        <p>No Connector Type Found!</p>
                                                    </div>
                                                </div>
                                            </Row> */}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <DeleteModal
                show={deleteModalUser}
                title='Are you sure?'
                description='Are you sure you want to delete this record?'
                onDeleteClick={handleDeleteContact}
                onCloseClick={() => setdeleteModalUser(false)}
            />
            <DisableModal
                show={disabledModal}
                title='Are you sure?'
                subtitle={getDetailEvse?.status === 'Active' ? 'Are you sure you want to disable this record?' : "Are you sure you want to enable this record?"}
                btnActionText={getDetailEvse?.status === 'Active' ? 'Yes, Disable it!' : 'Yes, Enable it!'}
                btncancelText='Cancel'
                onActionClick={(e) => handleDisableContact(e)}
                onCloseClick={() => setDisabledModal(false)}
            />
            <Modal className='opsDelete' isOpen={opsDelete} toggle={() => {
                setOpsDelete(false);
            }}
                centered={true}>

                <ModalBody className="">
                    <div className='main'>
                        <div className='iMain'>
                            <img src='https://res.cloudinary.com/djyl1goby/image/upload/v1667630611/Lubi/WebApp/Static-images/image_3.svg' alt='' />
                        </div>
                        <div className='cContainer'>
                            <h1>
                                Opps! You Can’t delete this business.
                            </h1>
                            <p>Please check the following thing to delete this business</p>
                            <ul>
                                <li>All stations must be free from charging.</li>
                                <li>All the connector must not connected to any vehicle.</li>
                            </ul>
                            <div className='bottom d-flex justify-content-center mt-1 mb-3'>
                                <Button onClick={() => { setOpsDelete(false); }}>
                                    Ok
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ViewEVSE;