import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import CustomButton from "../../Components/Common/CustomButton";
import TableContainer from "../../Components/Common/TableContainer";
import ContentLoader from "../../Components/Common/ContentLoader";
import useWindowDimension from "../../Components/Hooks/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import {
  LocManBus,
  allBusinessList,
  dashboardDataSuccess,
  getBusinessLogo,
  listAllStation,
  socketData,
  allLocation,
  getSuperAdminCustomer,
  getSuperAdminCustomerDownload,
  getBusinessLocation,
  getBusinessLocationDownload,
  getLocationManager,
  getLocationManagerDownload,
  getSuperAdminDashboardYear,
  getBusinessTransactionSA,
  getHeatmaptData,
  getListAllConnector,
  allConnectorStationId,
} from "../../store/actions";
// import socket from "../../socketConfig";
//import Components
// import UpgradeAccountNotise from "./UpgradeAccountNotise";
// import UsersByDevice from "./UsersByDevice";
// import Widget from "./Widget";
// import BreadCrumb from "../../Components/Common/BreadCrumb";
// import AudiencesMetrics from "./AudiencesMetrics";
// import AudiencesSessions from "./AudiencesSessions";
// import LiveUsers from "./LiveUsers";
// import TopReferrals from "./TopReferrals";
// import TopPages from "./TopPages";
import {
  formatPrice,
  getDaysInMonth,
  getWeekNumber,
  getWeeksInfo,
} from "../../helpers/helper.service";
import LocationManagerDashboard from "./LocationManagerDashboard";

import moment from "moment";

import Highcharts from "highcharts";
import HighchartsHeatmap from "highcharts/modules/heatmap";

import HighchartsReact from "highcharts-react-official";
import ExportModule from "highcharts/modules/exporting";
HighchartsHeatmap(Highcharts);
ExportModule(Highcharts);

const Dashboard = () => {
  document.title = "Dashboard | Lubi EV Solutions";
  const history = useHistory();
  const dispatch = useDispatch();
  const dimensions = useWindowDimension();
  const userRole = JSON.parse(localStorage.getItem("users"))?.userRole || "";

  const GroupOptionsDayList = [
    { id: "01", value: "01", label: "IC01" },
    { id: "02", value: "02", label: "IC02" },
    { id: "03", value: "03", label: "IC03" },
    { id: "04", value: "04", label: "IC04" },
    { id: "05", value: "05", label: "IC05" },
    { id: "06", value: "06", label: "IC06" },
    { id: "07", value: "07", label: "IC07" },
    { id: "08", value: "08", label: "IC08" },
    { id: "09", value: "09", label: "IC09" },
    { id: "10", value: "10", label: "IC10" },
    { id: "11", value: "11", label: "IC11" },
    { id: "12", value: "12", label: "IC12" },
    { id: "13", value: "13", label: "IC13" },
    { id: "14", value: "14", label: "IC14" },
    { id: "15", value: "15", label: "IC15" },
    { id: "16", value: "16", label: "IC16" },
    { id: "17", value: "17", label: "IC17" },
    { id: "18", value: "18", label: "IC18" },
    { id: "19", value: "19", label: "IC19" },
    { id: "20", value: "20", label: "IC20" },
    { id: "21", value: "21", label: "IC21" },
    { id: "22", value: "22", label: "IC22" },
    { id: "23", value: "23", label: "IC23" },
    { id: "24", value: "24", label: "IC24" },
    { id: "25", value: "25", label: "IC25" },
    { id: "26", value: "26", label: "IC26" },
    { id: "27", value: "27", label: "IC27" },
    { id: "28", value: "28", label: "IC28" },
    { id: "29", value: "29", label: "IC29" },
    { id: "30", value: "30", label: "IC30" },
    { id: "31", value: "31", label: "IC31" },
  ];

  const GroupOptionsWeekList = [
    { id: "1", value: "Week1" },
    { id: "2", value: "Week2" },
    { id: "3", value: "Week3" },
    { id: "4", value: "Week4" },
  ];
  const GroupOptionsMonth = [
    { id: "1", value: "January", label: "IC100" },
    { id: "2", value: "Februray", label: "IC200" },
    { id: "3", value: "March", label: "IC300" },
    { id: "4", value: "April", label: "IC400" },
    { id: "5", value: "May", label: "IC500" },
    { id: "6", value: "June", label: "IC600" },
    { id: "7", value: "July", label: "IC700" },
    { id: "8", value: "August", label: "IC800" },
    { id: "9", value: "September", label: "IC900" },
    { id: "10", value: "October", label: "IC1000" },
    { id: "11", value: "November", label: "IC1100" },
    { id: "12", value: "December", label: "IC1200" },
  ];
  let GroupOptionsYear = [
    { id: "1", value: "2020", label: "IC100" },
    { id: "2", value: "2021", label: "IC200" },
    { id: "3", value: "2022", label: "IC300" },
    { id: "4", value: "2023", label: "IC400" },
    { id: "5", value: "2024", label: "IC500" },
    { id: "6", value: "2025", label: "IC600" },
    { id: "7", value: "2026", label: "IC700" },
    { id: "8", value: "2027", label: "IC800" },
    { id: "9", value: "2028", label: "IC900" },
    { id: "10", value: "2029", label: "IC1000" },
    { id: "11", value: "2030", label: "IC1100" },
    { id: "12", value: "2031", label: "IC1200" },
    { id: "13", value: "2032", label: "IC1300" },
    { id: "14", value: "2033", label: "IC1400" },
    { id: "15", value: "2034", label: "IC1500" },
    { id: "16", value: "2035", label: "IC1600" },
  ];
  const [heatmapDataArray, setHeatmapDataArray] = useState([]);

  const {
    data,
    customers,
    stationDetailsDownload,
    customersDownload,
    allConnectorStationList,
    businessLocationsDownload,
    businessLocations,
    businessYear,
    socketData,
    businessTransactionSA,
    heatMapDataLM,
    businessList,
    locationList,
    listAllConnector,
    ListAllStations,
  } = useSelector((state) => ({
    data: state?.DashboardProject?.dashboardData?.data,
    customers: state?.DashboardProject?.dashboardCustomer,
    customersDownload: state?.DashboardProject?.dashboardCustomerDownload,
    businessLocations: state?.DashboardProject?.businessLocations,
    businessLocationsDownload:
      state?.DashboardProject?.businessLocationsDownload,
    businessYear: state?.DashboardProject?.dashboardBusinessYear,
    businessTransactionSA: state?.DashboardProject?.businessTransactionSA,
    heatMapDataLM: state?.DashboardProject?.heatMapDataLM,
    businessList: state?.businessList?.allBusinessList,
    locationList: state?.locationsList?.allLocationList,
    ListAllStations: state?.ManageStations?.listAllStation,
    listAllConnector: state?.ManageStations?.listAllConnector,
    socketData: state?.ManageStations?.getSocketStatus?.data,
    // socketData: JSON.parse(window.localStorage.getItem('socketHeart')),

    allConnectorStationList: state?.locationsList?.allConnectorStationList,

    stationDetailsDownload: state?.DashboardProject?.stationDetailsDownload,
  }));
  if (businessYear.length > 0) {
    GroupOptionsYear = businessYear;
  }
  const { loader } = useSelector((state) => state.Toast);
  const { stationDetails } = useSelector((state) => state.DashboardProject);
  // const { allBusinessList: businessList } = useSelector((state) => state.businessList);
  let obj = stationDetails.reduce(
    (acc, curr) => {
      acc.sessionCount += curr?.sessionCount;
      acc.connectorCount += curr?.connectors?.length;
      return acc;
    },
    { sessionCount: 0, connectorCount: 0 }
  );

  const [countSessionConnector, setCountSessionConnector] = useState(obj);
  const [GroupOptionsDay, setGroupOptionsDay] = useState(GroupOptionsDayList);
  const [GroupOptionsWeek, setGroupOptionsWeek] =
    useState(GroupOptionsWeekList);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [tableTopCard, setTableTopCard] = useState(0);
  const [paginationDiv, setPaginationDiv] = useState(0);
  const [activeButton, setActiveButton] = useState("day");
  const [selectedGroupDay, setSelectedGroupDay] = useState(
    GroupOptionsDay.filter((day) => +day.value === +new Date().getDate())?.[0]
  );
  const [selectedGroupWeek, setSelectedGroupWeek] = useState(null);
  const [selectedGroupMonth, setSelectedGroupMonth] = useState(
    GroupOptionsMonth.filter(
      (month) => +month.id === new Date().getMonth() + 1
    )?.[0]
  );
  const [selectedGroupYear, setSelectedGroupYear] = useState(
    GroupOptionsYear.filter((yr) => +yr.value === new Date().getFullYear())?.[0]
  );
  const [selectedBusiness, setSelectedBusiness] = useState({});
  const [newest, setNewest] = useState(null);
  const [highest, setHighest] = useState(null);
  const [businessApi, setBusinessApi] = useState("");
  const [businessApiidcheck, setBusinessApiidcheck] = useState(0);

  const [businessApiId, setBusinessApiId] = useState("");

  const [durationValue, setDurationValue] = useState("This Week");
  const [buttonSelectedValue, setButtonSelectedValue] = useState("Business");
  const [newDropDownList, setnewDropDownList] = useState([]);

  const [totalCountData, setTotalCountData] = useState(0);
  const [availablePercentageData, setAvailablePercentageData] = useState(0);
  const [availableCountData, setAvailableCountData] = useState(0);
  const [chargingPercentageData, setChargingPercentageData] = useState(0);
  const [chargingCountData, setChargingCountData] = useState(0);
  const [preparingPercentageData, setpreparingPercentageData] = useState(0);
  const [preparingCountData, setpreparingCountData] = useState(0);
  const [unAvailablePercentageData, setunAvailablePercentageData] = useState(0);
  const [unAvailableCountData, setunAvailableCountData] = useState(0);

  const [unknownCountData, setUnknownCountData] = useState(0);
  const [unknownPercentageData, setUnknownPercentageData] = useState(0);

  const [superAdminFilterModel, setSuperAdminFilterModel] = useState(false);

  const [businessName, setBusinessName] = useState([]);

  const [getBusId, setGetBusId] = useState("");
  const [getLocId, setGetLocId] = useState("");

  const [getStaId, setGetStaId] = useState("");
  const [stationHeatmap, setStationHeatmap] = useState("");

  const [getConId, setGetConId] = useState("");

  const [filterbusinessId, setFilterbusinessId] = useState("");
  const [filterlocationId, setFilterlocationId] = useState("");

  const [filterstationId, setFilterstationId] = useState("");
  const [filterConnectorId, setFilterconnectorId] = useState("");

  const [locationFilterData, setLocationFilterData] = useState("");
  const [stationFilterData, setStationFilterData] = useState("");
  const [connectorListDAta, setConnectorFilterDAta] = useState("");
  const [energyConsumeRange, setEnergyConsumeRange] = useState([0, 10000]);
  const [energyFilter, setEnergyFilter] = useState(false);
  const [sessionFilter, setSessionFilter] = useState(false);
  const [revenueFilter, setRevenueFilter] = useState(false);
  const [durationFilter, setDurationFilter] = useState(false);

  const [minEnergyConsume, setminEnergyConsume] = useState(0);
  const [maxEnergyConsume, setmaxEnergyConsume] = useState(10000);
  const [sessionRange, setSessionRange] = useState([0, 200]);
  const [minSession, setminSession] = useState(0);
  const [maxSession, setmaxSession] = useState(200);
  const [revenueRange, setRevenueRange] = useState([0, 25000]);
  const [minRevenue, setminRevenue] = useState(0);
  const [maxRevenue, setmaxRevenue] = useState(25000);
  const [durationRange, setDurationRange] = useState([0, 24]);
  const [minDuration, setminDuration] = useState(0);
  const [maxDuration, setmaxDuration] = useState(24);
  const [downLoadButton, setDownLoadButton] = useState(false);
  const [connectorLengthDynamic, setconnectorLengthDynamic] = useState([]);

  // const businessTransactionSA = [
  // 	{
  // 		"categoriesXaxis": "Monday, 10 Jun 2024",
  // 		"dayNames": "Monday",
  // 		"Revenue": 180,
  // 		"NoOfTransaction": 2,
  // 		"duration": "05:32:56",
  // 		"EnergyConsumption": 9
  // 	},
  // 	{
  // 		"categoriesXaxis": "Tuesday, 11 Jun 2024",
  // 		"dayNames": "Tuesday",
  // 		"Revenue": 40,
  // 		"NoOfTransaction": 1,
  // 		"duration": "05:33:38",
  // 		"EnergyConsumption": 11
  // 	}
  // ]

  const durationArray = [
    { id: "1", value: "This Week" },
    { id: "2", value: "This Month" },
    { id: "3", value: "This Year" },
    { id: "4", value: "Past Week" },
    { id: "5", value: "Past Month" },
    { id: "6", value: "Past Year" },
  ];
  const dayNames = businessTransactionSA.map((data) => data.dayNames);
  const noOfTransactions = businessTransactionSA.map(
    (data) => data.NoOfTransaction
  );
  const revenues = businessTransactionSA.map((data) => data.Revenue);
  const energyConsumptions = businessTransactionSA.map(
    (data) => data.EnergyConsumption
  );

  const timeDurations = businessTransactionSA.map((data) => {
    const timeParts = data?.duration?.split(":");
    // Convert hours and minutes to a decimal format
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    return hours + minutes / 60;
  });

  // Get today's date
  let currentDate = moment();

  // Calculate the start date of the current week (Sunday)
  let startDate = currentDate.clone().startOf("week");

  // Create an array to store the formatted dates for the current week
  let categoriesXaxis = [];
  // let dayNames = [];

  for (let i = 0; i < 7; i++) {
    let date = startDate.clone().add(i, "days");
    // Format the date as required ("DD:MM:YYYY HH:MM:SS")
    let formattedDate = date.format("dddd DD MMM YYYY");
    categoriesXaxis.push(formattedDate);
    // dayNames.push(date.format('ddd'));
  }

  useEffect(() => {
    setHeatmapDataArray(heatMapDataLM);
  }, [heatMapDataLM]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        setSuperAdminFilterModel(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  useEffect(() => {
    function convertToHours(durationStr) {
      const [h, m, s] = durationStr.split(":").map(Number);
      return h + m / 60 + s / 3600;
    }
    if (userRole === "SuperAdmin") {
      if (customers.length > 0) {
        const totalUnitValues = customers?.reduce((max, obj) => {
          return obj.totalUnit > max ? obj.totalUnit : max;
        }, customers[0]?.totalUnit);

        const sessionCountValues = customers?.reduce((max, obj) => {
          return obj.sessionCount > max ? obj.sessionCount : max;
        }, customers[0]?.sessionCount);

        const totalRevenueValues = customers?.reduce((max, obj) => {
          return obj.totalRevenue > max ? obj.totalRevenue : max;
        }, customers[0]?.totalRevenue);

        // setmaxEnergyConsume(parseInt(totalUnitValues))
        // setmaxSession(parseInt(sessionCountValues))
        // setmaxRevenue(parseInt(totalRevenueValues))

        // setEnergyConsumeRange([0, parseInt(totalUnitValues)])
        // setSessionRange([0, parseInt(sessionCountValues)])
        // setRevenueRange([0, parseInt(totalRevenueValues)])

        // Extract durations and convert to hours
        const hours = customers?.map((item) => convertToHours(item.duration));

        // Find the maximum hours
        const maxHours = Math.ceil(Math.max(...hours));
        // setmaxDuration(maxHours)
        // setDurationRange([0, maxHours])
      } else {
        // setEnergyConsumeRange([0, 10])
        // // setminEnergyConsume(0)
        // // setmaxEnergyConsume(10000)
        // setSessionRange([0, 10])
        // setminSession(0)
        // setmaxSession(10)
        // setRevenueRange([0, 10])
        // setminRevenue(0)
        // setmaxRevenue(10)
        // setDurationRange([0, 10])
        // setminDuration(0)
        // setmaxDuration(10)
      }
    }
    if (userRole === "BusinessOwner") {
      if (businessLocations.length > 0) {
        const totalUnitValues = businessLocations?.reduce((max, obj) => {
          return obj.totalUnit > max ? obj.totalUnit : max;
        }, businessLocations[0]?.totalUnit);

        const sessionCountValues = businessLocations?.reduce((max, obj) => {
          return obj.sessionCount > max ? obj.sessionCount : max;
        }, businessLocations[0]?.sessionCount);

        const totalRevenueValues = businessLocations?.reduce((max, obj) => {
          return obj.totalRevenue > max ? obj.totalRevenue : max;
        }, businessLocations[0]?.totalRevenue);

        // setmaxEnergyConsume(parseInt(totalUnitValues))
        // setmaxSession(parseInt(sessionCountValues))
        // setmaxRevenue(parseInt(totalRevenueValues))

        // setEnergyConsumeRange([0, parseInt(totalUnitValues)])
        // setSessionRange([0, parseInt(sessionCountValues)])
        // setRevenueRange([0, parseInt(totalRevenueValues)])

        // Extract durations and convert to hours
        const hours = businessLocations?.map((item) =>
          convertToHours(item.duration)
        );

        // Find the maximum hours
        const maxHours = Math.ceil(Math.max(...hours));
        // setmaxDuration(maxHours)
        // setDurationRange([0, maxHours])
      } else {
        // setEnergyConsumeRange([0, 10])
        // setminEnergyConsume(0)
        // setmaxEnergyConsume(10000)
        // setSessionRange([0, 10])
        // setminSession(0)
        // setmaxSession(10)
        // setRevenueRange([0, 10])
        // setminRevenue(0)
        // setmaxRevenue(10)
        // setDurationRange([0, 10])
        // setminDuration(0)
        // setmaxDuration(10)
      }
    }
    if (userRole === "LocationManager") {
      if (stationDetails.length > 0) {
        const totalUnitValues = stationDetails?.reduce((max, obj) => {
          return obj.totalUnit > max ? obj.totalUnit : max;
        }, stationDetails[0]?.totalUnit);

        const sessionCountValues = stationDetails?.reduce((max, obj) => {
          return obj.sessionCount > max ? obj.sessionCount : max;
        }, stationDetails[0]?.sessionCount);

        const totalRevenueValues = stationDetails?.reduce((max, obj) => {
          return obj.totalRevenue > max ? obj.totalRevenue : max;
        }, stationDetails[0]?.totalRevenue);

        // setmaxEnergyConsume(parseInt(totalUnitValues))
        // setmaxSession(parseInt(sessionCountValues))
        // setmaxRevenue(parseInt(totalRevenueValues))

        // setEnergyConsumeRange([0, parseInt(totalUnitValues)])
        // setSessionRange([0, parseInt(sessionCountValues)])
        // setRevenueRange([0, parseInt(totalRevenueValues)])

        // Extract durations and convert to hours
        const hours = stationDetails?.map((item) =>
          convertToHours(item.duration)
        );

        // Find the maximum hours
        const maxHours = Math.ceil(Math.max(...hours));
        // setmaxDuration(maxHours)
        // setDurationRange([0, maxHours])
      } else {
        // setEnergyConsumeRange([0, 10])
        // setminEnergyConsume(0)
        // setmaxEnergyConsume(10000)
        // setSessionRange([0, 10])
        // setminSession(0)
        // setmaxSession(10)
        // setRevenueRange([0, 10])
        // setminRevenue(0)
        // setmaxRevenue(10)
        // setDurationRange([0, 10])
        // setminDuration(0)
        // setmaxDuration(10)
      }
    }
  }, [customers, businessLocations, stationDetails]);

  const downloadData = () => {
    let query = "?";
    if (
      selectedGroupDay &&
      selectedGroupMonth &&
      selectedGroupYear &&
      activeButton === "day"
    ) {
      query += `year=${selectedGroupYear?.value}&month=${selectedGroupMonth?.id}&day=${selectedGroupDay?.value}`;
    } else if (
      selectedGroupWeek &&
      selectedGroupMonth &&
      selectedGroupYear &&
      activeButton === "week"
    ) {
      query += `year=${selectedGroupYear?.value}&month=${selectedGroupMonth?.id}&startDate=${selectedGroupWeek?.startDate}&endDate=${selectedGroupWeek?.endDate}`;
    } else if (
      selectedGroupMonth &&
      selectedGroupYear &&
      activeButton === "month"
    ) {
      query += `year=${selectedGroupYear?.value}&month=${selectedGroupMonth?.id}`;
    } else if (selectedGroupYear && activeButton === "year") {
      query += `year=${selectedGroupYear?.value}`;
    }
    if (filterbusinessId) {
      query += `&businessId=${filterbusinessId}`;
    }
    if (filterlocationId) {
      query += `&locationId=${filterlocationId}`;
    }
    if (filterstationId) {
      query += `&stationId=${filterstationId}`;
    }

    if (filterConnectorId) {
      query += `&connectorId=${filterConnectorId}`;
    }
    if (energyConsumeRange) {
      query += `&energyConsume=[${energyConsumeRange}]`;
    }

    if (sessionRange) {
      query += `&sessionCount=[${sessionRange}]`;
    }
    if (revenueRange) {
      query += `&revenueRange=[${revenueRange}]`;
    }
    if (durationRange) {
      query += `&duration=[${durationRange}]`;
    }

    if (userRole === "SuperAdmin") {
      dispatch(getSuperAdminCustomerDownload(query));
    } else if (userRole === "BusinessOwner")
      dispatch(getBusinessLocationDownload(query));
    else if (userRole === "LocationManager")
      dispatch(getLocationManagerDownload(query));
  };

  useEffect(() => {
    if (userRole === "SuperAdmin") {
      if (downLoadButton && customersDownload.length > 0) {
        initiateCSVDownload(customersDownload);
        setDownLoadButton(false);
      }
    }
    if (userRole === "BusinessOwner") {
      if (downLoadButton && businessLocationsDownload.length > 0) {
        initiateCSVDownload(businessLocationsDownload);
        setDownLoadButton(false);
      }
    }
    if (userRole === "LocationManager") {
      if (downLoadButton && stationDetailsDownload.length > 0) {
        initiateCSVDownload(stationDetailsDownload);
        setDownLoadButton(false);
      }
    }
  }, [customersDownload, businessLocationsDownload, stationDetailsDownload]);

  const initiateCSVDownload = (data) => {
    if (data.length > 0) {
      const link = document.createElement("a");
      link.setAttribute("href", encodeURI(createCSVContent(data)));
      link.setAttribute("download", "Data.csv");
      link.click();
    }
  };

  const createCSVContent = (data) => {
    let headers = [];
    let keyMappings = {};
    if (userRole === "SuperAdmin") {
      headers = [
        "Business",
        "Location",
        "Station",
        "Connector",
        "Energy Consumption (In kWh)",
        "No of Session",
        "Revenue (In Rupees)",
        "Duration",
        "TimeStamp",
      ];
      keyMappings = {
        Business: "businessName",
        Location: "locationName",
        Station: "stationName",
        Connector: "connectorNumber",
        "Energy Consumption (In kWh)": "totalUnit",
        "No of Session": "sessionCount",
        "Revenue (In Rupees)": "totalRevenue",
        Duration: "duration",
        TimeStamp: "createdAt",
      };
    }
    if (userRole === "BusinessOwner" || userRole === "LocationManager") {
      headers = [
        "Location Details",
        "Station",
        "Connector",
        "Energy Consumption (In kWh)",
        "No of Session",
        "Revenue (In Rupees)",
        "Duration",
        "TimeStamp",
      ];
      keyMappings = {
        "Location Details": "locationName",
        Station: "stationName",
        Connector: "connectorNumber",
        "Energy Consumption (In kWh)": "totalUnit",
        "No of Session": "sessionCount",
        "Revenue (In Rupees)": "totalRevenue",
        Duration: "duration",
        TimeStamp: "createdAt",
      };
    }

    const csvContent = [
      headers.join(","),
      ...data.map((row) =>
        headers.map((header) => row[keyMappings[header]]).join(",")
      ), // Data rows
    ].join("\n");

    return "data:text/csv;charset=utf-8," + csvContent;
  };

  // useEffect(() => {
  //   console.log(socketData, 'socketData')
  //   if (socketData !== undefined) {

  //     // Extract status information from the new socketData structure
  //     let extractedData = socketData?.reduce((acc, data) => {

  //       let key = `${data.chargerId}_${data.connectorNumber}`;
  //       acc[key] = data.status; // Adjust this line based on the actual field names in your data
  //       return acc;
  //     }, {});
  //     let validConnectors = new Set(listAllConnector.map(item => `${item.stationName}_${item.connectorNumber}`));
  //     console.log(validConnectors, 'validConnectors')
  //     console.log(extractedData, 'extractedData')

  //     let connectorsStatus = Object.keys(extractedData)
  //       .filter(key => validConnectors.has(key))
  //       .reduce((obj, key) => {
  //         obj[key] = extractedData[key];
  //         return obj;
  //       }, {});
  //     console.log(connectorsStatus, 'connectorsStatus')

  //     connectorsStatus = Object.values(connectorsStatus);

  //     if (connectorsStatus.length > 0) {
  //       let totalCount = connectorsStatus.length;
  //       let availableCount = connectorsStatus.filter(status => status === 'Available').length;
  //       let availablePercentage = (availableCount / totalCount) * 100;
  //       let chargingCount = connectorsStatus.filter(status => status === 'Charging').length;
  //       let chargingPercentage = (chargingCount / totalCount) * 100;
  //       let preparingCount = connectorsStatus.filter(status => status === 'Preparing').length;
  //       let preparingPercentage = (preparingCount / totalCount) * 100;
  //       let unAvailableCount = connectorsStatus.filter(status => status === 'Unavailable').length;
  //       let unAvailablePercentage = (unAvailableCount / totalCount) * 100;
  //       let unknownDatasCount = connectorsStatus.filter(status => status !== 'Available' && status !== 'Charging' && status !== 'Preparing' && status !== 'Unavailable').length;
  //       let unknownPercentage = (unknownDatasCount / totalCount) * 100;

  //       setTotalCountData(totalCount);
  //       setAvailablePercentageData(parseInt(availablePercentage));
  //       setAvailableCountData(availableCount);
  //       setChargingPercentageData(parseInt(chargingPercentage));
  //       setChargingCountData(chargingCount);
  //       setpreparingPercentageData(parseInt(preparingPercentage));
  //       setpreparingCountData(preparingCount);
  //       setunAvailablePercentageData(parseInt(unAvailablePercentage));
  //       setunAvailableCountData(unAvailableCount);
  //       setUnknownPercentageData(parseInt(unknownPercentage));
  //       setUnknownCountData(unknownDatasCount);
  //     } else {
  //       setTotalCountData(0);
  //       setAvailableCountData(0);
  //       setChargingCountData(0);
  //       setpreparingCountData(0);
  //       setunAvailableCountData(0);
  //       setUnknownCountData(0);

  //       setAvailablePercentageData(0);
  //       setChargingPercentageData(0);
  //       setpreparingPercentageData(0);
  //       setunAvailablePercentageData(0);
  //       setUnknownPercentageData(0);
  //     }
  //   }
  // }, [socketData]);

  const MINUTE_MS = 30000;

  useEffect(() => {
    const updateData = () => {
      // console.log(socketData, 'EVERY 30 SEC');
      // console.log(JSON.parse(window.localStorage.getItem('socketHeart')), 'socketHeart');

      if (socketData !== undefined) {
        let filteredData = {};
        let unmatchedStationNames = [];
        // console.log(socketData, 'EVERY 30 SEC');

        // Get all chargerIds from socketHeart
        let chargerIds = JSON.parse(
          window.localStorage.getItem("socketHeart")
        )?.map((heartbeat) => heartbeat.chargerId);

        // Filter socketData keys based on chargerIds
        Object.keys(socketData).forEach((key) => {
          chargerIds?.forEach((chargerId) => {
            if (key.startsWith(chargerId)) {
              filteredData[key] = socketData[key];
            }
          });
        });
        // console.log(chargerIds, 'chargerIds');

        // Check for unmatched stationNames in listAllConnector
        listAllConnector?.forEach((connector) => {
          let matchFound = false;
          chargerIds?.forEach((chargerId) => {
            if (connector.stationName.startsWith(chargerId)) {
              matchFound = true;
            }
          });
          if (!matchFound) {
            unmatchedStationNames.push(connector);
          }
        });
        // console.log(filteredData, 'EVERY 30 SEC');
        // console.log(listAllConnector);
        // console.log(unmatchedStationNames, 'unmatchedStationNames');
        // console.log(listAllConnector.length, 'listAllConnector.length');
        // console.log(unmatchedStationNames.length, 'unmatchedStationNames.length');
        setTotalCountData(listAllConnector?.length);
        setunAvailableCountData(unmatchedStationNames.length);

        let unAvailableCount = unmatchedStationNames.length;
        let unAvailablePercentage =
          (unAvailableCount / listAllConnector.length) * 100;

        setunAvailablePercentageData(parseInt(unAvailablePercentage));

        let validConnectors = new Set(
          listAllConnector.map(
            (item) => `${item.stationName}_${item.connectorNumber}`
          )
        );
        let connectorsStatus = Object.keys(filteredData)
          .filter((key) => validConnectors.has(key))
          .reduce((obj, key) => {
            obj[key] = filteredData[key];
            return obj;
          }, {});
        connectorsStatus = Object.values(connectorsStatus);

        if (connectorsStatus.length > 0) {
          let totalCount = listAllConnector.length;
          let availableCount = connectorsStatus.filter(
            (status) => status === "Available"
          ).length;
          let availablePercentage = (availableCount / totalCount) * 100;
          let chargingCount = connectorsStatus.filter(
            (status) => status === "Charging"
          ).length;
          let chargingPercentage = (chargingCount / totalCount) * 100;
          let preparingCount = connectorsStatus.filter(
            (status) => status === "Preparing"
          ).length;
          let preparingPercentage = (preparingCount / totalCount) * 100;
          let unAvailableCount =
            connectorsStatus.filter((status) => status === "Unavailable")
              .length || unmatchedStationNames.length;
          let unAvailablePercentage = (unAvailableCount / totalCount) * 100;
          let unknownDatasCount = connectorsStatus.filter(
            (status) =>
              status !== "Available" &&
              status !== "Charging" &&
              status !== "Preparing" &&
              status !== "Unavailable"
          ).length;
          let unknownPercentage = (unknownDatasCount / totalCount) * 100;

          setTotalCountData(totalCount);
          setAvailablePercentageData(parseInt(availablePercentage));
          setAvailableCountData(availableCount);
          setChargingPercentageData(parseInt(chargingPercentage));
          setChargingCountData(chargingCount);
          setpreparingPercentageData(parseInt(preparingPercentage));
          setpreparingCountData(preparingCount);
          setunAvailablePercentageData(parseInt(unAvailablePercentage));
          setunAvailableCountData(unAvailableCount);
          setUnknownPercentageData(parseInt(unknownPercentage));
          setUnknownCountData(unknownDatasCount);
        } else {
          setAvailableCountData(0);
          setChargingCountData(0);
          setpreparingCountData(0);
          setUnknownCountData(0);

          setAvailablePercentageData(0);
          setChargingPercentageData(0);
          setpreparingPercentageData(0);
          setUnknownPercentageData(0);
        }
      }
    };

    const interval = setInterval(updateData, MINUTE_MS);

    // Run updateData immediately on mount
    updateData();

    return () => clearInterval(interval);
  }, [listAllConnector, socketData]);


  useEffect(() => {
    const makeNewXAxis = allConnectorStationList?.map((station, index) => `Connector ${index + 1}`);
    setconnectorLengthDynamic(makeNewXAxis)
    const setLocationFilter1 = () => {
      const TempList1 = allConnectorStationList?.map((eve, ind) => {
        return {
          id: `${ind + 1}`,
          value: eve.connectorNumber,
          connectorId: eve.connectorId,
        };
      });
      return TempList1;
    };

    const LocationT1 = setLocationFilter1();
    console.log(LocationT1, 'LocationT1LocationT1')
    setConnectorFilterDAta(LocationT1);
  }, [allConnectorStationList]);


  const highChartOptions = {
    chart: {
      zooming: {
        type: "xy",
      },
    },
    title: {
      text: "Transaction & Energy History",
      align: "center",
    },

    xAxis: [
      {
        categories: dayNames,
        crosshair: true,
      },
    ],
    yAxis: [
      {
        labels: {
          style: {
            color: "orange",
          },
          formatter: function () {
            const totalMinutes = this.value * 60;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = Math.round(totalMinutes % 60);
            return hours + "h " + minutes + "m";
          },
        },
        title: {
          text: "Time Duration",
          style: {
            color: "orange",
          },
        },
        opposite: true,
      },
      {
        // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: "No. of Transaction",
          style: {
            color: "green",
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: "green",
          },
        },
      },
      {
        // Tertiary yAxis
        gridLineWidth: 0,
        title: {
          text: "Cost",
          style: {
            color: "deepskyblue",
          },
        },
        labels: {
          format: "₹{value}",
          style: {
            color: "deepskyblue",
          },
        },
        opposite: true,
        // min: 10000, // Set the minimum value for the Cost yAxis
        // max: 70000  // Set the maximum value for the Cost yAxis
      },
    ],
    noData: {
      position: {
        align: "center",
        verticalAlign: "middle",
        x: 0,
        y: 0,
      },
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "red",
      },
      useHTML: true,
    },
    tooltip: {
      shared: true,
      formatter: function () {
        let energyData = businessTransactionSA.find(
          (data) => data.dayNames === this.x
        );
        let tooltipText = "";
        if (energyData) {
          tooltipText += energyData.categoriesXaxis + "<br/>";
        }
        // let tooltipText = '<b>' + this.x + '</b><br/>';
        const order = ["Revenue", "No of Transaction", "Time Duration"];
        const tooltipNames = {
          Cost: "Revenue",
          "No. of Transaction": "No of Transaction",
          "Time Duration": "Time Duration",
        };

        const sortedPoints = this.points.sort((a, b) => {
          return (
            order.indexOf(tooltipNames[a.series.name]) -
            order.indexOf(tooltipNames[b.series.name])
          );
        });

        sortedPoints.forEach(function (point) {
          const seriesName = point.series.name;
          const tooltipName = tooltipNames[seriesName];
          let color;
          switch (seriesName) {
            case "Cost":
              color = "deepskyblue";
              break;
            case "No. of Transaction":
              color = "green";
              break;
            case "Time Duration":
              color = "orange";
              break;
            default:
              color = "black";
              break;
          }

          let value = point.y;
          if (seriesName === "Time Duration") {
            const totalMinutes = value * 60;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = Math.round(totalMinutes % 60);
            value = hours + "h " + minutes + "m";
          }

          tooltipText +=
            '<span style="color:' +
            color +
            '">\u25CF</span> ' +
            tooltipName +
            ": <b>" +
            value +
            "</b><br/>";
        });

        // Add energy consumption
        // const energyData = businessTransactionSA.find(data => data.categoriesXaxis === this.x);
        if (energyData) {
          tooltipText +=
            "Energy Consumption: <b>" +
            energyData.EnergyConsumption +
            "</b><br/>";
        }

        return tooltipText;
      },
    },
    /*  legend: {
       layout: 'vertical',
       align: 'left',
       x: 80,
       verticalAlign: 'top',
       y: 55,
       floating: true,
       backgroundColor:
         Highcharts.defaultOptions.legend.backgroundColor || // theme
         'rgba(255,255,255,0.25)'
     }, */
    legend: {
      enabled: false, // Disable the legend
    },
    series: [
      {
        name: "No. of Transaction",
        type: "column",
        yAxis: 1,
        color: "green",
        data: noOfTransactions,
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Cost",
        type: "column",
        yAxis: 2,
        color: "deepskyblue",

        data: revenues,
        marker: {
          enabled: false,
        },
        dashStyle: "shortdot",
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Time Duration",
        type: "spline",
        color: "orange",
        data: timeDurations,
        tooltip: {
          valueSuffix: " h",
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              floating: false,
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
              x: 0,
              y: 0,
            },
            yAxis: [
              {
                labels: {
                  align: "right",
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                labels: {
                  align: "left",
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                visible: false,
              },
            ],
          },
        },
      ],
    },
    credits: {
      enabled: false,
    }, // Disable Highcharts.com link
    // exporting: {
    // 	buttons: {
    // 		customButton: {
    // 			className: 'myClass',
    // 			text: "Download ",
    // 			onclick: function () {
    // 				console.log(document.querySelector(".myClass"))
    // 			}
    // 		},
    // 	},
    // },
  };
  const heatMapOptions = {
    chart: {
      type: "heatmap",
      height: 498,
      // width:524

    },
    title: {
      text: "Connector Usage",
      style: {
        fontSize: "1em",
      },
    },
    xAxis: {
      categories: connectorLengthDynamic,
    },
    yAxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      title: null,
      reversed: true,
    },
    colorAxis: {
      visible: true, // remove sidebar indicator
      min: 0,
      minColor: "#FFF6F5",
      maxColor: "#FF7B5E",
      stops: [
        [0, "#FFF6F5"],
        [1, "#FF7B5E"],
      ],
    },
    legend: {
      align: "center",
      layout: "horizontal",
      margin: 0,
      verticalAlign: "bottom",
    },
    tooltip: {
      enabled: false,
      formatter: function () {
        return (
          `<b>${this.series.xAxis.categories[this.point.x]}` +
          `<b>${this.series.yAxis.categories[this.point.y]}</b>`
        );
      },
    },
    series: [
      {
        name: "Sales per employee",
        borderWidth: 0,
        data: heatmapDataArray,
        dataLabels: {
          enabled: true,
          color: "#000000",
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 300,
          },
          chartOptions: {
            yAxis: {
              labels: {
                formatter: function () {
                  return this.value.substring(0, 1);
                },
              },
            },
          },
        },
      ],
    },
    credits: {
      enabled: false,
    },
  };

  const pieOptions = {
    title: {
      text: "Connector Status",
      align: "center",


      style: {
        fontSize: "0.9rem",
        textWrap: "nowrap"

      },
    },
    chart: {
      renderTo: "container",
      type: "pie",
      height: 200,
      width: 0,
      events: {
        load: function () {
          // Remove any unnecessary text or arrows after chart is loaded
          this.series[0].update({
            dataLabels: {
              enabled: false, // Disable data labels (text inside slices)
            },
            marker: {
              enabled: false, // Disable markers (potentially removing arrows or other icons)
            },
          });
        },
      },
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      valueSuffix: " %",
      shared: true,
      useHTML: true,
      headerFormat: "",
      pointFormatter: function () {
        return (
          '<span style="color:' +
          this.color +
          '">\u25CF</span> ' +
          this.name +
          ": <b>" +
          this.y +
          "</b><br/>"
        );
      },
    },
    series: [
      {
        innerSize: "75%",
        data: [
          { name: "Available", y: availablePercentageData, color: "#77B347" },
          { name: "Charging", y: chargingPercentageData, color: "#F5BE4F" },
          { name: "Unknown", y: unknownPercentageData, color: "#94BBF6" },
          {
            name: "Unavailable",
            y: unAvailablePercentageData,
            color: "#ED6B60",
          },
          { name: "Preparing", y: preparingPercentageData, color: "#87E7FC" },
        ],
      },
    ],

    legend: {
      enabled: false,
    },

    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true, // Enable data labels (show percentages or values inside slices)
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
        showInLegend: false, // Hide the legend for pie slices
        size: "100%", // Adjust pie size
        innerSize: "60%", // Adjust inner size of the pie (donut chart style)
      },
    },
    colorAxis: {
      visible: false, // remove sidebar indicator
    },
    credits: {
      enabled: false,
    },
  };

  // Get Day and Week details when Month or Year changes
  useEffect(() => {
    if (
      userRole === "SuperAdmin" ||
      userRole === "BusinessOwner" ||
      userRole === "LocationManager"
    ) {
      if (
        selectedGroupYear &&
        selectedGroupMonth &&
        (activeButton === "day" || activeButton === "week")
      ) {
        // Set months
        const monthYearWiseDays = getDaysInMonth(
          +selectedGroupMonth?.id,
          selectedGroupYear?.value
        );
        setGroupOptionsDay(monthYearWiseDays);

        // Set weeks
        const monthYearWiseWeeks = getWeeksInfo(
          +selectedGroupMonth?.id,
          selectedGroupYear?.value
        );
        setGroupOptionsWeek(monthYearWiseWeeks);
        const currentWeekNumber = getWeekNumber(new Date());
        const currentWeekDetails = monthYearWiseWeeks.filter(
          (week) => week.weekNumber === currentWeekNumber
        )?.[0];
        if (currentWeekDetails) {
          setSelectedGroupWeek(currentWeekDetails);
        } else {
          setSelectedGroupWeek(monthYearWiseWeeks[0]);
        }
      }
    }
  }, [selectedGroupMonth, activeButton, selectedGroupYear, userRole]);

  useEffect(() => {

    let query = "?";
    if (
      selectedGroupDay &&
      selectedGroupMonth &&
      selectedGroupYear &&
      activeButton === "day"
    ) {
      query += `year=${selectedGroupYear?.value}&month=${selectedGroupMonth?.id}&day=${selectedGroupDay?.value}`;
    } else if (
      selectedGroupWeek &&
      selectedGroupMonth &&
      selectedGroupYear &&
      activeButton === "week"
    ) {
      query += `year=${selectedGroupYear?.value}&month=${selectedGroupMonth?.id}&startDate=${selectedGroupWeek?.startDate}&endDate=${selectedGroupWeek?.endDate}`;
    } else if (
      selectedGroupMonth &&
      selectedGroupYear &&
      activeButton === "month"
    ) {
      query += `year=${selectedGroupYear?.value}&month=${selectedGroupMonth?.id}`;
    } else if (selectedGroupYear && activeButton === "year") {
      query += `year=${selectedGroupYear?.value}`;
    }
    if (selectedBusiness?.id && userRole !== "SuperAdmin") {
      query += `&businessId=${+selectedBusiness?.id}`;
    }
    if (query) {
      if (userRole === "SuperAdmin") dispatch(getSuperAdminCustomer(query));
      else if (userRole === "BusinessOwner" && selectedBusiness?.id)
        dispatch(getBusinessLocation(query));
      else if (userRole === "LocationManager" && selectedBusiness?.id)
        dispatch(getLocationManager(query));
    }

  }, [
    selectedGroupMonth,
    selectedGroupDay,
    selectedGroupWeek,
    selectedGroupYear,
    selectedBusiness,
    activeButton,
    userRole,
  ]);

  useEffect(() => {
    dispatch(getSuperAdminDashboardYear());
  }, []);
  useEffect(() => {
    if (businessList.length > 0 && userRole !== "SuperAdmin") {
      setSelectedBusiness({
        id: businessList?.[0]?.businessId.toString(),
        value: businessList?.[0]?.businessName,
      });
    }
    if (businessList.length > 0) {
      const BusinessName = setBusiness();
      setBusinessName([...BusinessName]);

      setBusinessApi(businessList[0]?.businessName);
      setBusinessApiId(businessList[0]?.businessId);
      dispatch(
        getBusinessTransactionSA(
          `?businessId=${businessApiId}&dateFilter=${durationValue}`
        )
      );
    }
  }, [businessList.length]);

  const setBusiness = () => {
    const tempArray = [{ id: "0", value: "All", BusinessID: "All" }];
    const businessName = businessList.map((eve, ind) => {
      return {
        id: `${ind + 1}`,
        value: eve.businessName,
        BusinessID: eve.businessId,
      };
    });
    return businessName;
  };

  useEffect(() => {
    dispatch(listAllStation(`?locationId=`));
    dispatch(getListAllConnector());

    dispatch(dashboardDataSuccess());
    dispatch(getBusinessLogo());
    if (userRole !== "SuperAdmin") dispatch(allBusinessList("?id="));
    // if (userRole === 'LocationManager') dispatch(getLocationManager())
  }, []);

  // socketio implementation
  // useEffect(() => {
  //     if (fullState.Login.isLoggedIn == true) {
  //         // SocketController();
  //         socket.on('connect', () => {
  //
  //         });

  //         socket.on('disconnect', () => {
  //
  //         });

  //         socket.on('statusNotifications', res => {
  //             if (res) {
  //                 dispatch(socketData(JSON.parse(res)))
  //             }
  //         });

  //         socket.on('connect_error', error => {
  //
  //         });

  //         return () => {
  //             socket.off('connect');
  //             socket.off('disconnect');
  //             // socket.off('statusNotifications');
  //         };
  //     }
  // }, [fullState.Login.isLoggedIn]);

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("users")).userRole == "LocationManager"
    ) {
      let userID;
      if (
        window.localStorage.getItem("users") !== undefined &&
        window.localStorage.getItem("users") !== null
      ) {
        userID = JSON.parse(window.localStorage.getItem("users"))?.userId;
      } else if (
        window.sessionStorage.getItem("users") !== undefined &&
        window.sessionStorage.getItem("users") !== null
      ) {
        userID = JSON.parse(window.sessionStorage.getItem("users"))?.userId;
      }

      userID && dispatch(LocManBus(userID));
    }
  }, []);

  useEffect(() => {
    setHeaderHeight(
      document.getElementsByClassName("navbar-header")[0]?.offsetHeight
    );
    setFooterHeight(document.getElementsByClassName("footer")[0]?.offsetHeight);
    setTableTopCard(
      document.getElementsByClassName("lubiDashboardTbleViewTopHeader")[0]
        ?.offsetHeight
    );
    setPaginationDiv(
      document.getElementsByClassName("paginationDiv")[0]?.offsetHeight
    );
  }, []);

  const handleResize = () => {
    setHeaderHeight(
      document.getElementsByClassName("navbar-header")[0]?.offsetHeight
    );
    setFooterHeight(document.getElementsByClassName("footer")[0]?.offsetHeight);
    setTableTopCard(
      document.getElementsByClassName("lubiDashboardTbleViewTopHeader")[0]
        ?.offsetHeight
    );
    setPaginationDiv(
      document.getElementsByClassName("paginationDiv")[0]?.offsetHeight
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  // Column for Super Admin
  const columnSuperAdmin = useMemo(
    () => [
      // {
      // 	Header: (
      // 		<div className='d-flex align-items-center dashboardTableHeader'>
      // 			<span>
      // 				Registration <span className='dateBlock'>Date</span>
      // 			</span>
      // 			<button
      // 				className={
      // 					newest == true
      // 						? "registrationValueSort activeSet"
      // 						: newest === false ? "registrationValueSort oldest" : "registrationValueSort"
      // 				}
      // 				onClick={(e) => {
      // 					e.stopPropagation();
      // 					if (newest === null) {
      // 						setNewest(true)
      // 						setHighest(null)
      // 					} else {
      // 						setNewest(prev => !prev);
      // 					}
      // 				}}
      // 				type='button'>
      // 				{newest === null || newest ? "Newest" : "Oldest"}
      // 			</button>
      // 		</div>
      // 	),
      // 	accessor: "formattedCreatedAt",
      // 	filterable: true,
      // 	disableSortBy: true,
      // 	show: true,
      // 	Cell: (registrationDate) => {
      // 		return (
      // 			<p className='text textOverflow'>
      // 				{registrationDate?.value
      // 					? registrationDate?.value
      // 					: "No Date"}
      // 			</p>
      // 		);
      // 	},
      // },
      {
        Header: "Business",
        accessor: "businessName",
        filterable: true,
        disableSortBy: true,
        show: true,
        Cell: (business) => {
          return (
            <div className="managerBox">
              <p className="text textOverflow">
                {business?.row?.original?.businessName}
              </p>
              <p className="text textOverflow">
                {business?.row?.original?.businessId}
              </p>
            </div>
          );
        },
      },
      {
        Header: "Location",
        accessor: "locationName",
        filterable: true,
        show: true,
        disableSortBy: true,
        Cell: (cell) => {
          return (
            <div className="managerBox">
              <p className="text textOverflow">
                {cell?.value ? cell?.value : "-"}
              </p>
            </div>
          );
        },
      },
      {
        Header: (
          <div className="blockTextHeader">
            <span>Station</span>
          </div>
        ),
        accessor: "stationName",
        filterable: true,
        show: true,
        Cell: (cell) => {
          return (
            <div className="managerBox">
              <p className="text textOverflow">
                {cell?.value ? cell?.value : "-"}
              </p>
            </div>
          );
        },
      },
      {
        Header: (
          <div className="blockTextHeader">
            <span>Connector</span>
          </div>
        ),
        accessor: "connectorNumber",
        filterable: true,
        show: true,
        Cell: (business) => {
          return (
            <div className="managerBox">
              <p className="text textOverflow">
                {business?.row?.original?.connectorNumber
                  ? "Connector " +
                  business?.row?.original?.connectorNumber +
                  "(" +
                  business?.row?.original.connectorType +
                  ")"
                  : "-"}
              </p>
            </div>
          );
        },
      },
      {
        Header: (
          <div className="blockTextHeader">
            <span>Energy Consumption</span>
          </div>
        ),
        accessor: "totalUnit",
        filterable: true,
        show: true,
        Cell: (cell) => {
          return (
            <p className="text textOverflow">{`${cell?.value ? cell?.value : 0
              } kWh`}</p>
          );
        },
      },

      {
        Header: ({ rows }) => (
          <div className="d-flex dashBoardTableHeadingChanging">
            <span>No. of Charging</span>
            <span className="sessionCharginText">
              Sessions (
              {rows.reduce(
                (total, row) => total + row.original.sessionCount,
                0
              )}
              )
            </span>
          </div>
        ),
        accessor: "sessionCount",
        filterable: true,
        show: true,
        Cell: (cell) => {
          return (
            <p className="text textOverflow">{cell?.value ? cell?.value : 0}</p>
          );
        },
      },
      {
        Header: ({ rows }) => (
          <div className="d-flex align-items-center dashBoardTableHeadingRevenue">
            <p>
              <span>Revenue</span>
              <span className="highestRevenueTextCount">
                ₹
                {formatPrice(
                  rows.reduce(
                    (total, row) => total + row.original.totalRevenue,
                    0
                  )
                )}
              </span>
            </p>
            <button
              className={
                highest === true
                  ? "highestRevenueText activeSet"
                  : highest === false
                    ? "highestRevenueText lowest"
                    : "highestRevenueText"
              }
              onClick={(e) => {
                e.stopPropagation();
                if (highest === null) {
                  setHighest(true);
                  setNewest(null);
                } else {
                  setHighest((prev) => !prev);
                }
              }}
              type="button"
            >
              {highest === null || highest ? "Highest" : "Lowest"}
            </button>
          </div>
        ),
        accessor: "totalRevenue",
        filterable: true,
        show: true,
        disableSortBy: true,
        Cell: (cell) => {
          return (
            <p className="text textOverflow">
              ₹{cell?.value ? cell?.value : 0}
            </p>
          );
        },
      },
      {
        Header: (
          <div className="blockTextHeader">
            <span>Duration</span>
          </div>
        ),
        accessor: "duration",
        filterable: true,
        show: true,
        Cell: (business) => {
          return (
            <div className="managerBox">
              <p className="text textOverflow">
                {business?.row?.original?.formattedCreatedAt}
              </p>
              <p className="text textOverflow">
                {business?.row?.original?.duration
                  ? business?.row?.original?.duration?.split(":")[0] +
                  " Hours" +
                  " " +
                  business?.row?.original?.duration?.split(":")[1] +
                  " Minutes"
                  : "-"}
              </p>
            </div>
          );
        },
      },
    ],
    [newest, highest]
  );

  // Column for Business Owner
  const columnsBusinessOwner = useMemo(
    () => [
      {
        Header: "Location Details",
        filterable: true,
        disableSortBy: true,
        show: true,
        Cell: (location) => {
          return (
            <div className="managerBox">
              <p className="text textOverflow">
                {location?.row?.original?.locationName}
              </p>
              <p className="text textOverflow">
                {location?.row?.original?.locationStation || "-"}
              </p>
            </div>
          );
        },
      },
      {
        Header: (
          <div className="blockTextHeader">
            <span>Station</span>
          </div>
        ),
        accessor: "stationName",
        filterable: true,
        show: true,
        Cell: (cell) => {
          return (
            <div className="managerBox">
              <p className="text textOverflow">
                {cell?.value ? cell?.value : "-"}
              </p>
            </div>
          );
        },
      },
      {
        Header: (
          <div className="blockTextHeader">
            <span>Connector</span>
          </div>
        ),
        accessor: "connectorNumber",
        filterable: true,
        show: true,
        Cell: (business) => {
          return (
            <div className="managerBox">
              <p className="text textOverflow">
                {business?.row?.original?.connectorNumber
                  ? "Connector " +
                  business?.row?.original?.connectorNumber +
                  "(" +
                  business?.row?.original.connectorType +
                  ")"
                  : "-"}
              </p>
            </div>
          );
        },
      },
      {
        Header: (
          <div className="blockTextHeader">
            <span>Energy Consumption</span>
          </div>
        ),
        accessor: "totalUnit",
        filterable: true,
        show: true,
        Cell: (cell) => {
          return (
            <p className="text textOverflow">{`${cell?.value ? cell?.value : 0
              } kW`}</p>
          );
        },
      },
      {
        Header: ({ rows }) => (
          <div className="d-flex dashBoardTableHeadingChanging">
            <span>No. of Charging</span>
            <span className="sessionCharginText">
              Sessions (
              {rows.reduce(
                (total, row) => total + row.original.sessionCount,
                0
              )}
              )
            </span>
          </div>
        ),
        accessor: "sessionCount",
        filterable: true,
        show: true,
        Cell: (cell) => {
          return (
            <p className="text textOverflow">{cell?.value ? cell?.value : 0}</p>
          );
        },
      },
      {
        Header: ({ rows }) => (
          <div className="d-flex align-items-center dashBoardTableHeadingRevenue">
            <p>
              <span>Revenue</span>
              <span className="highestRevenueTextCount">
                ₹
                {formatPrice(
                  rows.reduce(
                    (total, row) => total + row.original.totalRevenue,
                    0
                  )
                )}
              </span>
            </p>
            <button
              className={
                highest === true
                  ? "highestRevenueText activeSet"
                  : highest === false
                    ? "highestRevenueText lowest"
                    : "highestRevenueText"
              }
              onClick={(e) => {
                e.stopPropagation();
                if (highest === null) {
                  setHighest(true);
                } else {
                  setHighest((prev) => !prev);
                }
              }}
              type="button"
            >
              {highest === null || highest ? "Highest" : "Lowest"}
            </button>
          </div>
        ),
        accessor: "totalRevenue",
        filterable: true,
        show: true,
        disableSortBy: true,
        Cell: (cell) => {
          return (
            <p className="text textOverflow">
              ₹{cell?.value ? cell?.value : 0}
            </p>
          );
        },
      },

      {
        Header: (
          <div className="blockTextHeader">
            <span>Duration</span>
          </div>
        ),
        accessor: "duration",
        filterable: true,
        show: true,
        Cell: (business) => {
          return (
            <div className="managerBox">
              <p className="text textOverflow">
                {business?.row?.original?.formattedCreatedAt}
              </p>
              <p className="text textOverflow">
                {business?.row?.original?.duration
                  ? business?.row?.original?.duration?.split(":")[0] +
                  " Hours" +
                  " " +
                  business?.row?.original?.duration?.split(":")[1] +
                  " Minutes"
                  : "-"}
              </p>
            </div>
          );
        },
      },
    ],
    [highest]
  );

  // Column for Business Owner
  const columnsLocationManager = useMemo(
    () => [
      {
        Header: "Location Details",
        filterable: true,
        disableSortBy: true,
        show: true,
        Cell: (location) => {
          return (
            <div className="managerBox">
              <p className="text textOverflow">
                {location?.row?.original?.locationName}
              </p>
              <p className="text textOverflow">
                {location?.row?.original?.locationStation || "-"}
              </p>
            </div>
          );
        },
      },
      {
        Header: (
          <div className="blockTextHeader">
            <span>Station</span>
          </div>
        ),
        accessor: "stationName",
        filterable: true,
        show: true,
        Cell: (cell) => {
          return (
            <div className="managerBox">
              <p className="text textOverflow">
                {cell?.value ? cell?.value : "-"}
              </p>
            </div>
          );
        },
      },
      {
        Header: (
          <div className="blockTextHeader">
            <span>Connector</span>
          </div>
        ),
        accessor: "connectorNumber",
        filterable: true,
        show: true,
        Cell: (business) => {
          return (
            <div className="managerBox">
              <p className="text textOverflow">
                {business?.row?.original?.connectorNumber
                  ? "Connector " +
                  business?.row?.original?.connectorNumber +
                  "(" +
                  business?.row?.original.connectorType +
                  ")"
                  : "-"}
              </p>
            </div>
          );
        },
      },
      {
        Header: (
          <div className="blockTextHeader">
            <span>Energy Consumption</span>
          </div>
        ),
        accessor: "totalUnit",
        filterable: true,
        show: true,
        Cell: (cell) => {
          return (
            <p className="text textOverflow">{`${cell?.value ? cell?.value : 0
              } kW`}</p>
          );
        },
      },
      {
        Header: ({ rows }) => (
          <div className="d-flex dashBoardTableHeadingChanging">
            <span>No. of Charging</span>
            <span className="sessionCharginText">
              Sessions (
              {rows.reduce(
                (total, row) => total + row.original.sessionCount,
                0
              )}
              )
            </span>
          </div>
        ),
        accessor: "sessionCount",
        filterable: true,
        show: true,
        Cell: (cell) => {
          return (
            <p className="text textOverflow">{cell?.value ? cell?.value : 0}</p>
          );
        },
      },
      {
        Header: ({ rows }) => (
          <div className="d-flex align-items-center dashBoardTableHeadingRevenue">
            <p>
              <span>Revenue</span>
              <span className="highestRevenueTextCount">
                ₹
                {formatPrice(
                  rows.reduce(
                    (total, row) => total + row.original.totalRevenue,
                    0
                  )
                )}
              </span>
            </p>
            <button
              className={
                highest === true
                  ? "highestRevenueText activeSet"
                  : highest === false
                    ? "highestRevenueText lowest"
                    : "highestRevenueText"
              }
              onClick={(e) => {
                e.stopPropagation();
                if (highest === null) {
                  setHighest(true);
                } else {
                  setHighest((prev) => !prev);
                }
              }}
              type="button"
            >
              {highest === null || highest ? "Highest" : "Lowest"}
            </button>
          </div>
        ),
        accessor: "totalRevenue",
        filterable: true,
        show: true,
        disableSortBy: true,
        Cell: (cell) => {
          return (
            <p className="text textOverflow">
              ₹{cell?.value ? cell?.value : 0}
            </p>
          );
        },
      },

      {
        Header: (
          <div className="blockTextHeader">
            <span>Duration</span>
          </div>
        ),
        accessor: "duration",
        filterable: true,
        show: true,
        Cell: (business) => {
          return (
            <div className="managerBox">
              <p className="text textOverflow">
                {business?.row?.original?.formattedCreatedAt}
              </p>
              <p className="text textOverflow">
                {business?.row?.original?.duration
                  ? business?.row?.original?.duration?.split(":")[0] +
                  " Hours" +
                  " " +
                  business?.row?.original?.duration?.split(":")[1] +
                  " Minutes"
                  : "-"}
              </p>
            </div>
          );
        },
      },
    ],
    [highest]
  );
  useEffect(() => {
    if (buttonSelectedValue === "Business") {
      dispatch(allBusinessList("?id="));
    }

    if (buttonSelectedValue === "Location") {
      dispatch(allLocation("?id="));
    }
    if (buttonSelectedValue === "Station") {
      dispatch(listAllStation(`?locationId=`));
    }
    if (buttonSelectedValue === "Connector") {
      dispatch(getListAllConnector());
    }
  }, [buttonSelectedValue]);

  useEffect(() => {
    if (userRole == "LocationManager") {
      setStationHeatmap(ListAllStations[0]?.stationName)
      dispatch(allConnectorStationId(`?stationId=${ListAllStations[0]?.stationId}`));

      dispatch(getHeatmaptData(`?stationId=${ListAllStations[0]?.stationId}`));
    }
  }, [ListAllStations]);
  useEffect(() => {
    setLocationFilterData(
      locationList?.map((item) => {
        return {
          id: item.locationId.toString(),
          value: item.locationName,
        };
      })
    );

    const setLocationFilter = () => {
      const TempList = ListAllStations?.map((eve, ind) => {
        return {
          id: eve.stationId.toString(),
          value: eve.stationName,
        };
      });
      return TempList;
    };
    const LocationT = setLocationFilter();
    setStationFilterData(LocationT);
  }, [businessList, locationList, ListAllStations, listAllConnector]);

  // Ensure that `useEffect` depends on `buttonSelectedValue` changes
  // Function to generate dropdown list based on selected button
  const getDropdownItems = () => {
    switch (buttonSelectedValue) {
      case "Business":
        return businessList.map((item) => ({
          id: `${item.businessId}`, // Ensure unique keys by prefixing
          value: item.businessName,
          bId: item.businessId,
        }));
      case "Location":
        return locationList.map((item) => ({
          id: `${item.locationId}`, // Ensure unique keys by prefixing
          value: item.locationName,
          bId: item.businessId,
        }));
      case "Station":
        return ListAllStations.map((item) => ({
          id: `${item.stationId}`, // Ensure unique keys by prefixing
          value: item.stationName,
          bId: item.businessId,
        }));
      case "Connector":
        return listAllConnector?.map((item, index) => ({
          id: `${item.connectorId}-${index}`, // Ensure unique keys by combining identifiers
          value: item.stationConnector,
          bId: item.businessId,
        }));
      default:
        return [];
    }
  };

  useEffect(() => {
    let updatedList = getDropdownItems();
    updatedList = updatedList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.place === value.place && t.value === value.value
        )
    );
    setnewDropDownList(updatedList);
    if (!superAdminFilterModel) {
      if (updatedList.length > 0) {
        setBusinessApi(updatedList[0].value);
        dispatch(
          getBusinessTransactionSA(
            `?${buttonSelectedValue.toLowerCase()}Id=${updatedList[0].id.split("-")[0]
            }&dateFilter=${durationValue}`
          )
        );

        // if (buttonSelectedValue == 'Connector') {
        // 	dispatch(getBusinessTransactionSA(`?${buttonSelectedValue.toLowerCase()}Id=${updatedList[0].id.split('-')[0]}&dateFilter=${durationValue}`));

        // } else {

        // 	dispatch(getBusinessTransactionSA(`?${buttonSelectedValue.toLowerCase()}Id=${updatedList[0].id}&dateFilter=${durationValue}`));
        // }
      }
    }
  }, [
    buttonSelectedValue,
    businessList,
    locationList,
    ListAllStations,
    listAllConnector,
  ]);
  // [
  //   "Connector 1",
  //   "Connector 2",
  //   "Connector 3",
  //   "Connector 4",
  //   "Connector 5",
  // ]


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="dashboardLubi">
            <Row className="dashboardLubiTopCard">
              {window.localStorage.getItem("users") !== undefined &&
                window.localStorage.getItem("users") !== null &&
                JSON.parse(window.localStorage.getItem("users")).userRole ==
                "LocationManager" ? (
                <div className="dashboardLubiheader">
                  <div>
                    <div className="card-box">
                      <Col
                        lg={4}
                        md={6}
                        sm={12}
                        className="dashboardLubiTopCardCol"
                      >
                        <Card>
                          <CardBody>
                            <div className="totalBusinessesTitle">
                              Total Stations
                            </div>
                            <div className="totalBusinessesRow">
                              <div className="totalBusinessesColLeft">
                                <h4 className="totalBusinessesCountText">
                                  {data?.totalStationCounts
                                    ? data?.totalStationCounts
                                    : 0}
                                </h4>
                              </div>
                              <div className="totalBusinessesColRight">
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Active Stations
                                  </small>
                                  <div className="totalBusinessesCountBoxCard">
                                    {data?.activeStationCounts
                                      ? data?.activeStationCounts
                                      : 0}
                                  </div>
                                </div>
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Inactive Stations
                                  </small>
                                  <div className="totalBusinessesCountBoxCard color2">
                                    {data?.inactiveStationCounts
                                      ? data?.inactiveStationCounts
                                      : 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col
                        lg={4}
                        md={6}
                        sm={12}
                        className="dashboardLubiTopCardCol"
                      >
                        <Card>
                          <CardBody>
                            <div className="totalBusinessesTitle">
                              Total Connectors
                            </div>
                            <div className="totalBusinessesRow">
                              <div className="totalBusinessesColLeft">
                                {/* {console.log(listAllConnector, 'listAllConnector')} */}
                                <h4 className="totalBusinessesCountText">
                                  {data?.totalConnectorCounts
                                    ? data?.totalConnectorCounts
                                    : 0}
                                </h4>
                              </div>
                              <div className="totalBusinessesColRight">
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Active Connectors
                                  </small>
                                  <div className="totalBusinessesCountBoxCard">
                                    {data?.activeConnectorCounts
                                      ? data?.activeConnectorCounts
                                      : 0}
                                  </div>
                                </div>
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Inactive Connectors
                                  </small>
                                  <div className="totalBusinessesCountBoxCard color2">
                                    {data?.inactiveConnectorCounts
                                      ? data?.inactiveConnectorCounts
                                      : 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </div>
                    <div>

                      {userRole !== undefined && userRole === "LocationManager" ? (
                        <div className="chart-div" style={{
                          display: "flex", background: "white",
                          width: "548px", alignItems: "center", textWrap: "nowrap"
                        }}>

                          <HighchartsReact
                            highcharts={Highcharts}
                            options={pieOptions}
                          />

                          <div
                            className="chart-details"
                          >
                            <div
                              className="marginLeft"
                              style={{ margin: "0 0", }}
                            >
                              <p>
                                <svg height="20" width="20">
                                  <circle cx="8" cy="8" r="8" fill="#77B347" />
                                </svg>
                                Available
                              </p>
                              <span>{availablePercentageData}%</span>
                              <p>
                                {availableCountData} / {totalCountData}
                              </p>
                            </div>
                            <div
                              className="marginLeft"
                              style={{ margin: "0 0", }}
                            >
                              <p>
                                <svg height="20" width="20">
                                  <circle cx="8" cy="8" r="8" fill="#F5BE4F" />
                                </svg>
                                Charging
                              </p>
                              <span>{chargingPercentageData}%</span>
                              <p>
                                {chargingCountData} / {totalCountData}
                              </p>
                            </div>
                            <div
                              className="marginLeft"
                              style={{ margin: "0 0", }}
                            >
                              <p>
                                <svg height="20" width="20">
                                  <circle cx="8" cy="8" r="8" fill="#94BBF6" />
                                </svg>
                                Unknown
                              </p>
                              <span>{unknownPercentageData}%</span>
                              <p>
                                {unknownCountData} / {totalCountData}
                              </p>
                            </div>
                            <div
                              className="marginLeft"
                              style={{ margin: "0 0", }}
                            >
                              <p>
                                <svg height="20" width="20">
                                  <circle cx="8" cy="8" r="8" fill="#ED6B60" />
                                </svg>
                                Unavailable
                              </p>
                              <span>{unAvailablePercentageData}%</span>
                              <p>
                                {unAvailableCountData} / {totalCountData}
                              </p>
                            </div>
                            <div
                              className="marginLeft"
                              style={{ margin: "0 0", }}
                            >
                              <p>
                                <svg height="20" width="20">
                                  <circle cx="8" cy="8" r="8" fill="#87E7FC" />
                                </svg>
                                Preparing
                              </p>
                              <span>{preparingPercentageData}%</span>
                              <p>
                                {preparingCountData} / {totalCountData}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <br></br>
                      <></>
                      <></>
                    </div>

                  </div>
                  <div style={{ width: "100%" }}>
                    {userRole !== undefined &&
                      userRole === "LocationManager" && heatmapDataArray.length > 0 ? (
                      <div style={{ background: 'white' }}>
                        <Row
                          className="dashboardLubiTopCard"
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            margin: "5px"
                          }}
                        >
                          <Col lg={3} md={3} className="dropDownBusiness">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label"
                            >
                              Station
                            </Label>
                            <CustomDropdown
                              className="allDropdowns"
                              moreValue={true}
                              dropDownItems={stationFilterData}
                              defaultValue={stationHeatmap}
                              placeholder={"Choose Station"}
                              selectedValue={stationHeatmap}
                              showSecondVal={false}
                              onSelect={(evt) => {
                                // setFilterstationId(evt.id);
                                setStationHeatmap(evt.value);
                                dispatch(allConnectorStationId(`?stationId=${evt?.id}`));
                                dispatch(getHeatmaptData(`?stationId=${evt?.id}`));

                                // dispatch(allConnectorStationId(`?stationId=${evt.id}`));
                              }}
                            />
                          </Col>
                        </Row>
                        <HighchartsReact
                          containerProps={{ style: { height: "100%" } }}
                          highcharts={Highcharts}
                          options={heatMapOptions}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {/* <Col
                    lg={4}
                    md={6}
                    sm={12}
                    className='dashboardLubiTopCardCol'>
                    <Card>
                      <CardBody>
                        <div className='totalBusinessesTitle'>
                          Total Session
                        </div>
                        <div className='totalBusinessesRow'>
                          <div className='totalBusinessesColLeft'>
                            <h4 className='totalBusinessesCountText'>
                              {countSessionConnector?.sessionCount
                                ? countSessionConnector?.sessionCount
                                : 0}
                            </h4>
                          </div>

                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    lg={4}
                    md={6}
                    sm={12}
                    className='dashboardLubiTopCardCol'>
                    <Card>
                      <CardBody>
                        <div className='totalBusinessesTitle'>
                          Total Collection
                        </div>
                        <div className='totalBusinessesRow'>
                          <div className='totalBusinessesColLeft'>
                            <h4 className='totalBusinessesCountText'>
                              {stationDetails?.length > 0
                                ? '₹' + formatPrice(stationDetails?.reduce((total, val) => val.totalRevenue + total, 0))
                                : '₹' + 0}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col> */}
                </div>
              ) : (
                <>
                  {window.localStorage.getItem("users") !== undefined &&
                    window.localStorage.getItem("users") !== null &&
                    JSON.parse(window.localStorage.getItem("users")).userRole ==
                    "BusinessOwner" ? (
                    <>
                      <Col lg={4} md={6} className="dashboardLubiTopCardCol">
                        <Card>
                          <CardBody>
                            <div className="totalBusinessesTitle">
                              Total Locations
                            </div>
                            <div className="totalBusinessesRow">
                              <div className="totalBusinessesColLeft">
                                <h4 className="totalBusinessesCountText">
                                  {data?.totalLocationCounts
                                    ? data?.totalLocationCounts
                                    : 0}
                                </h4>
                              </div>
                              <div className="totalBusinessesColRight">
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Active Locations
                                  </small>
                                  <div className="totalBusinessesCountBoxCard">
                                    {data?.activeLocationCounts
                                      ? data?.activeLocationCounts
                                      : 0}
                                  </div>
                                </div>
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Inactive Locations
                                  </small>
                                  <div className="totalBusinessesCountBoxCard color2">
                                    {data?.inactiveLocationCounts
                                      ? data?.inactiveLocationCounts
                                      : 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={4} md={6} className="dashboardLubiTopCardCol">
                        <Card>
                          <CardBody>
                            <div className="totalBusinessesTitle">
                              Total Stations
                            </div>
                            <div className="totalBusinessesRow">
                              <div className="totalBusinessesColLeft">
                                <h4 className="totalBusinessesCountText">
                                  {data?.totalStationCounts
                                    ? data?.totalStationCounts
                                    : 0}
                                </h4>
                              </div>
                              <div className="totalBusinessesColRight">
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Active Stations
                                  </small>
                                  <div className="totalBusinessesCountBoxCard">
                                    {data?.activeStationCounts
                                      ? data?.activeStationCounts
                                      : 0}
                                  </div>
                                </div>
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Inactive Stations
                                  </small>
                                  <div className="totalBusinessesCountBoxCard color2">
                                    {data?.inActiveStationCounts
                                      ? data?.inActiveStationCounts
                                      : 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={4} md={6} className="dashboardLubiTopCardCol">
                        <Card>
                          <CardBody>
                            <div className="totalBusinessesTitle">
                              Total Connectors
                            </div>
                            <div className="totalBusinessesRow">
                              <div className="totalBusinessesColLeft">
                                <h4 className="totalBusinessesCountText">
                                  {data?.totalConnectorCounts
                                    ? data?.totalConnectorCounts
                                    : 0}
                                </h4>
                              </div>
                              <div className="totalBusinessesColRight">
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Active Connectors
                                  </small>
                                  <div className="totalBusinessesCountBoxCard">
                                    {data?.activeConnectorCounts
                                      ? data?.activeConnectorCounts
                                      : 0}
                                  </div>
                                </div>
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Inactive Connectors
                                  </small>
                                  <div className="totalBusinessesCountBoxCard color2">
                                    {data?.inActiveConnectorCounts
                                      ? data?.inActiveConnectorCounts
                                      : 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col lg={3} md={3} className="dashboardLubiTopCardCol">
                        <Card>
                          <CardBody>
                            <div className="totalBusinessesTitle">
                              Total Businesses
                            </div>
                            <div className="totalBusinessesRow">
                              <div className="totalBusinessesColLeft">
                                <h4 className="totalBusinessesCountText">
                                  {data?.totalBusinessCounts
                                    ? data?.totalBusinessCounts
                                    : 0}
                                </h4>
                              </div>
                              <div className="totalBusinessesColRight">
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Active Business
                                  </small>
                                  <div className="totalBusinessesCountBoxCard">
                                    {data?.activeBusinessCounts
                                      ? data?.activeBusinessCounts
                                      : 0}
                                  </div>
                                </div>
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Inactive Business
                                  </small>
                                  <div className="totalBusinessesCountBoxCard color2">
                                    {data?.inactiveBusinessCounts
                                      ? data?.inactiveBusinessCounts
                                      : 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={3} md={3} className="dashboardLubiTopCardCol">
                        <Card>
                          <CardBody>
                            <div className="totalBusinessesTitle">
                              Total Locations
                            </div>
                            <div className="totalBusinessesRow">
                              <div className="totalBusinessesColLeft">
                                <h4 className="totalBusinessesCountText">
                                  {data?.totalLocationCounts
                                    ? data?.totalLocationCounts
                                    : 0}
                                </h4>
                              </div>
                              <div className="totalBusinessesColRight">
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Active Locations
                                  </small>
                                  <div className="totalBusinessesCountBoxCard">
                                    {data?.activeLocationCounts
                                      ? data?.activeLocationCounts
                                      : 0}
                                  </div>
                                </div>
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Inactive Locations
                                  </small>
                                  <div className="totalBusinessesCountBoxCard color2">
                                    {data?.inactiveLocationCounts
                                      ? data?.inactiveLocationCounts
                                      : 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={3} md={3} className="dashboardLubiTopCardCol">
                        <Card>
                          <CardBody>
                            <div className="totalBusinessesTitle">
                              Total Stations
                            </div>
                            <div className="totalBusinessesRow">
                              <div className="totalBusinessesColLeft">
                                <h4 className="totalBusinessesCountText">
                                  {data?.totalStationCounts
                                    ? data?.totalStationCounts
                                    : 0}
                                </h4>
                              </div>
                              <div className="totalBusinessesColRight">
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Active Stations
                                  </small>
                                  <div className="totalBusinessesCountBoxCard">
                                    {data?.activeStationCounts
                                      ? data?.activeStationCounts
                                      : 0}
                                  </div>
                                </div>
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Inactive Stations
                                  </small>
                                  <div className="totalBusinessesCountBoxCard color2">
                                    {data?.inActiveStationCounts
                                      ? data?.inActiveStationCounts
                                      : 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={3} md={3} className="dashboardLubiTopCardCol">
                        <Card>
                          <CardBody>
                            <div className="totalBusinessesTitle">
                              Total Connectors
                            </div>
                            <div className="totalBusinessesRow">
                              <div className="totalBusinessesColLeft">
                                <h4 className="totalBusinessesCountText">
                                  {data?.totalConnectorCounts
                                    ? data?.totalConnectorCounts
                                    : 0}
                                </h4>
                              </div>
                              <div className="totalBusinessesColRight">
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Active Connectors
                                  </small>
                                  <div className="totalBusinessesCountBoxCard">
                                    {data?.activeConnectorCounts
                                      ? data?.activeConnectorCounts
                                      : 0}
                                  </div>
                                </div>
                                <div className="totalBusinessesColRightInner">
                                  <small className="title">
                                    Inactive Connectors
                                  </small>
                                  <div className="totalBusinessesCountBoxCard color2">
                                    {data?.inActiveConnectorCounts
                                      ? data?.inActiveConnectorCounts
                                      : 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </>
                  )}
                </>
              )}

            </Row>



            <div
              className="lubiDashboardTbleView"
              style={{ background: "white", padding: "15px" }}
            >
              <Row
                className="dashboardLubiTopCard"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <Col lg={3} md={3} className="dropDownBusiness">
                  <Label
                    htmlFor="choices-single-default"
                    className="form-label"
                  >
                    {buttonSelectedValue}
                  </Label>
                  <CustomDropdown
                    placeholder="Business Name"
                    dropDownItems={newDropDownList}
                    selectedValue={businessApi}
                    defaultValue={businessApi}
                    onSelect={(evt) => {
                      setBusinessApi(evt.value);
                      setBusinessApiidcheck(evt.id);
                      setBusinessApiId(evt.bId);

                      if (buttonSelectedValue === "Business") {
                        dispatch(
                          getBusinessTransactionSA(
                            `?businessId=${evt.id.split("-")[0]
                            }&dateFilter=${durationValue}`
                          )
                        );
                      }

                      if (buttonSelectedValue === "Location") {
                        dispatch(
                          getBusinessTransactionSA(
                            `?locationId=${evt.id.split("-")[0]
                            }&dateFilter=${durationValue}`
                          )
                        );
                      }
                      if (buttonSelectedValue === "Station") {
                        dispatch(
                          getBusinessTransactionSA(
                            `?stationId=${evt.id.split("-")[0]
                            }&dateFilter=${durationValue}`
                          )
                        );
                      }
                      if (buttonSelectedValue === "Connector") {
                        dispatch(
                          getBusinessTransactionSA(
                            `?connectorId=${evt.id.split("-")[0]
                            }&dateFilter=${durationValue}`
                          )
                        );
                      }
                    }}
                  />
                </Col>
                <Col lg={3} md={3} className="dropDownBusiness">
                  <Label
                    htmlFor="choices-single-default"
                    className="form-label"
                  >
                    Duration
                  </Label>
                  <CustomDropdown
                    placeholder="Business Name"
                    selectedValue={durationValue}
                    defaultValue={durationValue}
                    onSelect={(evt) => {
                      setDurationValue(evt.value);

                      let businessIdNew =
                        businessApiidcheck === 0
                          ? businessApiId
                          : businessApiidcheck.split("-")[0];
                      if (buttonSelectedValue === "Business") {
                        dispatch(
                          getBusinessTransactionSA(
                            `?businessId=${businessIdNew}&dateFilter=${evt.value}`
                          )
                        );
                      }

                      if (buttonSelectedValue === "Location") {
                        dispatch(
                          getBusinessTransactionSA(
                            `?locationId=${businessIdNew}&dateFilter=${evt.value}`
                          )
                        );
                      }
                      if (buttonSelectedValue === "Station") {
                        dispatch(
                          getBusinessTransactionSA(
                            `?stationId=${businessIdNew}&dateFilter=${evt.value}`
                          )
                        );
                      }
                      if (buttonSelectedValue === "Connector") {
                        dispatch(
                          getBusinessTransactionSA(
                            `?connectorId=${businessIdNew}&dateFilter=${evt.value}`
                          )
                        );
                      }

                    }}
                    dropDownItems={durationArray}
                  />
                </Col>
              </Row>

              <Row
                className="dashboardLubiTopCard"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <Col lg={3} md={3} className="dropDownBusiness">
                  <Button
                    type="button"
                    className={
                      buttonSelectedValue == "Business"
                        ? "setButtonFilter active"
                        : "setButtonFilter"
                    }
                    onClick={() => {
                      setButtonSelectedValue("Business");
                    }}
                  >
                    Business
                  </Button>
                  <Button
                    type="button"
                    className={
                      buttonSelectedValue == "Location"
                        ? "setButtonFilter active"
                        : "setButtonFilter"
                    }
                    onClick={() => {
                      setButtonSelectedValue("Location");
                    }}
                  >
                    Location
                  </Button>

                  <Button
                    type="button"
                    className={
                      buttonSelectedValue == "Station"
                        ? "setButtonFilter active"
                        : "setButtonFilter"
                    }
                    onClick={() => {
                      setButtonSelectedValue("Station");
                    }}
                  >
                    Station
                  </Button>

                  <Button
                    type="button"
                    className={
                      buttonSelectedValue == "Connector"
                        ? "setButtonFilter active"
                        : "setButtonFilter"
                    }
                    onClick={() => {
                      setButtonSelectedValue("Connector");
                    }}
                  >
                    Connector
                  </Button>
                </Col>

              </Row>

              <div className="dashboardLubiTitleSection">
                <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                  <span style={{ marginRight: "15px" }}>Legends:</span>
                  <span style={{ marginRight: "15px" }}>
                    <svg height="20" width="20">
                      <rect width="15" height="15" fill="deepskyblue" />


                    </svg>
                    Revenue
                  </span>
                  <span style={{ marginRight: "15px" }}>
                    <svg height="20" width="20">

                      <circle cx="8" cy="8" r="8" fill="green" />
                    </svg>
                    No. of Transactions
                  </span>
                  <span style={{ marginRight: "15px" }}>
                    <svg height="20" width="20">
                      <rect width="15" height="15" fill="orange" />
                    </svg>
                    Time Duration
                  </span>
                </div>

                {businessTransactionSA.length === 0 ? (
                  <div
                    style={{
                      margin: "50px 0",
                      textAlign: "center",
                      fontSize: "20px",
                      color: "black",
                    }}
                  >
                    No Data Found.
                  </div>
                ) : (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={highChartOptions}
                  />
                )}
              </div>
            </div>
            <div></div>
            <div
              className="dashboardLubiTitleSection"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              <h2 className="mainTitle">
                {userRole === "LocationManager"
                  ? "Current State"
                  : "Statistics"}
              </h2>
            </div>


            {userRole !== undefined && userRole === "LocationManager" ? (
              <div className="lubiDashboardTbleView">
                <Card>
                  <CardBody>
                    <>
                      <div className="lubiDashboardTbleViewTopHeader">
                        <div className="lubiDashboardTbleViewTopHeaderListLeft">
                          <button
                            className={
                              activeButton === "day"
                                ? "tabblingClickButton active"
                                : "tabblingClickButton"
                            }
                            type="button"
                            onClick={() => setActiveButton("day")}
                          >
                            Day
                          </button>
                          <button
                            className={
                              activeButton === "week"
                                ? "tabblingClickButton active"
                                : "tabblingClickButton"
                            }
                            type="button"
                            onClick={() => {
                              setActiveButton("week");
                            }}
                          >
                            Week
                          </button>
                          <button
                            className={
                              activeButton === "month"
                                ? "tabblingClickButton active"
                                : "tabblingClickButton"
                            }
                            type="button"
                            onClick={() => {
                              setActiveButton("month");
                            }}
                          >
                            Month
                          </button>
                          <button
                            className={
                              activeButton === "year"
                                ? "tabblingClickButton active"
                                : "tabblingClickButton"
                            }
                            type="button"
                            onClick={() => {
                              setActiveButton("year");
                            }}
                          >
                            Year
                          </button>
                          <Button
                            type="button"
                            className="filterBtn btn"
                            onClick={() => {
                              setSuperAdminFilterModel(true);
                            }}
                          >
                            <i className="bx bx-filter-alt fs-18" />
                          </Button>
                        </div>

                        <div className="lubiDashboardTbleViewTopHeaderListRight">
                          {activeButton === "day" ? (
                            <div className="lubiDashboardTbleViewFullYear">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label"
                              >
                                Day
                              </Label>
                              <CustomDropdown
                                placeholder="Day"
                                selectedValue={selectedGroupDay}
                                onSelect={(evt) => {
                                  setSelectedGroupDay(evt);
                                }}
                                dropDownItems={GroupOptionsDay}
                              />
                            </div>
                          ) : null}
                          {activeButton === "week" ? (
                            <div className="lubiDashboardTbleViewFullYear">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label"
                              >
                                Week
                              </Label>
                              <CustomDropdown
                                placeholder="Week"
                                selectedValue={selectedGroupWeek}
                                onSelect={(evt) => {
                                  setSelectedGroupWeek(evt);
                                }}
                                dropDownItems={GroupOptionsWeek}
                              />
                            </div>
                          ) : null}
                          {activeButton === "year" ? null : (
                            <div className="lubiDashboardTbleViewFullYear">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label"
                              >
                                Month
                              </Label>
                              <CustomDropdown
                                placeholder="Month"
                                selectedValue={selectedGroupMonth}
                                onSelect={(evt) => {
                                  setSelectedGroupMonth(evt);
                                }}
                                dropDownItems={GroupOptionsMonth}
                              />
                            </div>
                          )}
                          <div className="lubiDashboardTbleViewFullYear">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label"
                            >
                              Year
                            </Label>
                            <CustomDropdown
                              placeholder="Year"
                              selectedValue={selectedGroupYear}
                              onSelect={(evt) => {
                                setSelectedGroupYear(evt);
                              }}
                              dropDownItems={GroupOptionsYear}
                            />
                            <button
                              className="setButtonFilter active btn btn-secondary"
                              onClick={() => {
                                setDownLoadButton(true);
                                downloadData();
                              }}
                            >
                              Download{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                      {!loader ? (
                        userRole === "SuperAdmin" ? (
                          <TableContainer
                            columns={columnSuperAdmin}
                            data={
                              customers.sort((a, b) => {
                                if (newest === null && highest === null) {
                                  return a.businessId - b.businessId;
                                } else if (
                                  newest !== null &&
                                  highest === null
                                ) {
                                  if (newest === true) {
                                    return a.businessId - b.businessId;
                                  } else {
                                    return b.businessId - a.businessId;
                                  }
                                } else {
                                  if (highest === true) {
                                    return a.totalRevenue - b.totalRevenue;
                                  } else {
                                    return b.totalRevenue - a.totalRevenue; // Sort by totalRevenue ascending
                                  }
                                }
                              })
                            }
                            isGlobalFilter={false}
                            customPageSize={8}
                            maxHeight={
                              dimensions.height -
                              headerHeight -
                              footerHeight -
                              tableTopCard -
                              paginationDiv -
                              105
                            }
                            dropdownTitle="Business"
                            divClass="table-responsive mb-1 dashboardTable"
                            tableClass="align-middle table-nowrap"
                            theadClass="table-light text-muted"
                            searchPlaceholder="Search Location name, Region, Business Name, Location Manager"
                            noData={customers.length > 0 ? false : true}
                            noDataImage={
                              "https://res.cloudinary.com/https-www-nimblechapps-com/image/upload/v1688013508/Lubi/WebApp/Static-images/no_data_found.svg"
                            }
                            noDataTitle={"No Business Found!"}
                          />
                        ) : (
                          <TableContainer
                            columns={columnsLocationManager}
                            data={
                              highest === null
                                ? stationDetails
                                : highest === true
                                  ? stationDetails?.sort(
                                    (a, b) => a.totalRevenue - b.totalRevenue
                                  )
                                  : stationDetails?.sort(
                                    (a, b) => b.totalRevenue - a.totalRevenue
                                  )
                            }
                            customFilter={true}
                            allFilter={false}
                            customPageSize={8}
                            maxHeight={
                              dimensions.height -
                              headerHeight -
                              footerHeight -
                              tableTopCard -
                              paginationDiv -
                              105
                            }
                            dropdownTitle="Business"
                            divClass="table-responsive mb-1 dashboardTable"
                            tableClass="align-middle table-nowrap"
                            theadClass="table-light text-muted"
                            searchPlaceholder="Search Location name, Region, Business Name, Location Manager"
                            noData={stationDetails.length > 0 ? false : true}
                            noDataImage={
                              "https://res.cloudinary.com/djyl1goby/image/upload/v1670411148/Lubi/WebApp/Static-images/No_Location_found.svg"
                            }
                            noDataTitle={"No Locations Found!"}
                          />
                        )
                      ) : (
                        <ContentLoader />
                      )}
                    </>
                  </CardBody>
                </Card>
              </div>
            ) : (
              <div className="lubiDashboardTbleView">
                <Card>
                  <CardBody>
                    <>
                      <div className="lubiDashboardTbleViewTopHeader">
                        <div className="lubiDashboardTbleViewTopHeaderListLeft">
                          <button
                            className={
                              activeButton === "day"
                                ? "tabblingClickButton active"
                                : "tabblingClickButton"
                            }
                            type="button"
                            onClick={() => setActiveButton("day")}
                          >
                            Day
                          </button>
                          <button
                            className={
                              activeButton === "week"
                                ? "tabblingClickButton active"
                                : "tabblingClickButton"
                            }
                            type="button"
                            onClick={() => {
                              setActiveButton("week");
                            }}
                          >
                            Week
                          </button>
                          <button
                            className={
                              activeButton === "month"
                                ? "tabblingClickButton active"
                                : "tabblingClickButton"
                            }
                            type="button"
                            onClick={() => {
                              setActiveButton("month");
                            }}
                          >
                            Month
                          </button>
                          <button
                            className={
                              activeButton === "year"
                                ? "tabblingClickButton active"
                                : "tabblingClickButton"
                            }
                            type="button"
                            onClick={() => {
                              setActiveButton("year");
                            }}
                          >
                            Year
                          </button>
                          <Button
                            type="button"
                            className="filterBtn btn"
                            onClick={() => {
                              setSuperAdminFilterModel(true);
                            }}
                          >
                            <i className="bx bx-filter-alt fs-18" />
                          </Button>
                        </div>
                        <div className="lubiDashboardTbleViewTopHeaderListRight">
                          {activeButton === "day" ? (
                            <div className="lubiDashboardTbleViewFullYear">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label"
                              >
                                Day
                              </Label>
                              <CustomDropdown
                                placeholder="Day"
                                selectedValue={selectedGroupDay}
                                onSelect={(evt) => {
                                  setSelectedGroupDay(evt);
                                }}
                                dropDownItems={GroupOptionsDay}
                              />
                            </div>
                          ) : null}
                          {activeButton === "week" ? (
                            <div className="lubiDashboardTbleViewFullYear">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label"
                              >
                                Week
                              </Label>
                              <CustomDropdown
                                placeholder="Week"
                                selectedValue={selectedGroupWeek}
                                onSelect={(evt) => {
                                  setSelectedGroupWeek(evt);
                                }}
                                dropDownItems={GroupOptionsWeek}
                              />
                            </div>
                          ) : null}
                          {activeButton === "year" ? null : (
                            <div className="lubiDashboardTbleViewFullYear">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label"
                              >
                                Month
                              </Label>
                              <CustomDropdown
                                placeholder="Month"
                                selectedValue={selectedGroupMonth}
                                onSelect={(evt) => {
                                  setSelectedGroupMonth(evt);
                                }}
                                dropDownItems={GroupOptionsMonth}
                              />
                            </div>
                          )}
                          <div className="lubiDashboardTbleViewFullYear">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label"
                            >
                              Year
                            </Label>
                            <CustomDropdown
                              placeholder="Year"
                              selectedValue={selectedGroupYear}
                              onSelect={(evt) => {
                                setSelectedGroupYear(evt);
                              }}
                              dropDownItems={GroupOptionsYear}
                            />
                            <button
                              className="setButtonFilter active btn btn-secondary"
                              onClick={() => {
                                setDownLoadButton(true);
                                downloadData();
                              }}
                            >
                              Download{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                      {!loader ? (
                        userRole === "SuperAdmin" ? (
                          <TableContainer
                            columns={columnSuperAdmin}
                            isGlobalFilter={true}
                            allFilter={false}
                            data={
                              // Data is showing in DESC by default
                              customers.sort((a, b) => {
                                if (newest === null && highest === null) {
                                  return a.businessId - b.businessId; // Sort by businessId descending
                                } else if (
                                  newest !== null &&
                                  highest === null
                                ) {
                                  if (newest === true) {
                                    return a.businessId - b.businessId; // Sort by businessId descending
                                  } else {
                                    return b.businessId - a.businessId; // Sort by businessId ascending
                                  }
                                } else {
                                  if (highest === true) {
                                    return a.totalRevenue - b.totalRevenue; // Sort by totalRevenue descending
                                  } else {
                                    return b.totalRevenue - a.totalRevenue; // Sort by totalRevenue ascending
                                  }
                                }
                              })
                            }
                            customPageSize={8}
                            maxHeight={
                              dimensions.height -
                              headerHeight -
                              footerHeight -
                              tableTopCard -
                              paginationDiv -
                              105
                            }
                            dropdownTitle="Business"
                            divClass="table-responsive mb-1 dashboardTable"
                            tableClass="align-middle table-nowrap"
                            theadClass="table-light text-muted"
                            searchPlaceholder="Search Location name, Region, Business Name, Location Manager"
                            noData={customers.length > 0 ? false : true}
                            noDataImage={
                              "https://res.cloudinary.com/https-www-nimblechapps-com/image/upload/v1688013508/Lubi/WebApp/Static-images/no_data_found.svg"
                            }
                            noDataTitle={"No Business Found!"}
                          />
                        ) : (
                          <TableContainer
                            columns={columnsBusinessOwner}
                            data={
                              highest === null
                                ? businessLocations
                                : highest === true
                                  ? businessLocations?.sort(
                                    (a, b) => a.totalRevenue - b.totalRevenue
                                  )
                                  : businessLocations?.sort(
                                    (a, b) => b.totalRevenue - a.totalRevenue
                                  )
                            }
                            isGlobalFilter={true}
                            allFilter={false}
                            customPageSize={8}
                            maxHeight={
                              dimensions.height -
                              headerHeight -
                              footerHeight -
                              tableTopCard -
                              paginationDiv -
                              105
                            }
                            dropdownTitle="Business"
                            divClass="table-responsive mb-1 dashboardTable"
                            tableClass="align-middle table-nowrap"
                            theadClass="table-light text-muted"
                            searchPlaceholder="Search Location name, Region, Business Name, Location Manager"
                            noData={businessLocations.length > 0 ? false : true}
                            noDataImage={
                              "https://res.cloudinary.com/djyl1goby/image/upload/v1670411148/Lubi/WebApp/Static-images/No_Location_found.svg"
                            }
                            noDataTitle={"No Locations Found!"}
                          />
                        )
                      ) : (
                        <ContentLoader />
                      )}
                    </>
                  </CardBody>
                </Card>
              </div>
            )}
          </div>
        </Container>
      </div >

      <Modal
        className="tariffModal"
        isOpen={superAdminFilterModel}
        centered={true}
      >
        <ModalHeader>
          <p>Filter</p>
          <i
            className="bx bx-x"
            onClick={() => {
              setSuperAdminFilterModel(false);
            }}
          />
        </ModalHeader>
        <ModalBody className="">
          <div className="qrMain">
            <div className="fleet-filter-view">
              <></>
              <Row>
                <Col lg={6}>
                  <div className="lg-6">
                    <Label>Business Names</Label>
                    <CustomDropdown
                      className="allDropdowns"
                      moreValue={true}
                      dropDownItems={businessName}
                      defaultValue={getBusId}
                      placeholder={"Choose Business"}
                      selectedValue={getBusId}
                      showSecondVal={false}
                      onSelect={(evt) => {
                        setGetBusId(evt.value);
                        setFilterbusinessId(evt.BusinessID);
                        dispatch(allLocation(`?id=${evt.BusinessID}`));
                        setGetLocId("");
                        setGetStaId("");
                        setGetConId("");
                      }}
                    />
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="lg-6">
                    <Label>Location Names</Label>
                    <CustomDropdown
                      className="allDropdowns"
                      moreValue={true}
                      dropDownItems={locationFilterData}
                      defaultValue={getLocId}
                      placeholder={"Choose Location"}
                      selectedValue={getLocId}
                      showSecondVal={false}
                      onSelect={(evt) => {
                        setGetLocId(evt.value);
                        setFilterlocationId(evt.id);
                        dispatch(listAllStation(`?locationId=${evt.id}`));
                        // setGetStaId('')
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <></>
              <Row>
                <Col lg={6}>
                  <div className="lg-6">
                    <Label>Station Names</Label>
                    <CustomDropdown
                      className="allDropdowns"
                      moreValue={true}
                      dropDownItems={stationFilterData}
                      defaultValue={getStaId}
                      placeholder={"Choose Station"}
                      selectedValue={getStaId}
                      showSecondVal={false}
                      onSelect={(evt) => {
                        setFilterstationId(evt.id);
                        setGetStaId(evt.value);
                        dispatch(allConnectorStationId(`?stationId=${evt.id}`));
                      }}
                    />
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="lg-6">
                    <Label>Connector Names</Label>
                    <CustomDropdown
                      className="allDropdowns"
                      moreValue={true}
                      dropDownItems={connectorListDAta}
                      defaultValue={getConId}
                      placeholder={"Choose Connector"}
                      selectedValue={getConId}
                      showSecondVal={false}
                      onSelect={(evt) => {
                        setGetConId(evt.value);
                        setFilterconnectorId(evt.connectorId);
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <></>
              <Row>
                <Col lg={6}>
                  <div className="lg-6">
                    <Label>Energy Consumption</Label>
                    <Slider
                      range
                      onChange={(e) => {
                        setEnergyFilter(true);
                        setEnergyConsumeRange(e);
                        setminEnergyConsume(e[0]);
                        setmaxEnergyConsume(e[1]);
                      }}
                      max={10000}
                      min={0}
                      allowCross={false}
                      value={energyConsumeRange}
                    />
                    <Row>
                      <Col lg={3}>
                        <div className="lg-3">
                          <p>Min:{minEnergyConsume}kWh</p>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div className="lg-3">
                          <p>Max:{maxEnergyConsume}kWh</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="lg-6">
                    <Label>No. of session </Label>
                    <Slider
                      range
                      onChange={(e) => {
                        setSessionFilter(true);
                        setSessionRange(e);
                        setminSession(e[0]);
                        setmaxSession(e[1]);
                      }}
                      max={200}
                      min={0}
                      allowCross={false}
                      value={sessionRange}
                    />
                    <Row>
                      <Col lg={3}>
                        <div className="lg-3">
                          <p>Min:{minSession}</p>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div className="lg-3">
                          <p>Max:{maxSession}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <></>

              <Row>
                <Col lg={6}>
                  <div className="lg-6">
                    <Label>Revenue</Label>
                    <Slider
                      range
                      onChange={(e) => {
                        setRevenueFilter(true);
                        setRevenueRange(e);
                        setminRevenue(e[0]);
                        setmaxRevenue(e[1]);
                      }}
                      max={12500}
                      min={0}
                      allowCross={false}
                      value={revenueRange}
                    />
                    <Row>
                      <Col lg={3}>
                        <div className="lg-3">
                          <p>Min:₹{minRevenue}</p>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div className="lg-3">
                          <p>Max:₹{maxRevenue}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="lg-6">
                    <Label>Duration </Label>
                    <Slider
                      range
                      onChange={(e) => {
                        setDurationFilter(true);
                        setDurationRange(e);
                        setminDuration(e[0]);
                        setmaxDuration(e[1]);
                      }}
                      max={24}
                      min={0}
                      allowCross={false}
                      value={durationRange}
                    />
                    <Row>
                      <Col lg={3}>
                        <div className="lg-3">
                          <p>Min:{minDuration} Hour</p>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div className="lg-3">
                          <p>Max:{maxDuration} Hours</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="assignMain">
            <div className="hstack gap-2  mt-0 justify-content-end">
              <Button
                onClick={() => {
                  let query = "?";
                  if (
                    selectedGroupDay &&
                    selectedGroupMonth &&
                    selectedGroupYear &&
                    activeButton === "day"
                  ) {
                    query += `year=${selectedGroupYear?.value}&month=${selectedGroupMonth?.id}&day=${selectedGroupDay?.value}`;
                  } else if (
                    selectedGroupWeek &&
                    selectedGroupMonth &&
                    selectedGroupYear &&
                    activeButton === "week"
                  ) {
                    query += `year=${selectedGroupYear?.value}&month=${selectedGroupMonth?.id}&startDate=${selectedGroupWeek?.startDate}&endDate=${selectedGroupWeek?.endDate}`;
                  } else if (
                    selectedGroupMonth &&
                    selectedGroupYear &&
                    activeButton === "month"
                  ) {
                    query += `year=${selectedGroupYear?.value}&month=${selectedGroupMonth?.id}`;
                  } else if (selectedGroupYear && activeButton === "year") {
                    query += `year=${selectedGroupYear?.value}`;
                  }
                  if (userRole === "SuperAdmin") {
                    dispatch(getSuperAdminCustomer(query));
                  } else if (userRole === "BusinessOwner")
                    dispatch(getBusinessLocation(query));
                  else if (userRole === "LocationManager")
                    dispatch(getLocationManager(query));
                  setGetBusId("");
                  setGetLocId("");
                  setGetStaId("");
                  setGetConId("");
                  setLocationFilterData("");
                  setStationFilterData("");
                  setConnectorFilterDAta("");
                  setFilterbusinessId("");
                  setFilterlocationId("");
                  setFilterstationId("");
                  setFilterconnectorId("");
                  setEnergyFilter(false);
                  setSessionFilter(false);
                  setRevenueFilter(false);
                  setDurationFilter(false);
                  setminSession(0);
                  setmaxSession(200);
                  setSessionRange([0, 200]);
                  setEnergyConsumeRange([0, 10000]);
                  setminEnergyConsume(0);
                  setmaxEnergyConsume(10000);
                  setRevenueRange([0, 25000]);
                  setminRevenue(0);
                  setmaxRevenue(25000);
                  setDurationRange([0, 24]);
                  setminDuration(0);
                  setmaxDuration(24);
                  setSuperAdminFilterModel(false);
                }}
              >
                Clear
              </Button>
              <Button
                color={
                  filterbusinessId === "" &&
                    filterstationId === "" &&
                    filterConnectorId === "" &&
                    !energyFilter &&
                    !sessionFilter &&
                    !revenueFilter &&
                    !durationFilter
                    ? "secondary"
                    : "success"
                }
                type="submit"
                disabled={
                  filterbusinessId === "" &&
                  filterstationId === "" &&
                  filterConnectorId === "" &&
                  !energyFilter &&
                  !sessionFilter &&
                  !revenueFilter &&
                  !durationFilter
                }
                onClick={() => {
                  let query = "?";
                  if (
                    selectedGroupDay &&
                    selectedGroupMonth &&
                    selectedGroupYear &&
                    activeButton === "day"
                  ) {
                    query += `year=${selectedGroupYear?.value}&month=${selectedGroupMonth?.id}&day=${selectedGroupDay?.value}`;
                  } else if (
                    selectedGroupWeek &&
                    selectedGroupMonth &&
                    selectedGroupYear &&
                    activeButton === "week"
                  ) {
                    query += `year=${selectedGroupYear?.value}&month=${selectedGroupMonth?.id}&startDate=${selectedGroupWeek?.startDate}&endDate=${selectedGroupWeek?.endDate}`;
                  } else if (
                    selectedGroupMonth &&
                    selectedGroupYear &&
                    activeButton === "month"
                  ) {
                    query += `year=${selectedGroupYear?.value}&month=${selectedGroupMonth?.id}`;
                  } else if (selectedGroupYear && activeButton === "year") {
                    query += `year=${selectedGroupYear?.value}`;
                  }
                  if (filterbusinessId) {
                    query += `&businessId=${filterbusinessId}`;
                  }
                  if (filterlocationId) {
                    query += `&locationId=${filterlocationId}`;
                  }
                  if (filterstationId) {
                    query += `&stationId=${filterstationId}`;
                  }

                  if (filterConnectorId) {
                    query += `&connectorId=${filterConnectorId}`;
                  }
                  if (energyConsumeRange) {
                    query += `&energyConsume=[${energyConsumeRange}]`;
                  }

                  if (sessionRange) {
                    query += `&sessionCount=[${sessionRange}]`;
                  }
                  if (revenueRange) {
                    query += `&revenueRange=[${revenueRange}]`;
                  }
                  if (durationRange) {
                    query += `&duration=[${durationRange}]`;
                  }
                  console.log(userRole, "userRole");

                  if (userRole === "SuperAdmin") {
                    dispatch(getSuperAdminCustomer(query));
                  } else if (userRole === "BusinessOwner")
                    dispatch(getBusinessLocation(query));
                  else if (userRole === "LocationManager")
                    dispatch(getLocationManager(query));
                  setEnergyFilter(false);
                  setSessionFilter(false);
                  setRevenueFilter(false);
                  setDurationFilter(false);
                  setSuperAdminFilterModel(false);
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment >
  );
};

export default Dashboard;
