import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import Constant from "../../../utils/constant";
import { useFormik } from "formik";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useHistory, useLocation } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { showToast, addTariff, allBusinessList, detailViewTariff, editTariff, LocManBus } from "../../../store/actions";
import CustomDropdown from "../../../Components/Common/CustomDropdown";
import CustomButton from "../../../Components/Common/CustomButton";
import { getUserRole } from "../../../helpers/helper.service";

const AddTariff = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, state } = useLocation();
  const [businessName, setBusinessName] = useState(false);
  const [validBusiness, setValidBusiness] = useState([]);
  const [businessID, setBusinessID] = useState('');
  const [OPtype, setOPtype] = useState('');
  const [traiffsID, setTraiffsID] = useState('');
  const [editBUSName, setEditBUSName] = useState(false);

  useEffect(() => {
    dispatch(allBusinessList('?id='));
    // if (getUserRole() === 'LM') {
    //   const userID = JSON.parse(window.sessionStorage.getItem('users'))?.userId;

    //   userID && dispatch(LocManBus(userID));
    // }
  }, []);

  useEffect(() => {
    if (state?.state?.type === 'Edit') {
      setOPtype(state?.state?.type);
      setTraiffsID(state?.state?.tariffID);
      dispatch(detailViewTariff(state?.state?.tariffID));
    }
  }, []);

  const { tariffDetails, businessList, BusinessDetail, loading } = useSelector(state => ({
    businessList: state?.businessList?.allBusinessList,
    tariffDetails: OPtype === 'Edit' ? state?.ManageTariffs?.DetailTariff[0] : {},
    // BusinessDetail: state?.locationsList?.LocManBussDetail[0],
    loading: state.Toast.loader
  }));


  // redirect back to listing if no id is found in case of manual copying url from previos tab
  useLayoutEffect(() => {
    if (state == undefined && window.location.href.includes('edit')) {
      history.push('/tariff');
    }
  }, [])

  useEffect(() => {
    if (getUserRole() === 'LM') {
      setBusinessID(state?.state?.businessID)
    }
  }, [])


  useEffect(() => {
    if (businessList.length > 0) {
      const BusinessName = businessList?.map((e, i) => {
        return {
          id: `${e.businessId}`,
          value: e.businessName
        }
      })
      setValidBusiness(BusinessName);
    }
    businessList?.length === 1 && setBusinessID(businessList[0]?.businessId)
  }, [businessList]);

  useEffect(() => {
    if (state?.state?.type === 'Edit') {
      const BName = businessList?.filter(e => e.businessId === tariffDetails?.businessId).map(e => e.businessName);
      const editBUSValue = {
        id: `${tariffDetails?.businessId}`,
        value: BName.toString()
      }

      setEditBUSName(editBUSValue);
      setBusinessID(tariffDetails?.businessId);
    }
  }, [businessList, tariffDetails]);

  // avoid any input other than numbers, decimal point and backspace
  function checkForValidNumber(e) {
    if (!(/[0-9\b\.]/.test(e.key))) {
      e.preventDefault();
      return;
    }
  }

  // regex explained // ^[1-9][0-9]*$

  // ^\d{1,3}(?:\.\d{1,5})?$
  // ^\___/ \/ ^\/\___/ |
  // |  ^   ^  | |  |   `- Previous is optional (group of dot and minimum 1 number between 0-9 and optionally 4 extra numbers between 0-9)
  // |  |   |  | |  `- Match 1-5 instances of previous (single number 0-9)
  // |  |   |  | `- Match a single number 0-9
  // |  |   |  `- The dot between the 1-3 first number(s) and the 1-5 last number(s).
  // |  |   `- This round bracket should not create a backreference
  // |  `- Match 1-3 instances of previous (single number 0-9)
  // `- Match a single number 0-9



  const addTariffForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      tariffName: (tariffDetails && tariffDetails?.tariffname) || "",
      pricPriceperkW: (tariffDetails && tariffDetails?.KWPrice) || "",
      PriceperMinute: (tariffDetails && tariffDetails?.minutePrice) || "",
      Reservationcharge: (tariffDetails && tariffDetails?.rCharges) || "",
      Cancellationcharges: (tariffDetails && tariffDetails?.cCharges) || "",
      // businessName: (tariffDetails && tariffDetails?.businessId) || ''editBUSName,
      businessName: editBUSName?.value || ''

    },
    validationSchema: Yup.object({
      tariffName: Yup.string()
        .required("Tariif name is required")
        .matches(Constant.REGEX.ALPHANUMERIC, "Alphanumeric characters only"),
      pricPriceperkW: Yup.string()
        .required("Price per kw is required")
        // .matches(Constant.REGEX.NUMBER, "Numeric characters only"),
        // .matches(/^\d+(\.\d{1,})?$/, "Numeric characters only")
        // .matches(/^(\d{1,3}|1000)(?:\.\d{1,5})?$/, "Price per kW must be below 1000"),
        // .matches(/(^([1-9]{1})([0-9]{0,2})\.([0-9]{1,2})?$|^1000$|^([1-9]{1})([0-9]{0,2})$)/gm, "Price per kw is invalid or above 1000"),
        .test('ss', 'Price per kw is invalid', (value) => {
          return (/^(\d)+\.(\d)+$|^(\d)+$/gm).test(value)
        })
        .test('s', 'Price per kw must be below 1000', (value) => Number(value) <= 1000),
      PriceperMinute: Yup.string()
        .required("Price per Minute is required")
        // .matches(Constant.REGEX.NUMBER, "Numeric characters only"),
        // .matches(/^[0-9]{1,}$/g, "Numeric characters only"),
        // .matches(/^\d+(\.\d{1,})?$/, "Numeric characters only")
        // .matches(/^(\d{1,3}|1000)(?:\.\d{1,5})?$/, "Price per Minute must be below 1000"),
        // .matches(/(^([1-9]{1})([0-9]{0,2})\.([0-9]{1,2})?$|^1000$|^([1-9]{1})([0-9]{0,2})$)/gm, "Price per Minute is invalid or above 1000"),
        .test('ss', 'Price per Minute is invalid', (value) => {
          return (/^(\d)+\.(\d)+$|^(\d)+$/gm).test(value)
        })
        .test('s', 'Price per Minute must be below 1000', (value) => Number(value) <= 1000),
      Reservationcharge: Yup.string()
        .required("Reservation charge is required")
        // .matches(Constant.REGEX.NUMBER, "Numeric characters only"),
        // .matches(/^[0-9]{1,}$/g, "Numeric characters only"),
        // .matches(/^\d+(\.\d{1,})?$/, "Numeric characters only")
        // .matches(/^(\d{1,3}|1000)(?:\.\d{1,5})?$/, "Reservation charges must be below 1000"),
        // .matches(/(^([1-9]{1})([0-9]{0,2})\.([0-9]{1,2})?$|^1000$|^([1-9]{1})([0-9]{0,2})$)/gm, "Reservation charge is invalid or above 1000"),
        .test('ss', 'Reservation charge is invalid', (value) => {
          return (/^(\d)+\.(\d)+$|^(\d)+$/gm).test(value)
        })
        .test('s', 'Reservation charge must be below 1000', (value) => Number(value) <= 1000),
      Cancellationcharges: Yup.string()
        .required("Cancellation charge is required")
        // .matches(Constant.REGEX.NUMBER, "Numeric characters only"),
        // .matches(/^[0-9]{1,}$/g, "Numeric characters only"),
        // .matches(/^\d+(\.\d{1,})?$/, "Numeric characters only")
        // .matches(/^(\d{1,3}|1000)(?:\.\d{1,5})?$/, "Cancellation charges must be below 1000"),
        // .matches(/(^([1-9]{1})([0-9]{0,2})\.([0-9]{1,2})?$|^1000$|^([1-9]{1})([0-9]{0,2})$)/gm, "Cancellation charge is invalid or above 1000"),
        .test('ss', 'Cancellation charge is invalid', (value) => {
          return (/^(\d)+\.(\d)+$|^(\d)+$/gm).test(value)
        })
        .test('s', 'Cancellation charge must be below 1000', (value) => Number(value) <= 1000),
      businessName: window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null && JSON.parse(window.localStorage.getItem('users')).userRole !== 'BusinessOwner' ? Yup.string() : validBusiness.length === 1 ? Yup.string() : Yup.string().required(),
    }),
    onSubmit: (values) => {
      //('values >>>', values)
      if (OPtype === 'Edit') {
        const params1 = {
          tariffName: values?.tariffName,
          KWPrice: +(values?.pricPriceperkW),
          minutePrice: (values?.PriceperMinute),
          rCharges: +(values?.Reservationcharge),
          cCharges: +(values?.Cancellationcharges),
          businessId: +(businessID),
          variableTariff: []
        }
        dispatch(editTariff(params1, traiffsID, props.history));

      } else {
        const params = {
          tariffName: values?.tariffName,
          KWPrice: +(values?.pricPriceperkW),
          minutePrice: (values?.PriceperMinute),
          rCharges: +(values?.Reservationcharge),
          cCharges: +(values?.Cancellationcharges),
          businessId: +(businessID),
          variableTariff: [
            // {
            //   fromTime: "6:00 PM",
            //   toTime: "8:00 PM",
            //   KWPrice: 50,
            //   minutePrice: 5,
            //   day: "Mon"
            // },
          ]
        }
        dispatch(addTariff(params, props.history))


      }
      //onSuccess();
      // onFaild();

    }
  });


  const onSuccess = (values) => {
    // Success Toast
    dispatch(showToast({
      message: 'Tariff details updated successfully.',
      type: 'success'
    }));
    setTimeout(() => {
      history.push('/tariff')
    }, 3000);
  }

  const allBusinessName = [
    {
      id: '1',
      value: 'data 1'
    },
    {
      id: '2',
      value: 'data 2'
    },
    {
      id: '3',
      value: 'data 3'
    },
  ]
  const [variablePriceData, setvariablePriceData] = useState([
    {
      id: "1",
      startTime: "",
      endTime: "",
      PriceperkW: "",
      PriceperMinute: "",
      dayCheck: [
        {
          id: '1',
          dayCheck: false
        },
        {
          id: '2',
          dayCheck: false
        },
        {
          id: '3',
          dayCheck: false
        },
        {
          id: '4',
          dayCheck: false
        },
        {
          id: '5',
          dayCheck: false
        },
        {
          id: '6',
          dayCheck: false
        },
        {
          id: '7',
          dayCheck: false
        }
      ]
    },

  ]);
  const DefaultVariableData = {
    id: "1",
    startTime: "",
    endTime: "",
    PriceperkW: "",
    PriceperMinute: "",
    dayCheck: [
      {
        id: '1',
        dayCheck: false
      },
      {
        id: '2',
        dayCheck: false
      },
      {
        id: '3',
        dayCheck: false
      },
      {
        id: '4',
        dayCheck: false
      },
      {
        id: '5',
        dayCheck: false
      },
      {
        id: '6',
        dayCheck: false
      },
      {
        id: '7',
        dayCheck: false
      }
    ]
  }

  const dayCheckFunction = (e, i, index) => {
    setvariablePriceData(prev => {
      const WeekSedual = prev[index].dayCheck.map((x, ind) => {
        if (ind === i) {
          return {
            ...x,
            dayCheck: !x.dayCheck
          }
        } else {
          return x;
        }
      })
      const CheckData = { ...prev[index], dayCheck: WeekSedual }
      let newstate = [
        ...prev
      ];
      newstate.splice(index, 1, CheckData)
      //(newstate, 'newstate')
      return newstate;
    })
  }

  const [dataValid, setDataValid] = useState(false)
  const datepickerValidation = () => {
    const IsValid = variablePriceData.every((item) => {
      return item.startTime !== '' && item.endTime !== '' && item.startTime !== null && item.endTime !== null
    })
    setDataValid(IsValid);
  }

  const AddVariablePrice = () => {
    setvariablePriceData((prev) => {
      const NewState = [...prev, DefaultVariableData]
      return NewState
    })

  }
  const DeleteVariablePrice = (index) => {
    setvariablePriceData((prev) => {
      const NewState = [...prev]
      NewState.splice(index, 1);
      return NewState
    })

  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            onClickBack={(e) => {
              // history.push('/businesses')
              e.preventDefault();
              history.goBack();
            }}
            isButton={true}
            title={pathname === "/tariff/addtariff" ? "Add Tariff" : "Edit Tariff"}
            pageTitle="Tariff"
            OnClickpageTitle={(e) => { e.preventDefault(); history.goBack(); }}
          />
          <div className="addTariffPage">
            <Row>
              <Col lg={12} xl={12}>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    addTariffForm.handleSubmit();
                    return false;
                  }}
                  action="#"
                >
                  <Card className="mb-0">
                    <CardHeader>
                      <p className="headerTxt">{pathname === "/tariff/addtariff" ? 'Add Tariff' : 'Edit Traiff'}</p>
                    </CardHeader>
                    <CardBody>
                      <div className="main">
                        <Row>
                          <div className="subMian col-sm-5">
                            <Label
                              htmlFor="tariffName"
                              className="form-label requierdLabel"
                            >
                              Name
                            </Label>
                            <Input
                              name="tariffName"
                              type="text"
                              className="form-control"
                              id="tariffName"
                              maxLength={50}
                              placeholder="Enter Tariff Name"
                              onChange={addTariffForm.handleChange}
                              onKeyUp={addTariffForm.handleBlur}
                              value={addTariffForm.values.tariffName.trimStart() || ""}
                              invalid={
                                addTariffForm.errors.tariffName &&
                                  addTariffForm.touched.tariffName
                                  ? true
                                  : false
                              }
                            />
                            {addTariffForm.errors.tariffName &&
                              addTariffForm.touched.tariffName ? (
                              <FormFeedback type="invalid">
                                {addTariffForm.errors.tariffName}
                              </FormFeedback>
                            ) : null}
                          </div>
                          {getUserRole() !== 'LM' ?
                            <div className="subMian col-sm-5">
                              <Label
                                htmlFor="tariffName"
                                className="form-label requierdLabel"
                              >
                                Businesses
                              </Label>
                              <CustomDropdown
                                defaultValue={validBusiness.length === 1 ? validBusiness[0]?.value : ''}
                                className={validBusiness.length === 1 ? 'read_OnlyDropdown' : ''}
                                disabled={validBusiness.length === 1 ? true : false}
                                selectedValue={businessName || editBUSName}
                                dropDownItems={validBusiness}
                                placeholder={'Select business name'}
                                onSelect={(evt) => {
                                  setBusinessName(evt);
                                  setBusinessID(evt?.id);
                                  addTariffForm.handleChange('businessName')(evt.value);
                                }}
                              />
                            </div>
                            : null}

                        </Row>
                        <hr />
                        <div className="row">
                          <div className="col-sm-5">
                            <div className="subMian mb-3 col-sm-12">
                              <Label className="form-label requierdLabel">
                                Flat Price
                              </Label>
                              <Label
                                htmlFor="pricPriceperkW"
                                className="small-form-label requierdLabel"
                              >
                                Price per kw
                              </Label>
                              <Input
                                name="pricPriceperkW"
                                type="text"
                                className="form-control"
                                id="pricPriceperkW"
                                placeholder="₹ 0.00"
                                onChange={addTariffForm.handleChange}
                                onKeyUp={addTariffForm.handleBlur}
                                maxLength={8}
                                value={
                                  // addTariffForm.values.pricPriceperkW || ""
                                  addTariffForm.values.pricPriceperkW
                                }
                                invalid={
                                  addTariffForm.errors.pricPriceperkW &&
                                    addTariffForm.touched.pricPriceperkW
                                    ? true
                                    : false
                                }
                                onKeyDown={(e) => {
                                  if (!((e.key.charCodeAt() > 47 && e.key.charCodeAt() < 58) || e.key.charCodeAt() === 66 || e.key.charCodeAt() === 46)) {
                                    e.preventDefault();
                                  }
                                }}
                                onKeyPress={checkForValidNumber}
                              />
                              {addTariffForm.errors.pricPriceperkW &&
                                addTariffForm.touched.pricPriceperkW ? (
                                <FormFeedback type="invalid">
                                  {addTariffForm.errors.pricPriceperkW}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="subMian  col-sm-12">
                              <Label
                                htmlFor="PriceperMinute"
                                className="small-form-label "
                              >
                                Price per Minute
                              </Label>
                              <Input
                                name="PriceperMinute"
                                type="text"
                                className="form-control"
                                id="PriceperMinute"
                                placeholder="₹ 0.00"
                                maxLength={8}
                                onChange={addTariffForm.handleChange}
                                onKeyUp={addTariffForm.handleBlur}
                                value={
                                  addTariffForm.values.PriceperMinute
                                }
                                invalid={
                                  addTariffForm.errors.PriceperMinute &&
                                    addTariffForm.touched.PriceperMinute
                                    ? true
                                    : false
                                }
                                onKeyDown={(e) => {
                                  if (e.key.charCodeAt() === 32) {
                                    e.preventDefault();
                                  }
                                }}
                                onKeyPress={checkForValidNumber}
                              />
                              {addTariffForm.errors.PriceperMinute &&
                                addTariffForm.touched.PriceperMinute ? (
                                <FormFeedback type="invalid">
                                  {addTariffForm.errors.PriceperMinute}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-5">
                            <div className="subMian mb-3 col-sm-12">
                              <Label className="form-label requierdLabel">
                                Other Charges
                              </Label>
                              <Label
                                htmlFor="Reservationcharge"
                                className="small-form-label requierdLabel"
                              >
                                Reservation charge
                              </Label>
                              <Input
                                name="Reservationcharge"
                                type="text"
                                className="form-control"
                                maxLength={8}
                                id="Reservationcharge"
                                placeholder="₹ 0.00"
                                onChange={addTariffForm.handleChange}
                                onKeyUp={addTariffForm.handleBlur}
                                value={
                                  // addTariffForm.values.Reservationcharge || ""
                                  addTariffForm.values.Reservationcharge
                                }
                                invalid={
                                  addTariffForm.errors.Reservationcharge &&
                                    addTariffForm.touched.Reservationcharge
                                    ? true
                                    : false
                                }
                                onKeyDown={(e) => {
                                  if (!((e.key.charCodeAt() > 47 && e.key.charCodeAt() < 58) || e.key.charCodeAt() === 66 || e.key.charCodeAt() === 46)) {
                                    e.preventDefault();
                                  }
                                }}
                                onKeyPress={checkForValidNumber}
                              />
                              {addTariffForm.errors.Reservationcharge &&
                                addTariffForm.touched.Reservationcharge ? (
                                <FormFeedback type="invalid">
                                  {addTariffForm.errors.Reservationcharge}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="subMian  col-sm-12">
                              <Label
                                htmlFor="Cancellationcharges"
                                className="small-form-label requierdLabel"
                              >
                                Cancellation charge
                              </Label>
                              <Input
                                name="Cancellationcharges"
                                type="text"
                                className="form-control"
                                id="Cancellationcharges"
                                placeholder="₹ 0.00"
                                maxLength={8}
                                onChange={addTariffForm.handleChange}
                                onKeyUp={addTariffForm.handleBlur}
                                value={
                                  // addTariffForm.values.Cancellationcharges || ""
                                  addTariffForm.values.Cancellationcharges
                                }
                                invalid={
                                  addTariffForm.errors.Cancellationcharges &&
                                    addTariffForm.touched.Cancellationcharges
                                    ? true
                                    : false
                                }
                                onKeyDown={(e) => {
                                  if (e.key.charCodeAt() === 32) {
                                    e.preventDefault();
                                  }
                                }}
                                onKeyPress={checkForValidNumber}
                              />
                              {addTariffForm.errors.Cancellationcharges &&
                                addTariffForm.touched.Cancellationcharges ? (
                                <FormFeedback type="invalid">
                                  {addTariffForm.errors.Cancellationcharges}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <hr />
                        <Label htmlFor="tariffName" className="form-label">
                          Variable Price
                        </Label>
                        <div className="Bottom-Table">
                          <div className="Table-header">
                            <ul className="Table-row">
                              <li className="Table-name">
                                <Label className="form-label requierdLabel">
                                  From
                                </Label>
                              </li>
                              <li className="Table-name">
                                <Label className="form-label requierdLabel">
                                  To
                                </Label>
                              </li>
                              <li className="Table-name">
                                <Label className="form-label">
                                  Price per kW
                                </Label>
                              </li>
                              <li className="Table-name">
                                <Label className="form-label ">
                                  Price per Minute
                                </Label>
                              </li>
                              <li className="Table-name last-table">
                                <ul className="Date-Table-name">
                                  <li className="Table-name">
                                    <Label className="form-label">Mon</Label>
                                  </li>
                                  <li className="Table-name">
                                    <Label className="form-label ">Tue</Label>
                                  </li>
                                  <li className="Table-name">
                                    <Label className="form-label ">Wed</Label>
                                  </li>
                                  <li className="Table-name">
                                    <Label className="form-label ">Thu</Label>
                                  </li>
                                  <li className="Table-name">
                                    <Label className="form-label ">Fri</Label>
                                  </li>
                                  <li className="Table-name">
                                    <Label className="form-label ">Sat</Label>
                                  </li>
                                  <li className="Table-name">
                                    <Label className="form-label ">Sun</Label>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                            {variablePriceData?.map((item, index) => {
                              return (
                                <ul className="Table-Body" key={index}>
                                  <div onClick={() => {
                                    DeleteVariablePrice(index)
                                  }} className="text-danger d-inline-block remove-item-btn removeAddTriff">
                                    <i className="bx bx-minus-circle "></i></div>
                                  <li className="Table-name">
                                    <div className="timerDiv">
                                      <ReactDatePicker
                                        placeholderText="--:-- --"
                                        className="simpleTimePicker"
                                        selected={item.startTime}
                                        onChange={(e) => {

                                          variablePriceData[index].startTime =
                                            e;
                                          variablePriceData[index].endTime =
                                            "";
                                          setvariablePriceData([
                                            ...variablePriceData,
                                          ]);
                                          datepickerValidation();
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        name="startTime"
                                        timeIntervals={30}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                      />
                                      <i className="mdi mdi-clock-time-four-outline fs-16"></i>
                                    </div>
                                  </li>
                                  <li className="Table-name">
                                    <div className="timerDiv">
                                      <ReactDatePicker
                                        placeholderText="--:-- --"
                                        className='simpleTimePicker'
                                        selected={item.endTime}
                                        name="endTime"
                                        onChange={(e) => {
                                          variablePriceData[index].endTime = e;
                                          setvariablePriceData([
                                            ...variablePriceData,
                                          ]);
                                          datepickerValidation();
                                        }}
                                        showTimeSelect
                                        minTime={
                                          item.startTime
                                            ? new Date(
                                              moment(item.startTime)
                                                .add(30, "minutes")
                                                .format()
                                            )
                                            : setHours(
                                              setMinutes(new Date(), 0),
                                              0
                                            )
                                        }
                                        maxTime={setHours(
                                          setMinutes(new Date(), 30),
                                          23
                                        )}
                                        showTimeSelectOnly

                                        timeIntervals={30}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                      />
                                      <i className="mdi mdi-clock-time-four-outline fs-16"></i>
                                    </div>
                                  </li>
                                  <li className="Table-name">
                                    <Input
                                      name="variablePriceperkW"
                                      type="text"
                                      className="form-control"
                                      id={item.id}
                                      placeholder="₹ 0.00"
                                      // onChange={addTariffForm.handleChange}
                                      onKeyUp={addTariffForm.handleBlur}
                                      value={
                                        variablePriceData.PriceperkW
                                      }
                                      onChange={(e) => {
                                        variablePriceData[index].PriceperkW = e;
                                        setvariablePriceData([
                                          ...variablePriceData,
                                        ]);
                                      }}

                                    />
                                  </li>
                                  <li className="Table-name">
                                    <Input
                                      name="variablePricepermin"
                                      type="text"
                                      className="form-control"
                                      id={item.id}
                                      placeholder="₹ 0.00"
                                      onKeyUp={addTariffForm.handleBlur}
                                      value={
                                        variablePriceData.PriceperMinute
                                      }
                                      onChange={(e) => {
                                        variablePriceData[index].PriceperMinute = e;
                                        setvariablePriceData([
                                          ...variablePriceData,
                                        ]);
                                      }}

                                    />
                                  </li>
                                  <li className="Table-name last-table">
                                    <ul className="Date-Table-name">
                                      {item?.dayCheck?.map((value, i) => {
                                        return (
                                          <li className="Table-name" key={i}>
                                            <div className="form-check form-check-outline form-check-secondary">
                                              <Input
                                                className='form-check-input form-check-input'
                                                type="checkbox"
                                                id={value.id}
                                                name='checkDay'
                                                checked={value.dayCheck}
                                                onChange={(e) => {
                                                  dayCheckFunction(e, i, index);
                                                }}

                                              // onChange={(e) => {

                                              // }}
                                              />
                                            </div>
                                          </li>
                                        );
                                      })}

                                    </ul>
                                  </li>
                                </ul>
                              );
                            })}
                          </div>
                        </div>
                        <div className="BottomFormBtn">
                          <Button color="success" className="btn btn-add-more" onClick={AddVariablePrice}>
                            Add more
                          </Button>
                        </div>

                      </div>
                    </CardBody>

                  </Card>
                  <div className="Form-Bottom-button">
                    {/* <Button color="success" className="btn btn-form" disabled={!(addTariffForm.dirty && addTariffForm.isValid && dataValid)}> */}
                    {/* <Button color="success" className="btn btn-form" disabled={!(addTariffForm.dirty && addTariffForm.isValid)}>

                      <i className="ri-add-line align-middle fs-16 me-2"></i>
                      {pathname === "/tariff/addtariff" ? 'Add Location' : 'Update'}
                    </Button> */}
                    <CustomButton
                      title={pathname === "/tariff/addtariff" ? 'Add Tariff' : 'Update'}
                      disabled={!(addTariffForm.dirty && addTariffForm.isValid)}
                      type='submit'
                      loading={loading}
                      className='btn addButtonWithLoader btn-form btn-left'
                      btnIcon={pathname === '/tariff/edittariff' ? '' : "ri-add-line align-middle fs-16 me-2"}
                    />
                    <Button className="btn btn-form cancelBtn btn-soft btn btn-secondary" onClick={() => {
                      history.goBack();
                    }} >
                      Cancel
                    </Button>

                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddTariff;
