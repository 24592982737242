import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { Dropdown, DropdownMenu, DropdownToggle, Form } from 'reactstrap';

//import images
// import logoSm from "../assets/images/logo-sm.png";
// import logoDark from "../assets/images/logo-dark.png";
// import logoLight from "../assets/images/logo-light.png";

//import Components
// import SearchOption from '../Components/Common/SearchOption';
import FullScreenDropdown from '../Components/Common/FullScreenDropdown';
import NotificationDropdown from '../Components/Common/NotificationDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';
import avatar from '../assets/images/users/avatar-1.jpg'
import { getValueFromObject } from '../helpers/helper.service';
import { socket } from '../App';
import { logoutUserSuccess } from '../store/actions';

const Header = ({ headerClass }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [search, setSearch] = useState(false);
    const toogleSearch = () => {
        setSearch(!search);
    };
    // const userDetails = JSON.parse(sessionStorage.getItem('users'));
    const userDetails = JSON.parse(localStorage.getItem('users'));
    

    // let imageUser = '';
    // let name = '';
    // useEffect(() => {
    //     if (userDetails) {
    //         name = `${userDetails?.firstName} ${userDetails?.lastName}`
    
    //         imageUser = name.toUpperCase().match(/\b\w/g).join('');
    
    //     }
    // }, [userDetails]);
    // const { userDetails } = useSelector(state => ({
    //     userDetails: state?.Login?.loginData
    // }));
    

    const { data } = useSelector(state => ({
        data: state?.DashboardProject?.dashboardData?.data
    }))


    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;

        if (windowSize > 767)
            document.querySelector(".hamburger-icon").classList.toggle('open');

        //For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === "horizontal") {
            document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
        }

        //For collapse vertical menu
        if (document.documentElement.getAttribute('data-layout') === "vertical") {
            if (windowSize < 1024.99 && windowSize > 767) {
                document.body.classList.add('vertical-sidebar-enable');
                // (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            }
            else if (windowSize > 1024.99) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
            else if (windowSize <= 1024.99) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }

        //Two column menu
        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
        }
    };
    return (
        <React.Fragment>
            <header id="page-topbar" className={headerClass}>
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">

                            {/* <div className="navbar-brand-box horizontal-logo">
                                <Link to="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="17" />
                                    </span>
                                </Link>
                            </div> */}

                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>


                            {/* <SearchOption /> */}
                        </div>

                        <div className="d-flex align-items-center">

                            {/* FullScreenDropdown */}
                            <FullScreenDropdown />

                            {/* NotificationDropdown */}
                            <NotificationDropdown />

                            {/* ProfileDropdown */}
                            {/* <ProfileDropdown /> */}
                            <div className='profileRight d-flex align-items-center justify-content-center'>
                                <div className='left'>
                                    <div className='main' onClick={() => {
                                        history.push('/profile')
                                    }}>
                                        {userDetails?.profileImage ?
                                            <img src={userDetails?.profileImage} alt='' />
                                            : <span style={{ color: '#fff' }}>{`${userDetails?.firstName} ${userDetails?.lastName}`?.toUpperCase()?.match(/\b\w/g)?.join('')}</span>
                                        }
                                    </div>
                                </div>
                                <div className='right'>
                                    <p className='text' onClick={() => {
                                        history.push('/profile')
                                    }}>{getValueFromObject('firstName', userDetails, '').charAt(0).toUpperCase() + getValueFromObject('firstName', userDetails, '').slice(1)} {getValueFromObject('lastName', userDetails, '').charAt(0).toUpperCase() + getValueFromObject('lastName', userDetails, '').slice(1)}</p>
                                    <p className='logOut' onClick={() => {
                                        history.push('/logout');
                                        // socket.disconnect()
                                        dispatch(logoutUserSuccess())
                                        window.localStorage.removeItem('islogged');
                                        window.sessionStorage.clear();
                                        window.localStorage.removeItem('users')
                                        window.localStorage.removeItem('token')
                                        window.localStorage.removeItem('businessLogo')
                                        window.localStorage.removeItem('businessName')
                                        window.localStorage.setItem('current', 'login');
                                        //const color = JSON.parse(window.sessionStorage.getItem("users"));
                                        document.getElementById('12').setAttribute("data-theme", '#37D3EE');


                                        // force reload on logging out for protected routes to initialize
                                        // window.location.reload();
                                    }}>Logout</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;