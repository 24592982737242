import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from "prop-types";
import { useLocation, withRouter } from "react-router-dom";

//routes
import AddBusinesses from "../pages/Admin/Businesses/AddBusinesses/AddBusiness";
import Dashboard from '../pages/Dashboard';
import Customers from '../pages/Admin/Customers/Customers';
import Fleet from '../pages/Admin/Fleet/Fleet';
import RFIDManagement from '../pages/Admin/RFID Manager/RFIDManagement';
import AddLocation from '../pages/Admin/Location/AddLocation/AddLocation';
import Location from '../pages/Admin/Location/Location';
import ViewLocation from '../pages/Admin/Location/ViewLocation';
import Stations from '../pages/Admin/Station/Stations';
import ViewStation from '../pages/Admin/Station/ViewStation';
import AddStation from '../pages/Admin/Station/AddStation/AddStation';
import OnBoarding from '../pages/OnBoarding/OnBoarding';
import Tariff from '../pages/Admin/Tariff/Tariff';
import AddTariff from '../pages/Admin/Tariff/addTariff';
import Rfid from '../pages/Admin/RFID/RFID';
import Profile from '../pages/Profile/Settings/EditProfile';
import Logs from '../pages/Logs/LogsPage/EditLogs';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
import Businesses from '../pages/Admin/Businesses/BussinessPage';

import AddEVSEModel from "../pages/Admin/Assets/AddEVSEModel/AddEVSEModel";
import Addtariff from "../pages/Admin/Tariff/addTariff";
import EVSEPage from "../pages/Admin/Assets/EVSEPage";
import ViewEVSE from "../pages/Admin/Assets/ViewEVSEmodel";

import BussinessDetail from "../pages/Admin/Businesses/BussinessDetail";
import Station from "../pages/Admin/Station/Stations";
import ConnectorType from "../pages/Admin/Assets/ConnectorType";


//import Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import RightSidebar from '../Components/Common/RightSidebar';

//import actions
import {
    changeLayout,
    changeSidebarTheme,
    changeLayoutMode,
    changeLayoutWidth,
    changeLayoutPosition,
    changeTopbarTheme,
    changeLeftsidebarSizeType,
    changeLeftsidebarViewType
} from "../store/actions";

import { authProtectedRoutes, publicRoutes } from "../Routes/allRoutes"

// redux
import { useSelector, useDispatch } from "react-redux";


const Layout = (props) => {
    const { pathname } = useLocation();
    const [headerClass, setHeaderClass] = useState("");
    const [routeIndex, setRouteIndex] = useState(false);
    const dispatch = useDispatch();
    const {
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType
    } = useSelector(state => ({
        layoutType: state.Layout.layoutType,
        leftSidebarType: state.Layout.leftSidebarType,
        layoutModeType: state.Layout.layoutModeType,
        layoutWidthType: state.Layout.layoutWidthType,
        layoutPositionType: state.Layout.layoutPositionType,
        topbarThemeType: state.Layout.topbarThemeType,
        leftsidbarSizeType: state.Layout.leftsidbarSizeType,
        leftSidebarViewType: state.Layout.leftSidebarViewType,
    }));
    // class add remove in header
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });
    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setHeaderClass("topbar-shadow");
        } else {
            setHeaderClass("");
        }
    }

    // user role change
    const fullState = useSelector(state => state)
    const authRoutes = useMemo(() => {
        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            if (JSON.parse(window.localStorage.getItem('users')).userRole == 'LocationManager') {
                return [
                    { path: "/dashboard", component: Dashboard },
                    { path: "/dashboard/", component: Dashboard },
                    { path: "/customers", component: Customers },
                    { path: "/customers/", component: Customers },
                    { path: "/fleet", component: Fleet },
                    { path: "/fleet/", component: Fleet },
                    { path: "/rfid", component: Rfid },
                    { path: "/rfid/", component: Rfid },
                    { path: "/rfidmanagement", component: RFIDManagement },
                    { path: "/rfidmanagement/", component: RFIDManagement },
                    { path: "/profile", component: Profile },
                    { path: "/profile/", component: Profile },
                    { path: "/logs", component: Logs },
                    { path: "/logs/", component: Logs },
                    { path: "/location/addlocation", component: AddLocation },
                    { path: "/location/addlocation/", component: AddLocation },
                    { path: "/location/editlocation", component: AddLocation },
                    { path: "/location/editlocation/", component: AddLocation },
                    { path: "/location", component: Location },
                    { path: "/location/", component: Location },
                    { path: "/location/viewlocation", component: ViewLocation },
                    { path: "/location/viewlocation/", component: ViewLocation },
                    { path: "/stations", component: Stations },
                    { path: "/stations/", component: Stations },
                    { path: "/stations/viewstation", component: ViewStation },
                    { path: "/stations/viewstation/", component: ViewStation },
                    { path: "/stations/addstation", component: AddStation },
                    { path: "/stations/addstation/", component: AddStation },
                    { path: "/stations/editstation", component: AddStation },
                    { path: "/stations/editstation/", component: AddStation },
                    { path: "/onboarding", component: OnBoarding },
                    { path: "/onboarding/", component: OnBoarding },
                    { path: "/tariff", component: Tariff },
                    { path: "/tariff/", component: Tariff },
                    { path: "/tariff/addtariff", component: AddTariff },
                    { path: "/tariff/addtariff/", component: AddTariff },
                    { path: "/tariff/edittariff", component: AddTariff },
                    { path: "/tariff/edittariff/", component: AddTariff },
                    {
                        path: "/",
                        exact: true,
                        component: () => <Redirect to="/login" />,
                    },
                    { path: "/*", component: Basic404 },
                ];
            } else if (JSON.parse(window.localStorage.getItem('users')).userRole == 'BusinessOwner') {
                return [
                    { path: "/dashboard", component: Dashboard },
                    { path: "/dashboard/", component: Dashboard },
                    { path: "/businesses", component: Businesses },
                    { path: "/businesses/", component: Businesses },
                    { path: "/businesses/businessesdetail", component: BussinessDetail },
                    { path: "/businesses/businessesdetail/", component: BussinessDetail },
                    { path: "/businesses/addbusiness", component: AddBusinesses },
                    { path: "/businesses/addbusiness/", component: AddBusinesses },
                    { path: "/businesses/editbusiness", component: AddBusinesses },
                    { path: "/businesses/editbusiness/", component: AddBusinesses },
                    { path: "/customers", component: Customers },
                    { path: "/customers/", component: Customers },
                    { path: "/fleet", component: Fleet },
                    { path: "/fleet/", component: Fleet },
                    { path: "/rfid", component: Rfid },
                    { path: "/rfid/", component: Rfid },
                    { path: "/rfidmanagement", component: RFIDManagement },
                    { path: "/rfidmanagement/", component: RFIDManagement },
                    { path: "/profile", component: Profile },
                    { path: "/profile/", component: Profile },
                    { path: "/location/addlocation", component: AddLocation },
                    { path: "/location/addlocation/", component: AddLocation },
                    { path: "/location/editlocation", component: AddLocation },
                    { path: "/location/editlocation", component: AddLocation },
                    { path: "/location/editlocation/", component: AddLocation },
                    { path: "/location", component: Location },
                    { path: "/location/", component: Location },
                    { path: "/location/viewlocation", component: ViewLocation },
                    { path: "/location/viewlocation/", component: ViewLocation },
                    { path: "/stations", component: Stations },
                    { path: "/stations/", component: Stations },
                    { path: "/stations/viewstation", component: ViewStation },
                    { path: "/stations/viewstation/", component: ViewStation },
                    { path: "/stations/addstation", component: AddStation },
                    { path: "/stations/addstation/", component: AddStation },
                    { path: "/stations/editstation", component: AddStation },
                    { path: "/stations/editstation/", component: AddStation },
                    { path: "/onboarding", component: OnBoarding },
                    { path: "/onboarding/", component: OnBoarding },
                    { path: "/tariff", component: Tariff },
                    { path: "/tariff/", component: Tariff },
                    { path: "/tariff/addtariff", component: AddTariff },
                    { path: "/tariff/addtariff/", component: AddTariff },
                    { path: "/tariff/edittariff", component: AddTariff },
                    { path: "/tariff/edittariff/", component: AddTariff },
                    {
                        path: "/",
                        exact: true,
                        component: () => <Redirect to="/login" />,
                    },

                    { path: "/*", component: Basic404 },
                ];
            } else if (JSON.parse(window.localStorage.getItem('users')).userRole == 'SuperAdmin') {
                return [
                    { path: "/dashboard", component: Dashboard },
                    { path: "/dashboard/", component: Dashboard },
                    { path: "/businesses", component: Businesses },
                    { path: "/businesses/", component: Businesses },
                    { path: "/businesses/businessesdetail", component: BussinessDetail },
                    { path: "/businesses/businessesdetail/", component: BussinessDetail },
                    { path: "/businesses/addbusiness", component: AddBusinesses },
                    { path: "/businesses/addbusiness/", component: AddBusinesses },
                    { path: "/businesses/editbusiness", component: AddBusinesses },
                    { path: "/businesses/editbusiness/", component: AddBusinesses },
                    { path: "/customers", component: Customers },
                    { path: "/customers/", component: Customers },
                    { path: "/fleet", component: Fleet },
                    { path: "/fleet/", component: Fleet },
                    { path: "/rfid", component: Rfid },
                    { path: "/rfid/", component: Rfid },
                    { path: "/logs", component: Logs },
                    { path: "/logs/", component: Logs },
                    { path: "/rfidmanagement", component: RFIDManagement },
                    { path: "/rfidmanagement/", component: RFIDManagement },
                    { path: "/profile", component: Profile },
                    { path: "/profile/", component: Profile },
                    { path: "/location/addlocation", component: AddLocation },
                    { path: "/location/addlocation/", component: AddLocation },
                    { path: "/location/editlocation", component: AddLocation },
                    { path: "/location/editlocation/", component: AddLocation },
                    { path: "/location", component: Location },
                    { path: "/location/", component: Location },
                    { path: "/location/viewlocation", component: ViewLocation },
                    { path: "/location/viewlocation/", component: ViewLocation },
                    { path: "/stations", component: Station },
                    { path: "/stations/", component: Station },
                    { path: "/stations/viewstation", component: ViewStation },
                    { path: "/stations/viewstation/", component: ViewStation },
                    { path: "/stations/addstation", component: AddStation },
                    { path: "/stations/addstation/", component: AddStation },
                    { path: "/stations/editstation", component: AddStation },
                    { path: "/stations/editstation/", component: AddStation },
                    { path: "/onboarding", component: OnBoarding },
                    { path: "/onboarding/", component: OnBoarding },
                    { path: "/assets/connectortype", component: ConnectorType },
                    { path: "/assets/connectortype/", component: ConnectorType },
                    { path: "/assets/addevsemodel", component: AddEVSEModel },
                    { path: "/assets/addevsemodel/", component: AddEVSEModel },
                    { path: "/assets/editevsemodel", component: AddEVSEModel },
                    { path: "/assets/editevsemodel/", component: AddEVSEModel },
                    { path: "/assets/editevseconnector", component: AddEVSEModel },
                    { path: "/assets/editevseconnector/", component: AddEVSEModel },
                    { path: "/assets/evsemodel", component: EVSEPage },
                    { path: "/assets/evsemodel/", component: EVSEPage },
                    { path: "/assets/evsemodel/viewevse", component: ViewEVSE },
                    { path: "/assets/evsemodel/viewevse/", component: ViewEVSE },


                    // this route should be at the end of all other routes
                    // eslint-disable-next-line react/display-name
                    // {
                    //   path: "/",
                    //   exact: true,
                    //   component: () => <Redirect to="/dashboard-analytics" />,
                    // },
                    {
                        path: "/",
                        exact: true,
                        component: () => <Redirect to="/login" />,
                    },

                    { path: "/*", component: Basic404 },
                ];
            }
        }
        else {
            return [
                { path: "/dashboard", component: Dashboard },
                { path: "/dashboard/", component: Dashboard },
                { path: "/businesses", component: Businesses },
                { path: "/businesses/", component: Businesses },
                { path: "/businesses/businessesdetail", component: BussinessDetail },
                { path: "/businesses/businessesdetail/", component: BussinessDetail },
                { path: "/businesses/addbusiness", component: AddBusinesses },
                { path: "/businesses/addbusiness/", component: AddBusinesses },
                { path: "/businesses/editbusiness", component: AddBusinesses },
                { path: "/businesses/editbusiness/", component: AddBusinesses },
                { path: "/customers", component: Customers },
                { path: "/customers/", component: Customers },
                { path: "/fleet", component: Fleet },
                { path: "/fleet/", component: Fleet },
                { path: "/rfid", component: Rfid },
                { path: "/rfid/", component: Rfid },
                { path: "/rfidmanagement", component: RFIDManagement },
                { path: "/rfidmanagement/", component: RFIDManagement },
                { path: "/profile", component: Profile },
                { path: "/profile/", component: Profile },
                { path: "/location/addlocation", component: AddLocation },
                { path: "/location/addlocation/", component: AddLocation },
                { path: "/location/editlocation", component: AddLocation },
                { path: "/location/editlocation/", component: AddLocation },
                { path: "/location", component: Location },
                { path: "/location/", component: Location },
                { path: "/location/viewlocation", component: ViewLocation },
                { path: "/location/viewlocation/", component: ViewLocation },
                { path: "/stations", component: Station },
                { path: "/stations/", component: Station },
                { path: "/stations/viewstation", component: ViewStation },
                { path: "/stations/viewstation/", component: ViewStation },
                { path: "/stations/addstation", component: AddStation },
                { path: "/stations/addstation/", component: AddStation },
                { path: "/stations/editstation", component: AddStation },
                { path: "/stations/editstation/", component: AddStation },
                { path: "/onboarding", component: OnBoarding },
                { path: "/onboarding/", component: OnBoarding },
                { path: "/tariff", component: Tariff },
                { path: "/tariff/", component: Tariff },
                { path: "/tariff/addtariff", component: AddTariff },
                { path: "/tariff/addtariff/", component: AddTariff },
                { path: "/tariff/edittariff", component: AddTariff },
                { path: "/tariff/edittariff/", component: AddTariff },
                { path: "/assets/connectortype", component: ConnectorType },
                { path: "/assets/connectortype/", component: ConnectorType },
                { path: "/assets/addevsemodel", component: AddEVSEModel },
                { path: "/assets/addevsemodel/", component: AddEVSEModel },
                { path: "/assets/editevsemodel", component: AddEVSEModel },
                { path: "/assets/editevsemodel/", component: AddEVSEModel },
                { path: "/assets/editevseconnector", component: AddEVSEModel },
                { path: "/assets/editevseconnector/", component: AddEVSEModel },
                { path: "/assets/evsemodel", component: EVSEPage },
                { path: "/assets/evsemodel/", component: EVSEPage },
                { path: "/assets/evsemodel/viewevse", component: ViewEVSE },
                { path: "/assets/evsemodel/viewevse/", component: ViewEVSE },
                // this route should be at the end of all other routes
                // eslint-disable-next-line react/display-name
                // {
                //   path: "/",
                //   exact: true,
                //   component: () => <Redirect to="/dashboard-analytics" />,
                // },
                {
                    path: "/",
                    exact: true,
                    component: () => <Redirect to="/login" />,
                },
                { path: "/*", component: Basic404 },
            ];
        }
    }, [fullState.Login.isLoggedIn])

    useEffect(() => {
        // let index = authProtectedRoutes.findIndex((value) => value.path === pathname);
        let index = authRoutes.findIndex((value) => value.path === pathname);
        if (index === -1) {
            setRouteIndex(true);
        } else {
            setRouteIndex(false);
        }
    }, [pathname, authRoutes])
    /*
    layout settings
    */
    useEffect(() => {
        if (
            layoutType ||
            leftSidebarType ||
            layoutModeType ||
            layoutWidthType ||
            layoutPositionType ||
            topbarThemeType ||
            leftsidbarSizeType ||
            leftSidebarViewType) {
            dispatch(changeLeftsidebarViewType(leftSidebarViewType));
            dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
            dispatch(changeSidebarTheme(leftSidebarType));
            dispatch(changeLayoutMode(layoutModeType));
            dispatch(changeLayoutWidth(layoutWidthType));
            dispatch(changeLayoutPosition(layoutPositionType));
            dispatch(changeTopbarTheme(topbarThemeType));
            dispatch(changeLayout(layoutType));
        }
    }, [layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        dispatch]);
    /*
    call dark/light mode
    */
    const onChangeLayoutMode = (value) => {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value));
        }
    };
    return (
        <React.Fragment>
            {
                !routeIndex ? <div id="layout-wrapper">
                    {pathname === '/onboarding' ?
                        <>
                            {props.children}</> :
                        <>
                            <Header
                                headerClass={headerClass}
                                layoutModeType={layoutModeType}
                            // onChangeLayoutMode={onChangeLayoutMode}
                            />
                            <Sidebar layoutType={layoutType} />
                            <div className={pathname === '/onboarding' ? "main-content onBoarding" : "main-content"}>
                                {props.children}
                            </div>
                            <Footer /></>

                    }
                </div>
                    : <div>{props.children}</div>
            }

            {/* <RightSidebar /> */}
        </React.Fragment>

    );
};

Layout.propTypes = {
    children: PropTypes.object,
};

export default withRouter(Layout);