import {
  ALL_BUSINESS_LIST,
  BUSINESS_LIST_SUCCESS,
  ADD_NEW_BUSINESS,
  GET_BUSINESS_DETAIL,
  BUSINESS_DETAIL_SUCCESS,
  EDIT_BUSINESS,
  GET_MATCH_EMAIL,
  GET_MATCH_EMAIL_SUCC,
  BUSINESS_RESEND_INVITE,
  GET_BUSINESS_LOGO,
  DELETE_BUSINESS,
  ACTIVATE_DEACTIVATE_BUSINESS,
  BUSINESS_LIST_FAILURE,
} from "./actionTypes";

export const allBusinessList = (data) => {
  return {
    type: ALL_BUSINESS_LIST,
    payload: { data },
  };
};

export const getBusinessLogo = (history) => {
  return {
    type: GET_BUSINESS_LOGO,
    payload: {},
  };
};

export const BusinessListSuccess = (data) => {
  return {
    type: BUSINESS_LIST_SUCCESS,
    payload: data,
  };
};

export const BusinessListFailure = (data) => {
  return {
    type: BUSINESS_LIST_FAILURE,
    payload: data,
  };
};

export const addNewBusiness = (user, history) => {
  return {
    type: ADD_NEW_BUSINESS,
    payload: { user, history },
  };
};

export const getBusinessDetails = (history, businessId) => {
  console.log(businessId);
  return {
    type: GET_BUSINESS_DETAIL,
    payload: { history, businessId },
  };
};

export const businessDetailSuccess = (data) => {
  return {
    type: BUSINESS_DETAIL_SUCCESS,
    payload: data,
  };
};

export const editBusiness = (user, history, businessId) => {
  return {
    type: EDIT_BUSINESS,
    payload: { user, history, businessId },
  };
};

export const getMatchEmail = (role, email) => {
  return {
    type: GET_MATCH_EMAIL,
    payload: { role, email },
  };
}; // GET_MATCH_EMAIL_SUCC

export const getMatchEmailSuccess = (data) => {
  return {
    type: GET_MATCH_EMAIL_SUCC,
    payload: { data },
  };
};

export const businessResendInvite = (data) => {
  return {
    type: BUSINESS_RESEND_INVITE,
    payload: { data },
  };
};

export const deleteBusinessAction = (data) => {
  return {
    type: DELETE_BUSINESS,
    payload: { data },
  };
};
export const activateDeactivateBusinessAction = (data) => {
  console.log("businessId,", data);
  return {
    type: ACTIVATE_DEACTIVATE_BUSINESS,
    payload: data,
  };
};
