import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import DeleteModal from "../../../Components/Common/DeleteModal";
import QRCode from "qrcode.react";
//import Components
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DisableModal from "../../../Components/Common/DisableModal";
import { useDispatch, useSelector } from "react-redux";
import {
  clearfirmwareStatus,
  listAllStation,
  showToast,
  activateDeactivateStationAction,
  deleteStationAction,
  getReservation,
  updateReservation,
  deleteReservation
} from "../../../store/actions";
import OutsideClickHandler from "react-outside-click-handler";
import CustomDropdown from "../../../Components/Common/CustomDropdown";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  detailViewStation,
  listAllTariffs,
  getStatConnector,
  AssingTariff,
} from "../../../store/actions";
import moment from "moment";
import useWindowDimension from "../../../Components/Hooks/useWindowDimension";
import { getUserRole } from "../../../helpers/helper.service";
import ContentLoader from "../../../Components/Common/ContentLoader";
import socket from "../../../socketConfig";
import TableContainer from "../../../Components/Common/TableContainer";
import Constant from "../../../utils/constant";
import classnames from "classnames";
import { object } from "yup/lib/locale";
// import setStationConfiguration from "../../../App"



const ViewStation = () => {
  document.title = "View Station | Lubi EV Solutions";
  const dimensions = useWindowDimension();
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [qrModel, setQrModel] = useState();
  const [opsDelete, setOpsDelete] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalUser, setdeleteModalUser] = useState(false);
  const [disabledModal, setDisabledModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [viewRfidModal, setViewRfidModal] = useState(false);
  const [qrValue, setQrValue] = useState("jeftar");
  const [status] = useState(true);
  const [isChecked, setIsChecked] = useState(true);
  const [reservationCheck, setReservationCheck] = useState(false);
  const [timeModal, setTimeModal] = useState(false);
  const [orderTab, setOrderTab] = useState(0);
  const [qrtempValue, setQrtempValue] = useState("");
  const [selectedGroup2, setSelectedGroup2] = useState(null);
  const [assignTariif, setAssignTariif] = useState(false);

  const [timerDetail, setTimerDetail] = useState([]);
  const [timerDetailFull, setTimerDetailFull] = useState([]);
  const [getConnectors, setGetConnectors] = useState([]);
  const [tariffssList, setTariffssList] = useState([]);
  const [getBusinessID, setGetBusinessID] = useState("");
  const [headerHeight, SetHeaderHeight] = useState(0);
  const [breadCrumb, setBreadCrumbHeight] = useState(0);
  const [footerHeight, SetFooterHeight] = useState(0);
  const [cardHeader, setCardHeader] = useState(0);
  const [firmwareData, setFirmwareData] = useState({});

  const [customLOader, setCustomLoader] = useState(false);
  const [noData] = useState(true);
  const [stationStatus, setStationStatus] = useState(false);
  const [enableModal, setEnableModal] = useState(false);
  const [statusMondayData1, setstatusMondayData1] = useState([]);

  const [configKeyUpdate, SetconfigKeyUpdate] = useState([]);
  const [allHoursData, setAllHoursData] = useState([]);


  const [deleteReservationModel, setDeleteReservationModel] = useState(false);
  const [deleteReservationData, setDeleteReservationData] = useState({});


  const [initialValuesObj, setInitialValues] = useState({
    AuthorizeRemoteTxRequests: false,
    AllowOfflineTxForUnknownId: false,

    LocalAuthorizeOffline: false,
    StopTransactionOnInvalidId: false,

    TransactionMessageAttempts: "123",
    TransactionMessageRetryInterval: "",
    MeterValuesSampledDataMaxLength: "",
    NumberOfConnectors: "",
    UnlockConnectorOnEVSideDisconnect: false,
    StopTransactionOnEVSideDisconnect: false,
    AuthorizationCacheEnabled: false,

    MinimumStatusDuration: "",
    supportedFeatureProfiles: "",
    stopTxnAlignedData: "",
    stopTxnSampledData: "",
    meterValuesAlignedData: "",
    meterValuesSampledData: "",
    ClockAlignedDataInterval: "",
    MeterValueSampleInterval: "",
    LightIntensity: "",
    BlinkRepeat: "",
    ResetRetries: "",
    ConnectionTimeOut: "",
    HeartbeatInterval: "",
    CustomKeyInput: "",
    CustomValueInput: "",
    LocalPreAuthorize: false,
  });
  const [filterDateReserve, setFilterDateReserve] = useState(moment(new Date()).format('YYYY-MM-DD'));


  const extraRow = {
    label: "",
    value: ""
  }
  const customKeyValueData = [
    { id: 1 },
  ]
  const [customLabelValueList, setcustomLabelValueList] = useState([])
  const [newLabelValue, setNewLabelValue] = useState([])

  const [customLabelValueListCheck, setcustomLabelValueListCheck] = useState(false)

  const [machineNotconfig, setMachineNotconfig] = useState(false)


  const addRowForLabelValueList = () => {
    setcustomLabelValueList((prev) => prev.concat([extraRow]))
  }

  const removeRowForLabelValueList = (index) => {
    setcustomLabelValueList((prev) => prev.filter((prevItem, prevItemIndex) => prevItemIndex !== index));
  }

  const updateRowForLabelValueList = (inputlabel, inputvalue, index) => {
    setcustomLabelValueListCheck(true)
    setcustomLabelValueList((prev) => prev.map((prevItem, prevItemIndex) => {
      if (prevItemIndex == index) {
        return {
          label: inputlabel !== undefined ? inputlabel : "",
          value: inputvalue !== undefined ? inputvalue : ""
        }
      } else {
        return prevItem

      }
    }));
  }
  // configarkey
  const [isconfigarkeyChecked, setIsconfigarkeyChecked] = useState(true);

  const [currentStationSocketData, setcurrentStationSocketData] =
    useState(undefined);
  const [noConnectorSocketDataMessage, setnoConnectorSocketDataMessage] =
    useState("Unavailable");
  const [stationOnline, setstationOnline] = useState("Offline");

  useEffect(() => {
    SetHeaderHeight(
      document.getElementsByClassName("navbar-header")[0]?.offsetHeight
    );
    setCardHeader(
      document.getElementsByClassName("cardHeader")[0]?.offsetHeight
    );
    SetFooterHeight(document.getElementsByClassName("footer")[0]?.offsetHeight);
    setBreadCrumbHeight(
      document.getElementsByClassName("breadCrumb")[0]?.offsetHeight
    );
  }, []);


  const handleResize = () => {
    SetHeaderHeight(
      document.getElementsByClassName("navbar-header")[0]?.offsetHeight
    );
    setCardHeader(
      document.getElementsByClassName("cardHeader")[0]?.offsetHeight
    );
    SetFooterHeight(document.getElementsByClassName("footer")[0]?.offsetHeight);
    setBreadCrumbHeight(
      document.getElementsByClassName("breadCrumb")[0]?.offsetHeight
    );
  };


  useEffect(() => {
    window.addEventListener("resize", handleResize);
    dispatch(listAllStation(`?locationId=`));


  }, []);




  useEffect(() => {
    setTimeout(() => {
      setCustomLoader(true);
    }, 2000);
  }, []);
  useEffect(() => {
    const stationID = state?.state?.stationId;
    const EvseID = state?.state?.EvseID;
    //
    dispatch(detailViewStation(stationID));
    dispatch(getStatConnector(stationID));
  }, []);

  const {
    stationDetails,
    getStationConnector,
    allTariffList,
    socketData,
    socketStatusData,
    socketCreds,
    firmwareStatus,
    getallReservationData,
    deleteSucess
  } = useSelector((state) => (
    {
      stationDetails: state?.ManageStations?.DetailStation[0],
      getStationConnector: state?.ManageStations?.getStationConn?.data,
      allTariffList: state?.ManageTariffs?.listAllTariff,
      socketData: state?.ManageStations?.getSocketHeartStatus?.data,
      socketStatusData: state?.ManageStations?.getSocketStatus?.data,
      // socketData: JSON.parse(window.localStorage.getItem('socketHeart')),
      socketCreds: state?.ManageStations?.socketCreds,
      deleteSucess: state?.ManageStations?.deleteSucess,

      firmwareStatus: state?.ManageStations?.firmwareStatus,
      getallReservationData: state?.ManageStations?.listAllReservation,
    }
  ));

  useEffect(() => {
    dispatch(getReservation(`?stationId=${state?.state?.stationId}&date=${filterDateReserve}&connectorId=${orderTab}`));

  }, [deleteSucess])

  useEffect(() => {
    if (getStationConnector !== undefined) {
      dispatch(getReservation(`?stationId=${state?.state?.stationId}&date=${filterDateReserve}&connectorId=${getStationConnector[0]?.connectorId}`));
    }
  }, [getStationConnector])
  useEffect(() => {
    let statusMondayData11 = [];
    const startTime = 0; // Assuming it starts from 00:00
    const endTime = 24 * 60; // 24 hours * 60 minutes
    const slotDuration = 30;
    let idCounter = 1;

    // Loop to create slots
    for (let i = startTime; i < endTime; i += slotDuration) {
      const hourStart = Math.floor(i / 60);
      const minuteStart = i % 60;
      const hourEnd = Math.floor((i + slotDuration) / 60);
      const minuteEnd = (i + slotDuration) % 60;
      const adjustedHourEnd = hourEnd === 24 ? 0 : hourEnd;
      const adjustedMinuteEnd = hourEnd === 24 ? 0 : minuteEnd;

      const slot = {
        id: idCounter.toString().padStart(2, '0'),
        time: hourStart.toString().padStart(2, '0') + ':' + minuteStart.toString().padStart(2, '0') + ' - ' +
          adjustedHourEnd.toString().padStart(2, '0') + ':' + adjustedMinuteEnd.toString().padStart(2, '0'),
        name: "",
        slotActive: false,
        noOfHours: 0.5
      };
      statusMondayData11.push(slot);
      idCounter++;
    }

    // Set initial state
    setAllHoursData(statusMondayData11);

  }, [timerDetailFull]);


  useEffect(() => {
    let finalAllHoursData = [];
    // Fetch data
    // let today = new Date().toLocaleString('en-us', { weekday: 'long' });
    let today = moment(filterDateReserve).format('dddd');
    let todayEntry = timerDetailFull.find(entry => entry.value === today);
    if (todayEntry) {
      let secondValue = todayEntry.secondValue;
      finalAllHoursData = [...allHoursData];
      // if (secondValue === "24 Hours") {
      //   finalAllHoursData = [...allHoursData]; // Use the initial data
      // } else if (secondValue === "Closed") {
      //   finalAllHoursData = [...allHoursData];
      // } else {
      //   // let [startTime, endTime] = secondValue.split(' - ');
      //   finalAllHoursData = [...allHoursData]
      // if (startTime.includes('pm') || startTime.includes('am')) {
      //   let [startHour, startMinute] = startTime.split(/:| /).map(Number);
      //   if (startTime.includes('pm') && startHour !== 12) {
      //     startHour += 12;
      //   }
      //   startTime = `${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}`;
      // }
      // if (endTime.includes('pm') || endTime.includes('am')) {
      //   let [endHour, endMinute] = endTime.split(/:| /).map(Number);
      //   if (endTime.includes('pm') && endHour !== 12) {
      //     endHour += 12;
      //   }
      //   endTime = `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;
      // }

      // finalAllHoursData = allHoursData.filter(entry => {
      //   let entryHour = parseInt(entry.time.split(':')[0]);
      //   let entryMinute = parseInt(entry.time.split(' - ')[0].split(':')[1]);
      //   let entryTime = entryHour * 60 + entryMinute;
      //   let startTimeInMinutes = parseInt(startTime.split(':')[0]) * 60 + parseInt(startTime.split(':')[1]);
      //   let endTimeInMinutes = parseInt(endTime.split(':')[0]) * 60 + parseInt(endTime.split(':')[1]);
      //   return entryTime >= startTimeInMinutes && entryTime <= endTimeInMinutes;
      // });
      // }

    } else {
      // console.log("Today's schedule is not available.");
    }

    let newArray = [];
    let newArray2 = finalAllHoursData.map((bi) => ({
      ...bi,
      startTime: bi.time.split(' - ')[0],
      endTime: bi.time.split(' - ')[1]
    }));

    getallReservationData.forEach((itmA) => {

      newArray2.forEach((itemB) => {
        const reserveStartTime = moment.utc(itmA.reserveStartTime).format('HH:mm');
        const reserveEndTime = moment.utc(itmA.reserveEndTime).format('HH:mm');
        const slotStartTime = itemB.startTime;
        const slotEndTime = itemB.endTime;
        let secondValue = todayEntry.secondValue;
        if (reserveStartTime < slotEndTime && reserveEndTime > slotStartTime) {
          if (secondValue === "Closed") {
            newArray.push({ id: itemB.id, userId: itmA.userId, transId: itmA.transId, rCharges: itmA.reservationCharges, cCharges: itmA.reservationCancel, connectorNumber: itmA.connectorNumber, reservationId: itmA.reservationId, name: itmA.firstName + ' ' + itmA.lastName, noOfHours: 0.5, slotActive: true, time: itemB.time, blockActive: false });

          } else {

            newArray.push({ id: itemB.id, userId: itmA.userId, transId: itmA.transId, rCharges: itmA.reservationCharges, cCharges: itmA.reservationCancel, connectorNumber: itmA.connectorNumber, reservationId: itmA.reservationId, name: itmA.firstName + ' ' + itmA.lastName, noOfHours: 0.5, slotActive: true, time: itemB.time, blockActive: true });
          }
        }
      });
    });

    let c = finalAllHoursData.map(itemA => {
      let matchingItem = newArray.find(itemB => itemB.id === itemA.id);

      if (matchingItem) {
        return { ...matchingItem };
      } else {
        return { ...itemA, userId: matchingItem?.userId };
      }
    });


    // Usage: Call mergeSlots function with your array of slots
    let mergedSlots = mergeSlots(c);

    setstatusMondayData1(mergedSlots)



  }, [getallReservationData, timerDetailFull])
  function mergeSlots(slots) {
    let mergedSlots = [];
    let currentSlot = null;

    for (let slot of slots) {
      if (slot.slotActive) {
        if (currentSlot === null) {
          currentSlot = { ...slot };
        } else {
          const currentUserId = currentSlot.userId;
          const currentSlotEnd = parseInt(currentSlot.time.split(' - ')[1].split(':')[0]);
          const slotStart = parseInt(slot.time.split(' - ')[0].split(':')[0]);

          // Check if time slots are consecutive and userIds match
          if (currentSlotEnd === slotStart && currentUserId === slot.userId) {
            currentSlot.time = `${currentSlot.time.split(' - ')[0]} - ${slot.time.split(' - ')[1]}`;
          } else {
            mergedSlots.push(currentSlot);
            currentSlot = { ...slot };
          }
        }
      } else {
        if (currentSlot !== null) {
          mergedSlots.push(currentSlot);
          currentSlot = null;
        }
        mergedSlots.push(slot);
      }
    }

    if (currentSlot !== null) {
      mergedSlots.push(currentSlot);
    }

    return mergedSlots;
  }
  // function mergeSlots(slots) {
  //   let mergedSlots = [];
  //   let currentSlot = null;

  //   for (let slot of slots) {
  //     if (slot.slotActive) {
  //       if (currentSlot === null) {
  //         currentSlot = { ...slot };
  //       } else {
  //         currentSlot.time = `${currentSlot.time.split(' - ')[0]} - ${slot.time.split(' - ')[1]}`;
  //       }
  //     } else {
  //       if (currentSlot !== null) {
  //         mergedSlots.push(currentSlot);
  //         currentSlot = null;
  //       }
  //       mergedSlots.push(slot);
  //     }
  //   }

  //   if (currentSlot !== null) {
  //     mergedSlots.push(currentSlot);
  //   }

  //   return mergedSlots;
  // }


  // set station status
  useEffect(() => {
    try {
      if (
        socketData !== undefined &&
        getConnectors.length > 0 &&
        stationDetails !== undefined
      ) {

        // const result = getConnectors.some(
        //   (conn) =>
        //     socketData &&
        //     socketData[
        //     `${stationDetails.stationName}_${conn.connectorNumber}`
        //     ] !== "Unavailable" &&
        //     socketData[
        //     `${stationDetails.stationName}_${conn.connectorNumber}`
        //     ] !== undefined
        // );

        let result = socketData?.some(item => item.chargerId === stationDetails.stationName && item.updateResp === 'Accepted');
        // setOrderTab(getConnectors[0].connectorId)
        setstationOnline(result ? "Online" : "Offline");
      } else {
        setstationOnline("Offline");
      }
    } catch (error) {
      console.log(error, 'catch Useeffect')
    }

  });


  useEffect(() => {
    setReservationCheck(stationDetails?.isReservable === "true" ? true : false)

    if (stationDetails?.isReservable === "false") {
      toggleTab("2");
      getsocketDate()
    } else {
      toggleTab("1");
    }
  }, [stationDetails]);



  useEffect(() => {
    if (stationDetails?.stationName === socketData?.stationName) {
      setStationStatus();
    }
  }, []);

  useEffect(() => {
    if (getStationConnector?.length > 0) {
      setGetConnectors(getStationConnector);
      //
      if (!assignTariif) {
        setOrderTab(getStationConnector[0]?.connectorId);
      }
      setSelectedGroup2(null);
      if (getStationConnector[0]?.tariff !== null) {
        const currentTariff = allTariffList
          .filter((e) => e?.tariffId === getStationConnector[0]?.tariff)
          .map((e) => e.tariffName);
        setSelectedGroup2({
          id: `${getStationConnector[0]?.tariff}`,
          value: currentTariff,
        });
      }
    }
    //
  }, [getStationConnector]);
  // }, [])

  // redirect back to listing if no id is found in case of manual copying url from previos tab
  useLayoutEffect(() => {
    if (state == undefined) {
      history.push("/stations");
    }

  }, []);

  useEffect(() => {

    getsocketDate()


    if (stationDetails?.businessId) {
      // (!getUserRole() || getUserRole() === 'LM') && dispatch(listAllTariffs(`?businessId=${stationDetails?.businessId}`));
      localStorage.getItem("users") !== null &&
        localStorage.getItem("users") !== undefined &&
        JSON.parse(localStorage.getItem("users")).userRole !== "SuperAdmin" &&
        dispatch(listAllTariffs(`?businessId=${stationDetails?.businessId}`));
      setGetBusinessID(stationDetails?.businessId);
    }
    if (stationDetails?.timings) {
      const getDay = new Date().getDay();
      setTimerDetail(
        JSON.parse(stationDetails?.timings)?.filter((e) => e.id == getDay)
      );

      const LocServiceTime = JSON.parse(stationDetails?.timings).map(
        (evt, ind) => {
          return {
            id: `${evt.id}`,
            value: evt.dayCheck
              ? evt.fullDayCheck
                ? `${evt?.day}`
                : `${evt?.day}`
              : `${evt?.day}`,
            secondValue: evt.dayCheck
              ? evt.fullDayCheck
                ? `24 Hours`
                : `${moment(evt?.startTime).format("hh:mm a")} - ${moment(
                  evt?.endTime
                ).format("hh:mm a")}`
              : `Closed`,
          };
        }
      );
      setTimerDetailFull(LocServiceTime);
    }
  }, [stationDetails]);

  useEffect(() => {
    const tariffOptions = allTariffList.map((e, i) => {
      return { id: `${e.tariffId}`, value: e.tariffName };
    });
    setTariffssList(tariffOptions);
  }, [allTariffList]);

  // firmware submit
  const updateForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      version: "http://113.20.17.179:8080/fota.bin",
    },
    validationSchema: Yup.object({
      version: Yup.string(),
    }),
    onSubmit: (values) => {
      const data = {
        cpId: stationDetails.stationName,
        socketId: socketCreds.id,
        url: values.version,
      };



      setTimeout(() => {
        dispatch(clearfirmwareStatus());
      }, 60000);
      onSuccess();
    },
  });
  const onSuccess = (values) => {
    // Success Toast
    setUpdateModal(false);
    dispatch(
      showToast({
        message: "Firmware version updated successfully.",
        type: "success",
      })
    );
  };
  const handleOnChange = (event) => {
    const { value } = event.target;
    setQrValue(value);
  };

  const handleDeleteContact = async () => {
    if (stationOnline === 'Online') {
      setDeleteModal(false);
      setdeleteModalUser(false);
      dispatch(
        showToast({
          message: 'Unable to delete station as there are Online connectors associated with it.',
          type: "error",
        })
      );

    } else {
      const res = await dispatch(
        deleteStationAction(
          `?locationId=${stationDetails?.locationId}&stationId=${stationDetails?.stationId}`
        )
      );
      //           if(res){
      setTimeout(() => {
        history.push("/stations");
      }, 1000);
      //           }   else{
      //  setTimeout(() => {
      //    const stationID = state?.state?.stationId;

      //    dispatch(detailViewStation(stationID));
      //    dispatch(getStatConnector(stationID));
      //  }, 1000);
      //           }
      setDeleteModal(false);
      setdeleteModalUser(false);
    }

  };

  const handleDisableContact = () => {
    if (stationOnline === 'Online') {
      setDisabledModal(false);
      dispatch(
        showToast({
          message: 'Unable to de-activate station as there are Online connectors associated with it.',
          type: "error",
        })
      );
    } else {
      setIsChecked(!isChecked);
      dispatch(
        activateDeactivateStationAction(
          `?status=Deactivate&locationId=${stationDetails?.locationId}&stationId=${stationDetails?.stationId}`
        )
      );

      setTimeout(() => {
        if (state !== undefined) {
          const stationID = state?.state?.stationId;

          dispatch(detailViewStation(stationID));
          dispatch(getStatConnector(stationID));
        }
      }, 1000);

      setDisabledModal(false);
    }

  };
  const handleEnableContact = () => {
    setIsChecked(!isChecked);
    dispatch(
      activateDeactivateStationAction(
        `?status=Activate&locationId=${stationDetails?.locationId}&stationId=${stationDetails?.stationId}`
      )
    );
    setTimeout(() => {
      if (state !== undefined) {
        const stationID = state?.state?.stationId;

        dispatch(detailViewStation(stationID));
        dispatch(getStatConnector(stationID));
      }
    }, 1000);
    setEnableModal(false);
  };







  // const handleDisableContact = () => {
  //   if (isChecked === true) {
  //     setIsChecked(!isChecked);
  //           dispatch(
  //             activateDeactivateStationAction(
  //               `?status=Deactivate&locationId=${stationDetails?.locationId}&stationId=${stationDetails?.stationId}`
  //             )

  //           );

  //     setTimeout(() => {
  //         const stationID = state?.state?.stationId;

  //     dispatch(detailViewStation(stationID));
  //     dispatch(getStatConnector(stationID));}, 1000);

  //     setDisabledModal(false);
  //   } else {
  //     setIsChecked(!isChecked);
  //           dispatch(
  //             activateDeactivateStationAction(
  //               `?status=Activate&locationId=${stationDetails?.locationId}&stationId=${stationDetails?.stationId}`
  //             )
  //           );
  //          setTimeout(() => {
  //       const stationID = state?.state?.stationId;

  //       dispatch(detailViewStation(stationID));
  //       dispatch(getStatConnector(stationID));
  //     }, 1000);
  //     setDisabledModal(false);

  //   }
  // };

  const setAssignTariff = (evt, Connid, cpId) => {
    const params = {
      tariffId: evt?.id,
      connectorId: Connid,
      cpId: cpId,
    };
    dispatch(AssingTariff(params));

    setTimeout(() => {
      dispatch(getStatConnector(state?.state?.stationId));
    }, 1000);
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${qrtempValue}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const [nextSevenDays, setNextSevenDays] = useState([]);
  useEffect(() => {
    const generateNextSevenDays = () => {
      const today = new Date();
      const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

      const nextSevenDaysArray = Array.from({ length: 7 }, (_, index) => {
        const currentDate = new Date(today);
        currentDate.setDate(today.getDate() + index);

        return {
          date: currentDate.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }),
          day: daysOfWeek[currentDate.getDay()],
        };
      });

      setNextSevenDays(nextSevenDaysArray);
    };

    generateNextSevenDays();
  }, []);
  const connectionData1 = [
    {
      id: "1",
      type: "IEC_62196_T2_COMBO",
      format: "Socket",
      powerType: "DC",
      maxVoltage: "240.00V",
      maxAmperage: "125.00A",
      macElectricPower: "20.00 KW",
      cStatus: true,
    },
    {
      id: "2",
      type: "IEC_62196_T2_COMBO",
      format: "Socket",
      powerType: "DC",
      maxVoltage: "240.00V",
      maxAmperage: "125.00A",
      macElectricPower: "20.00 KW",
      cStatus: false,
    },
    {
      id: "3",
      type: "IEC_62196_T2_COMBO",
      format: "Socket",
      powerType: "DC",
      maxVoltage: "240.00V",
      maxAmperage: "125.00A",
      macElectricPower: "20.00 KW",
      cStatus: false,
    },
    {
      id: "4",
      type: "IEC_62196_T2_COMBO",
      format: "Socket",
      powerType: "DC",
      maxVoltage: "240.00V",
      maxAmperage: "125.00A",
      macElectricPower: "20.00 KW",
      cStatus: true,
    },
    {
      id: "5",
      type: "IEC_62196_T2_COMBO",
      format: "Socket",
      powerType: "DC",
      maxVoltage: "240.00V",
      maxAmperage: "125.00A",
      macElectricPower: "20.00 KW",
      cStatus: false,
    },
    {
      id: "6",
      type: "IEC_62196_T2_COMBO",
      format: "Socket",
      powerType: "DC",
      maxVoltage: "240.00V",
      maxAmperage: "125.00A",
      macElectricPower: "20.00 KW",
      cStatus: true,
    },
    {
      id: "7",
      type: "IEC_62196_T2_COMBO",
      format: "Socket",
      powerType: "DC",
      maxVoltage: "240.00V",
      maxAmperage: "125.00A",
      macElectricPower: "20.00 KW",
      cStatus: false,
    },
  ];
  const statusDay = [
    {
      id: "1",
      day: "SUN",
      dNumber: 1,
      isActive: false,
    },
    {
      id: "2",
      day: "MON",
      dNumber: 2,
      isActive: false,
    },
    {
      id: "3",
      day: "TUE",
      dNumber: 3,
      isActive: true,
    },
    {
      id: "4",
      day: "WED",
      dNumber: 4,
      isActive: false,
    },
    {
      id: "5",
      day: "THU",
      dNumber: 5,
      isActive: false,
    },
    {
      id: "6",
      day: "FRI",
      dNumber: 6,
      isActive: false,
    },
    {
      id: "7",
      day: "SAT",
      dNumber: 7,
      isActive: false,
    },
  ];
  const statusTime = [
    {
      id: "1",
      dTime: "08:00 - 08:30",
      isBooked: true,
    },
    {
      id: "2",
      dTime: "08:30 - 09:00",
      isBooked: false,
    },
    {
      id: "3",
      dTime: "09:00 - 09:30",
      isBooked: true,
    },
    {
      id: "4",
      dTime: "09:30 - 10:00",
      isBooked: true,
    },
    {
      id: "5",
      dTime: "10:00 - 10:30",
      isBooked: false,
    },
    {
      id: "6",
      dTime: "10:30 - 11:00",
      isBooked: true,
    },
    {
      id: "7",
      dTime: "11:00 - 11:30",
      isBooked: false,
    },
    {
      id: "8",
      dTime: "11:30 - 12:00",
      isBooked: false,
    },
    {
      id: "9",
      dTime: "12:00 - 12:30",
      isBooked: false,
    },
    {
      id: "10",
      dTime: "12:30 - 01:00",
      isBooked: false,
    },
    {
      id: "11",
      dTime: "01:00 - 01:30",
      isBooked: false,
    },
    {
      id: "12",
      dTime: "01:30 - 02:00",
      isBooked: false,
    },
    {
      id: "13",
      dTime: "02:00 - 02:30",
      isBooked: false,
    },
    {
      id: "14",
      dTime: "02:30 - 03:00",
      isBooked: false,
    },
    {
      id: "15",
      dTime: "03:00 - 03:30",
      isBooked: true,
    },
    {
      id: "16",
      dTime: "03:30 - 04:00",
      isBooked: false,
    },
    {
      id: "17",
      dTime: "04:00 - 04:30",
      isBooked: false,
    },
    {
      id: "18",
      dTime: "04:30 - 05:00",
      isBooked: true,
    },
    {
      id: "19",
      dTime: "05:00 - 05:30",
      isBooked: true,
    },
    {
      id: "20",
      dTime: "05:30 - 06:00",
      isBooked: false,
    },
  ];
  const timeItemData = [
    {
      id: "1",
      day: "Monday",
      slot: "10:00 am - 05:00 pm",
    },
    {
      id: "2",
      day: "Tuesday",
      slot: "10:00 am - 05:00 pm",
    },
    {
      id: "3",
      day: "Wednesday",
      slot: "10:00 am - 05:00 pm",
    },
    {
      id: "4",
      day: "Thursday",
      slot: "10:00 am - 05:00 pm",
    },
    {
      id: "5",
      day: "Friday",
      slot: "10:00 am - 05:00 pm",
    },
    {
      id: "6",
      day: "Saturday",
      slot: "10:00 am - 05:00 pm",
    },
    {
      id: "7",
      day: "Sunday ",
      slot: "10:00 am - 05:00 pm",
    },
  ];
  // const GroupOptions2 = [
  //     { id: '1', value: 'Tariff 1' },
  //     { id: '2', value: 'Tariff 2' },
  //     { id: '3', value: 'Tariff 3' },
  //     { id: '4', value: 'Tariff 4' },
  //     { id: '5', value: 'Tariff 5' },
  //     { id: '6', value: 'Tariff 6' },
  //     { id: '7', value: 'Tariff 7' },
  // ];
  const tabButton = [
    {
      id: "01",
      title: "Connector 1",
    },
    {
      id: "02",
      title: "Connector 2",
    },
  ];
  const tabsContainer = [
    {
      id: "01",
      src: "https://res.cloudinary.com/djyl1goby/image/upload/v1671164263/Lubi/WebApp/Static-images/CCS_4_1.svg",
      status: false,
      type: "IEC_62196_T2_COMBO",
      format: "Socket",
      powerType: "DC",
      maxVolatage: "240.00V",
      maxAmpere: "125.00A",
      electricPower: "20.00 KW",
      qrValue: "example1",
    },
    {
      id: "02",
      src: "https://res.cloudinary.com/djyl1goby/image/upload/v1671164263/Lubi/WebApp/Static-images/CCS_4_1.svg",
      status: true,
      type: "IEC_62196_T2_COMBO",
      format: "Socket",
      powerType: "DC",
      maxVolatage: "240.00V",
      maxAmpere: "125.00A",
      electricPower: "20.00 KW",
      qrValue: "example2",
    },
  ];
  const daySloatData = [
    {
      id: "01",
      day: "SUN",
      date: "10 Dec 2022",
      active: false,
    },
    {
      id: "02",
      day: "MON",
      date: "10 Dec 2022",
      active: false,
    },
    {
      id: "03",
      day: "TUE",
      date: "10 Dec 2022",
      active: true,
    },
    {
      id: "04",
      day: "WED",
      date: "10 Dec 2022",
    },
    {
      id: "05",
      day: "THU",
      date: "10 Dec 2022",
      active: true,
    },
    {
      id: "06",
      day: "FRI",
      date: "10 Dec 2022",
      active: false,
    },
    {
      id: "07",
      day: "SAT",
      date: "10 Dec 2022",
      active: false,
    },
  ];
  const [daySloatList, setDaySloatList] = useState(daySloatData);

  const statusTimeData = [
    {
      id: "01",
      time: "08:00 - 08:30",
      name: "Adam S.",
      slotActive: false,
      noOfHours: 1,
    },
    {
      id: "02",
      time: "08:30 - 09:00",
      name: "Adam S.",
      slotActive: false,
      noOfHours: 1,
    },
    {
      id: "03",
      time: "09:00 - 09:30",
      name: "Adam S.",
      slotActive: false,
      noOfHours: 1,
    },
    {
      id: "04",
      time: "09:30 - 10:00",
      name: "Adam S.",
      slotActive: false,
      noOfHours: 1,
    },
    {
      id: "05",
      time: "10:00 - 10:30",
      name: "Adam S.",
      slotActive: true,
      noOfHours: 1,
    },
    {
      id: "06",
      time: "10:30 - 11:00",
      name: "Adam S.",
      slotActive: false,
      noOfHours: 1,
    },
    {
      id: "07",
      time: "11:00 - 11:30",
      name: "Adam S.",
      slotActive: false,
      noOfHours: 1,
    },
    {
      id: "08",
      time: "11:30 - 12:00",
      name: "Adam S.",
      slotActive: false,
      noOfHours: 1,
    },
    {
      id: "09",
      time: "12:00 - 12:30",
      name: "Adam S.",
      slotActive: false,
      noOfHours: 1,
    },
    {
      id: "10",
      time: "12:30 - 01:00",
      name: "Adam S.",
      slotActive: true,
      noOfHours: 1,
    },
    {
      id: "11",
      time: "01:00 - 01:30",
      name: "Adam S.",
      slotActive: false,
      noOfHours: 1,
    },
    {
      id: "12",
      time: "01:30 - 02:00",
      name: "Adam S.",
      slotActive: false,
      noOfHours: 1,
    },
    {
      id: "13",
      time: "02:30 - 03:00",
      name: "Adam S.",
      slotActive: true,
      noOfHours: 1,
    },
    {
      id: "14",
      time: "03:00 - 03:30",
      name: "Adam S.",
      slotActive: true,
      noOfHours: 1,
    },
    {
      id: "15",
      time: "03:30 - 04:00",
      name: "Adam S.",
      slotActive: false,
      noOfHours: 1,
    },
    {
      id: "16",
      time: "04:00 - 04:30",
      name: "Adam S.",
      slotActive: false,
      noOfHours: 1,
    },
  ];


  const rowNumber = [
    {
      id: "1",
      number: [
        {
          id: "1",
          value: "74ABC945DEF848",
        },
        {
          id: "2",
          value: "74ABC945DEF848",
        },
        {
          id: "3",
          value: "74ABC945DEF848",
        },
        {
          id: "4",
          value: "74ABC945DEF848",
        },
        {
          id: "5",
          value: "74ABC945DEF848",
        },
        {
          id: "6",
          value: "74ABC945DEF848",
        },
      ],
    },
    {
      id: "2",
      number: [
        {
          id: "1",
          value: "74ABC945DEF848",
        },
        {
          id: "2",
          value: "74ABC945DEF848",
        },
        {
          id: "3",
          value: "74ABC945DEF848",
        },
        {
          id: "4",
          value: "74ABC945DEF848",
        },
        {
          id: "5",
          value: "74ABC945DEF848",
        },
        {
          id: "6",
          value: "74ABC945DEF848",
        },
      ],
    },
  ];

  const colorIndicators = {
    Available: "green",
    Preparing: "orange",
    Charging: "orange",
    SuspendedEVSE: "red",
    SuspendedEV: "red",
    Finishing: "orange",
    Reserved: "blue",
    Unavailable: "red",
    Faulted: "red",
    Empty: "black",
  };

  const showfirmwareupdateStatus =
    firmwareStatus.updateResp.status !== "" &&
    stationDetails.stationName == firmwareStatus.StationName;

  // const handleAddButtonClicks = () => {
  //   // history.push('');
  //   setAddTypeModal(true);
  // }

  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [activeDateTab, setActiveDateTab] = useState(moment().format('dddd').toLowerCase());
  const toggleDateTab = (tab, day) => {
    setFilterDateReserve(moment(day.date).format('YYYY-MM-DD'))
    dispatch(getReservation(`?stationId=${state?.state?.stationId}&date=${moment(day.date).format('YYYY-MM-DD')}&connectorId=${orderTab}`));

    if (activeDateTab !== tab) {
      setActiveDateTab(tab);
    }
  };


  useEffect(() => {

  }, [customLabelValueList])


  // Formik validation
  const configurekeyform = useFormik({

    initialValues: {
      AuthorizeRemoteTxRequests: false,
      AllowOfflineTxForUnknownId: false,
      LocalAuthorizeOffline: false,
      StopTransactionOnInvalidId: false,
      TransactionMessageAttempts: "",
      TransactionMessageRetryInterval: "",
      MeterValuesSampledDataMaxLength: "",
      NumberOfConnectors: "",
      UnlockConnectorOnEVSideDisconnect: false,
      StopTransactionOnEVSideDisconnect: false,
      AuthorizationCacheEnabled: false,
      MinimumStatusDuration: "",
      supportedFeatureProfiles: "",
      stopTxnAlignedData: "",
      stopTxnSampledData: "",
      meterValuesAlignedData: "",
      meterValuesSampledData: "",
      ClockAlignedDataInterval: "",
      MeterValueSampleInterval: "",
      LightIntensity: "",
      BlinkRepeat: "",
      ResetRetries: "",
      ConnectionTimeOut: "",
      HeartbeatInterval: "",
      CustomKeyInput: "",
      CustomValueInput: "",
      LocalPreAuthorize: false,
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      AuthorizeRemoteTxRequests: Yup.boolean(),
      AllowOfflineTxForUnknownId: Yup.boolean(),
      LocalAuthorizeOffline: Yup.boolean(),
      StopTransactionOnInvalidId: Yup.boolean(),
      LocalPreAuthorize: Yup.boolean(),
      TransactionMessageAttempts: Yup.string(),
      TransactionMessageRetryInterval:
        Yup.string(),
      MeterValuesSampledDataMaxLength:
        Yup.string(),
      NumberOfConnectors: Yup.string(),
      UnlockConnectorOnEVSideDisconnect: Yup.boolean(),
      StopTransactionOnEVSideDisconnect: Yup.boolean(),
      AuthorizationCacheEnabled: Yup.boolean(),

      MinimumStatusDuration: Yup.string(),
      supportedFeatureProfiles: Yup.string(),
      stopTxnAlignedData: Yup.string(),
      stopTxnSampledData: Yup.string(),
      meterValuesAlignedData: Yup.string(),
      meterValuesSampledData: Yup.string(),
      ClockAlignedDataInterval: Yup.string(),
      MeterValueSampleInterval: Yup.string(),
      LightIntensity: Yup.string(),
      BlinkRepeat: Yup.string(),
      ResetRetries: Yup.string(),
      ConnectionTimeOut: Yup.string(),
      HeartbeatInterval: Yup.string(),
      CustomKeyInput: Yup.string(),
      CustomValueInput: Yup.string(),

      // .matches(
      //   Constant.REGEX.ALPHANUMERIC,
      //   "Alphanumeric character allower only"
      // )
    }),
    onSubmit: (values) => {
      let extraRowContent = {}
      if (customLabelValueListCheck) {
        customLabelValueList.forEach(i => {
          extraRowContent[i?.label] = i?.value
        })
      }

      const { initialValuesObj, ...tempObj } = values;
      // console.log("values=>", values);

      let mergedObject = {
        ...configurekeyform.initialValues.initialValuesObj,
        ...tempObj
      };

      // console.log("mergedObject=>", mergedObject);
      const initialvalues = configurekeyform.initialValues
      const newvalues = { ...values }

      let finalpayload = {}
      let newArray = [];
      let newArray2 = [];
      let newArray3 = [];

      Object.keys(newvalues).forEach((itm) => {
        // if (initialvalues[itm] !== newvalues[itm]) {
        finalpayload[itm] = newvalues[itm]
        newArray.push({ key: itm, readonly: false, value: newvalues[itm] })
        //}
      })

      for (let key in extraRowContent) {
        newArray3.push({
          key: key,
          readonly: false,
          value: extraRowContent[key]
        });
      }

      // Object.keys(extraRowContent).forEach((itm) => {
      //   // if (initialvalues[itm] !== newvalues[itm]) {
      //   finalpayload[itm] = newvalues[itm]
      //   newArray.push({ key: itm, readonly: false, value: newvalues[itm] })
      //   //}
      // })
      // console.log(finalpayload,'finalpayload');
      // configurationSocket.setStationConfiguration(finalpayload)



      configKeyUpdate.forEach(item => {
        if (values[item.key].toString() !== item.value.toString()) {
          newArray2.push(item)

        }
      });

      // SetconfigKeyUpdate()
      newArray.forEach(item => {
        newArray2.forEach(item1 => {
          if (item.key.toString() === item1.key.toString()) {
            // console.log(item,'item!!!!')
            newArray3.push(item)
          }
        });
      });
      // console.log(newArray3, 'newArray3')
      // console.log(mergedObject)
      setStationConfiguration(newArray3)
      setInitialValues(mergedObject)
      SetconfigKeyUpdate(newArray)
      setcustomLabelValueListCheck(false)
    },
  });


  useEffect(() => {
    let newArray2 = [];
    socket.on('getConfigKeysUpdateWEB', (res) => {
      res = JSON.parse(res);

      if (res.msg !== 'No such client is connected to the server') {
        // console.log(res?.updateResp?.configurationKey, 'configurationKey')
        // setFirmwareData(res)
        setMachineNotconfig(true)

        // let a  =configurekeyform.values.HeartbeatInterval
        // object.keys(configurekeyform.values);
        // let array = firmwareData.updateResp.configurationKey;
        let obj = initialValuesObj;

        // console.log(initialValuesObj, 'initialValuesObj')
        // console.log(res.updateResp.configurationKey, 'befiree')
        SetconfigKeyUpdate(res?.updateResp?.configurationKey)

        // setInitialValues(res.updateResp.configurationKey)
        res?.updateResp?.configurationKey?.forEach(item => {
          obj[item.key] = item.value;
          // console.log(obj);


        });
        // console.log(res.updateResp.configurationKey, 'afterrrr')
        let newinitialValuesObj = res?.updateResp?.configurationKey
        // console.log(initialValuesObj, 'initialValuesObj!!!!')

        for (let itm in newinitialValuesObj) {
          if (Object.prototype.hasOwnProperty.call(newinitialValuesObj, itm)) {
            if (newinitialValuesObj[itm] === 'true') {
              newinitialValuesObj[itm] = (newinitialValuesObj[itm] === "true");

            }
            if (newinitialValuesObj[itm] === 'false') {
              newinitialValuesObj[itm] = (newinitialValuesObj[itm] === "false");

            }
          }
        }
        // for (let i = 0; i < res.updateResp.configurationKey.length; i++) {
        //   let key = res.updateResp.configurationKey[i].key;
        //   if (Object.prototype.hasOwnProperty.call(initialValuesObj, key)) {
        //     initialValuesObj.readonly = res.updateResp.configurationKey[i].readonly;
        //   }
        // }
        // console.log(initialValuesObj, 'initialValuesObj')

        const convertedObj = convertValues(initialValuesObj);


        configurekeyform.setValues(convertedObj);



        // configurekeyform.forceUpdate(); 
        // console.log(  configurekeyform.initialValues ,'AFTER!!!')

        // setInitialValues(obj)
        //  configurekeyform.values = obj;
        // console.log(configurekeyform.values,'123')


      } else {
        setMachineNotconfig(false)

        // dispatch(showToast({
        //   message: res.msg,
        //   type: 'error'
        // }));
      }


      // const ressss = firmwareData.updateResp.configurationKey
      // .filter((e) => {
      //   console.log(e.key,'e!!!!')

      // });


      // console.log(ressss.values, 'ressss!!!!')



    })
    function convertValues(obj) {
      const result = {};

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];

          // Convert "true" and "false" strings to boolean
          if (value === "true") {
            result[key] = true;
          } else if (value === "false") {
            result[key] = false;
          } else {
            // Keep other values as they are
            result[key] = value;
          }
        }
      }

      return result;
    }
    socket.on('getfirmwareUpdateWEBMultiple', (res) => {
      res = JSON.parse(res);
      let newObj = []
      if (res.length > 0) {
        res.forEach(item => {
          addRowForLabelValueList()
          if (item.key != '') {
            newObj.push({
              label: item.key,
              value: item.value
            })
          }

          setNewLabelValue(newObj)
          setcustomLabelValueList(newObj)

        });
      }

    })

    return () => {
      socket.off('getConfigKeysUpdateWEB');
      socket.off('getfirmwareUpdateWEBMultiple');
    };
  }, [configurekeyform.values])


  const getsocketDate = async () => {
    if (stationDetails?.stationName !== undefined) {
      let obj1 = { cpId: stationDetails.stationName, url: "http://113.20.17.179:8080/fota.bin" }
      socket.emit('getConfigKeys', JSON.stringify(obj1));

      let obj2 = { cpId: stationDetails.stationName, keys: ['CpStateA', 'CpStateB', 'CpStateC', 'CpStateD'], url: "http://113.20.17.179:8080/fota.bin" }
      socket.emit('getConfigKeysMultiple', JSON.stringify(obj2));

    }
  };




  const reservationFunc = async () => {
    const reservationId = Math.floor(Math.random() * 10000);
    let obj = { cpId: stationDetails.stationName, "idTag": 'KTJ85MUHUOTL', "expiryDate": '2024-05-23T06:20:00.000Z', "connectorId": 1, "reservationId": 9999 }
    socket.emit('setReservation', JSON.stringify(obj))
  }






  const clearCacheSetFunc = async () => {
    let obj = { cpId: 'JP-Lb-ST29785' }
    socket.emit('setclearCache', JSON.stringify(obj))
  }


  const reservationCancelFunc = async () => {
    let obj = { cpId: stationDetails.stationName, "reservationId": 1 }
    socket.emit('cancelReservation', JSON.stringify(obj))
  }


  // useEffect(()=>{

  // },[stationDetails?.stationName])

  socket.on('setfirmwareUpdateWeb', res => {
    if (res.status === 200) {
      if (res.updateResp.status === "Accepted") {
        dispatch(
          showToast({
            message: "Configuration Keys updated successfully!",
            type: "success",
          })
        );
      } else {
        dispatch(
          showToast({
            message: "This key is " + res.updateResp.status,
            type: "error",
          })
        );
      }

    } else {
      dispatch(
        showToast({
          message: res.message,
          type: "error",
        })
      );
    }
  });



  const setStationConfiguration = async (data) => {
    let obj1 = { cpId: `${stationDetails?.stationName}`, socketId: socket.id, url: "http://113.20.17.179:8080/fota.bin" }
    socket.emit('setfirmwareUpdate', JSON.stringify(obj1), JSON.stringify(data));
  };

  function inpNum(e) {
    if (!/[0-9\b\.]/.test(e.key)) {
      e.preventDefault();
      return;
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            onClickBack={(e) => {
              e.preventDefault();
              history.goBack();
            }}
            isButton={true}
            title={stationDetails?.stationName}
            pageTitle={stationDetails?.locationName}
            OnClickpageTitle={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          // OnClickpageTitle2={(e) => {e.preventDefault();history.goBack();}}
          />
          {customLOader ? (
            <div className="viewStationPage">
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardHeader className="cardHeader">
                      <Row>
                        <Col lg={12} className="mb-2">
                          <div className="main">
                            <Row>
                              <Col
                                lg={12}
                                md={12}
                                className="d-flex align-items-center justify-content-start"
                              >
                                <Row>
                                  <Col lg={12}>
                                    <div className="lA">
                                      <p className="lnameText">
                                        {stationDetails?.stationName}
                                      </p>
                                      <p className="txt">
                                        {stationDetails?.region
                                          ? `${stationDetails?.region}, ${stationDetails?.city}`
                                          : `${stationDetails?.city}`}
                                      </p>

                                      <span
                                        className={
                                          stationOnline == "Online"
                                            ? "green"
                                            : "red"
                                        }
                                      >
                                        {stationOnline}
                                      </span>
                                    </div>
                                    {/* <p className='rName'>Odhav Road , Ahmedabad</p> */}
                                  </Col>
                                </Row>
                              </Col>
                              {/* <Col lg={6} md={6}>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <div className='option'>
                                                                        <Button>
                                                                            <div className="form-check form-switch">
                                                                                <Input
                                                                                    onClick={() => { }}
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    role="switch"
                                                                                    id=""
                                                                                    checked={isChecked}
                                                                                    onChange={() => {
                                                                                        if (isChecked === true) {
                                                                                            setDisabledModal(true);
                                                                                        } else {
                                                                                            dispatch(showToast({
                                                                                                message: 'station has been enabled successfully.',
                                                                                                type: 'success'
                                                                                            }));
                                                                                            setIsChecked(!isChecked);

                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Button>
                                                                        <Button
                                                                            disabled={isChecked === false ? true : false}
                                                                            className={isChecked === false ? 'disabled' : ''}
                                                                            onClick={() => { history.push('/stations/editstation') }}
                                                                        >
                                                                            <i className='bx bx-edit-alt editBtn' />
                                                                        </Button>
                                                                        <Button>
                                                                            <i className='bx bx-trash deleteBtn' onClick={() => { setdeleteModalUser(true); }} />
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col> */}
                            </Row>
                            <Row>
                              {/* <Col className={!getUserRole() ? 'left col-xl-9 col-lg-9 col-md-8' : 'left col-xl-10 col-lg-10 col-md-8'}> */}
                              <Col
                                className={
                                  !(
                                    window.localStorage.getItem("users") !==
                                    undefined &&
                                    window.localStorage.getItem("users") !==
                                    null &&
                                    JSON.parse(
                                      window.localStorage.getItem("users")
                                    ).userRole !== "BusinessOwner"
                                  )
                                    ? "left col-xl-9 col-lg-9 col-md-8"
                                    : "left col-xl-10 col-lg-10 col-md-10"
                                }
                              >
                                {/* <div className={!getUserRole() ? 'mainContect BOmainContect' : 'mainContect'}> */}
                                <div
                                  className={
                                    !(
                                      window.localStorage.getItem("users") !==
                                      undefined &&
                                      window.localStorage.getItem("users") !==
                                      null &&
                                      JSON.parse(
                                        window.localStorage.getItem("users")
                                      ).userRole !== "BusinessOwner"
                                    )
                                      ? "mainContect BOmainContect"
                                      : "mainContect"
                                  }
                                >
                                  <div className="content">
                                    <div className="box">
                                      <p className="mainTitle">Model</p>
                                      <p className="txt">
                                        {stationDetails?.modelName}
                                      </p>
                                    </div>
                                    <div className="box">
                                      <p className="mainTitle">
                                        Max. Sanctioned Power
                                      </p>
                                      <p className="txt">
                                        {stationDetails?.maxPower + " kW"}
                                      </p>
                                    </div>
                                    <div className="box">
                                      <p className="mainTitle">
                                        Firmware Version
                                      </p>
                                      {!showfirmwareupdateStatus && (
                                        <p className="txt">
                                          {stationDetails?.firmware
                                            ? stationDetails?.firmware
                                            : "-"}
                                          <span
                                            className="update"
                                            onClick={() => {
                                              setUpdateModal(true);
                                            }}
                                          >
                                            Update
                                          </span>
                                        </p>
                                      )}
                                      {showfirmwareupdateStatus && (
                                        <p className="txt">
                                          <span className="update">
                                            {firmwareStatus.updateResp.status}
                                          </span>
                                        </p>
                                      )}
                                    </div>
                                    <div className="box">
                                      <p className="mainTitle">Station Type</p>
                                      <p
                                        className={
                                          stationDetails?.stationType ===
                                            "Private"
                                            ? "txt blue"
                                            : "text"
                                        }
                                      >
                                        {stationDetails?.stationType
                                          ? stationDetails?.stationType
                                          : "-"}
                                      </p>
                                    </div>
                                    <div className="box">
                                      <p className="mainTitle">
                                        Service Timing
                                      </p>

                                      <OutsideClickHandler
                                        onOutsideClick={() => {
                                          setTimeModal(false);
                                        }}
                                      >
                                        <div className="aA">
                                          <p
                                            className="txt"
                                            onClick={(e) => {
                                              setTimeModal(!timeModal);
                                            }}
                                          >
                                            {timerDetail[0]?.dayCheck
                                              ? timerDetail[0]?.fullDayCheck
                                                ? `${timerDetail[0]?.day} 24 Hours`
                                                : `${timerDetail[0]?.day
                                                } ${moment(
                                                  timerDetail[0]?.startTime
                                                ).format(
                                                  "hh:mm a"
                                                )} - ${moment(
                                                  timerDetail[0]?.endTime
                                                ).format("hh:mm a")}`
                                              : `${timerDetail[0]?.day}  Closed`}
                                            <i className="ri ri-arrow-down-s-line" />
                                          </p>
                                          {timeModal && (
                                            <div className="menu show">
                                              {timerDetailFull.map(
                                                (item, index) => {
                                                  return (
                                                    <>
                                                      <div
                                                        className={
                                                          timerDetail[0]?.id ==
                                                            item?.id
                                                            ? "item selected"
                                                            : "item"
                                                        }
                                                      >
                                                        <span className="day">
                                                          {item.value}
                                                        </span>
                                                        <span
                                                          className={
                                                            (item.secondValue ===
                                                              "Closed" &&
                                                              "closed") ||
                                                            (item.secondValue ===
                                                              "24 Hours" &&
                                                              "fullDay")
                                                          }
                                                        >
                                                          {item.secondValue}
                                                        </span>
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </OutsideClickHandler>
                                    </div>
                                    {/* business role */}
                                    {/* {start} */}
                                    {/* {!getUserRole() && <div className='box'> */}
                                    {!(
                                      window.localStorage.getItem("users") !==
                                      undefined &&
                                      window.localStorage.getItem("users") !==
                                      null &&
                                      JSON.parse(
                                        window.localStorage.getItem("users")
                                      ).userRole !== "BusinessOwner"
                                    ) && (
                                        <div className="box">
                                          <p className="mainTitle">
                                            Allow Reservation
                                          </p>
                                          <Button className="inputBtn">
                                            <div className="form-check form-switch">
                                              <Input
                                                onClick={(e) => {
                                                  setReservationCheck(e.target.checked);
                                                  dispatch(updateReservation(`?stationId=${stationDetails?.stationId}&status=${e.target.checked}`));

                                                }}
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id=""
                                                checked={reservationCheck}
                                              // checked={reservationCheck || stationDetails.isReservable === "true" ? true : false}
                                              // onChange={(e) => {
                                              //   console.log(e.target.checked, 'checked')
                                              //   // setReservationCheck(
                                              //   //   e.target.checkedz
                                              //   // );
                                              //   // let statusCheck = reservationCheck === false ? 'true' : 'false';
                                              //   // console.log(statusCheck, 'statusCheck')
                                              //   // dispatch(updateReservation(`?stationId=${stationDetails?.stationId}&status=${statusCheck}`));

                                              // }}
                                              />
                                            </div>
                                          </Button>
                                        </div>
                                      )}
                                    {/* {end} */}
                                  </div>
                                </div>
                              </Col>
                              {/* <Col className={!getUserRole() ? 'right col-xl-3 col-lg-3 col-md-4' : 'right col-xl-2 col-lg-2 col-md-4'}> */}
                              <Col
                                className={
                                  !(
                                    window.localStorage.getItem("users") !==
                                    undefined &&
                                    window.localStorage.getItem("users") !==
                                    null &&
                                    JSON.parse(
                                      window.localStorage.getItem("users")
                                    ).userRole !== "BusinessOwner"
                                  )
                                    ? "right col-xl-3 col-lg-3 col-md-4"
                                    : "right col-xl-2 col-lg-2 col-md-2"
                                }
                              >
                                <Row>
                                  <Col
                                    lg={12}
                                    className="d-flex align-items-center justify-content-end content"
                                  >
                                    {/* business role */}
                                    {/* {start} */}
                                    {!(
                                      window.localStorage.getItem("users") !==
                                      undefined &&
                                      window.localStorage.getItem("users") !==
                                      null &&
                                      JSON.parse(
                                        window.localStorage.getItem("users")
                                      ).userRole !== "BusinessOwner"
                                    ) && (
                                        <Button
                                          className="viewRfidBtn"
                                          onClick={() => {
                                            setViewRfidModal(true);
                                          }}
                                        >
                                          View Assigned RFID
                                        </Button>
                                      )}
                                    {/* {!getUserRole() && <Button className='viewRfidBtn' onClick={() => { setViewRfidModal(true); }}>View Assigned RFID</Button>} */}
                                    {/* {end} */}
                                    <div className="option">
                                      <Button>
                                        <div className="form-check form-switch">
                                          <Input
                                            onClick={() => { }}
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id=""
                                            checked={
                                              stationDetails?.status ===
                                                "Active"
                                                ? true
                                                : false
                                            }
                                            onChange={() => {
                                              if (
                                                stationDetails?.status ===
                                                "Active"
                                              ) {
                                                setDisabledModal(true);
                                              } else {
                                                setEnableModal(true);
                                              }
                                            }}
                                          />
                                        </div>
                                      </Button>
                                      <Button
                                        disabled={
                                          stationDetails?.status === "Inactive"
                                            ? true
                                            : false
                                        }
                                        className={
                                          stationDetails?.status === "Inactive"
                                            ? "disabled"
                                            : ""
                                        }
                                        onClick={() => {
                                          history.push(
                                            "/stations/editstation",
                                            {
                                              state: {
                                                StationID:
                                                  stationDetails?.stationId,
                                                type: "EDIT",
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        <i className="bx bx-edit-alt editBtn" />
                                      </Button>
                                      <Button>
                                        <i
                                          className="bx bx-trash deleteBtn"
                                          onClick={() => {
                                            setdeleteModalUser(true);
                                          }}
                                        />
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>
                    {/* <CardBody>
                                        <div className='title'>
                                            <p className='Txt'>Connectons (3)</p>
                                        </div>
                                        <div className='main'>
                                            <Row className='row'>
                                                {
                                                    connectionData1.map((i, index) => {
                                                        return (
                                                            <Col lg={6} xl={4} md={6} key={index}>
                                                                <Card className='customCard1'>
                                                                    <CardBody>
                                                                        <div className='upper'>
                                                                            <div className='mimage'>
                                                                                <img src='https://res.cloudinary.com/djyl1goby/image/upload/v1667470119/Lubi/WebApp/Static-images/CCS_4.svg' alt='' />
                                                                            </div>
                                                                            <div className='content'>
                                                                                <div className="table-responsive">
                                                                                    <Table className="table-sm mb-0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td className="fw-medium"><p className='txt'>TYPE:</p></td>
                                                                                                <td>
                                                                                                    <p className='txt'>{i.type}</p>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="fw-medium"><p className='txt'>Format:</p></td>
                                                                                                <td> <p className='txt'>{i.format}</p></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="fw-medium"><p className='txt'>Power Type:</p></td>
                                                                                                <td><p className='txt'>{i.powerType}</p></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="fw-medium"><p className='txt'>Max Voltage:</p></td>
                                                                                                <td><p className='txt'>{i.maxVoltage}</p></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="fw-medium"><p className='txt'>Max Amperage:</p></td>
                                                                                                <td><p className='txt'>{i.maxAmperage}</p></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="fw-medium"><p className='txt'>Mac Electric Power:</p></td>
                                                                                                <td><p className='txt'>{i.macElectricPower}</p></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='bottomPart d-flex align-items-center justify-content-end mt-2'>
                                                                            <Button className={i.cStatus ? 'green' : 'red'}>
                                                                                {i.cStatus ? 'Available' : 'Not Available'}
                                                                            </Button>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </div>
                                    </CardBody> */}
                  </Card>
                  {getConnectors?.length > 0 && (
                    <Row className="tabButtons">
                      <div className={stationDetails?.status === "Inactive" ? "disabled" : "orderTab"}>
                        {getConnectors?.map((item, index) => {
                          return (
                            <Button
                              key={index}
                              className={
                                orderTab === item.connectorId
                                  ? "activeTab tab"
                                  : "tab"
                              }
                              onClick={() => {
                                dispatch(getReservation(`?stationId=${state?.state?.stationId}&date=${filterDateReserve}&connectorId=${item.connectorId}`));

                                setOrderTab(item.connectorId);
                                if (item.tariff !== null) {
                                  const currentTariff = allTariffList
                                    .filter((e) => e?.tariffId === item.tariff)
                                    .map((e) => e.tariffName);
                                  setSelectedGroup2({
                                    id: `${item.tariff}`,
                                    value: currentTariff,
                                  });
                                } else {
                                  setSelectedGroup2(null);
                                }
                              }}
                            >
                              {`Connector ${index + 1}`}
                            </Button>
                          );
                        })}
                      </div>
                    </Row>
                  )}
                  {/* className={stationDetails?.status === "Inactive" ? "tabContainer mb-0 disabled" : "green"} */}

                  <Card className="tabContainer mb-0">
                    {/* connector details */}
                    <CardBody className={stationDetails?.status === "Inactive" ? "disabled" : ""}>
                      {getConnectors?.length > 0 ? (
                        <>
                          {getConnectors?.map((i, index) => {
                            return (
                              <>
                                {orderTab === i.connectorId && (
                                  <>
                                    <div className="mainConnector" key={index}>
                                      <div className="boxColumn">
                                        <div className="connectorColumn">
                                          <div className="imgDiv">
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_CLOUDINARY_URL +
                                                i.iconUrl
                                              }
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                        <div className="connectorColumn">
                                          <p className="mainTitle">Status</p>
                                          {/* <span className={i.status === "Active" ? 'green statusBtn' : 'red statusBtn'}>{i.status === "Active" ? 'Available' : 'Unavailable'}</span> */}
                                          {/* <span
                                            className={currentStationSocketData !== undefined && currentStationSocketData[index] !== undefined ? (currentStationSocketData[index]?.statusNotification?.status == "Unavailable" ? 'red statusBtn' : colorIndicators[currentStationSocketData[index]?.statusNotification?.status] + ' statusBtn') : 'red statusBtn'}
                                          >
                                          </span> */}
                                          {/* {currentStationSocketData !== undefined && currentStationSocketData.length > 0 && currentStationSocketData[index] !== undefined ? (currentStationSocketData[index].statusNotification.status !== undefined ? currentStationSocketData[index].statusNotification.status : 'Unavailable') : noConnectorSocketDataMessage}</span> */}
                                          <span
                                            className={
                                              socketData &&
                                              socketStatusData &&
                                              (() => {
                                                const status = socketStatusData[
                                                  `${stationDetails?.stationName}_${i.connectorNumber}`
                                                ];
                                                if (stationOnline === 'Offline') {
                                                  return 'statusBtn red'; // Apply red class and 'Unavailable' when offline
                                                }

                                                switch (status) {
                                                  case 'Charging':
                                                    return 'statusBtn' + ' charging'; // Custom class for charging, apply custom color #F5BE4F
                                                  case 'Available':
                                                    return 'statusBtn' + ' green'; // Custom class for available, apply custom color #77B347
                                                  case 'Unknown':
                                                    return 'statusBtn' + ' unknown'; // Custom class for unknown, apply custom color #94BBF6
                                                  case 'Unavailable':
                                                    return 'statusBtn' + ' red'; // Custom class for unavailable, apply custom color #ED6B60
                                                  case 'Preparing':
                                                    return 'statusBtn' + ' preparing'; // Custom class for preparing, apply custom color #87E7FC
                                                  case 'Finishing':
                                                    return 'statusBtn' + ' finishing'; // Custom class for preparing, apply custom color #87E7FC
                                                  case 'Faulted':
                                                    return 'statusBtn' + ' red'; // Custom class for unavailable, apply custom color #ED6B60
                                                  case 'SuspendedEVSE':
                                                    return 'statusBtn' + ' red'; // Custom class for unavailable, apply custom color #ED6B60
                                                  case 'SuspendedEV':
                                                    return 'statusBtn' + ' red'; // Custom class for unavailable, apply custom color #ED6B60

                                                  default:
                                                    return colorIndicators[
                                                      stationOnline !== 'Offline' ? 'Available' : 'Unavailable'
                                                    ] + " statusBtn" || "red statusBtn"; // Fallback logic
                                                }
                                              })()
                                            }
                                          >
                                            {
                                              stationOnline !== 'Offline'
                                                ? (
                                                  socketStatusData &&
                                                  socketStatusData[
                                                  `${stationDetails?.stationName}_${i.connectorNumber}`
                                                  ]
                                                ) || 'Available' // If station is online but no specific status, show 'Available'
                                                : 'Unavailable' // If station is offline, show 'Unavailable'
                                            }
                                            {/* {(socketStatusData &&
                                              socketStatusData[
                                              `${stationDetails?.stationName}_${i.connectorNumber}`
                                              ]) ||
                                              "Unavailable"} */}
                                            {/* {stationOnline !== 'Offline' ? 'Available' :
                                              "Unavailable"} */}
                                          </span>
                                        </div>
                                        <div className="connectorColumn">
                                          <p className="mainTitle">TYPE</p>
                                          <p className="txt">
                                            {i.connectorType}
                                          </p>
                                        </div>
                                        <div className="connectorColumn">
                                          <p className="mainTitle">Format</p>
                                          <p className="txt">{i.format}</p>
                                        </div>
                                        <div className="connectorColumn">
                                          <p className="mainTitle">
                                            Power Type
                                          </p>
                                          <p className="txt">{i.powerType}</p>
                                        </div>
                                        <div className="connectorColumn">
                                          <p className="mainTitle">
                                            Max. Voltage
                                          </p>
                                          <p className="txt">{i.maxVoltage}</p>
                                        </div>
                                        <div className="connectorColumn">
                                          <p className="mainTitle">
                                            Max. Current
                                          </p>
                                          <p className="txt">{i.maxAmpere}</p>
                                        </div>
                                        <div className="connectorColumn">
                                          <p className="mainTitle">
                                            Max. Electric Power
                                          </p>
                                          <p className="txt">
                                            {i.maxPower ? i.maxPower : "-"}
                                          </p>
                                        </div>
                                        {localStorage.getItem("users") !==
                                          null &&
                                          localStorage.getItem("users") !==
                                          undefined &&
                                          JSON.parse(
                                            localStorage.getItem("users")
                                          ).userRole !== "SuperAdmin" && (
                                            <div className="connectorColumn">
                                              <p className="mainTitle">
                                                Assigned Tariff
                                              </p>
                                              <CustomDropdown
                                                className="allDropdowns"
                                                id={i.connectorId}
                                                dropDownItems={tariffssList}
                                                defaultValue={""}
                                                placeholder={"Choose model"}
                                                selectedValue={selectedGroup2}
                                                onSelect={(evt) => {
                                                  setSelectedGroup2(evt);
                                                  setAssignTariif(true);

                                                  if (tariffssList.length > 0) {
                                                    setAssignTariff(
                                                      evt,
                                                      i?.connectorId,
                                                      stationDetails?.stationName
                                                    );
                                                  }
                                                  //
                                                }}
                                              />
                                            </div>
                                          )}
                                      </div>
                                      <div className="qrBox">
                                        <div className="qrSection">
                                          <QRCode
                                            id={"qr-gen"}
                                            value={
                                              stationDetails?.stationName +
                                              "*" +
                                              i.connectorId.toString()
                                            }
                                            size={52}
                                            level={"H"}
                                            renderAs="canvas"
                                            includeMargin={false}
                                            onClick={(e) => {
                                              // setQrValue(i.connectorId.toString())
                                              setQrModel(true);
                                              setQrtempValue(
                                                i.connectorId.toString()
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <p className="bBorder"></p>
                                  </>
                                )}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <Row>
                          <p className="headerTxt">Connector (0)</p>
                          <div
                            className="noData"
                            style={{
                              height:
                                dimensions.height -
                                headerHeight -
                                footerHeight -
                                breadCrumb -
                                cardHeader -
                                137 +
                                "px",
                            }}
                          >
                            <div className="contentNoData">
                              <img
                                alt=""
                                src="https://res.cloudinary.com/djyl1goby/image/upload/v1671183689/Lubi/WebApp/Static-images/No_Connectors_found.svg"
                              />
                              <p>No Connectors Found!</p>
                            </div>
                          </div>
                        </Row>
                      )}

                      <div className="configureSlotDesign">
                        <div className="px-2 pt-2">
                          <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                            <NavItem style={{ display: stationDetails?.isReservable === "true" ? "" : 'none' }}>
                              <NavLink
                                href="#"
                                className={classnames({
                                  active: activeTab === "1",
                                })}
                                onClick={() => {
                                  toggleTab("1");
                                }}
                              // disabled={stationDetails?.isReservable === "true" ? true : false}

                              >
                                Reservation Slot
                              </NavLink>
                            </NavItem>
                            <NavItem style={{ display: !machineNotconfig ? "none" : '' }}>
                              <NavLink
                                href="#"
                                className={classnames({
                                  active: activeTab === "2",
                                })}
                                onClick={() => {
                                  toggleTab("2");
                                }}
                              >
                                Configure Keys
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                        {/* <button onClick={() => { clearCacheSetFunc() }} >clearCache</button> */}

                        <TabContent activeTab={activeTab}>
                          {/* <TabPane tabId="1"> */}
                          {/* <p>Reservation Slots</p>
                            <button onClick={() => { reservationFunc() }} >Reservation</button>
                            // <button onClick={() => { reservationCancelFunc() }} >CancelReservation</button> */}
                          {/* <button onClick={() => { reservationFunc() }} >Reservation</button> */}
                          {/* <button onClick={() => { reservationCancelFunc() }} >CancelReservation</button> */}
                          {/* </TabPane> */}
                          <TabPane tabId="1" style={{ display: stationDetails?.isReservable === "true" ? "" : 'none' }}>
                            {/* <br></br>

                            <button onClick={() => { reservationFunc() }} >Reservation</button>
                            <button onClick={() => { reservationCancelFunc() }} >CancelReservation</button>

                            <br></br>
                            <br></br>
                            <button onClick={() => { reservationFunc1() }} >Reservation1</button>
                            <button onClick={() => { reservationCancelFunc1() }} >CancelReservation1</button> */}

                            {/* <p>Reservation Slots</p> */}
                            <div className='bookingDateTabs'>
                              <p className="headerTxt">
                              </p>
                              <div className='timeBar'>
                                <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                                  {nextSevenDays.map((day, index) => (
                                    <NavLink key={index}
                                      href="#"
                                      className={classnames({
                                        active: activeDateTab === moment(day.day, 'ddd').format('dddd').toLowerCase(),
                                      })}
                                      onClick={() => {
                                        toggleDateTab(moment(day.day, 'ddd').format('dddd').toLowerCase(), day);
                                        // setActiveDateTab(day.day);

                                      }}
                                    >
                                      <p>{day.day}</p>
                                      <p>{day.date}</p>
                                    </NavLink>
                                    // <li key={index}>{`${day.date} (${day.day})`}</li>
                                  ))}
                                </Nav>
                              </div>
                              <Row >
                                <div className="slotSignMain">
                                  <div className="slotSign">
                                    <div></div>
                                    <span>Avaialble</span>
                                  </div>
                                  <div className="slotSign">
                                    <div></div>
                                    <span>Booked</span>
                                  </div>
                                </div>
                              </Row>
                              <div className='tabsContainStyle'>
                                <TabContent activeTab={activeDateTab}>

                                  {nextSevenDays.map((day, index) => (
                                    <TabPane key={index} tabId={moment(day.day, 'ddd').format('dddd').toLowerCase()}>
                                      <div className="statusDisplay">
                                        {statusMondayData1.map((i, index) => {
                                          return (
                                            <div
                                              key={index}
                                              disabled={!i.blockActive}
                                              style={{
                                                width:
                                                  dimensions.width <= 1600
                                                    ? dimensions.width <= 1200
                                                      ? `calc(24.5 % * ${i.noOfHours})`
                                                      : `calc(19.5 % * ${i.noOfHours})`
                                                    : `calc(12 % * ${i.noOfHours})`,
                                              }}
                                              className={
                                                i.blockActive && i.slotActive === true
                                                  ? "slotBox active"
                                                  : "slotBox"
                                              }
                                            >
                                              <p className="txt">{i.time}</p>
                                              <div
                                                className={
                                                  i.slotActive === true
                                                    ? "userAction show"
                                                    : "userAction"
                                                }
                                              >
                                                <span className="txt">
                                                  {i.name}
                                                </span>
                                                <Button
                                                  id={i.id}
                                                  onClick={() => {
                                                    setDeleteReservationModel(true)
                                                    setDeleteReservationData(i)
                                                    // dispatch(deleteReservation(`?reservationId=${i.reservationId}&cancelUser=${i.userId}`));
                                                    // dispatch(getReservation(`?stationId=${state?.state?.stationId}&date=${filterDateReserve}&connectorId=${orderTab}`));
                                                  }}
                                                >
                                                  {!i.blockActive ? '' : 'Cancel'}
                                                </Button>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </TabPane>
                                  ))}

                                </TabContent>
                              </div>
                            </div>



                          </TabPane>
                          <TabPane tabId="2" style={{ display: !machineNotconfig ? "none" : '' }}>
                            <div className="slotWrapper">
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  configurekeyform.handleSubmit();
                                  return false;
                                }}
                                action="#"
                              >
                                <div className="slotFormTitle">
                                  <Row>
                                    <Col sm={6}>
                                      <h5>Keys</h5>
                                    </Col>
                                    <Col sm={6}>
                                      <h5>Value</h5>
                                    </Col>
                                  </Row>
                                </div>

                                <Button
                                  type="submit"
                                  className=""
                                  disabled={!(configurekeyform.isValid && configurekeyform.dirty)}
                                  onClick={() => { }}
                                >
                                  Update & Save
                                </Button>

                                <div
                                  className="configureslotWrapper"
                                  style={{
                                    height:
                                      dimensions.height -
                                      headerHeight -
                                      footerHeight -
                                      breadCrumb -
                                      cardHeader -
                                      400 +
                                      "px",
                                  }}
                                >
                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>AuthorizeRemoteTxRequests</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <div className="form-check form-switch">
                                          <Input
                                            name="AuthorizeRemoteTxRequests"
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id=""
                                            checked={
                                              configurekeyform.values
                                                .AuthorizeRemoteTxRequests
                                            }
                                            onChange={(e) => {
                                              // console.log(
                                              //   "e",
                                              //   e.target.checked
                                              // );
                                              // setIsconfigarkeyChecked(false);
                                              configurekeyform.handleChange(
                                                "AuthorizeRemoteTxRequests"
                                              )(e);
                                              // if (isChecked === true) {
                                              //   setDisabledModal(true);
                                              // } else {
                                              //   setEnableModal(true);
                                              // }
                                            }}
                                          />

                                          {/* {configurekeyform.values.AuthorizeRemoteTxRequests} */}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>AllowOfflineTxForUnknownId</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <div className="form-check form-switch">
                                          <Input
                                            name="AllowOfflineTxForUnknownId"
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id=""
                                            checked={
                                              configurekeyform.values
                                                .AllowOfflineTxForUnknownId
                                            }
                                            onChange={(e) => {
                                              // console.log(
                                              //   "e",
                                              //   e.target.checked
                                              // );
                                              // setIsconfigarkeyChecked(false);
                                              configurekeyform.handleChange(
                                                "AllowOfflineTxForUnknownId"
                                              )(e);
                                              // if (isChecked === true) {
                                              //   setDisabledModal(true);
                                              // } else {
                                              //   setEnableModal(true);
                                              // }
                                            }}
                                          />
                                          {/* True */}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>


                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>LocalAuthorizeOffline</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <div className="form-check form-switch">
                                          <Input
                                            name="LocalAuthorizeOffline"
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id=""
                                            checked={
                                              configurekeyform.values
                                                .LocalAuthorizeOffline
                                            }
                                            onChange={(e) => {
                                              console.log(
                                                "e",
                                                e.target.checked
                                              );
                                              // setIsconfigarkeyChecked(false);
                                              configurekeyform.handleChange(
                                                "LocalAuthorizeOffline"
                                              )(e);
                                              // if (isChecked === true) {
                                              //   setDisabledModal(true);
                                              // } else {
                                              //   setEnableModal(true);
                                              // }
                                            }}
                                          />
                                          {/* True */}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>StopTransactionOnInvalidId</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <div className="form-check form-switch">
                                          <Input
                                            name="StopTransactionOnInvalidId"
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id=""
                                            checked={
                                              configurekeyform.values
                                                .StopTransactionOnInvalidId
                                            }
                                            onChange={(e) => {
                                              console.log(
                                                "e",
                                                e.target.checked
                                              );
                                              // setIsconfigarkeyChecked(false);
                                              configurekeyform.handleChange(
                                                "StopTransactionOnInvalidId"
                                              )(e);
                                              // if (isChecked === true) {
                                              //   setDisabledModal(true);
                                              // } else {
                                              //   setEnableModal(true);
                                              // }
                                            }}
                                          />
                                          {/* {isconfigarkeyChecked
                                            ? "True"
                                            : "False"} */}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>TransactionMessageAttempts</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>

                                      <div className="center-input">
                                        <Input
                                          name="TransactionMessageAttempts"
                                          type="text"
                                          className="form-control"
                                          id="TransactionMessageAttempts"
                                          placeholder="1000"
                                          maxLength={5}
                                          value={
                                            configurekeyform.values.TransactionMessageAttempts ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            console.log(e.nativeEvent.data)
                                            // if (
                                            //   /[0-9\b]/.test(
                                            //     e.nativeEvent.data
                                            //   ) ||
                                            //   e.nativeEvent.data == null
                                            // ) {
                                            //configurekeyform.handleChange();
                                            // }
                                          }}
                                        // onKeyDown={
                                        //   configurekeyform.handleBlur
                                        // }
                                        // onKeyUp={(e) => {
                                        //   inpNum(e);
                                        // }}
                                        // onKeyPress={inpNum}
                                        // invalid={
                                        //   configurekeyform.errors
                                        //     .TransactionMessageAttempts &&
                                        //   configurekeyform.touched
                                        //     .TransactionMessageAttempts
                                        //     ? true
                                        //     : false
                                        // }
                                        />
                                        {configurekeyform.errors
                                          .TransactionMessageAttempts &&
                                          configurekeyform.touched
                                            .TransactionMessageAttempts ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .TransactionMessageAttempts
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>TransactionMessageRetryInterval</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="TransactionMessageRetryInterval"
                                          type="text"
                                          className="form-control"
                                          id="TransactionMessageRetryInterval"
                                          placeholder="1000"
                                          maxLength={5}
                                          value={
                                            configurekeyform.values.TransactionMessageRetryInterval ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            if (
                                              /[0-9\b]/.test(
                                                e.nativeEvent.data
                                              ) ||
                                              e.nativeEvent.data == null
                                            ) {
                                              configurekeyform.handleChange(e);
                                            }
                                          }}
                                          onKeyDown={
                                            configurekeyform.handleBlur
                                          }
                                          onKeyUp={(e) => {
                                            inpNum(e);
                                          }}
                                          onKeyPress={inpNum}
                                          invalid={
                                            configurekeyform.errors
                                              .TransactionMessageRetryInterval &&
                                              configurekeyform.touched
                                                .TransactionMessageRetryInterval
                                              ? true
                                              : false
                                          }
                                        />
                                        <p className="secondText">S</p>

                                        {configurekeyform.errors
                                          .TransactionMessageRetryInterval &&
                                          configurekeyform.touched
                                            .TransactionMessageRetryInterval ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .TransactionMessageRetryInterval
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="readOnly">
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>MeterValuesSampledDataMaxLength</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="MeterValuesSampledDataMaxLength"
                                          type="text"
                                          className="form-control"
                                          id="MeterValuesSampledDataMaxLength"
                                          placeholder="1000"
                                          maxLength={5}
                                          value={
                                            configurekeyform.values.MeterValuesSampledDataMaxLength ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            if (
                                              /[0-9\b]/.test(
                                                e.nativeEvent.data
                                              ) ||
                                              e.nativeEvent.data == null
                                            ) {
                                              configurekeyform.handleChange(e);
                                            }
                                          }}
                                          onKeyDown={
                                            configurekeyform.handleBlur
                                          }
                                          onKeyUp={(e) => {
                                            inpNum(e);
                                          }}
                                          onKeyPress={inpNum}
                                          invalid={
                                            configurekeyform.errors
                                              .MeterValuesSampledDataMaxLength &&
                                              configurekeyform.touched
                                                .MeterValuesSampledDataMaxLength
                                              ? true
                                              : false
                                          }
                                        />
                                        {configurekeyform.errors
                                          .MeterValuesSampledDataMaxLength &&
                                          configurekeyform.touched
                                            .MeterValuesSampledDataMaxLength ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .MeterValuesSampledDataMaxLength
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="readOnly">
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>NumberOfConnectors</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="NumberOfConnectors"
                                          type="text"
                                          className="form-control"
                                          id="NumberOfConnectors"
                                          placeholder="1000"
                                          maxLength={5}
                                          value={
                                            configurekeyform.values.NumberOfConnectors ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            if (
                                              /[0-9\b]/.test(
                                                e.nativeEvent.data
                                              ) ||
                                              e.nativeEvent.data == null
                                            ) {
                                              configurekeyform.handleChange(e);
                                            }
                                          }}
                                          onKeyDown={
                                            configurekeyform.handleBlur
                                          }
                                          onKeyUp={(e) => {
                                            inpNum(e);
                                          }}
                                          onKeyPress={inpNum}
                                          invalid={
                                            configurekeyform.errors
                                              .NumberOfConnectors &&
                                              configurekeyform.touched
                                                .NumberOfConnectors
                                              ? true
                                              : false
                                          }
                                        />

                                        {configurekeyform.errors
                                          .NumberOfConnectors &&
                                          configurekeyform.touched
                                            .NumberOfConnectors ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .NumberOfConnectors
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="readOnly">
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>UnlockConnectorOnEVSideDisconnect</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <div className="form-check form-switch">
                                          <Input
                                            name="UnlockConnectorOnEVSideDisconnect"
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id=""
                                            checked={
                                              configurekeyform.values
                                                .UnlockConnectorOnEVSideDisconnect
                                            }
                                            onChange={(e) => {
                                              console.log(
                                                "e",
                                                e.target.checked
                                              );
                                              // setIsconfigarkeyChecked(false);
                                              configurekeyform.handleChange(
                                                "UnlockConnectorOnEVSideDisconnect"
                                              )(e);
                                              // if (isChecked === true) {
                                              //   setDisabledModal(true);
                                              // } else {
                                              //   setEnableModal(true);
                                              // }
                                            }}
                                          />
                                          {/* True */}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="readOnly">
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>StopTransactionOnEVSideDisconnect</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <div className="form-check form-switch">
                                          <Input
                                            name="StopTransactionOnEVSideDisconnect"
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id=""
                                            checked={
                                              configurekeyform.values
                                                .StopTransactionOnEVSideDisconnect
                                            }
                                            onChange={(e) => {
                                              console.log(
                                                "e",
                                                e.target.checked
                                              );
                                              // setIsconfigarkeyChecked(false);
                                              configurekeyform.handleChange(
                                                "StopTransactionOnEVSideDisconnect"
                                              )(e);
                                              // if (isChecked === true) {
                                              //   setDisabledModal(true);
                                              // } else {
                                              //   setEnableModal(true);
                                              // }
                                            }}
                                          />
                                          {/* True */}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>MinimumStatusDuration</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="MinimumStatusDuration"
                                          type="text"
                                          className="form-control"
                                          id="MinimumStatusDuration"
                                          placeholder="1000"
                                          maxLength={5}
                                          value={
                                            configurekeyform.values.MinimumStatusDuration ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            if (
                                              /[0-9\b]/.test(
                                                e.nativeEvent.data
                                              ) ||
                                              e.nativeEvent.data == null
                                            ) {
                                              configurekeyform.handleChange(e);
                                            }
                                          }}
                                          onKeyDown={
                                            configurekeyform.handleBlur
                                          }
                                          onKeyUp={(e) => {
                                            inpNum(e);
                                          }}
                                          onKeyPress={inpNum}
                                          invalid={
                                            configurekeyform.errors
                                              .MinimumStatusDuration &&
                                              configurekeyform.touched
                                                .MinimumStatusDuration
                                              ? true
                                              : false
                                          }
                                        />
                                        <p className="secondText">S</p>
                                        {configurekeyform.errors
                                          .MinimumStatusDuration &&
                                          configurekeyform.touched
                                            .MinimumStatusDuration ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .MinimumStatusDuration
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>AuthorizationCacheEnabled</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <div className="form-check form-switch">
                                          <Input
                                            name="AuthorizationCacheEnabled"
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id=""
                                            checked={
                                              configurekeyform.values
                                                .AuthorizationCacheEnabled
                                            }
                                            onChange={(e) => {
                                              configurekeyform.handleChange(
                                                "AuthorizationCacheEnabled"
                                              )(e);
                                            }}
                                          />
                                          {/* True */}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>LocalPreAuthorize</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <div className="form-check form-switch">
                                          <Input
                                            name="LocalPreAuthorize"
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id=""
                                            checked={
                                              configurekeyform.values
                                                .LocalPreAuthorize
                                            }
                                            onChange={(e) => {
                                              configurekeyform.handleChange(
                                                "LocalPreAuthorize"
                                              )(e);
                                            }}
                                          />
                                          {/* True */}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="readOnly">
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>SupportedFeatureProfiles</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="supportedFeatureProfiles"
                                          type="text"
                                          className="form-control"
                                          id="supportedFeatureProfiles"
                                          placeholder="Core, FirmwareManagement, RemoteTrigger"
                                          value={
                                            configurekeyform.values.supportedFeatureProfiles ||
                                            ""
                                          }
                                          onChange={
                                            configurekeyform.handleChange
                                          }
                                          invalid={
                                            configurekeyform.errors
                                              .supportedFeatureProfiles &&
                                              configurekeyform.touched
                                                .supportedFeatureProfiles
                                              ? true
                                              : false
                                          }
                                        />
                                        {configurekeyform.errors
                                          .supportedFeatureProfiles &&
                                          configurekeyform.touched
                                            .supportedFeatureProfiles ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .supportedFeatureProfiles
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>StopTxnAlignedData</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="stopTxnAlignedData"
                                          type="text"
                                          className="form-control"
                                          id="stopTxnAlignedData"
                                          placeholder="Core, FirmwareManagement, RemoteTrigger"
                                          value={
                                            configurekeyform.values.stopTxnAlignedData ||
                                            ""
                                          }
                                          onChange={
                                            configurekeyform.handleChange
                                          }
                                          invalid={
                                            configurekeyform.errors
                                              .stopTxnAlignedData &&
                                              configurekeyform.touched
                                                .stopTxnAlignedData
                                              ? true
                                              : false
                                          }
                                        />
                                        {configurekeyform.errors
                                          .stopTxnAlignedData &&
                                          configurekeyform.touched
                                            .stopTxnAlignedData ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .stopTxnAlignedData
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>stopTxnSampledData</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="stopTxnSampledData"
                                          type="text"
                                          className="form-control"
                                          id="stopTxnSampledData"
                                          placeholder="Energy.Active.Import.Register"
                                          value={
                                            configurekeyform.values.stopTxnSampledData ||
                                            ""
                                          }
                                          onChange={
                                            configurekeyform.handleChange
                                          }
                                          invalid={
                                            configurekeyform.errors
                                              .stopTxnSampledData &&
                                              configurekeyform.touched
                                                .stopTxnSampledData
                                              ? true
                                              : false
                                          }
                                        />
                                        {configurekeyform.errors
                                          .stopTxnSampledData &&
                                          configurekeyform.touched
                                            .stopTxnSampledData ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .stopTxnSampledData
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>MeterValuesAlignedData</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="meterValuesAlignedData"
                                          type="text"
                                          className="form-control"
                                          id="meterValuesAlignedData"
                                          placeholder="Current.Import, Power.Active.Import, Voltage, Energy.Active.Import.Register"
                                          value={
                                            configurekeyform.values.meterValuesAlignedData ||
                                            ""
                                          }
                                          onChange={
                                            configurekeyform.handleChange
                                          }
                                          invalid={
                                            configurekeyform.errors
                                              .meterValuesAlignedData &&
                                              configurekeyform.touched
                                                .meterValuesAlignedData
                                              ? true
                                              : false
                                          }
                                        />

                                        {configurekeyform.errors
                                          .meterValuesAlignedData &&
                                          configurekeyform.touched
                                            .meterValuesAlignedData ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .meterValuesAlignedData
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>MeterValuesSampledData</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="meterValuesSampledData"
                                          type="text"
                                          className="form-control"
                                          id="meterValuesSampledData"
                                          placeholder="Current.Import, Power.Active.Import, Voltage, Energy.Active.Import.Register"
                                          value={
                                            configurekeyform.values.meterValuesSampledData ||
                                            ""
                                          }
                                          onChange={
                                            configurekeyform.handleChange
                                          }
                                          invalid={
                                            configurekeyform.errors
                                              .meterValuesSampledData &&
                                              configurekeyform.touched
                                                .meterValuesSampledData
                                              ? true
                                              : false
                                          }
                                        />
                                        {configurekeyform.errors
                                          .meterValuesSampledData &&
                                          configurekeyform.touched
                                            .meterValuesSampledData ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .meterValuesSampledData
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>ClockAlignedDataInterval</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="ClockAlignedDataInterval"
                                          type="text"
                                          className="form-control"
                                          id="ClockAlignedDataInterval"
                                          placeholder="1000"
                                          maxLength={5}
                                          value={
                                            configurekeyform.values.ClockAlignedDataInterval ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            if (
                                              /[0-9\b]/.test(
                                                e.nativeEvent.data
                                              ) ||
                                              e.nativeEvent.data == null
                                            ) {
                                              configurekeyform.handleChange(e);
                                            }
                                          }}
                                          onKeyDown={
                                            configurekeyform.handleBlur
                                          }
                                          onKeyUp={(e) => {
                                            inpNum(e);
                                          }}
                                          onKeyPress={inpNum}
                                          invalid={
                                            configurekeyform.errors
                                              .ClockAlignedDataInterval &&
                                              configurekeyform.touched
                                                .ClockAlignedDataInterval
                                              ? true
                                              : false
                                          }
                                        />
                                        <p className="secondText">S</p>
                                        {configurekeyform.errors
                                          .ClockAlignedDataInterval &&
                                          configurekeyform.touched
                                            .ClockAlignedDataInterval ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .ClockAlignedDataInterval
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>MeterValueSampleInterval</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="MeterValueSampleInterval"
                                          type="text"
                                          className="form-control"
                                          id="MeterValueSampleInterval"
                                          placeholder="1000"
                                          maxLength={5}
                                          value={
                                            configurekeyform.values.MeterValueSampleInterval ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            if (
                                              /[0-9\b]/.test(
                                                e.nativeEvent.data
                                              ) ||
                                              e.nativeEvent.data == null
                                            ) {
                                              configurekeyform.handleChange(e);
                                            }
                                          }}
                                          onKeyDown={
                                            configurekeyform.handleBlur
                                          }
                                          onKeyUp={(e) => {
                                            inpNum(e);
                                          }}
                                          onKeyPress={inpNum}
                                          invalid={
                                            configurekeyform.errors
                                              .MeterValueSampleInterval &&
                                              configurekeyform.touched
                                                .MeterValueSampleInterval
                                              ? true
                                              : false
                                          }
                                        />
                                        <p className="secondText">S</p>
                                        {configurekeyform.errors
                                          .MeterValueSampleInterval &&
                                          configurekeyform.touched
                                            .MeterValueSampleInterval ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .MeterValueSampleInterval
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="readOnly">
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>LightIntensity</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="LightIntensity"
                                          type="text"
                                          className="form-control"
                                          id="LightIntensity"
                                          placeholder="1000"
                                          maxLength={5}
                                          value={
                                            configurekeyform.values.LightIntensity ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            if (
                                              /[0-9\b]/.test(
                                                e.nativeEvent.data
                                              ) ||
                                              e.nativeEvent.data == null
                                            ) {
                                              configurekeyform.handleChange(e);
                                            }
                                          }}
                                          onKeyDown={
                                            configurekeyform.handleBlur
                                          }
                                          onKeyUp={(e) => {
                                            inpNum(e);
                                          }}
                                          onKeyPress={inpNum}
                                          invalid={
                                            configurekeyform.errors
                                              .LightIntensity &&
                                              configurekeyform.touched
                                                .LightIntensity
                                              ? true
                                              : false
                                          }
                                        />
                                        {configurekeyform.errors.FormFeedback &&
                                          configurekeyform.touched
                                            .FormFeedback ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .FormFeedback
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="readOnly">
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>BlinkRepeat</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="BlinkRepeat"
                                          type="text"
                                          className="form-control"
                                          id="BlinkRepeat"
                                          placeholder="1000"
                                          maxLength={5}
                                          value={
                                            configurekeyform.values.BlinkRepeat ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            if (
                                              /[0-9\b]/.test(
                                                e.nativeEvent.data
                                              ) ||
                                              e.nativeEvent.data == null
                                            ) {
                                              configurekeyform.handleChange(e);
                                            }
                                          }}
                                          onKeyDown={
                                            configurekeyform.handleBlur
                                          }
                                          onKeyUp={(e) => {
                                            inpNum(e);
                                          }}
                                          onKeyPress={inpNum}
                                          invalid={
                                            configurekeyform.errors
                                              .BlinkRepeat &&
                                              configurekeyform.touched.BlinkRepeat
                                              ? true
                                              : false
                                          }
                                        />
                                        <p className="secondText">S</p>
                                        {configurekeyform.errors.BlinkRepeat &&
                                          configurekeyform.touched.BlinkRepeat ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .BlinkRepeat
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>ResetRetries</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="ResetRetries"
                                          type="text"
                                          className="form-control"
                                          id="ResetRetries"
                                          placeholder="1000"
                                          maxLength={5}
                                          value={
                                            configurekeyform.values.ResetRetries ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            if (
                                              /[0-9\b]/.test(
                                                e.nativeEvent.data
                                              ) ||
                                              e.nativeEvent.data == null
                                            ) {
                                              configurekeyform.handleChange(e);
                                            }
                                          }}
                                          onKeyDown={
                                            configurekeyform.handleBlur
                                          }
                                          onKeyUp={(e) => {
                                            inpNum(e);
                                          }}
                                          onKeyPress={inpNum}
                                          invalid={
                                            configurekeyform.errors
                                              .ResetRetries &&
                                              configurekeyform.touched
                                                .ResetRetries
                                              ? true
                                              : false
                                          }
                                        />
                                        {configurekeyform.errors.ResetRetries &&
                                          configurekeyform.touched
                                            .ResetRetries ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .ResetRetries
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="readOnly">
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>ConnectionTimeOut</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="ConnectionTimeOut"
                                          type="text"
                                          className="form-control"
                                          id="ConnectionTimeOut"
                                          placeholder="1000"
                                          maxLength={5}
                                          value={
                                            configurekeyform.values.ConnectionTimeOut ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            if (
                                              /[0-9\b]/.test(
                                                e.nativeEvent.data
                                              ) ||
                                              e.nativeEvent.data == null
                                            ) {
                                              configurekeyform.handleChange(e);
                                            }
                                          }}
                                          onKeyDown={
                                            configurekeyform.handleBlur
                                          }
                                          onKeyUp={(e) => {
                                            inpNum(e);
                                          }}
                                          onKeyPress={inpNum}
                                          invalid={
                                            configurekeyform.errors
                                              .ConnectionTimeOut &&
                                              configurekeyform.touched
                                                .ConnectionTimeOut
                                              ? true
                                              : false
                                          }
                                        />
                                        <p className="secondText">S</p>
                                        {configurekeyform.errors
                                          .ConnectionTimeOut &&
                                          configurekeyform.touched
                                            .ConnectionTimeOut ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .ConnectionTimeOut
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm={6}>
                                      <div className="slotKey">
                                        <p>HeartbeatInterval</p>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="center-input">
                                        <Input
                                          name="HeartbeatInterval"
                                          type="text"
                                          className="form-control"
                                          id="HeartbeatInterval"
                                          placeholder="1000"
                                          maxLength={5}
                                          value={
                                            configurekeyform.values.HeartbeatInterval ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            if (
                                              /[0-9\b]/.test(
                                                e.nativeEvent.data
                                              ) ||
                                              e.nativeEvent.data == null
                                            ) {
                                              configurekeyform.handleChange(e);
                                            }
                                          }}
                                          onKeyDown={
                                            configurekeyform.handleBlur
                                          }
                                          onKeyUp={(e) => {
                                            inpNum(e);
                                          }}
                                          onKeyPress={inpNum}
                                          invalid={
                                            configurekeyform.errors
                                              .HeartbeatInterval &&
                                              configurekeyform.touched
                                                .HeartbeatInterval
                                              ? true
                                              : false
                                          }
                                        />
                                        <p className="secondText">S</p>
                                        {configurekeyform.errors
                                          .HeartbeatInterval &&
                                          configurekeyform.touched
                                            .HeartbeatInterval ? (
                                          <FormFeedback type="invalid">
                                            {
                                              configurekeyform.errors
                                                .HeartbeatInterval
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>

                                  {customLabelValueList.length > 0 && customLabelValueList?.map((item, index) => (
                                    // <Row key={index} className={newLabelValue.length !== index ? "customInputRowStyle" : "customInputRowStyle disable"}>
                                    <Row key={index} className={newLabelValue.length <= index ? "customInputRowStyle" : "customInputRowStyle disable"}>
                                      <Col>
                                        <div className="center-input">
                                          <Input
                                            name="CustomKeyInput"
                                            type="text"
                                            className="form-control"
                                            id="CustomKeyInput"
                                            placeholder="Enter custom key "
                                            // value={
                                            //   configurekeyform.values.CustomKeyInput ||
                                            //   ""
                                            // }
                                            value={item?.label}
                                            onChange={(e) => {
                                              if (
                                                /^[ a-zA-Z0-9\.\-\,]+$/i.test(
                                                  e.nativeEvent.data
                                                ) ||
                                                e.nativeEvent.data == null
                                              ) {
                                                updateRowForLabelValueList(e.target.value, item.value, index)
                                                configurekeyform.handleChange(e);
                                              }
                                            }}
                                            onKeyDown={
                                              configurekeyform.handleBlur
                                            }

                                            invalid={
                                              configurekeyform.errors
                                                .CustomKeyInput &&
                                                configurekeyform.touched
                                                  .CustomKeyInput
                                                ? true
                                                : false
                                            }
                                          />
                                          {/* <p className="secondText">S</p> */}
                                          {configurekeyform.errors
                                            .CustomKeyInput &&
                                            configurekeyform.touched
                                              .CustomKeyInput ? (
                                            <FormFeedback type="invalid">
                                              {
                                                configurekeyform.errors
                                                  .CustomKeyInput
                                              }
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col>
                                        <div className="center-input">
                                          <Input
                                            name="CustomValueInput"
                                            type="text"
                                            className="form-control"
                                            id="CustomValueInput"
                                            placeholder="Enter custom value"
                                            maxLength={5}
                                            // value={
                                            //   configurekeyform.values.CustomValueInput ||
                                            //   ""
                                            // }
                                            value={item?.value}
                                            onChange={(e) => {
                                              if (
                                                /[0-9\b]/.test(
                                                  e.nativeEvent.data
                                                ) ||
                                                e.nativeEvent.data == null
                                              ) {
                                                updateRowForLabelValueList(item.label, e.target.value, index)
                                                configurekeyform.handleChange(e);
                                              }
                                            }}
                                            onKeyDown={
                                              configurekeyform.handleBlur
                                            }
                                            onKeyUp={(e) => {
                                              inpNum(e);
                                            }}
                                            onKeyPress={inpNum}
                                            invalid={
                                              configurekeyform.errors
                                                .CustomValueInput &&
                                                configurekeyform.touched
                                                  .CustomValueInput
                                                ? true
                                                : false
                                            }
                                          />
                                          {/* <p className="secondText">S</p> */}
                                          {configurekeyform.errors
                                            .CustomValueInput &&
                                            configurekeyform.touched
                                              .CustomValueInput ? (
                                            <FormFeedback type="invalid">
                                              {
                                                configurekeyform.errors
                                                  .CustomValueInput
                                              }
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col className='iconRemoveButtonStyle'>
                                        <div
                                          onClick={() => removeRowForLabelValueList(index)}
                                          className="text-danger d-inline-block remove-item-btn removeAddTriff">
                                          <i className="bx bx-minus-circle "></i>
                                        </div>
                                      </Col>
                                    </Row>
                                  ))}
                                  <Row className='addMoreBtnRow'>
                                    <Button onClick={addRowForLabelValueList} color="success" className="btn btn-add-more text-right">
                                      Add more
                                    </Button>
                                  </Row>
                                </div>
                              </Form>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            <ContentLoader />
          )}
        </Container>
      </div >
      <DeleteModal
        show={deleteModalUser}
        title="Are you sure?"
        description="Are you sure you want to delete this record"
        onDeleteClick={handleDeleteContact}
        onCloseClick={() => setdeleteModalUser(false)}
      />
      <DeleteModal
        show={deleteModal}
        title="Are you sure?"
        description="Are you sure you want to delete this record"
        onDeleteClick={handleDeleteContact}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DisableModal
        show={disabledModal}
        title="Are you sure?"
        subtitle="Are you sure you want to disable this station?"
        btnActionText="Yes, Disable it!"
        btncancelText="Cancel"
        onActionClick={handleDisableContact}
        onCloseClick={() => setDisabledModal(false)}
      />
      <DisableModal
        show={enableModal}
        title="Are you sure?"
        subtitle="Are you sure you want to enable this station?"
        btnActionText="Yes, enable it!"
        btncancelText="Cancel"
        onActionClick={handleEnableContact}
        onCloseClick={() => setEnableModal(false)}
      />

      <Modal
        className="qrModel"
        isOpen={qrModel}
        toggle={() => {
          setQrModel(false);
        }}
        centered={true}
      >
        <ModalHeader>
          <p>QR Code</p>
          <i
            className="bx bx-x"
            onClick={() => {
              setQrModel(false);
            }}
          />
        </ModalHeader>
        <ModalBody className="">
          <div className="qrMain">
            <QRCode
              id="qr-gen"
              value={qrtempValue}
              size={270}
              level={"H"}
              includeMargin={true}
            />

            <div className="bottom d-flex justify-content-center mt-1 mb-3">
              <Button
                className=""
                onClick={() => {
                  setQrModel(false);
                  downloadQRCode();
                }}
              >
                Download
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>



      <Modal
        className="deleteModal"
        isOpen={deleteReservationModel}
        toggle={() => {
          setDeleteReservationModel(false);
        }}
        centered={true}
      >
        <ModalHeader>
          <p>Slot Cancellation</p>
          {/* <i
            className="bx bx-x"
            onClick={() => {
              setDeleteReservationModel(false);
            }}
          /> */}
        </ModalHeader>
        <ModalBody className="py-3 px-5">
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#FFBC0A,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              {/* <h4>Are you sure?</h4> */}
              <p className="text-muted mx-4 mb-0">
                Cancellation reserved slot cost Summary
                {/* Are you sure you want to cancel reservation for <strong>{deleteReservationData?.name}</strong> at <strong>{deleteReservationData?.time}</strong> */}
              </p>

            </div>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <Row className='rightpartRowInner'>
                <Col lg={6}>
                  <div className='box d-flex align-items-start justify-content-start flex-column'>
                    <div className='header d-flex align-items-center justify-content-center mb-4'>
                      <span className='txt'>Reservation Charges</span>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className='box d-flex align-items-start justify-content-start flex-column'>
                    <div className='header d-flex align-items-center justify-content-center mb-4'>
                      <span className='txt'>₹{deleteReservationData.rCharges}</span>
                    </div>
                  </div>
                </Col>

              </Row>
              <Row className='rightpartRowInner'>
                <Col lg={6}>
                  <div className='box d-flex align-items-start justify-content-start flex-column'>
                    <div className='header d-flex align-items-center justify-content-center mb-4'>
                      <span className='txt'>Cancellation Charges</span>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className='box d-flex align-items-start justify-content-start flex-column'>
                    <div className='header d-flex align-items-center justify-content-center mb-4'>
                      <span className='txt'>-₹{deleteReservationData.cCharges}</span>
                    </div>
                  </div>
                </Col>

              </Row>
              <Row className='rightpartRowInner'>
                <Col lg={6}>
                  <div className='box d-flex align-items-start justify-content-start flex-column'>
                    <div className='header d-flex align-items-center justify-content-center mb-4'>
                      <span className='txt'>Refund</span>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className='box d-flex align-items-start justify-content-start flex-column'>
                    <div className='header d-flex align-items-center justify-content-center mb-4'>
                      <span className='txt'>₹{(deleteReservationData.rCharges - deleteReservationData.cCharges)}</span>
                    </div>
                  </div>
                </Col>

              </Row>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"

            >
              Cancel
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => {
                dispatch(deleteReservation(`?reservationId=${deleteReservationData?.reservationId}&cancelUser=${deleteReservationData?.userId}&refundAmount=${deleteReservationData.rCharges - deleteReservationData.cCharges}&transId=${parseInt(deleteReservationData?.transId)}`));
                setDeleteReservationModel(false)
                // dispatch(getReservation(`?stationId=${state?.state?.stationId}&date=${filterDateReserve}&connectorId=${orderTab}`));

              }}

            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
        {/* <ModalBody className="">
          <div className="qrMain">
            <QRCode
              id="qr-gen"
              value={qrtempValue}
              size={270}
              level={"H"}
              includeMargin={true}
            />

            <div className="bottom d-flex justify-content-center mt-1 mb-3">
              
              <Button
                className=""
                onClick={() => {
                  setQrModel(false);
                  downloadQRCode();
                }}
              >
                Download
              </Button>
            </div>
          </div>
        </ModalBody> */}
      </Modal>





      {/* firmware update modal */}
      <Modal
        className="updateVersionModal"
        isOpen={updateModal}
        toggle={() => {
          setUpdateModal(false);
        }}
        centered={true}
      >
        <ModalHeader>
          <p>Firmware Version</p>
          <i
            className="bx bx-x"
            onClick={() => {
              setUpdateModal(false);
            }}
          />
        </ModalHeader>
        <ModalBody className="">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              updateForm.handleSubmit();
              return false;
            }}
            action="#"
          >
            <div className="main">
              <div className="subMian mb-3">
                <Input
                  name="version"
                  type="text"
                  className="form-control"
                  id="version"
                  disabled={true}
                  placeholder="http://"
                  onChange={updateForm.handleChange}
                  onKeyUp={updateForm.handleBlur}
                  value={
                    "http://113.20.17.179:8080/fota.bin" ||
                    updateForm.values.version ||
                    ""
                  }
                  invalid={
                    // updateForm.errors.version && updateForm.touched.version ? true : false
                    updateForm.errors.version ? true : false
                  }
                />
                {updateForm.errors.version && updateForm.touched.version ? (
                  <FormFeedback type="invalid">
                    {updateForm.errors.version}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="bottom d-flex justify-content-end mt-1">
                <Button
                  // type='submit' disabled={!(updateForm.isValid && updateForm.dirty)}>
                  type="submit"
                  disabled={!updateForm.isValid}
                >
                  Update
                </Button>
                <Button
                  type="button"
                  className="me-2"
                  onClick={() => {
                    setUpdateModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <Modal
        className="opsDelete"
        isOpen={opsDelete}
        toggle={() => {
          setOpsDelete(false);
        }}
        centered={true}
      >
        <ModalBody className="">
          <div className="main">
            <div className="iMain">
              <img
                src="https://res.cloudinary.com/djyl1goby/image/upload/v1667630611/Lubi/WebApp/Static-images/image_3.svg"
                alt=""
              />
            </div>
            <div className="cContainer">
              <h1>Opps! You Can’t delete this business.</h1>
              <p>Please check the following thing to delete this business</p>
              <ul>
                <li>All stations must be free from charging.</li>
                <li>All the connector must not connected to any vehicle.</li>
              </ul>
              <div className="bottom d-flex justify-content-center mt-1 mb-3">
                <Button
                  onClick={() => {
                    setOpsDelete(false);
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        className="statusModal"
        isOpen={statusModal}
        toggle={() => {
          setStatusModal(false);
        }}
        centered={true}
      >
        <ModalHeader>
          <p></p>
          <i
            className="bx bx-x"
            onClick={() => {
              setStatusModal(false);
            }}
          />
        </ModalHeader>
        <ModalBody className="">
          <div className="main">
            <div className="dayRow">
              {statusDay.map((i, index) => {
                return (
                  <div
                    key={index}
                    className={i.isActive ? "dayBox active" : "dayBox"}
                  >
                    <p>{i.day}</p>
                    <p>{i.dNumber}</p>
                  </div>
                );
              })}
            </div>
            <div className="durationTime">
              {statusTime.map((i, index) => {
                return (
                  <div
                    key={index}
                    className={i.isBooked ? "timeBox booked" : "timeBox"}
                  >
                    <p>{i.dTime}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        className="viewRfidModal"
        isOpen={viewRfidModal}
        toggle={() => {
          setViewRfidModal(false);
        }}
        centered={true}
      >
        <ModalHeader>
          <p>View Assigned RFID</p>
          <i
            className="bx bx-x"
            onClick={() => {
              setViewRfidModal(false);
            }}
          />
        </ModalHeader>
        <ModalBody className="">
          <h5 className="header_h">RFID Number</h5>
          <div className="main">
            <div style={{ maxHeight: "300px", overflowY: "auto" }}></div>
            {rowNumber.map((value, index) => {
              return (
                <div className="rRow" key={index}>
                  {value.number.map((item, index) => {
                    return (
                      <div key={index} className="rCell">
                        <p>{item.value}</p>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment >
  );
};

export default ViewStation;
