import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
    GET_CONNECTOR_TYPES,
    GET_MODEL_TYPES,
    ADD_EVSE, LIST_ALL_EVSE,
    DETAIL_EVSE,
    DETAIL_EVSE_CONNECTOR,
    GET_CONNECTOR_LIST,
    GET_CONNECTORS_ICONS,
    ADD_CONNECTORS_TYPE_DATA,
    EDIT_CONNECTOR_TYPE,
    DETAIL_CONNECTOR_TYPE,
    EDIT_EVSE_MODEL,
    DETAIL_EVSE_SINGLE_CONNECTOR,
    GET_CONNECTORS_DETAILS,
    EDIT_EVSE_CONNECTER,
    TOGGLE_EVSE,
    DELET_EVSE,
    TOGGLE_CONNECTOR,
    DELET_CONNECTOR
} from "./actionTypes";

import {
    connectorTypesSuccess,
    modelTypesSuccess,
    listAllEVSEsuccess,
    DetailEvseSuccess,
    DetailEvseConnectorSuccess,
    connecterIconSuccess,
    getConnectorListSuccess,
    editconnecterSuccess,
    DetailconnecterSuccess,
    DetailEvseSingleConnectorSuccess
} from "../actions"

import {
    getModelTypes,
    getConnectorTypes,
    getConnectorIcons,
    addEVSE,
    listAllEvse,
    detailEvse,
    detailEvseConnector,
    getConnectorType,
    addconnecterType,
    editconnecterType,
    detailConnecterType,
    editEvseModel,
    detailEvseSingleConnector,
    getConnecterDetails,
    editEvseConnecter,
    toggleEvseModel,
    deleteEvseModel,
    deleteConnectorModel,
    toggleConnectorModel
} from "../../helpers/Auth";

import { showToast, showLoader } from "../actions";


function* connectorTypes({ payload: { data } }) {
    try {
        const response = yield call(getConnectorTypes, data)
        if (response) {
            yield put(connectorTypesSuccess(response.payload));
        }
    } catch (error) {
        
        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}

function* modelTypes({ payload: { } }) {
    try {
        const response = yield call(getModelTypes)
        if (response) {
            yield put(modelTypesSuccess(response.payload));
        }
    } catch (error) {
        
        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}

function* addEvse({ payload: { data, history } }) {
    yield put(showLoader(true));
    try {
        const response = yield call(addEVSE, data, history)
        if (response) {
            
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
            setTimeout(() => {
                history.goBack();
            }, 1000);
            yield put(showLoader(false));
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
        yield put(showLoader(false));
    }
}


function* ListAllEvse({ payload: { data } }) {
    try {
        const response = yield call(listAllEvse, data)
        if (response) {
            yield put(listAllEVSEsuccess(response.payload));
        }
    } catch (error) {
        
        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}

function* DetailsEVSE({ payload: { Id } }) {
    try {
        const response = yield call(detailEvse, Id)
        if (response) {
            yield put(DetailEvseSuccess(response.payload));
        }
    } catch (error) {
        
        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}

function* DetailEVSEconnector({ payload: { Id } }) {
    try {
        const response = yield call(detailEvseConnector, Id)
        if (response) {
            yield put(DetailEvseConnectorSuccess(response.payload));
        }
    } catch (error) {
        
        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}
function* connectorIcon({ payload: { } }) {
    try {
        const response = yield call(getConnectorIcons)
        if (response) {
            yield put(connecterIconSuccess(response.payload));
            //(response.payload);
        }
    } catch (error) {
        
        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}


function* connectorList({ payload: { data } }) {
    try {
        const response = yield call(getConnectorType, data)
        if (response) {
            yield put(getConnectorListSuccess(response.payload));
        }
    } catch (error) {
        
        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}

function* addconnecter({ payload: { data, history } }) {
    try {
        const response = yield call(addconnecterType, data)
        if (response) {
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
            setTimeout(() => {
                history.goBack();
            }, 1000);
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
    }
}

function* editconnecter({ payload: { data, ID } }) {
    try {
        const response = yield call(editconnecterType, data, ID)
        if (response) {
            
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));

        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
    }
}

function* editevsemodel({ payload: { data, ID, history } }) {
    try {
        const response = yield call(editEvseModel, data, ID)
        if (response) {
            
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
            history.goBack();

        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
    }
}

function* getDetailconnecter({ payload: { data } }) {
    try {
        const response = yield call(detailConnecterType, data)
        if (response) {
            yield put(DetailconnecterSuccess(response.payload));
        }
    } catch (error) {
        
        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}

function* DetailEVSEsingleConnector({ payload: { Id } }) {
    try {
        const response = yield call(detailEvseSingleConnector, Id)
        if (response) {
            yield put(DetailEvseSingleConnectorSuccess(response.payload));
            
        }
    } catch (error) {
        
        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}

// function* connecterDetails({ payload: { Id } }) {
//     try {
//         const response = yield call(getConnecterDetails, Id)
//         if (response) {
//             
//             yield put(connecterDetailsSuccess(response.payload));
//             
//         }
//     } catch (error) {
//         
//         // yield put(showToast({
//         //     message: error.message,
//         //     type: 'error'
//         // }));
//     }
// }

function* editevseConecter({ payload: { data, Id, history } }) {
    try {
        const response = yield call(editEvseConnecter, data, Id)
        if (response) {
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
            history.goBack();

        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
    }
}

function* toggleButtonEVSE({ payload: { data, id } }) {
    try {
        const response = yield call(toggleEvseModel, data, id)
        if (response) {
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
    }
}

function* DeletEVSE({ payload: { data, id, history } }) {
    try {
        const response = yield call(deleteEvseModel, data, id)
        if (response) {
            yield put(showToast({
                message: response?.message,
                type: 'success'
            }));
            setTimeout(() => {
                history.goBack();
            }, 1000);
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
    }
}

function* toggleConnectorButton({ payload: { data, id } }) {
    try {
        const response = yield call(toggleConnectorModel, data, id)
        if (response) {
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
    }
}

function* DeletCOnnector({ payload: { data, id } }) {
    try {
        const response = yield call(deleteConnectorModel, data, id)
        if (response) {
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
    }
}


export function* AssetMangment() {
    yield takeEvery(GET_CONNECTOR_TYPES, connectorTypes);
    yield takeEvery(GET_MODEL_TYPES, modelTypes);
    yield takeEvery(GET_MODEL_TYPES, modelTypes);
    yield takeEvery(ADD_EVSE, addEvse);
    yield takeEvery(LIST_ALL_EVSE, ListAllEvse);
    yield takeEvery(DETAIL_EVSE, DetailsEVSE);
    yield takeEvery(DETAIL_EVSE_CONNECTOR, DetailEVSEconnector);
    yield takeEvery(GET_CONNECTOR_LIST, connectorList);
    yield takeEvery(GET_CONNECTORS_ICONS, connectorIcon);
    yield takeEvery(ADD_CONNECTORS_TYPE_DATA, addconnecter);
    yield takeEvery(EDIT_CONNECTOR_TYPE, editconnecter);
    yield takeEvery(DETAIL_CONNECTOR_TYPE, getDetailconnecter);
    yield takeEvery(EDIT_EVSE_MODEL, editevsemodel);
    yield takeEvery(DETAIL_EVSE_SINGLE_CONNECTOR, DetailEVSEsingleConnector);
    yield takeEvery(EDIT_EVSE_CONNECTER, editevseConecter);
    yield takeEvery(TOGGLE_EVSE, toggleButtonEVSE);
    yield takeEvery(DELET_EVSE, DeletEVSE);
    yield takeEvery(TOGGLE_CONNECTOR, toggleConnectorButton);
    yield takeEvery(DELET_CONNECTOR, DeletCOnnector);
}

export default AssetMangment;