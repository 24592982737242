import { takeEvery, fork, put, all, call, actionChannel, take } from "redux-saga/effects";

import { ALL_BUSINESS_LIST, ADD_NEW_BUSINESS, GET_BUSINESS_DETAIL, EDIT_BUSINESS, GET_MATCH_EMAIL, BUSINESS_RESEND_INVITE, GET_BUSINESS_LOGO, DELETE_BUSINESS, ACTIVATE_DEACTIVATE_BUSINESS, BUSINESS_LIST_FAILURE } from "./actionTypes";

import { BusinessListFailure, BusinessListSuccess, businessDetailSuccess, getMatchEmailSuccess, deleteBusinessAction, activateDeactivateBusinessAction, refreshToken } from "../actions"

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
    Business,
    newBusinessAdd,
    GetBusinessDetail,
    EditBusiness,
    getMatchedEmail,
    businessInviteResend, RefreshToken,
    activateDeactivateBusiness,
    deleteBusiness,
} from "../../helpers/Auth";
import { showToast, showLoader } from "../actions";
import { useHistory, useLocation } from 'react-router-dom';
import { REFRESH_TOKEN } from "../auth/login/actionTypes";

function* businessList(data) {
    try {
        const response = yield call(Business, data?.payload?.data)
        if (response) {
            yield put(BusinessListSuccess(response.payload));
        }
    } catch (error) {

        // yield put(refreshToken());

        yield put(BusinessListFailure());

        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}




function* addingBusiness({ payload: { user, history } }) {
    yield put(showLoader(true));
    try {
        const response = yield call(newBusinessAdd, {
            businessName: user.businessName,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            countryCode: user.countryCode,
            contactNumber: user.contactNumber,
            website: user.website,
            businessLogo: user.businessLogo,
            primaryColor: user.primaryColor,
            secondaryColor: user.secondaryColor
        });
        if (response) {
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
            setTimeout(() => {
                history.goBack();
                // put(showLoader(false));
            }, 1000);
            // yield put(showLoader(false));
        }
    } catch (e) {
        yield put(showLoader(false));
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))

    }
}

function* businessDetail({ payload: { history, businessId } }) {
    try {
        const response = yield call(GetBusinessDetail, businessId);
        if (response) {
            yield put(businessDetailSuccess(response?.payload));

        }
    } catch (error) {

        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}

function* editingBusiness({ payload: { user, history, businessId } }) {
    yield put(showLoader(true));

    try {
        const response = yield call(EditBusiness, {
            businessName: user.businessName,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            countryCode: user.countryCode,
            contactNumber: user.contactNumber,
            website: user.website,
            businessLogo: user.businessLogo,
            primaryColor: user.primaryColor,
            secondaryColor: user.secondaryColor
        }, businessId);
        if (response) {
            yield put(showLoader(false));
            yield put(showToast({

                message: response.message,
                type: 'success'
            }));
            if (JSON.parse(localStorage.getItem('users')).userRole === 'BusinessOwner') {
                const innerresponse = yield call(Business)
                if (innerresponse.status < 400) {

                    if (innerresponse.payload.length === 1) {
                        const response2 = yield call(GetBusinessDetail, innerresponse.payload[0].businessId);
                        if (response2.status < 400) {
                            window.localStorage.setItem('businessLogo', response2.payload[0].businessLogo)
                            window.localStorage.setItem('businessName', response2.payload[0].businessName)
                            yield put(businessDetailSuccess(response2.payload[0]));
                        }
                    }
                }
            }
            history.push('/businesses');
            // setTimeout(() => {
            //     history.goBack();
            // }, 1000);
        }
    } catch (e) {

        yield put(showLoader(false));
        yield put(showToast({
            message: e?.response?.data?.message + 'gfdgfdg',  //'Account verified successfully.',
            type: 'error'
        }))
    }
}

function* getMatchingEmail({ payload: { role, email } }) {
    try {
        const response = yield call(getMatchedEmail, role, email);
        if (response) {
            yield put(getMatchEmailSuccess(response?.payload));

        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
    }
}

function* resendBusinessInvite({ payload: { data } }) {
    try {
        const response = yield call(businessInviteResend, data);
        if (response) {
            yield put(showToast({
                message: response.message,
                type: 'success'
            }));
        }
    } catch (e) {
        yield put(showToast({
            message: e?.response?.data?.message,  //'Account verified successfully.',
            type: 'error'
        }))
    }
}

function* setBusinessLogo() {
    try {
        const response = yield call(Business, '?id=')
        if (response.status < 400) {

            if (response.payload.length === 1) {
                const response2 = yield call(GetBusinessDetail, response.payload[0].businessId);
                if (response2.status < 400) {
                    window.localStorage.setItem('businessLogo', response2.payload[0].businessLogo)
                    window.localStorage.setItem('businessName', response2.payload[0].businessName)
                    yield put(businessDetailSuccess(response2.payload[0]));
                }
            }
        }
    } catch (error) {

        // yield put(showToast({
        //     message: error.message,
        //     type: 'error'
        // }));
    }
}

function* deleteBusinessFunction(payload) {
    try {
        const response = yield call(deleteBusiness, payload);
        if (response) {
            yield put(showToast({
                message: response?.message,
                type: "success",
            }));
        }
    } catch (error) {

        yield put(showToast({
            message: error?.response?.data?.message,
            type: 'error'
        }));
    }
}
function* activateDeactivateBusinessFunction(payload) {
    try {

        const response = yield call(activateDeactivateBusiness, payload);
        if (response) {
            yield put(showToast({
                message: response?.message,
                type: "success",
            }));
        }
    } catch (error) {

        yield put(showToast({
            message: error?.response?.data?.message,
            type: 'error'
        }));
    }
}



export function* listAllBusiness() {

    yield takeEvery(DELETE_BUSINESS, deleteBusinessFunction);
    yield takeEvery(ACTIVATE_DEACTIVATE_BUSINESS, activateDeactivateBusinessFunction);
    yield takeEvery(ALL_BUSINESS_LIST, businessList);
    yield takeEvery(ADD_NEW_BUSINESS, addingBusiness);
    yield takeEvery(GET_BUSINESS_DETAIL, businessDetail);
    yield takeEvery(EDIT_BUSINESS, editingBusiness);
    yield takeEvery(GET_MATCH_EMAIL, getMatchingEmail);
    yield takeEvery(BUSINESS_RESEND_INVITE, resendBusinessInvite);
    yield takeEvery(GET_BUSINESS_LOGO, setBusinessLogo);
}

export default listAllBusiness;